import { type IAppConfig } from './models/IAppConfig';

let appConfig: IAppConfig;

export const initConfig = (config: IAppConfig): void => {
  appConfig = config;
};

export const setConfig = (key: string, value: any): void => {
  // @ts-expect-error  error: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'IAppConfig'.
  appConfig[key] = value;
};

export const getConfig = (): IAppConfig => appConfig;
