import React from "react";
import styled from "styled-components";
import scssVar from "../../../../styles/variables.module.scss";
interface IOwnProps {
  content:string;
  source?:string;
 }
const TermModalContent: React.FC<IOwnProps> = ({source , content}:IOwnProps) => {
  return (
    <StyleTermOfUse source={source}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
    </StyleTermOfUse>
  );
};


const StyleTermOfUse = styled.div<{
  source?: string;
}>`
*{
  font-family: ${scssVar.fontInterstate} !important;
  font-size: ${(props) => ((props.source) ? '10px' : '12px')}  !important;
  color: ${(props) => ((props.source) ? scssVar.alternateblack : 'rgb(196, 196, 205)')};
}

.se-modal-body{
  position:relative !important;
  top:-25px !important;
}

 .term-modal .center_bold_text {
  font-weight: bold;
  text-align: center;
  display: flex;
  width: 100% !important;;
  justify-content: center !important;
  display : none; !important
}
p {
  line-height: 15px;
  font-weight: 100;
  padding: 3px;
  margin: 3px;
 }
 h4 {
  line-height: 20px;
  color: rgb(246, 246, 250) !important;
 }
  .text-color {
    & a {
      text-decoration: none;
      color: #b14891 !!important;

    }
  }
  strong {
   }
  .m-b-15 {
    margin-bottom: -15px;
  }
  .term-modal {
    .motif-h4,
    .motif-h5 {
      font-family: ${scssVar.fontInterstate};
      font-size: 14px;
      line-height: 20px;
      text-transform: none;
      color: ${scssVar.gray2} !important;
      font-weight: bold;
    }
    .motif-h6 {
      font-family: ${scssVar.fontInterstate};
      font-size: 12px;
      line-height: 20px;
      color: ${scssVar.gray2} !important;
      font-weight: lighter;
    }

    .terms-outer-div{
      display: flex;
      flex-direction: row !important;
      justify-content: flex-start;
      align-items: baseline;
      .terms-sr-number{
        min-width:30px;
        width: 30px;
        font-family: ${scssVar.fontInterstate};
        font-size: 12px;
        line-height: 20px;
        text-transform: none;
        color: ${scssVar.gray2} !important;
      }
      .terms-sr-number-main{
        min-width:30px;
        width: 30px;
        font-family: ${scssVar.fontInterstate};
        font-size: 14px;
        line-height: 20px;
        text-transform: none;
        color: ${scssVar.gray2} !important;
        font-weight: bold;
      }
    }
    .terms-sub-child{
      display: flex;
      flex-direction: column !important;
    }
    .center_bold_text{
      font-weight: bold;
      text-align: center;
  
    }
    .text-color {
    }
  }
`;


export default TermModalContent;
