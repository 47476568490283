import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { IQuery, RESPONSE_BY } from '../../models/IQueryType';
import SendMessage from '../openAIChat/SendMessage';
import ChatReply from '../openAIChat/ChatReply';
import InputBox from '../openAIChat/InputBox';
import LABELS from '../../ui-constants/en.json';
import { useDispatch, useSelector } from 'react-redux';
import { HttpStatusCode } from '../../ui-constants/base/enum';
import { IStore } from '../../store';
import {
  setChatHistory,
  setChatSummary,
  setChunkLoading,
  setWhoelChatTable,
  toggleLoader,
  setChatErrors,
  clearDownloadFileMessage,
  setChatTable,
  setDownloadFileMessageWord,
  setChatMessageHistory,
  regenerateChatSummary,
  setAnySourceSelected,
  setLastDeletedThreadID,
  saveUserChatThreadHistory
} from '../../store/actions/userChatActions';
import LinkButton from '../LinkButton/LinkButton';
import { downloadImg } from '../../commonImage';
import exportToWord from '../openAIChat/utils/exportToWord';
import './PublicChatTile.scss';
import {
  getChatThreadConversations,
  getEmbroynicQueryResponse,
  saveDownloadChatActivity,
  saveUserChatHistory
} from '../../services/chatQuery.service';
import { selectedSourceValues, setRightSideBarOpenClose, setSideBarOpenClose,triggerDnfOpenAiSearch } from '../../store/actions/commonActions';
import {
  getComputedRequestObject,
  getErrorLabel,
  handleSessionID,
  handleUserChatThreadHistory,
  IsJSONResponse,
  setThreadsUnSelected
} from '../../utils/commonUtils';
import { DataSourceTypes } from '../../models/IDataSourceTypes';
import { publicSources } from '../../label.constant';
import { initializeSession, setCurrentSessionId } from '../../services/session.service';
import { MotifToast } from '@ey-xd/motif-react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import SuggestionText from '../common/errorPages/ErrorSuggestion';

type IChatcontainerPRops = {
  chatQueryApi?: string;
};
const ChatContainerTile = ({ chatQueryApi = '' }: IChatcontainerPRops) => {
  const [seQuery, setSeQuery] = useState<IQuery[]>([]);
  const [queryText, setQueryText] = useState<string>('');
  const [chatBoxHeight, setChatBoxHeight] = useState(window.innerHeight / 1.4);
  const [minScrollHeight, setMinScrollHeight] = useState(310);
  const refInput = useRef<HTMLTextAreaElement>(null);
  const refToScroll = useRef<HTMLDivElement>(null);
  const { isClearLoading, chatDataSummmary, isLoading, isSideBarOpen, chunkLoading } = useSelector(
    (state: any) => state.userChat
  );
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const { isRightSideBarOpen, anySourceSelected } = useSelector((state: IStore) => state.common);
  const userChatThreads = useSelector((store: IStore) => store.userChat.chatThreadHistory);
  const [currentUserChatThreads, setCurrentUserChatThreads] = useState(userChatThreads);
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);
  const checkedCheckboxes = useSelector((state: any) => state.common?.checkedCheckboxes);
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const validURLinputValue = useSelector((state: IStore) => state?.common?.validURLinputValue);
  const lastSavedThreadID = useSelector((state: IStore) => state?.userChat?.lastSavedThreadID);
  const isSidebarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
  const [chatLoadCompleted, setChatLoadCompleted] = useState(false);
  const [firstResponseTime, setFirstResponseTime] = useState(0);
  const [totalResponseTime, setTotalResponseTime] = useState<number|null>();
  const [regeneratedMessageId, setRegeneratedMessageId] = useState(0);
  const [isChatThreadLoaded, setIsChatThreadLoaded] = useState(true);
  const lastDeletedThreadID = useSelector((state: IStore) => state?.userChat?.lastDeletedThreadID);
  const [showDownloadInitiated, setsShowDownloadInitiated] = useState(false);
  const [controller, setController] = useState<any>(null);
  const [isCompletelyAbortedResposne, setIsCompletelyAbortedResposne] = useState<boolean>(false);
  const [isParitallyAbortedResponse, setIsParitallyAbortedResponse] = useState<boolean>(false);
  const IsDnfOpenAiSearchTriggered = useSelector((state: IStore) => state.common?.triggerDnfOpenAiSearchValue);
  const [showDnfSuggestion, setShowDnfSuggestionState] = useState(false);
  const [currentQuestion, setsetCurrentQuestionState] =useState<string>('');
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (IsDnfOpenAiSearchTriggered)
    {
        setQueryText(currentQuestion??'');
        handleSendMessage({} as React.MouseEvent<HTMLElement>, currentQuestion);
        setQueryText('');
        dispatch(triggerDnfOpenAiSearch(false));
        setShowDnfSuggestionState(false);
    }
  });

  let chunkLoader = true;
  const MATCH_TO = '}}]';
  let replyServerError: any = [];
  const timeoutError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: LABELS.chat_reply.SE_TOKEN_ERROR_MSG,
        content: [],
        responseType: 'error',
        sources: ['Embryonic']
      }
    }
  ];
  useEffect(() => {
    setSeQuery(() => chatDataSummmary?.table);
  }, [chatDataSummmary, chunkLoading]);

  const updateFirstResponseTime = (firstByteTime: any, startTime: number) => {
    if (firstByteTime === null) {
      firstByteTime = performance.now();
      setFirstResponseTime((firstByteTime - startTime) / 1000);
    }
  }

  const updateTotalResponseTime = (startTime: number, isStaticResponse: boolean = false) => {
    if (isStaticResponse) {
      setTotalResponseTime(null);
      return;
    } else {
      const endTime = performance.now(); // Record the end time
      const duration = (endTime - startTime) / 1000; // Calculate the duration
      setTotalResponseTime(duration); // Update state with the response time
    }
  };

  const sendChatQuery = async (summary: string) => {
    setsetCurrentQuestionState(summary)
    scrollToBottom();
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    let chunks = '';
    let sources: string[] = [];
    const controller = new AbortController();
    setController(controller);
    if (checkedCheckboxes.includes(publicSources[0])) {
      sources.push('emb');
    }
    if (checkedCheckboxes.includes(publicSources[1])) {
      sources.push('openai');
    }
    handleSessionID(selectedChatThread);
    const startTime = performance.now();
    let firstByteTime : any = null;
    try {
      const response: any = await getEmbroynicQueryResponse(summary, userData, sources, controller);
      scrollToBottom();
      const { status, statusText } = response;
      if (controller.signal.aborted) {
        const stopGenerationErrorMsg = [{
          [RESPONSE_BY.STRATEGY_EDGE]: {
            summary: LABELS.chat_reply.STOP_GENERATION_MSG,
            content: [],
            responseType: 'stop_generation',
            sources: [],
            isCompletelyAbortedResposne: true,
            isParitallyAbortedResponse: false
          }
        }];
        dispatch(setChatErrors([...stopGenerationErrorMsg]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        setChatLoadCompleted(true);
      }
      else if (
        status === HttpStatusCode.InternalServerError ||
        status === HttpStatusCode.TimedOut ||
        status === HttpStatusCode.BadRequest ||
        status === HttpStatusCode.FunctionSelectionError ||
        status === HttpStatusCode.NotFound ||
        status === HttpStatusCode.NotAcceptable||
        statusText === LABELS.SERVER_ERROR
        // Object.keys(response).length === 0
      ) {
        updateFirstResponseTime(firstByteTime, startTime);
        replyServerError = getErrorMessageSummary(status);
        dispatch(setChatErrors([...replyServerError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        setTimeout(()=>{return scrollToBottom()},100);
        setChatLoadCompleted(true);
        dispatch(triggerDnfOpenAiSearch(false));
        setShowDnfSuggestionState(false)
        if ((status === HttpStatusCode.NotFound || status === HttpStatusCode.FunctionSelectionError) && checkedCheckboxes.length == 1 && checkedCheckboxes[0] == 'Embryonic')
        {
          setShowDnfSuggestionState(true)
        }
      } else {
        updateFirstResponseTime(firstByteTime, startTime);
        const reader = response.body.getReader();
        reader
          .read()
          .then(function pump({ done, value }: any) {
            const decodedChunks = new TextDecoder().decode(value);
            chunks += decodedChunks;
            dispatch(setChunkLoading(true));
            if (chunkLoader) {
              if (chunks) {
                handleChunkSelection(chunks);
                scrollToBottom();
              } else {
                replyServerError = getErrorMessageSummary(status);
                dispatch(setChatHistory([...replyServerError]));
                updateTotalResponseTime(startTime, true);
                dispatch(setChunkLoading(false));
                dispatch(toggleLoader(false));
                chunks = '';
              }
            } else {
              dispatch(
                setChatSummary(
                  decodedChunks.replace(/Summary:|Summary|:|SummaryResponse:|Response/gi, '')
                )
              );
              scrollToBottom();
            }

            if (done) {
              dispatch(triggerDnfOpenAiSearch(false));
              setShowDnfSuggestionState(false)
              updateTotalResponseTime(startTime);
              setChatLoadCompleted(true);
              dispatch(setChunkLoading(false));
              dispatch(setWhoelChatTable(true));
              dispatch(toggleLoader(false));
              scrollToBottom();
              chunks = '';
              chunkLoader = true;
              return;
            }

            return reader.read().then(pump);
          })
          .catch((err: any) => {
            // dispatch(setChatErrors([...timeoutError]));
            updateTotalResponseTime(startTime, true);
            scrollToBottom();
            dispatch(toggleLoader(false));
            dispatch(setChunkLoading(false));
            chunks = '';
            chunkLoader = true;
          });
      }
    } catch (err) {
      if (controller.signal.aborted) {
        dispatch(setChatErrors([...timeoutError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        scrollToBottom();
      } else {
        replyServerError = getErrorMessageSummary(500);
        dispatch(setChatErrors([...replyServerError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        dispatch(setChunkLoading(false));
        setChatLoadCompleted(true);
        scrollToBottom();
        chunks = '';
        chunkLoader = true;
      }
    }
  };

  
  const getErrorMessageSummary = (status = 404) => {
    let errorMessage = getErrorLabel(status, checkedCheckboxes);
    replyServerError = [
      {
        [RESPONSE_BY.STRATEGY_EDGE]: {
          summary: errorMessage,
          content: [],
          responseType: 'error',
          sources: ['Embryonic']
        }
      }
    ];
    return replyServerError;
  };

  const handleChunkSelection = useCallback((data: string) => {
    if (
      Array.isArray(findIndexandExtractSubstring(data, MATCH_TO).tableData) &&
      findIndexandExtractSubstring(data, MATCH_TO).tableData.length > 0
    ) {
      dispatch(setChatTable(findIndexandExtractSubstring(data, MATCH_TO)?.tableData));
      if (findIndexandExtractSubstring(data, MATCH_TO).initialSummary) {
        dispatch(setChatSummary(findIndexandExtractSubstring(data, MATCH_TO).initialSummary));
      }
      chunkLoader = false;
    }
  }, []);

  const findIndexandExtractSubstring = (chunks: string, s: string | any[]) => {
    let flag = false;
    for (let i = 0; i < chunks.length - s.length + 1; i++) {
      if (chunks.substring(i, s.length + i) == s) {
        const extractedTableObject = [...JSON.parse(chunks.substring(0, i + 3))];
        const restChunk =
          extractedTableObject.length > 0 &&
          chunks
            .substring(i + 3, chunks.length)
            .replace(/Summary:|Summary|SummaryResponse:|Response/gi, '')
            .trim();
        flag = true;
        const desiredData: any = {
          tableData: extractedTableObject.length > 0 ? extractedTableObject : [],
          initialSummary: restChunk && restChunk.length > 0 ? restChunk : ''
        };
        return desiredData;
      }
    }

    if (flag === false) {
      const desiredData: any = {
        tableData: [],
        initialSummary: ''
      };
      return desiredData;
    }
  };

  const handleSendMessage = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading && anySourceSelected) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        setRegeneratedMessageId(0);

        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
        if(Object.keys(selectedChatThread).length === 0 && !localStorage.isSidebarManuallyClosed){
          dispatch(setRightSideBarOpenClose(true));
        }
      }
    } else if (!anySourceSelected) {
      dispatch(setAnySourceSelected(false));
    }
  };

  const handleRegenerate = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading && anySourceSelected) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        setRegeneratedMessageId(
          chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1]?.SEResponse?.messageId ||
            regeneratedMessageId
        );
        dispatch(regenerateChatSummary([newMsg]));
        dispatch(setChatHistory([newMsg]));
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
      }
    }
  };

  const scrollToBottom = () => {
    refToScroll?.current?.scroll({
      left: 0,
      top: refToScroll.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  const handleScrollToBottom = () => {
    scrollToBottom();
  };

  const handleOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQueryText(ev?.target?.value);
  };
  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSendMessage(ev);
    }
  };

  useEffect(() => {
    if (chatLoadCompleted) saveChatConversation();
  }, [chatLoadCompleted]);

  const saveChatConversation = async () => {
    if (chatDataSummmary?.table?.length === 0) return;
    let requestObject = getComputedRequestObject(
      selectedChatThread,
      regeneratedMessageId,
      chatDataSummmary,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue,
      firstResponseTime,
      totalResponseTime,
      false,
      isCompletelyAbortedResposne,
      isParitallyAbortedResponse,
    );
    if (requestObject?.response) {
      let res = IsJSONResponse(requestObject.response)
        ? JSON.parse(requestObject.response)
        : requestObject.response;
      if (IsJSONResponse(requestObject.response) && res.suggestions) delete res.suggestions;
      requestObject.response = JSON.stringify(res);
    }
    const response: any = await saveUserChatHistory(requestObject);
    if (response && response?.threadId) {
      if (response.threadId !== selectedChatThread?.threadId) {
        setThreadsUnSelected(currentUserChatThreads);
        handleUserChatThreadHistory(
          requestObject,
          response.threadId,
          currentUserChatThreads,
          userData,
          dispatch
        );
      }
    }
    setRegeneratedMessageId(response?.messageId);
    setChatLoadCompleted(false);
  };

  const loadSelectedThreadConversations = async () => {
    setIsChatThreadLoaded(false);
    setCurrentSessionId(selectedChatThread?.sessionId);
    let currentThreadConversations = await getChatThreadConversations(selectedChatThread?.threadId);
    if (currentThreadConversations?.messages) {
      let formattedREsponse = currentThreadConversations.messages.map((message: any) => {
        let computedResponse: any = {};
        if (IsJSONResponse(message.response)) {
          computedResponse = JSON.parse(message.response);
          computedResponse.messageId = message.messageId;
        } else {
          computedResponse = {
            summary: message.response,
            Sources: [message.dataSubSource],
            messageId: message.messageId
          };
        }

        const obj = {
          [RESPONSE_BY.USER]: {
            summary: message.question
          },
          [RESPONSE_BY.STRATEGY_EDGE]: computedResponse
        };
        return obj;
      });
      dispatch(setChatMessageHistory(formattedREsponse));
      dispatch(
        selectedSourceValues({
          eyIPInterRadio: '',
          checkedCheckboxes:
            selectedChatThread.dataSource === DataSourceTypes.PublicData
              ? selectedChatThread.dataSubSource.split(',')
              : [],
          validURLinputValue: ''
        })
      );
      setIsChatThreadLoaded(true);
    }
  };

  useEffect(() => {
    if (selectedChatThread?.threadId && lastSavedThreadID !== selectedChatThread?.threadId) {
      dispatch(setSideBarOpenClose(true));
      loadSelectedThreadConversations();
    }
  }, [selectedChatThread]);

  useEffect(() => {
    setCurrentUserChatThreads(userChatThreads);
  }, [userChatThreads]);

  useEffect(() => {
    if (lastDeletedThreadID != 0) {
      const updated = userChatThreads.filter((item: any) => item.threadId !== lastDeletedThreadID);
      setCurrentUserChatThreads(updated);
      dispatch(saveUserChatThreadHistory(updated));
      dispatch(setLastDeletedThreadID(0));
    }
  }, [lastDeletedThreadID]);

  useEffect(() => {
    if (chatQueryApi?.length > 0 && Object.keys(selectedChatThread).length === 0) {
      sendChatQuery(chatQueryApi);
    }
  }, [chatQueryApi]);

  const singleCheck =
    checkedCheckboxes[0] === 'Embryonic' ? RESPONSE_BY.EMBROYNIC : RESPONSE_BY.OPENAI;
  const sources = checkedCheckboxes.length === 2 ? RESPONSE_BY.EMBAI : singleCheck;

  const handleDocDownload = async () => {
    dispatch(setDownloadFileMessageWord());
    setsShowDownloadInitiated(true);
    await exportToWord(
      seQuery,
      userData.profileImage,
      sources,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue
    );
    dispatch(clearDownloadFileMessage());
    saveDownloadChatActivity(sources);
    setTimeout(() => {
      setsShowDownloadInitiated(false);
    }, 1000);
  };

  useEffect(() => {
    refInput.current?.focus();
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [seQuery]);

  
  const handleStopGeneration = () => {
    controller.abort();
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    if(chunkLoading) {
      setIsParitallyAbortedResponse(true);
      let lastChatData = chatDataSummmary?.table[chatDataSummmary?.table?.length - 1]?.SEResponse || undefined;
      if (lastChatData) {
        if (!("isCompletelyAbortedResposne" in lastChatData) && !("isParitallyAbortedResponse" in lastChatData)) {
          lastChatData.isCompletelyAbortedResposne = false;
          lastChatData.isParitallyAbortedResponse = true;
        }
      }
      setChatLoadCompleted(true);
    } 
    else {
      setIsCompletelyAbortedResposne(true);
    }
  }

  const getResponseFlag = (SEResponse: any, flag: string) => {
    if(flag in SEResponse) {
      return SEResponse[flag];
    } else {
      return false;
    }
  };
  const breadcrumbs = [{ label: 'Home' }, { label: 'External Data', current: true }];

  return (
    <>
      <div
        className={isRightSideBarOpen ? 'chatcontainer' : 'chatcontainer__sidebarClosed'}
        // style={{
        //   display: isRightSideBarOpen ? 'flex' : 'block',
        //   flexDirection: isRightSideBarOpen ? 'column' : 'row'
        // }}
      >
        <div
          className={
            isRightSideBarOpen ? 'chatcontainer__breadcrumbOpen' : 'chatcontainer__breadcrumb'
          }>
          <Breadcrumb items={breadcrumbs} />
        </div>
        <div
          ref={refToScroll}
          className={`chatcontainer__scroll-container ${seQuery?.length > 0 && "scroll-height"}`}
          // style={{minHeight: seQuery?.length > 0 ? 160 : 0}}
          >
          {isChatThreadLoaded ? (
            seQuery?.map((chat: IQuery, ind: number) => {
              const { user, SEResponse, LLMResponse } = chat;
              return (
                <React.Fragment key={ind}>
                  {showDownloadInitiated && (
                    <MotifToast
                      className={'chatcontainer__toast-container-public'}
                      variant='info'
                      actionName=''
                      position='bottom'
                      onClose={() => {
                        setsShowDownloadInitiated(false);
                      }}>
                      <div>Downloading</div>
                    </MotifToast>
                  )}
                  {user && (
                    <SendMessage
                      key={ind}
                      message={user}
                      isNew={true}
                      userQueryWithResponse={chat}
                      loader={isLoading}
                      chatData={{ SEResponse }}
                      handleSendMessage={handleSendMessage}
                    />
                  )}

                  {SEResponse && (
                    <ChatReply
                      message={user?.summary}
                      chatData={{ SEResponse }}
                      key={ind.toString() + '__strctrd-se-chats'}
                      isNew={true}
                      isSE={true}
                      messageLoading={isLoading}
                      chunkLoading={chunkLoading}
                      isLastItem={ind === seQuery.length - 1}
                      scrollToBottom={handleScrollToBottom}
                      handleRegenerate={handleRegenerate}
                      handleSendMessage={handleSendMessage}
                      isCompletelyAbortedResposne={getResponseFlag(SEResponse, "isCompletelyAbortedResposne")}
                      isParitallyAbortedResponse={getResponseFlag(SEResponse, "isParitallyAbortedResponse")}
                      showDnfSuggestion={showDnfSuggestion}
                    />
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <div className='loader-position'>
              <div className='loader'></div>
              <p>Loading...</p>
            </div>
          )}
          {isLoading && !chunkLoading && <ChatReply loader={isLoading} isNew={true} isSE={true} />}
          <div className="stop-generation-container">
            {(isLoading || chunkLoading) && <button onClick={handleStopGeneration} className='stop-generation-button'>Stop Generating</button>}
          </div>
        </div>

        {isChatThreadLoaded && (
          <div className={'chatcontainer__input-container'}>
            <InputBox
              selectedSegment={true}
              queryText={queryText}
              refInput={refInput}
              hasQueries={seQuery?.length > 0}
              handleOnChange={handleOnChange}
              handleResetMessage={() => {}}
              handleSendMessage={handleSendMessage}
              handleKeyDown={handleKeyDown}
              disabled={isLoading}
              chatLoadCompleted={chatLoadCompleted}
            />
            <div className='chatcontainer__download-btn-box'>
              <LinkButton
                onClick={() => handleDocDownload()}
                disabled={isLoading || isClearLoading}>
                <img src={downloadImg} /> &nbsp;
                <span className='chatcontainer__download-text'>Download Chat</span>
              </LinkButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatContainerTile;
