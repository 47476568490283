import { appInsights } from "../services/appInsights.js";

class CommonService {
  logException(error, componentName, methodName, requestObject=null) {
    appInsights.trackException({
      error: error,
      properties: {
        Component: componentName,
        Method: methodName,
        ProjectName: sessionStorage.getItem("DashboardProjectName"),
        ProjectUUID: sessionStorage.getItem("DashboardProjectId"),
        UserId: sessionStorage.getItem("Email"),
        payload: requestObject
      },
    });
  }
}
export default CommonService;