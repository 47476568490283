// read more about this code here https://www.codemzy.com/blog/react-select-all-checkbox

import React, { useState } from 'react';
import './SurveySectorCheckbox.scss';
import { survey_tooltip } from '../../commonImage';
import SurveyFileDetailModal from './SurveyFileDetailModal';

// checkbox
const Checkbox = ({
  name,
  value = false,
  updateValue = () => {},
  children
}: {
  name: string;
  value?: boolean;
  updateValue?: (value: boolean, name: string) => void;
  children: React.ReactNode;
}) => {
  // handle checkbox change
  const handleChange = () => {
    updateValue(!value, name);
  };

  return (
    <label>
      <input
        type='checkbox'
        id={`${name}-checkbox`}
        name={name}
        checked={value}
        className='surveycheckbox__input'
        onChange={handleChange}
      />{' '}
      {children}
    </label>
  );
};

// checkboxes component
const Checkboxes = ({
  options = [],
  handleSelectedItems,
  handleSelectAll,
  selections = [],
  surveyDocList = []
}: any) => {
  const [showSurveyDetail, setShowSurveyDetail] = useState<any>(false);

  const handleModal = (event:any,index: number) => {
    event.stopPropagation();
    setShowSurveyDetail(index);
  };
  const onClose = () => {
    setShowSurveyDetail(false);
  };
  return (
    <div>
      <div>
        {options && options.length > 0 && (
          <Checkbox
            name='all'
            value={selections.length === options?.length}
            updateValue={handleSelectAll}>
            <span className='surveycheckbox__list-name'>{'Select All'}</span>
          </Checkbox>
        )}
      </div>
      {options.length > 0 &&
        options.map((item: any, ind: number) => {
          return (
            <>
              {item.title ? (
                <div className='surveycheckbox__mainDiv'>
                  <Checkbox
                    name={item.title}
                    value={selections.includes(item.title)}
                    updateValue={handleSelectedItems}
                    key={ind}>
                    <span className='surveycheckbox__list-name'>{item.title.split('.')[0]}
                    <img
                    onClick={(event) => handleModal(event,ind)} key={ind}
                      className='surveycheckbox__tooltip-image'
                      src={survey_tooltip}
                      alt='tooltip'></img>
                  </span>
                  </Checkbox>            
                  {showSurveyDetail !== false && (
                    <SurveyFileDetailModal
                      show={true}
                      onClose={() => onClose()}
                      sector={options[showSurveyDetail].sectors}
                      geography={options[showSurveyDetail].geography}
                      surveyView={options[showSurveyDetail].summaryoverview}
                    />
                  )}
                </div>
              ) : (
                <Checkbox
                  name={item}
                  value={selections.includes( item)}
                  updateValue={handleSelectedItems}
                  key={ind}>
                  <span className='surveycheckbox__list-name'>{item}</span>
                </Checkbox>
              )}
            </>
          );
        })}
    </div>
  );
};
export default Checkboxes;
