import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_APP_KEY,
    extensions: [reactPlugin],  
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };