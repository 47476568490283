import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import scssVar from '../../../styles/variables.module.scss';
const { lightgray, grey50 } = scssVar;
import {
  MotifButton,
  MotifDatePicker,
  MotifErrorMessage,
  MotifFormField,
  MotifInput,
  MotifLabel,
  MotifOption,
  MotifSelect
} from '@ey-xd/motif-react';
// import {MotifInput} from '@ey-/xd/motif-react/Input';
import { deleteImg, addRow } from '../../../../src/commonImage';
import { MotifIconButton } from '@ey-xd/motif-react/components/button';
import { getCurrentTimeSlotIndex } from '../../../utils/commonUtils';

interface AddMilestonesProps {
  onSave: (milestonesArray: any[]) => void;
  onValidation: (milestonesArray: any) => void;
}

const AddMilestones: React.FC<AddMilestonesProps> = ({ onSave, onValidation }) => {
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        // 15-minute intervals
        const time = new Date(0, 0, 0, hour, minute);
        const timeString = time.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });
        times.push(timeString);
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();
  const getTimeOptionsIndex = (time:String) => {
    return timeOptions?.findIndex((timeOption) => timeOption === time);
  }
  const fromTimeSlotIndex = getCurrentTimeSlotIndex(new Date()) + 1;
  const toTimeSlotIndex = fromTimeSlotIndex + 2;
  
  const [milestonesArray, setMilestonesArray] = useState(() => {
    const savedMilestones = localStorage.getItem('milestones');
    if (savedMilestones) {
      const parsedMilestones = JSON.parse(savedMilestones);
      // if (parsedMilestones.length === 0) {
      //   return [{ title: '', date: '', fromTime: '', toTime: '' }];
      // }
      return parsedMilestones;
    } else {
      return [
        {
          title: '',
          date: new Date(),
          fromTime: !timeOptions[fromTimeSlotIndex] ? timeOptions[0] : timeOptions[fromTimeSlotIndex],
          toTime: !timeOptions[toTimeSlotIndex] ? timeOptions[2] :  timeOptions[toTimeSlotIndex]
        }
      ];
    }
  });

  const [hasValidationError, setHasValidationError] = useState(false);

  useEffect(() => {
    saveMilestones();
    validateMilestones();
  }, [milestonesArray]);

  const inputChange = (index: any, event: any) => {
    const newMilestonesArray = [...milestonesArray];
    newMilestonesArray[index].title = event.target.value;
    setMilestonesArray(newMilestonesArray);
  };

  const validateMilestones = () => {
    const invalidMilestone = milestonesArray.some(
      (milestone: any) =>
        !milestone.title && (milestone.date || milestone.fromTime || milestone.toTime)
    );
    setHasValidationError(invalidMilestone);
    onValidation(!invalidMilestone);
  };

  const handleDeleteClick = (index: any) => {
    const newMilestonesArray = milestonesArray.filter((_: any, i: any) => i !== index);
    setMilestonesArray(newMilestonesArray);
  };

  const handleDateChange = (index: any, date: any) => {
    const newMilestonesArray = milestonesArray.map((milestone: any, i: any) => {
      if (i === index) {
        return { ...milestone, date: date };
      }
      return milestone;
    });
    setMilestonesArray(newMilestonesArray);
  };

  const timeStringToDate = (timeString: any) => {
    const [time, modifier] = timeString.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    return new Date(0, 0, 0, hours, minutes);
  };

  const handleFromTimeChange = (index: any, event: any) => {
    const newMilestonesArray = [...milestonesArray];
    newMilestonesArray[index].fromTime = event;
    setMilestonesArray(newMilestonesArray);
  };

  const handleToTimeChange = (index: any, event: any) => {
    const newMilestonesArray = [...milestonesArray];
    newMilestonesArray[index].toTime = event;
    setMilestonesArray(newMilestonesArray);
  };

  const onAddIconClick = () => {
    setMilestonesArray([
      ...milestonesArray,
      {
        title: '',
        date: new Date(),
        fromTime: !timeOptions[fromTimeSlotIndex] ? timeOptions[0] : timeOptions[fromTimeSlotIndex],
          toTime: !timeOptions[toTimeSlotIndex] ? timeOptions[2] :  timeOptions[toTimeSlotIndex]
      }
    ]);
  };

  const saveMilestones = () => {
    onSave(milestonesArray);
  };

  return (
    <StyledAddMilestones>
      <div className='motif-button-content'>
        <MotifButton variant='primary' onClick={onAddIconClick} className='motif-add-button'>
          <img src={addRow} alt='add row' />
          <span className='add-row'>Add row</span>
        </MotifButton>
      </div>

      {milestonesArray.map((milestone: any, index: any) => (
        <div key={index} className='milestonesClass'>
          <div className='column'>
            <div className='Label'>Title</div>
            <MotifFormField role='group' aria-labelledby='email'>
              {!milestone.title ? (
                <MotifLabel className='fieldLable'> {'Enter title'}</MotifLabel>
              ) : (
                ''
              )}
              <MotifInput
                id={`milestone-input-${index}`}
                // placeholder='Enter Title'
                value={milestone.title}
                onChange={(event) => inputChange(index, event)}
                label='Label'
                type='text'
                className='WidthTextbox'
                required
                autocomplete='off'
                // disabled = {true}
              />
              {!milestone.title && (milestone.date || milestone.fromTime || milestone.toTime) && (
                <MotifErrorMessage id='error' aria-label='Error Message'>
                  Please input title.
                </MotifErrorMessage>
              )}
            </MotifFormField>
          </div>

          <div className='column'>
            <div className='Label'>Date</div>
            <MotifFormField aria-label='Form Field Date Picker'>
              {!milestone.date ? (
                <MotifLabel className='fieldLable'> {'Select date'}</MotifLabel>
              ) : (
                ''
              )}
              <MotifDatePicker
                minDate={new Date()}
                format='dd/MM/yyyy'
                value={milestone.date}
                todayMark={true}
                className='calendar'
                // dayPlaceholder='DD'
                // monthPlaceholder='MM'
                // yearPlaceholder='YYYY'
                onChange={(date) => handleDateChange(index, date)}
              />
            </MotifFormField>
          </div>

          <div className='column'>
            <div className='Label'>From</div>
            <MotifFormField>
              {!milestone.fromTime ? (
                <MotifLabel className='fieldLable'> {'HH:MM'}</MotifLabel>
              ) : (
                ''
              )}
              <MotifSelect
                id={'milestone-from'}
                className='inputTime'
                ariaLabelledBy='select-label-from'
                onChange={(event) => handleFromTimeChange(index, event)}
                value={milestone.fromTime}
                visibleOptions={3}>
                {timeOptions.map((time, index) => (
                  <MotifOption key={index} value={time} disabled={index >= getTimeOptionsIndex(milestone.toTime)}>
                    {time}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>

          <div className='column'>
            <div className='Label'>To</div>
            <div className='inputTime'>
              <MotifFormField className='inputTime'>
                {!milestone.toTime ? (
                  <MotifLabel className='fieldLable'> {'HH:MM'}</MotifLabel>
                ) : (
                  ''
                )}
                <MotifSelect
                  id={'milestone-to'}
                  disabled={!milestone.fromTime}
                  className='inputTime'
                  ariaLabelledBy='select-label-to'
                  onChange={(event) => handleToTimeChange(index, event)}
                  value={milestone.toTime}
                  visibleOptions={3}>
                  {milestone.fromTime
                    ? timeOptions
                        .filter((time) => {
                          const fromTimeDate = timeStringToDate(milestone.fromTime);
                          const toTimeDate = timeStringToDate(time);
                          return toTimeDate > fromTimeDate;
                        })
                        .map((time, optionIndex) => (
                          <MotifOption key={optionIndex} value={time}>
                            {time}
                          </MotifOption>
                        ))
                    : timeOptions.map((time, optionIndex) => (
                        <MotifOption key={optionIndex} value={time}>
                          {time}
                        </MotifOption>
                      ))}
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>

          <div className='delete-icon'>
            <MotifIconButton onClick={() => handleDeleteClick(index)}>
              <img src={deleteImg} />
            </MotifIconButton>
          </div>
        </div>
      ))}
    </StyledAddMilestones>
  );
};

const StyledAddMilestones = styled.div`
  min-height: 380px;
  margin-left: -1%;
  .milestonesClass {
    display: flex;
    flex-direction: row;
  }
  .MotifInput::placeholder {
    visibility: visible;
  }
  .column {
    padding-left: 6px;
  }
  .delete-icon {
    margin-left: 10px;
    cursor: pointer;
    margin-top: 30px;
  }
  .add-row {
    font-family: EYInterstate !important;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .Label {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #2e2e38;
  }
  .WidthTextbox {
    width: 275px !important;
    border: 1px solid #4d4d5c;
    border-radius: 6px;
    border-width: 1px;
  }
  .inputTime {
    width: 135px !important;
    border-color: #4d4d5c !important;
    border-width: 1px;
  }
  .inputTime:focus {
    border: 3px solid #d32626 !important;
  }
  .calendar {
    width: 204px !important;
    border-width: 1px;
    border-radius: 6px;
    border: 1px solid #4d4d5c;
  }

  .react-daterange-picker__inputGroup,
  .react-date-picker__inputGroup {
    margin-top: -8px;
    margin-left: 10px;
  }
  /* .react-date-picker__calendar--open,
  .react-daterange-picker__calendar--open {
    width: 204px;
  } */

  .motif-date-picker-wrapper {
    width: 205px !important;
  }
  .loader {
    position: relative;
    bottom: 30px;
  }
  .motif-icon-button {
    width: 20px !important;
  }

  .motif-button-content {
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  .motif-add-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .fieldLable {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    color: #656579;
  }

  .motif-input,
  .motif-text-area {
    &:focus-visible:not(:disabled) {
      outline: none !important;
      /* outline-color: #2e2e38 !important; */
      --text-area--outline-color: #2e2e38 !important;
    }
  }

  .motif-date-picker-wrapper {
    .motif-date-picker {
      &.react-date-picker--open,
      &.react-daterange-picker--open {
        outline: none !important;
        /* outline-color: #2e2e38 !important; */
      }
      .react-daterange-picker__inputGroup,
      .react-date-picker__inputGroup {
        padding: 1.5rem 0.7142857143rem 0.0714285714rem 0.6428571429rem;
      }
      .react-date-picker__inputGroup__month {
        width: 10px !important;
      }
      .react-date-picker__inputGroup__day {
        width: 10px !important;
      }
      .react-date-picker__inputGroup__year {
        width: 40px !important;
      }
    }
    .react-calendar__month-view__weekdays,
    .react-calendar__month-view__weekdays__weekday {
      font-weight: 800;
    }
    .motif-date-picker {
      &.motif-date-picker-today-highlight .react-calendar__tile--now {
        background-color: #2e2e38 !important;
        color: #fff !important;
      }
    }
  }
  .react-date-picker,
  .react-date-picker *,
  .react-date-picker *:before,
  .react-date-picker *:after {
    color: #656579 !important;
  }
   /* .motif-input-component {
    border: 1px solid #4d4d5c;
    border-radius: 4px;
  } */
  .motif-input-component .motif-input:focus-visible:not(:disabled) {
    outline: none;
    border: 1px solid #4d4d5c;
  } 
  .motif-select {
    border: 1px solid #4d4d5c;
    border-radius: 4px;
  }
  .motif-option .motif-option-disabled {
    cursor: not-allowed !important;
  }
`;

export default React.memo(AddMilestones);
