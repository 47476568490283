import { EMBRYONIC_APP_URL, KC_APP_URL, SI_APP_URL, SUI_APP_URL } from "./app.constant";
import { getAppURL } from "./utils/commonUtils";

// #region "Generic Tiles content"
export const GPT_ICON = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEzIiBoZWlnaHQ9IjExNCIgdmlld0JveD0iMCAwIDExMyAxMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGlkPSJSZWN0YW5nbGUgNTAwIiBkPSJNMC4zMDA5NjggMC4xNzk4MTRMMjQuMzI5MSAwLjE3OTgxN0gzNy44NDQ5QzM3Ljg0NDkgMC4xNzk4MTcgMzkuMTA5NyAwLjI4ODkyIDM5Ljg0NzIgMC42NzgxMkM0MC41ODQ4IDEuMDY3MzIgNDEuNjIyMiAxLjY3NDc1IDQxLjYyMjIgMS42NzQ3NUwxMTEuNDMxIDcyLjE5MzdDMTExLjQzMSA3Mi4xOTM3IDExMS42NCA3Mi43MDUxIDExMS45MzIgNzMuNDMwNkMxMTIuMjY0IDc0LjI1NjUgMTEyLjQzMiA3Ni40NjU0IDExMi40MzIgNzYuNDY1NEwxMTIuNDMyIDg5Ljg3NDZMMTEyLjQzMiAxMTMuMTFDMTEyLjQzMiAxMTMuMTEgMTEyLjIyNSAxMTAuNTc0IDExMS45MzIgMTA5LjgwN0MxMTEuNjM4IDEwOS4wMzkgMTExLjQzMSAxMDguODY3IDExMS40MzEgMTA4Ljg2N0w0LjExNDY4IDEuNjc0NzVDNC4xMTQ2OCAxLjY3NDc1IDMuNTQ4NTcgMS4wNjczMiAyLjgwMzkgMC42NzgxMkMyLjA1OTIyIDAuMjg4OTIgMC4zMDA5NjggMC4xNzk4MTQgMC4zMDA5NjggMC4xNzk4MTRaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMjcwNF8yMDU3MSkiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8yNzA0XzIwNTcxIiB4MT0iLTkuMjEwMTUiIHkxPSItMjkuNDY3OCIgeDI9IjE0NS4yNDkiIHkyPSIxMjguNzAyIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiM2NDJBMEMiLz4KPHN0b3Agb2Zmc2V0PSIwLjMyODM1NCIgc3RvcC1jb2xvcj0iIzg2NDAxOSIvPgo8c3RvcCBvZmZzZXQ9IjAuNDgyODE3IiBzdG9wLWNvbG9yPSIjQjU1NDFEIi8+CjxzdG9wIG9mZnNldD0iMC42NDM3MjgiIHN0b3AtY29sb3I9IiM4QTNGMTgiLz4KPHN0b3Agb2Zmc2V0PSIwLjk4Mjc2MSIgc3RvcC1jb2xvcj0iIzdCMzcxNiIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=`;
export const IE_APPS = {
  EMBRYONIC: 'Embryonic',
  PEVC: 'Private Equity & Venture Capital',
  SUSTAINABILITY: 'Sustainability',
  OUTSIDE_WEB_DATA: 'Outside-In Web Data',
  GENERATE: 'Generate',
  BENCHMARKS: 'Benchmarks',
  VA: 'Value Accelerator',
};
export const MARKET_INTELLIGENCE_TILES = [
  {
    name: IE_APPS.EMBRYONIC,
    description:
      'Delivers external, data-driven insights sourced from consolidated databases, empowering teams to assess corporate landscapes, identify strategic acquisition targets, conduct in-depth competitor analysis, explore partnership prospects, and access detailed patent intelligence.',
    descriptionClass: 'font-14 wd-90',
    isGPTEnabled: true,
    url: getAppURL(IE_APPS.EMBRYONIC),
  }
];

export const EY_PROPRIETARY_TILES = [
  {
    name: IE_APPS.EMBRYONIC,
    description:
      'Delivers external, data-driven insights sourced from consolidated databases, empowering teams to assess corporate landscapes, identify strategic acquisition targets, conduct in-depth competitor analysis, explore partnership prospects, and access detailed patent intelligence.',
     descriptionClass: 'font-14 wd-90',
    isFavourite: true,
    url: getAppURL(IE_APPS.EMBRYONIC),
    internalUse: '',
    imageName: 'embryonic'
  },
  {
    name: IE_APPS.GENERATE,
    description:
      'Enables rapid creation of AI-driven, concise analytical reports on companies, competitors, and industry sectors, providing the latest intelligence tailored to specific research criteria, supporting agile decision-making.',
    descriptionClass: 'font-14 wd-90',
    isFavourite: false,
    url: getAppURL(IE_APPS.GENERATE),
    internalUse: '',
    imageName: 'generate'
  },
  {
    name: IE_APPS.OUTSIDE_WEB_DATA,
    description:
      'Offers expansive insights across a universe of 70 million companies, using open web data to uncover financial performance, workforce dynamics, breaking news, and regulatory filings, with interactive AI-powered analytics for deeper exploration.',
    descriptionClass: 'font-14 wd-90',
    isFavourite: true,
    url: getAppURL(IE_APPS.OUTSIDE_WEB_DATA),
    internalUse: '',
    imageName: 'secondary'
  },
  {
    name: IE_APPS.PEVC,
    description:
      'Provides targeted intelligence for the PE & VC landscape using premium data sources, facilitating asset screening, investment pipeline development, trend analysis, portfolio tracking, and exit monitoring, supporting deal origination and competitive positioning.',
    descriptionClass: 'font-14 wd-90',
    isFavourite: false,
    url: getAppURL(IE_APPS.PEVC),
    internalUse: '',
    imageName: 'pevc'
  },
  {
    name: IE_APPS.SUSTAINABILITY,
    description:
      'Specializes in ESG and sustainability intelligence, enabling analysis of sustainable investment trends across various segments, identification of transaction-focused sustainability leaders, and in-depth ESG profiling for large organizations, supporting alignment with sustainability-driven strategies.',
     descriptionClass: 'font-14 wd-90',
    isFavourite: false,
    url: getAppURL(IE_APPS.SUSTAINABILITY),
    internalUse: '',
    imageName: 'sustainability'
  },
  {
    name: IE_APPS.VA,
    description:"EY Value Accelerator is a framework to identify value creation opportunities in the enterprise...",
    descriptionClass: 'font-14 wd-90',
    isFavourite: false,
    url: getAppURL(IE_APPS.VA),
    internalUse: '',
    imageName: 'va'
  },

];

export const APP_STORE_LABEL = 'App store';
export const MARKET_INTELLIGENCE_HEADING = 'Market intelligence';
export const EY_PROPRIETARY_HEADING = 'EY Proprietary data (Internal use only)';

export const EY_GPT = 'EY GPT';

export const TILES_MOCKED_DATA = [
  {
    name: IE_APPS.EMBRYONIC,
    description:
      'Delivers external, data-driven insights sourced from consolidated databases, empowering teams to assess corporate landscapes, identify strategic acquisition targets, conduct in-depth competitor analysis, explore partnership prospects, and access detailed patent intelligence.',
    isGPTEnabled: true,
    url: getAppURL(IE_APPS.EMBRYONIC)
  },
  {
    name: IE_APPS.EMBRYONIC,
    description:
      'Delivers external, data-driven insights sourced from consolidated databases, empowering teams to assess corporate landscapes, identify strategic acquisition targets, conduct in-depth competitor analysis, explore partnership prospects, and access detailed patent intelligence.',
    isGPTEnabled: false,
    url: getAppURL(IE_APPS.EMBRYONIC),
    additionalDesc: 'This is a desktop app downloaded from EY App Store.'
  },
  {
    name: IE_APPS.EMBRYONIC,
    description:
      'Delivers external, data-driven insights sourced from consolidated databases, empowering teams to assess corporate landscapes, identify strategic acquisition targets, conduct in-depth competitor analysis, explore partnership prospects, and access detailed patent intelligence.',
    isGPTEnabled: true,
    url: getAppURL(IE_APPS.EMBRYONIC),
    internalUse: 'internal use only'
  }
];
// #endregion "Custom Defined Types"

// #region "UnAuthorized Access"
export const UNAUTHORIZED_WARNINGICON = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb24vQWxlcnQvd2FybmluZyI+CjxwYXRoIGlkPSJTaGFwZSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yIDIwLjEzNjRIMjNMMTIuNSAyTDIgMjAuMTM2NFpNMTMuNDU0NSAxNy4yNzI3SDExLjU0NTVWMTUuMzYzNkgxMy40NTQ1VjE3LjI3MjdaTTEzLjQ1NDUgMTMuNDU0NUgxMS41NDU1VjkuNjM2MzZIMTMuNDU0NVYxMy40NTQ1WiIgZmlsbD0iI0ZGNkQwMCIvPgo8L2c+Cjwvc3ZnPgo=`;
export const UNAUTHORIZED_HEADING = 'Unauthorised access';
export const UNAUTHORIZED_DESCRIPTION1 =
  'Looks like You do not have the credentials to access Competitive Edge.';
export const UNAUTHORIZED_DESCRIPTION2 =
  'Please contact the admin at the email given below to request for access.';

export const UNAUTHORIZED_LINK = 'D-UG-COE-Admin@ey.net';
// #endregion "UnAuthorized Access"
export const CHAT_HISTORY_MAX_LIMIT = 10;
export const SUBDATASOURCES = {
  EMBRYONIC: 'Embryonic',
  OPEN_AI: 'OpenAI',
  MARKET_REPORTS : 'MarketReports',
  KNOWLEDGE_CAPTURE: 'KnowledgeCapture',
  SURVEYS: 'Surveys',
  TRANSCRIPTS: 'Transcripts',
  SECTOR_INSIGHTS: 'SectorInsights',
  UPLOAD_DOCUMENT_QUERY : 'UploadDocumentQuery',
  UPLOAD_URL_QUERY : 'UploadURLQuery',
}

export const publicSources = ["Embryonic", "Open AI"];
export const internalSources = [ 'Surveys', 'Discover: Sector Insights','EY-P Strategy projects'];
export const uploadUrl = ["Upload URL and query"];
export const uploadDocument = ["Upload document and query"];

export const FileStatus ={
  PENDING: 'Pending',
  INPROGRESS: 'InProgress',
  COMPLETE: 'Complete',
  FAILED: 'Failed',
  ERROR: 'Error'
}

export const PROGRESS_BAR_STATUS = {
  DEFAULT: 'default' as 'default',
  ACTIVE: 'active' as 'active',
  COMPLETED: 'completed' as 'completed',
  WARNING: 'warning'as 'warning',
};

export const PROGRESS_BAR_STEPS = [
  { heading: 'Step 1', body: 'Team creation' },
  { heading: 'Step 2', body: 'Add members' },
  { heading: 'Step 3', body: 'Apps installed' },
  { heading: 'Step 4', body: 'Folder creation' },
];

export const LOCALSTORAGE_PROJECT_DATA = "addProjectDetails";
export const USER_TYPE = {
  CO_OWNER: 'coOwner',
  MEMBER: 'member',
  ENGAGEMENT_PARTNER: 'engagementPartner',
  ENGAGEMENT_MANAGER: 'engagementManager'
}

export const ENGAGEMENT_MANAGEMENT_GUIDANCE_URL = 'https://discovercontent.ey.net/knd24073j61gtw5l';