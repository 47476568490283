import { type AnyAction } from 'redux';

export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const ADD_LOADING_INDICATOR = 'ADD_LOADING_INDICATOR';
export const REMOVE_LOADING_INDICATOR = 'REMOVE_LOADING_INDICATOR';
export const SET_SIDEBAR_OPEN_CLOSE = 'SET_SIDEBAR_OPEN_CLOSE';
export const SET_RIGHT_SIDEBAR_OPEN_CLOSE = 'SET_RIGHT_SIDEBAR_OPEN_CLOSE';
export const SELECTED_SOURCE_VALUES = 'SELECTED_SOURCE_VALUES';
export const CHAT_SEARCH_INPUT_VALUE = 'CHAT_SEARCH_INPUT_VALUE';
export const SELECT_SOURCE_ON_NEW_CHAT = 'SELECT_SOURCE_ON_NEW_CHAT';
export const SET_SURVEY_DOC_OPEN_CLOSE = 'SET_SURVEY_DOC_OPEN_CLOSE';
export const FAVOURITE_STATUS = 'FAVOURITE_STATUS';
export const SHOW_PRIVACY_AND_TERMS_MODAL = 'SHOW_PRIVACY_AND_TERMS_MODAL';
export const SHOW_UNAUTHORIZED_ACCESS = 'SHOW_UNAUTHORIZED_ACCESS';
export const SET_BYOD_DOC_OPEN_CLOSE = 'SET_BYOD_DOC_OPEN_CLOSE';
//#region variables for handling 404 scenario
export const TRIGGER_DNF_OPENAI_SEARCH = 'TRIGGER_DNF_OPENAI_SEARCH';
// #endregion

export function setUSerEmail(email: string): AnyAction {
  return {
    type: SET_USER_EMAIL,
    email
  };
}

export function addLoadingIndicator(name: string): AnyAction {
  return {
    type: ADD_LOADING_INDICATOR,
    value: name
  };
}

export function removeLoadingIndicator(name: string): AnyAction {
  return {
    type: REMOVE_LOADING_INDICATOR,
    value: name
  };
}

export function setSideBarOpenClose(value: boolean): AnyAction {
  return {
    type: SET_SIDEBAR_OPEN_CLOSE,
    value
  };
}
export function setSurveyDocOpenClose(value: boolean): AnyAction {
  return {
    type: SET_SURVEY_DOC_OPEN_CLOSE,
    value
  };
}
export function setByodDocOpenClose(value: boolean): AnyAction {
  return {
    type: SET_BYOD_DOC_OPEN_CLOSE,
    value
  };
}

export function selectedSourceValues(value: any): AnyAction {
  return {
    type: 'SELECTED_SOURCE_VALUES',
    value
  };
}

export function chatSearchInputValue(value: string): AnyAction {
  return {
    type: 'CHAT_SEARCH_INPUT_VALUE',
    value
  };
}

export function setRightSideBarOpenClose(value: boolean): AnyAction {
  return {
    type: SET_RIGHT_SIDEBAR_OPEN_CLOSE,
    value
  }
}

export function selectSourceOnNewChat(value: string): AnyAction {
  return {
    type: 'SELECT_SOURCE_ON_NEW_CHAT',
    value
  }
}


export function favouriteStatus(value: boolean): AnyAction {
  return {
    type: 'FAVOURITE_STATUS',
    value
  }
}

export function showPrivacyAndTermsModal(value: boolean, policyTypeList:any): AnyAction {
  return {
    type: SHOW_PRIVACY_AND_TERMS_MODAL,
    data: value,
    policyTypeList
  };
}

export function showUnauthorizedAccess(value: boolean): AnyAction {
  return {
    type: 'SHOW_UNAUTHORIZED_ACCESS',
    value
  };
}

//#region Handling 404 scenario

export function triggerDnfOpenAiSearch(value: boolean): AnyAction {
  return {
    type: TRIGGER_DNF_OPENAI_SEARCH,
    value
  };
}
// #endregion

