import React from 'react';

interface IInputProps {
    value: string;
    readOnly: boolean;
    handleChange(event: any): void;
    type?: string;
    style?: any;
    setIsSearchBarOverflowShown?: (payload: any) => void;
    maxLength?: number;
    disabled?: boolean;
    placeholder: string;
}

const Input = (props: IInputProps) => {
    return (<input
        onFocus={() => props.setIsSearchBarOverflowShown && props.setIsSearchBarOverflowShown({ input: props.type, value: false })}
        type={props.type || 'text'}
        style={props.style}
        className="input"
        readOnly={props.readOnly}
        value={props.value}
        onChange={props.handleChange}
        maxLength={props.maxLength}
        disabled={props.disabled}
        placeholder={props.placeholder}
    />);
};

export default Input;
