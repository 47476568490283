import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import SAT_DELIVERY_PORTAL_IMG from '../../../src/assets/images/sat-delivery-portal-bg.png';
import SAT_DELIVERY_PORTAL_ARROW from '../../../src/assets/images/arrow-tr.svg';
import AppContainer from '../appContainer/AppContainer';
import Header from '../common/header/Header';
import Banner from '../common/banner/Banner';
import SearchContainer from '../searchContainer/SearchContainer';
import BottomBanner from '../common/bottomBanner/BottomBanner';
import Sidebar from '../sideBarComponent/SidebarComponent';
import { IStore } from '../../store';
import RightSidebar from '../sideBarComponent/RightSidebar';
import { setByodDocOpenClose, setRightSideBarOpenClose } from '../../store/actions/commonActions';
import useWindowSize from '../../utils/CommonHooks/useWindowSize';
import UploadDataTiles from '../uploadDataContainer/UploadDataTiles';
import NewChatPage from '../newChatPage/NewChatPage';
import { sideBarExpanded, sidebarExpendedOpen } from '../../commonImage';
import { AddDocument } from '../uploadDocumentsContainer/AddDocument';
import ExploretData from '../ExploreData/ExploreData';
import PublicChatTile from '../publicChatContainer/PublicChatTile';
import SurveyInsights from '../surveyInsights/SurveyInsights';
import SectorInsights from '../sectorInsights/SectorInsights';
import Transcripts from '../transcripts/Transcripts';
import SectorChatPage from '../SectorChatPage/SectorChatPage';
import SurveySidebar from '../sideBarComponent/SurveySideBarComponent';
import LoadingIndicator from '../openAIChat/LoadingIndicator';
import PolicyStepper from '../common/policy/PolicyStepper';
import { setIsSurveyDocList } from '../../store/actions/userChatActions';
import { getXMonthsDate } from '../../utils/commonUtils';
import { getSurveysListResponse } from '../../services/chatQuery.service';
import ExpertChatPage from '../EyExperts/ExpertChatPage';
import { SAT_PORTAL_ROUTE } from '../../app.constant';
import EyIpKcChatPage from '../EyIpKcContainer/EyIpKcChatPage'
// import UploadURLTile from '../uploadURLContainer/UploadURLTile';

type UserApp = {
  isFavourite: boolean;
  name: string;
  url?: string;
  imageName?: string;
};
type UserApps = UserApp[];
export interface IAppStoreProps {
  userApps: UserApps;
  dataSources?: string[];
  isLoading?: boolean;
}

const AppStoreComponent = ({ userApps, dataSources, isLoading }: IAppStoreProps): JSX.Element => {
  const dispatch = useDispatch();
  const [windowSize] = useWindowSize();
  const showPrivacyAndTermsModal = useSelector(
    (store: IStore) => store.common.showPrivacyAndTermsModal
  );
  let transformedUserApps: UserApps = [];
  if (userApps?.length > 0) {
    transformedUserApps = userApps
      .join('|')
      .toLowerCase()
      .split('|')
      .map((name) => ({
        isFavourite: false,
        name
      }));
  }
  const [homeInputValue, setHomeInputValue] = useState('');
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);
  const isSurveyDocOpen = useSelector((state: IStore) => state.common.isSurveyDocOpen);
  const isByodDocOpen = useSelector((state: IStore) => state.common.isByodDocOpen);
  const pathName = window.location.pathname;
  const isSidebarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);

  window.onload = function () {
    delete localStorage.isSidebarManuallyClosed;
  };

  const toggleRightSideBar = (event: any) => {
    if (event?.type === 'click' && isRightSideBarOpen === true) {
      localStorage.isSidebarManuallyClosed = true;
    }
    dispatch(setRightSideBarOpenClose(!isRightSideBarOpen));
  };

  const handleInputData = (data: string) => {
    setHomeInputValue(data);
  };

  const handleSectorApi = async () => {
    const queryBody = JSON.stringify({
      fromdate: ''
    });
    const controller = new AbortController();
    try {
      const response = await getSurveysListResponse(queryBody);
      const result = response ? await (response as Response).json() : null;
      if (result && result?.sectors) {
        dispatch(setIsSurveyDocList(result));
      }
    } catch (error) {
      if (controller.signal.aborted) {
        console.warn('::APi call failed', error);
      }
    }
  };

  useEffect(() => {
    handleSectorApi();
    localStorage.removeItem('surveycustomperiod');
    localStorage.removeItem('surveyDateSelectedOption');
    localStorage.removeItem('selectedSurveyList');
    localStorage.removeItem('selectedDropSectors');
    localStorage.removeItem('surveytoDate');
    localStorage.removeItem('surveycustomperiod');
    localStorage.removeItem('queryFromDate');
    localStorage.removeItem('queryToDate');
  }, []);

  const routeToSaTDeliveryPortal = () => {
    window.open(SAT_PORTAL_ROUTE, '_blank');
  }

  const renderSaTDeliveryPortal = () => {
    return (
      <div className='sat-delivery-div'>
        <div className='image-container'>
          <img src={SAT_DELIVERY_PORTAL_IMG} alt='SaT Delivery Portal image' />
          <div className='text-div'>
            <span className='info'>Strategy and Transactions Delivery Portal</span>
            <br className='teams-app-break-div'></br>
            <span className='info-1'>Transform the way we work through technology</span>
          </div>
          <div className='button-div'>
            <button className='btn secondary-btn launch-btn' onClick={routeToSaTDeliveryPortal}>Launch SAT Delivery Portal  <img className='btn-arrow' src={SAT_DELIVERY_PORTAL_ARROW}></img></button>
          </div>
        </div>
      </div>
    );
  }

  return (
    //@ts-ignore
    <StyleAppStoreComponent
      isRightSideBarOpen={isRightSideBarOpen}
      isSidebarOpen={isSidebarOpen}
      pathName={pathName}
      isLoadingAPI = {isLoading}
      isSurveyDocOpen={isSurveyDocOpen}
      isByodDocOpen={isByodDocOpen}>
      <Header />

      <div className='bg-img-div'>
        <RightSidebar isOpen={isRightSideBarOpen} userApps={userApps} />
        {!isSurveyDocOpen && (
          <Sidebar isOpen={isSidebarOpen} dataSources={dataSources} isLoading={isLoading} />
        )}
        {isSurveyDocOpen && <SurveySidebar isOpen={isSurveyDocOpen} />}

        <div
          className={`main-content ${isSurveyDocOpen ? 'sidebar-with-survey' : 'sidebar-closed'} ${pathName === '/surveyChatPage' ? 'surveychatpage' : ''}`}>
          {/* <button onClick={toggleRightSideBar} className='toggleRightSideBar'>
          <img src ={isRightSideBarOpen ? sideBarOpenIcon:sideBarCloseIcon}></img>
          </button> */}
          {!isRightSideBarOpen ? (
            <img
              src={sideBarExpanded}
              onClick={(e) => toggleRightSideBar(e)}
              className='toggleRightSideBar toggle-open'></img>
          ) : (
            <img
              src={sidebarExpendedOpen}
              onClick={(e) => toggleRightSideBar(e)}
              className='toggleRightSideBar toggle-close'></img>
          )}
          <Routes>
            <Route
              path='/publicChatPage'
              element={
                <>
                  <PublicChatTile chatQueryApi={homeInputValue} />
                </>
              }
            />
            <Route
              path='/sectorChatPage'
              element={
                <>
                  <SectorChatPage />
                </>
              }
            />
             <Route
              path='/kcChatPage'
              element={
                <>
                  <EyIpKcChatPage sectors={[]} geographies={[]} contentage={''} />
                </>
              }
            />
            <Route
              path='/sectorChatPage'
              element={
                <>
                  <SectorInsights sectors={[]} geographies={[]} contentage={''} />
                </>
              }
            />
            <Route
              path='/surveyChatPage'
              element={
                <>
                  <SurveyInsights />
                </>
              }
            />
            <Route
              path='/transScriptChatPage'
              element={
                <>
                  <Transcripts />
                </>
              }
            />
            <Route
              path='/uploadPage'
              element={
                <>
                  <AddDocument />
                </>
              }
            />
            {/* <Route
              path='/uploadURLPage'
              element={
                <>
                  <UploadURLTile chatQueryApi={homeInputValue} />
                </>
              }
            /> */}
            <Route
              path='/exploreData'
              element={
                <>
                  <ExploretData />
                </>
              }
            />
            <Route
              path='/newChat'
              element={
                <>
                  <NewChatPage />
                </>
              }
            />
            <Route
              path='/expertChatPage'
              element={
                <>
                  <ExpertChatPage />
                </>
              }
            />
            <Route
              path='/'
              element={
                <>
                  <Banner />
                  <SearchContainer getInputData={handleInputData} />
                  <BottomBanner />
                  {!isLoading ? (
                    <div className='scrollable'>
                      <AppContainer userApps={userApps} isLoading={isLoading} />
                      <div className='app-store-div footer-bg-img'></div>
                    </div>
                  ) : (
                    <div className='loadingClass'>
                      <LoadingIndicator />
                    </div>
                  )}
                  {renderSaTDeliveryPortal()}
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </StyleAppStoreComponent>
  );
};

const StyleAppStoreComponent = styled.div<{
  isSidebarOpen?: boolean;
  isRightSideBarOpen?: boolean;
  width: string;
  pathName: string;
  isLoadingAPI?: boolean;
  isSurveyDocOpen?: boolean;
  isByodDocOpen?: boolean;
}>`
  background: #fff;
  .bg-img-div {
    height: 100%;
    min-height: ${(props) =>
      props.pathName == '/newChat' || props.pathName == '/expertChatPage'
        ? '96vh'
        : props.pathName == '/sectorChatPage'
          ? '97vh'
          : props.pathName === '/uploadPage'
            ? '100vh'
            : '100vh'};
    background: rgb(255, 255, 255);
    margin: 0px;
    ::-webkit-scrollbar {
      display: none;
    }
    width: ${(props) =>
      (props.isSidebarOpen && props.isRightSideBarOpen) ||
      (props.isSurveyDocOpen && props.isRightSideBarOpen) ||
      (props.isByodDocOpen && props.isRightSideBarOpen)
        ? 'calc(100% - 480px)'
        : !props.isByodDocOpen &&
            !props.isSidebarOpen &&
            !props.isSurveyDocOpen &&
            !props.isRightSideBarOpen
          ? '100%'
          : 'calc(100% - 240px)'};

    margin-top: ${(props) => props.isSurveyDocOpen && props.isRightSideBarOpen && 0 + 'px'};
    margin-right: ${(props) => props.isSurveyDocOpen && props.isRightSideBarOpen && 'auto'};
    margin-bottom: ${(props) => props.isSurveyDocOpen && props.isRightSideBarOpen && 0 + 'px'};
    margin-left: ${(props) => props.isSurveyDocOpen && props.isRightSideBarOpen && 'auto'};
    margin-top: ${(props) => props.isByodDocOpen && props.isRightSideBarOpen && 0 + 'px'};
    margin-right: ${(props) => props.isByodDocOpen && props.isRightSideBarOpen && 'auto'};
    margin-bottom: ${(props) => props.isByodDocOpen && props.isRightSideBarOpen && 0 + 'px'};
    margin-left: ${(props) => props.isByodDocOpen && props.isRightSideBarOpen && 'auto'};
    margin-left: ${(props) => props.isRightSideBarOpen && 'auto'};
    margin-right: ${(props) => props.isSidebarOpen && 'auto'};
    margin-right: ${(props) => props.isSidebarOpen && props.isRightSideBarOpen && 'auto'};

    @media screen and (min-width: 1000px) and (max-width: 1300px) {
      min-height: ${(props) => (props.pathName == '/sectorChatPage' ? '110vh' : '100vh')};
    }
    @media screen and (min-width: 1500px) {
      min-height: ${(props) => (props.pathName == '/uploadPage' ? '99vh' : '100vh')};
    }
  }

  .app-store-label-div {
    display: inline-block;
    padding: 5px 0px;
    margin-left: 10px;
  }
  .app-store-label {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
  .app-store-logo-div {
    display: inline-block;
    height: 30px;
    vertical-align: middle;
  }

  .apps-unauthorized-height {
    min-height: 34vh;
  }

  .main-content {
    height: 100%;
    width: calc(100% - 9px);

    .toggleRightSideBar {
      top: 5rem;
      position: absolute;
      border: none;
      cursor: pointer;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
    .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
    display: flex;
    flex-direction: column;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 650px) and (max-width: 849px) {
    .info {
      font-size: 18px;
      line-height: 18px;
    }
    .info-1 {
      font-size: 13px;
      line-height: 13px;
    }
    .text-div {
      top: 10%;
    }
    .teams-app-break-div { display: none; }
    .launch-btn {
      font-size: 11px;
      line-height: 11px;
    }
    .button-div {
      top : 15%;    
      left: 65%;
    }
  }
  @media screen and (min-width: 850px) and (max-width: 983px) {
    .info {
      font-size: 20px;
      line-height: 20px;
    }
    .info-1 {
      font-size: 14px;
      line-height: 14px;
    }
    .text-div {
      top: 18%;
    }
    .teams-app-break-div { display: block; }
    .launch-btn {
      font-size: 11px;
    }
    .button-div {
      top : 25%;    
      left: 72%;
    }
  }
  @media screen and (min-width: 984px) and (max-width: 1023px) {
    .info {
      font-size: 26px;
      line-height: 26px;
    }
    .info-1 {
      font-size: 18px;
    }
    .teams-app-break-div { display: block; }
    .launch-btn {
      font-size: 12px;
    }
    .button-div {
      left: 74%;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1395px) {
    .info {
      font-size: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '18px' : '32px'};
      line-height: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '18px' : '32px'};
    }
    .info-1 {
      font-size: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '17px' : '20px'};
      line-height: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '17px' : '20px'};
    }
    .teams-app-break-div { display: block; }
    .launch-btn {
      font-size: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '12px' : '14px'};
    }
    .button-div {
      top : ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen && props.isSidebarOpen) ? '25%' : (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '30%' : '35%'};
      left : ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen && props.isSidebarOpen) ? '68%' : (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '73%' : '78%'};
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1535px) {
    .info {
      font-size: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '24px' : '32px'};
      line-height: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '24px' : '32px'};
    }
    .teams-app-break-div { display: block; }
    .launch-btn {
      font-size: 13px;
    }
    .button-div {
      left : ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '72%' : '77%'};
    }
  }
  @media screen and (min-width: 1536px) and (max-width: 1710px) {
    .info {
      font-size: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '24px' : '32px'};
      line-height: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '24px' : '32px'};
    }
    .teams-app-break-div { display: block; }
    .launch-btn {
      font-size: ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '13px' : '16px'};
    }
    .button-div {
      left : ${(props) => (props.isSurveyDocOpen || props.isRightSideBarOpen || props.isSidebarOpen) ? '76%' : '78%'};
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
  
  .info, .info-1{
    font-family: EYInterstate;
    position: relative;
    color: #23232F;
    font-size: 32px;
    font-weight: 500;
    line-height: 32px;
  }
  .info-1{
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    color: #656579;
  }
  .image-container {
    position: relative;
  }
  .sat-delivery-div {
    margin:0% 2%;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    position: absolute;
    top: 25%;
    left: 4%;
  }
  .button-div {
    position: absolute;
    top: 35%;
    left: 78%;
  }
  .launch-btn {
    font-family: EYInterstate;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  .btn-arrow {
    vertical-align: sub;
  }
  @media screen and (min-width: 1024px) and (max-width: 1229px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 77%;
    }
  }

  @media screen and (min-width: 1396px) and (max-width: 1536px) {
    .launch-btn {
      font-size: 14px;
    }
    .button-div {
      left: 80%;
    }
    .toggle-open {
      margin-left: 1rem;
    }
    .toggle-close {
      //  margin-left:${(props) => (props.pathName === '/' ? '-2rem' : '-1rem')};
    }

    .toggleRightSideBar:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 0;
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-bottom: 50px solid transparent;
      border-left: 20px solid #4caf50;
    }
  }

  .main-content.sidebar-closed {
    width: 100%;
    height: 100%;
  }
  .main-content.sidebar-with-survey {
    width: calc(100% - 75px);
  }
  .main-content.sidebar-with-survey.surveychatpage {
    margin-top: 18px;
  }
  .main-content.sidebar-with-survey.surveychatpage .surveyChatContainer__breadcrumbOpen {
    margin-top: 0px !important;
  }

  .loadingClass {
    display: flex;
    justify-content: center;
    position: relative;
    top: 10%;
  }
  .scrollable {
    padding-bottom: 3%;
  }
`;

export default AppStoreComponent;