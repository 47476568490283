import React, { useEffect, useState } from 'react';
import Dropdown from '../NewDropdown/Dropdown';
import DropdownFooter from '../sectorInsights/SectorComponents/DropdownFooter';
import { MotifDatePicker, MotifFormField, MotifLabel } from '@ey-xd/motif-react';
// import ThemeSwitcher from '../theme/theme';
import './SurveyDropdown.scss';
import { getXMonthsDate } from '../../utils/commonUtils';
import { faHourglassEnd } from '@fortawesome/free-solid-svg-icons';

type ICustomDropdwnoProps = {
  setdropdownValue?: (val: string) => void;
  toggleDropdown?: () => void;
  clearDropdownValues?: () => void;
  clearDropdown?: () => void;
  setSelectedOption?: (val: string) => void;
  selectedContentAge?: string;
  options: {
    value: string | number;
    label: string;
  }[];
  placeholder?: string;
  showOptions: boolean;
  isSideBarOpen?: boolean;
  surveyLabel?: string;
  dropdownType?: string;
  selectedOption?: string;
  showFooter?: boolean;
  customRef?: any;
  timeLineFilter?: (fromdate?: string, todate?: string) => void;
};

const SurveyDropdown = ({
  options = [],
  placeholder,
  showOptions,
  selectedContentAge = '',
  isSideBarOpen,
  surveyLabel = '',
  dropdownType = 'radio',
  selectedOption = '',
  showFooter = true,
  setSelectedOption,
  setdropdownValue,
  toggleDropdown,
  clearDropdownValues,
  customRef,
  timeLineFilter,
  clearDropdown
}: ICustomDropdwnoProps) => {
  const [value, setValue] = useState<any>(new Date());

  const filteredResponse = () => {
    switch (selectedOption) {
      case '6 months':
        timeLineFilter?.(new Date(getXMonthsDate(new Date(), 6)).toISOString());
        break;
      case '1 year':
        timeLineFilter?.(new Date(getXMonthsDate(new Date(), 12)).toISOString());
        break;
      case '2 years':
        timeLineFilter?.(new Date(getXMonthsDate(new Date(), 24)).toISOString());
        break;
      case '3 years':
        timeLineFilter?.(new Date(getXMonthsDate(new Date(), 36)).toISOString());
        break;
      case 'More than 3 years':
        timeLineFilter?.(new Date(getXMonthsDate(new Date(), 48)).toISOString());
        break;
      default:
        timeLineFilter?.('', '');
        break;
    }
  };

  const selecteDropdownValue = (val: string) => {
    localStorage.setItem('surveyDateSelectedOption', val);
    setdropdownValue?.(val);
    setSelectedOption?.(val);
    toggleDropdown?.();
  };


  useEffect(() => {
    filteredResponse();
  }, [selectedOption, value]);

  return (
    <div className='surveydropdown' ref={customRef}>
      <label className='surveydropdown__title'>{surveyLabel}</label>
      <Dropdown
        options={[{ label: ' ', value: ' ' }]}
        onFocus={toggleDropdown}
        placeholder={placeholder}
        classCss={'no-class'}
        showBackground={selectedContentAge !== '' ? true : false}
        isSideBarOpen={isSideBarOpen}
        defaultValue={dropdownType === 'radio' ? selectedOption : ''}
      />
      <div className='surveydropdown__container'>
        <div 
          className={`surveydropdown__options ${isSideBarOpen ? "width-sideBarOpen" : "width-sideBarClose"}`}
          // style={{ width: isSideBarOpen ? 233 : 286 }}
        >
          {options && showOptions && (
            <>
              {options.map((opt: any, ind: number) => {
                return (
                  <label>
                    <input
                      className={`surveydropdown__option-menu ${+selectedOption === ind && selectedContentAge !== '' ? 'bg-selected' : 'bg-unselected'}`}
                      // style={{
                      //   background:
                      //     +selectedOption === ind && selectedContentAge !== ''
                      //       ? 'red'
                      //       : 'transparent',
                      //   display: 'block'
                      // }}
                      type={dropdownType}
                      value={selectedOption}
                      defaultValue={'Custom period'}
                      checked={selectedOption === opt.label}
                      onChange={() => selecteDropdownValue(opt.label)}
                    />
                    {opt.label}
                  </label>
                );
              })}{' '}
              {showFooter && (
                <DropdownFooter
                  clearText='Clear filter'
                  customClass={'surveydropdown__footer'}
                  closeFilter={toggleDropdown}
                  clearAll={clearDropdown}
                  showClearAll={true}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SurveyDropdown;
