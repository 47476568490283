// RightSidebar.tsx

import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import CommonAppsContainer from '../common/CommonAppsContainer';
import {
  CHAT_HISTORY_MAX_LIMIT,
  EY_PROPRIETARY_TILES,
  internalSources
} from '../../label.constant';
import { MotifFormField, MotifInput, MotifLabel } from '@ey-xd/motif-react';
import {
  clearChatHistory,
  saveUserChatThreadHistory,
  saveUserDocumentThreadHistory,
  selectedDataSourceSection,
  setChatClicked,
  setISActiveTab,
  setLastDeletedThreadID,
  setLastSavedThreadID,
  setSelectedChatThread,
  setSelectedDocumentThread
} from '../../store/actions/userChatActions';
import { useLocation, useNavigate } from 'react-router-dom';
import { editIcon, deleteIcon, tickIcon, crossIcon } from '../../commonImage';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSourceOnNewChat,
  selectedSourceValues,
  setSurveyDocOpenClose
} from '../../store/actions/commonActions';
import ChatHistoryTab from '../openAIChat/tabWithoutRoute/Tab';
import ChatHistoryTabs from '../openAIChat/tabWithoutRoute/Tabs';
import { IStore } from '../../store';
import acceptIcon from '../../../src/assets/images/accept.svg';
import rejectIcon from '../../../src/assets/images/reject.svg';
import {
  deleteChatThreadHistory,
  deleteDocThreadHistory,
  getChatThreadHistory,
  getDocumentThreadHistory,
  removeThreadFromCache,
  updateChatThreadTitle,
  updateDocThreadTitle
} from '../../services/chatQuery.service';
import { DataSourceTypes } from '../../models/IDataSourceTypes';
import { HttpStatusCode } from '../../ui-constants/base/enum';
import { getCacheRemoveAPIURL, setThreadsUnSelected } from '../../utils/commonUtils';
import {
  getCurrentSessionId,
  initializeSession,
  setCurrentSessionId
} from '../../services/session.service';
import path from 'path';
import OverflowTooltip from '../common/OverflowTooltip';

interface RightSidebarProps {
  isOpen?: boolean;
  userApps: Array<{ name: string; isFavourite: boolean }>;
}

const RightSidebar: React.FC<RightSidebarProps> = ({ isOpen, userApps }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const selectedChatThread = useSelector((state: IStore) => state.userChat.selectedChatThread);
  const userEmail = useSelector((state: IStore) => state.common?.userEmail);
  const selectedDocumentThread = useSelector(
    (state: IStore) => state.userChat.selectedDocumentThread
  );
  const [userChatThreadHistory, setUserChatThreadHistory] = useState<any>([]);
  const [userDocThreadHistory, setUserDocThreadHistory] = useState<any>([]);
  const activeTabValue = useSelector((store: IStore) => store.userChat.activeTabValue);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);
  const isLoading = useSelector((state: any) => state.userChat.isLoading);
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const publicSource: string[] =
    useSelector((state: IStore) => state.common.checkedCheckboxes) ?? [];
  const EYIPSource: string = useSelector((state: IStore) => state.common.eyIPInterRadio) ?? '';
  const selectedDataSource = useSelector((state: any) => state.userChat.selectedDataSourceSection);
  const currentDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const onNewChatClick = () => {
    if (!isLoading) {
      if (pathName !== '/') {
        if (pathName === '/exploreData' || pathName === '/uploadPage') {
          navigate('/uploadPage');
        } else if (pathName === '/expertChatPage' && selectedDataSource === 5) {
          navigate('/expertChatPage');
        } else if (EYIPSource.length === 0) {
          navigate('/');
        } else if (EYIPSource.length > 0) {
          if (EYIPSource === internalSources[1]) navigate('/sectorChatPage');
          else if (EYIPSource === internalSources[2]) navigate('/kcChatPage');
          else navigate('/newChat');
        } else if (pathName === '/uploadURLPage') {
          navigate('/newChat');
        } else navigate('/newChat');
      }
      selectedChatThread.isSelected = false;
      dispatch(setLastSavedThreadID(0));
      dispatch(setSelectedChatThread({}));
      selectedDocumentThread.isSelected = false;
      dispatch(setSelectedDocumentThread({}));
      dispatch(clearChatHistory());
      dispatch(setChatClicked(true));

      setTimeout(() => {
        dispatch(
          selectedSourceValues({
            eyIPInterRadio: EYIPSource,
            checkedCheckboxes: publicSource,
            validURLinputValue: ''
          })
        );
        dispatch(setChatClicked(false));
      }, 10);
    }
  };

  useEffect(() => {
    if (activeTabValue === 'Document history') {
      const updated = userChatThreadHistory.map((item: any) => {
        item.isEditEnabled = false;
        return item;
      });
      setUserChatThreadHistory(updated);
    } else {
      const updated = userDocThreadHistory.map((item: any) => {
        item.isEditEnabled = false;
        return item;
      });
      setUserDocThreadHistory(updated);
    }
  }, [activeTabValue, currentDataSourceSection]);

  //#region Chat History
  const [selectedChatThreadTitle, setSelectedChatThreadTitle] = useState('');
  const userChatThreads = useSelector((store: IStore) => store.userChat.chatThreadHistory);
  useEffect(() => {
    setUserChatThreadHistory(userChatThreads);
  }, [userChatThreads]);

  const loadUserChatThreads = async () => {
    const chatHistoryResponse = await getChatThreadHistory();
    const chatThreads: any = [];
    let chatThreadsToBeDeleted: any = [];
    if (chatHistoryResponse?.length > 0) {
      chatHistoryResponse.forEach((thread: any, index: number) => {
        if (index <= 9) {
          chatThreads.push({
            threadId: thread.threadId,
            sessionId: thread.sessionId,
            title: thread.title,
            dataSource: thread.dataSource,
            dataSubSource: thread.dataSubSource,
            isEditEnabled: false
          });
        } else {
          chatThreadsToBeDeleted.push(thread.threadId);
          if (chatThreadsToBeDeleted?.length > 0) {
            const currentThread: any = chatThreadsToBeDeleted[0];
            deleteChatThreadHistory(currentThread);
            const targetAPIURL = getCacheRemoveAPIURL(currentThread);
            if (targetAPIURL) removeThreadFromCache(targetAPIURL, currentThread.sessionId);
          }
        }
      });
    }
    dispatch(saveUserChatThreadHistory(chatThreads));
  };
  useEffect(() => {
    if (userChatThreads.length === 0) loadUserChatThreads();
  }, []);

  /**
   * Enable/Diable edit option and update title
   * @param event
   * @param currentThread
   * @param isEditEnabled
   */
  const updateChatThreadEditOption = (event: any, currentThread: any, isEditEnabled: boolean) => {
    if (!isLoading) {
      event.stopPropagation();
      let selectedThread: any = userChatThreadHistory.find(
        (item: any) => item.threadId === currentThread.threadId
      );
      setSelectedChatThreadTitle(selectedThread.title);
      if (isEditEnabled) {
        setUserChatThreadHistory(
          userChatThreadHistory.map((item: any) => (item.isEditEnabled = false))
        );
      }
      selectedThread.isEditEnabled = isEditEnabled;
      if (!isEditEnabled) selectedThread.title = selectedChatThreadTitle;

      setUserChatThreadHistory(
        userChatThreadHistory.map((item: any) =>
          item.threadId === currentThread.threadId ? selectedThread : item
        )
      );
    }
  };

  /**
   * Save chat thread title
   */
  const saveThreadTitle = async (chatThread: any) => {
    if (selectedChatThreadTitle !== chatThread.title) {
      const updateThreadTitleResponse = await updateChatThreadTitle(
        chatThread?.threadId,
        chatThread?.title
      );
      if (updateThreadTitleResponse?.status === HttpStatusCode.Success) {
        const updated = userChatThreadHistory.map((item: any) => {
          item.isEditEnabled = false;
          return item;
        });
        setUserChatThreadHistory(updated);
      }
    } else {
      const updated = userChatThreadHistory.map((item: any) => {
        item.isEditEnabled = false;
        return item;
      });
      setUserChatThreadHistory(updated);
    }
  };

  /**
   * Delete chat thread
   * @param currentThread
   */
  const deleteChatThread = async (event: any, currentThread: any, index: number) => {
    if (!isLoading) {
      event.stopPropagation();
      const deleteChatThreadResponse: any = await deleteChatThreadHistory(currentThread.threadId);
      if (deleteChatThreadResponse?.status === HttpStatusCode.Success) {
        const updated = userChatThreadHistory.filter(
          (item: any) => item.threadId !== currentThread.threadId
        );
        setUserChatThreadHistory(updated);
        dispatch(setLastDeletedThreadID(currentThread.threadId));
        const targetAPIURL = getCacheRemoveAPIURL(currentThread);
        if (targetAPIURL) removeThreadFromCache(targetAPIURL, currentThread.sessionId);
        if (updated?.length > 0) {
          let targetThread: any = {};
          if (index === userChatThreadHistory?.length - 1) {
            targetThread = updated[0];
          } else {
            targetThread = updated[index];
          }
          loadSelectedChatThread(targetThread);
        } else if (updated?.length === 0) {
          navigate('/');
          dispatch(clearChatHistory());
          dispatch(setSelectedChatThread({}));
        }
      }
    }
  };

  /**
   * Load selected chat thread into redux store and navigate to chat page
   * @param currentThread
   */
  const loadSelectedChatThread = (currentThread: any) => {
    if (!isLoading && currentThread?.threadId !== selectedChatThread?.threadId) {
      dispatch(clearChatHistory());
      setCurrentSessionId(currentThread.sessionId);
      dispatch(setLastSavedThreadID(0));
      setThreadsUnSelected(userDocThreadHistory);
      dispatch(setSelectedDocumentThread({}));
      setThreadsUnSelected(userChatThreadHistory);
      dispatch(setSurveyDocOpenClose(false));
      currentThread.isSelected = true;
      dispatch(setSelectedChatThread(currentThread));
      dispatch(
        selectedSourceValues({
          eyIPInterRadio: '',
          checkedCheckboxes: [],
          validURLinputValue: ''
        })
      );
      if (currentThread.dataSource === DataSourceTypes.PublicData) {
        dispatch(selectedDataSourceSection(DataSourceTypes.PublicData));
        navigate('/publicChatPage');
      } else if (currentThread.dataSource === DataSourceTypes.EYIP) {
        {
          dispatch(selectedDataSourceSection(DataSourceTypes.EYIP));
          if (currentThread.dataSubSource === 'Discover: Sector Insights')
            navigate('/sectorChatPage');
          else if (currentThread.dataSubSource === 'Surveys') navigate('/surveyChatPage');
          else if (currentThread.dataSubSource === internalSources[2]) navigate('/kcChatPage');
        }
      } else if (currentThread.dataSource === DataSourceTypes.UploadURL) {
        dispatch(selectedDataSourceSection(DataSourceTypes.UploadURL));
        navigate('/uploadURLPage');
      } else if (currentThread.dataSource === DataSourceTypes.Experts) {
        dispatch(selectedDataSourceSection(DataSourceTypes.Experts));
        navigate('/expertChatPage');
      } else navigate('/newChat');
    }
  };
  //#endregion Chat History

  //#region Document History
  const [selectedDocumentThreadTitle, setSelectedDocumentThreadTitle] = useState('');
  const userDocumentThreads = useSelector((store: IStore) => store.userChat.documentThreadHistory);
  useEffect(() => {
    setUserDocThreadHistory(userDocumentThreads);
  }, [userDocumentThreads]);

  const loadUserDocumentThreads = async () => {
    const documentThreadHistoryResponse = await getDocumentThreadHistory(userEmail);
    const documentThreads: any = [];
    if (documentThreadHistoryResponse?.length > 0) {
      documentThreadHistoryResponse.forEach((thread: any, index: number) => {
        if (index <= 9) {
          documentThreads.push({
            threadId: thread.project_UUID,
            title: thread.projectName,
            dataSource: DataSourceTypes.BYOD,
            isEditEnabled: false,
            isSelected: false
          });
        }
      });
    }
    dispatch(saveUserDocumentThreadHistory(documentThreads));
  };
  useEffect(() => {
    if (userEmail.length > 0 && userDocumentThreads.length === 0) loadUserDocumentThreads();
  }, [userEmail]);

  /**
   * Enable/Diable edit option and update title
   * @param event
   * @param currentThread
   * @param isEditEnabled
   */
  const updateDocumentThreadEditOption = (
    event: any,
    currentThread: any,
    isEditEnabled: boolean
  ) => {
    if (!isLoading) {
      event.stopPropagation();
      let selectedThread: any = userDocThreadHistory.find(
        (item: any) => item.threadId === currentThread.threadId
      );
      setSelectedDocumentThreadTitle(selectedThread.title);
      if (isEditEnabled) {
        setUserDocThreadHistory(
          userDocThreadHistory.map((item: any) => (item.isEditEnabled = false))
        );
      }
      selectedThread.isEditEnabled = isEditEnabled;
      if (!isEditEnabled) selectedThread.title = selectedDocumentThreadTitle;

      setUserDocThreadHistory(
        userDocThreadHistory.map((item: any) =>
          item.threadId === currentThread.threadId ? selectedThread : item
        )
      );
    }
  };

  /**
   * Update threas's title value after each key press
   * @param currentThread
   * @param updatedTitle
   */
  const updateThreadTitleValue = (currentThread: any, updatedTitle: string) => {
    if (!isLoading) currentThread.title = updatedTitle;
  };

  /**
   * Save document thread title
   */

  const saveDocumentThreadTitle = async (docThread: any) => {
    if (selectedChatThreadTitle !== docThread.title) {
      const updateThreadTitleResponse = await updateDocThreadTitle(
        docThread?.threadId,
        docThread?.title,
        userEmail
      );
      if (updateThreadTitleResponse?.status === HttpStatusCode.Success) {
        const updated = userDocThreadHistory.map((item: any) => {
          item.isEditEnabled = false;
          return item;
        });
        setUserDocThreadHistory(updated);
      }
    } else {
      const updated = userDocThreadHistory.map((item: any) => {
        item.isEditEnabled = false;
        return item;
      });
      setUserDocThreadHistory(updated);
    }
  };
  /**
   * Delete document thread
   * @param currentThread
   */
  const deleteDocumentThread = async (event: any, currentDocumentThread: any, index: number) => {
    if (!isLoading) {
      event.stopPropagation();
      setCurrentSessionId(currentDocumentThread.sessionId);
      const deleteChatThreadResponse: any = await deleteDocThreadHistory(
        currentDocumentThread.threadId,
        userData
      );
      if (deleteChatThreadResponse?.status === HttpStatusCode.Success) {
        const updated = userDocThreadHistory.filter(
          (item: any) => item.threadId !== currentDocumentThread.threadId
        );
        setUserDocThreadHistory(updated);
        dispatch(setLastDeletedThreadID(currentDocumentThread.threadId));
        if (updated?.length > 0) {
          let targetThread: any = {};
          if (index === userDocThreadHistory?.length - 1) {
            targetThread = updated[0];
          } else {
            targetThread = updated[index];
          }
          loadSelectedDocumentThread(targetThread);
        } else navigate('/');
      }
    }
  };

  /**
   * Load selected document thread into redux store and navigate to upload page
   * @param currentDocumentThread
   */
  const checkIframe = window.location !== window.parent.location;

  const loadSelectedDocumentThread = (currentDocumentThread: any) => {
    if (!isLoading) {
      dispatch(
        selectedSourceValues({
          eyIPInterRadio: '',
          checkedCheckboxes: [],
          validURLinputValue: ''
        })
      );
      dispatch(setISActiveTab('Document history'));
      setThreadsUnSelected(userChatThreadHistory);
      dispatch(setLastSavedThreadID(0));
      dispatch(setSelectedChatThread({}));
      setThreadsUnSelected(userDocThreadHistory);
      currentDocumentThread.isSelected = true;
      dispatch(setSelectedDocumentThread(currentDocumentThread));
      currentDocumentThread.dataSource === DataSourceTypes.BYOD
        ? navigate('/exploreData')
        : navigate('/newChat');
      if (currentDocumentThread.dataSource === DataSourceTypes.BYOD)
        dispatch(selectedDataSourceSection(DataSourceTypes.BYOD));
    }
  };
  //#endregion Document History

  return (
    //@ts-ignore
    <RightSidebarStyle isRightSideBarOpen={isRightSideBarOpen} pathName={pathName}>
      <div className={`motif-theme-light right_sidebar ${isOpen ? 'open' : ''}`}>
        <div className='right_sidebar_component'>
          <div className='new-chat-button-container'>
            <button
              className={`new-chat-button ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              disabled={pathName === '/' || isLoading}
              // style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
              onClick={onNewChatClick}>
              <span className='newChatText'> New Chat </span>
            </button>
          </div>

          <ChatHistoryTabs>
            <ChatHistoryTab
              className={activeTabValue === 'Chat history' ? 'active' : ''}
              title='Chat history'>
              <div className='divider-light chatTab'></div>
              <div
                className={`chat-history-container ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
              >
                <div className='chat-thread-container'>
                  {userChatThreadHistory &&
                    userChatThreadHistory.map((chatThread: any, index: number) => {
                      return (
                        <div
                          className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                          // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                        >
                          {!chatThread.isEditEnabled ? (
                            <div
                              className={`${chatThread.isSelected ? 'thread-container-selected' : 'thread-container'} ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                              // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                              onClick={() => loadSelectedChatThread(chatThread)}>
                              <OverflowTooltip
                                tooltipContent={chatThread.title}
                                placement='right'
                                className='pointer'
                                tooltipClassName='ie-tooltip'
                                trigger='mouseenter'>
                                <p
                                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                  // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                >
                                  {chatThread.title?.length > 25
                                    ? chatThread.title?.substring(0, 25) + '...'
                                    : chatThread.title}
                                </p>
                              </OverflowTooltip>
                              <span
                                className={`${chatThread.isSelected ? 'show-options-selected' : 'show-options'} ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                              >
                                <span
                                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                  // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                  onClick={(event) =>
                                    updateChatThreadEditOption(event, chatThread, true)
                                  }>
                                  <img
                                    className={`action-icons edit-icon ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                    // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                    src={editIcon}
                                    alt='edit'></img>
                                </span>
                                <span
                                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                  // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                  onClick={(event) => deleteChatThread(event, chatThread, index)}>
                                  <img
                                    className={`action-icons ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                    // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                    src={deleteIcon}
                                    alt='Delete'></img>
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div className='editable-div-container'>
                              <input
                                type='text'
                                defaultValue={selectedChatThreadTitle}
                                disabled={isLoading}
                                onChange={(event) =>
                                  updateThreadTitleValue(chatThread, event.target.value)
                                }
                              />
                              <span
                                className={`edit-actions ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                              >
                                <span
                                  onClick={() => saveThreadTitle(chatThread)}
                                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                  // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                >
                                  <img
                                    className={`action-icons ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                    // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                    src={tickIcon}></img>
                                </span>
                                <span
                                  className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                  // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                  onClick={(event) =>
                                    updateChatThreadEditOption(event, chatThread, false)
                                  }>
                                  <img
                                    className={`action-icons reject-icon ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                    // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                    src={crossIcon}></img>
                                </span>
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div className='flex-chat-info'>
                  <div className='flex-1'>
                    <div className='chat-history-title'></div>
                  </div>
                  <div className='flex-1'>
                    <div className='chat-history-title'>
                      {userChatThreadHistory?.length}/{CHAT_HISTORY_MAX_LIMIT}
                    </div>
                  </div>
                </div>
              </div>
            </ChatHistoryTab>
            <ChatHistoryTab
              className={activeTabValue === 'Document history' ? 'active' : ''}
              title='Document history'>
              <div
                className={`chat-history-container ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
              >
                <div className='chat-thread-container'>
                  {userDocThreadHistory &&
                    userDocThreadHistory.map((documentThread: any, index: number) => {
                      return (
                        <div>
                          {!documentThread.isEditEnabled ? (
                            <div>
                              <div
                                className={`${documentThread.isSelected ? 'thread-container-selected' : 'thread-container'} ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                onClick={() => loadSelectedDocumentThread(documentThread)}>
                                <OverflowTooltip
                                  tooltipContent={documentThread.title}
                                  placement='right'
                                  className='pointer'
                                  tooltipClassName='ie-tooltip'
                                  trigger='mouseenter'>
                                  <p
                                    className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                    // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                  >
                                    {documentThread.title?.length > 25
                                      ? documentThread.title?.substring(0, 25) + '...'
                                      : documentThread.title}
                                  </p>
                                </OverflowTooltip>
                                <span
                                  className={`${documentThread.isSelected ? 'show-options-selected' : 'show-options'} ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                  // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                >
                                  <span
                                    className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                    // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                    onClick={(event) =>
                                      updateDocumentThreadEditOption(event, documentThread, true)
                                    }>
                                    <img
                                      className={`action-icons edit-icon ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                      // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                      src={editIcon}
                                      alt='edit'></img>
                                  </span>
                                  <span
                                    className={`${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                    // style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                    onClick={(docEvent) =>
                                      deleteDocumentThread(docEvent, documentThread, index)
                                    }>
                                    <img
                                      className={`action-icons ${isLoading ? 'cursor-not-allowed' : 'cursor-default'}`}
                                      style={{ cursor: isLoading ? 'not-allowed' : 'default' }}
                                      src={deleteIcon}
                                      alt='Delete'></img>
                                  </span>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className='editable-div-container'>
                              <input
                                type='text'
                                defaultValue={selectedDocumentThreadTitle}
                                disabled={isLoading}
                                onChange={(event) =>
                                  updateThreadTitleValue(documentThread, event.target.value)
                                }
                              />
                              <span onClick={() => saveDocumentThreadTitle(documentThread)}>
                                <img className='action-icons' src={tickIcon}></img>
                              </span>
                              <span
                                onClick={(event) =>
                                  updateDocumentThreadEditOption(event, documentThread, false)
                                }>
                                <img className='action-icons reject-icon' src={crossIcon}></img>
                              </span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div className='flex-chat-info'>
                  <div className='flex-1'>
                    <div className='chat-history-title'></div>
                  </div>
                  <div className='flex-1'>
                    <div className='chat-history-title'>
                      {userDocThreadHistory?.length}/{CHAT_HISTORY_MAX_LIMIT}
                    </div>
                  </div>
                </div>
              </div>
            </ChatHistoryTab>
            <div className='divider'></div>
          </ChatHistoryTabs>
          <div className='divider'></div>

          <div className='appContainer'>
            <p className='app-title'>Go to apps</p>
            <div className={checkIframe ? 'iframe-apps-container' : 'apps-container'}>
              {userApps?.length > 0 &&
                userApps?.map((item, index) => {
                  {
                    return <CommonAppsContainer item={item} key={index}></CommonAppsContainer>;
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
      </div>
    </RightSidebarStyle>
  );
};

const RightSidebarStyle = styled.div<{
  isRightSideBarOpen?: boolean;
  pathName: string;
}>`

.right_sidebar.open {
	width: 240px;
  border-style: solid;
  border-color: rgb(246, 246, 250);
}

.right_sidebar_component {
	.new-chat-button-container {
		height: 110px;
	}
	.chat-history-container {
		height: 50vh;
    padding-right : 0.7rem;
        ::-webkit-scrollbar-corner {
            background: rgba(0,0,0,0);
          }

		.flex-chat-info {
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			font-weight: 400;
      margin: 0% 3%;
      .chat-history-title{
        color : rgb(152, 151, 166);
      }
		}
	}
    .app-title{
        font-size:12px;
        font-weight: 400;
        color:#F9F9FA;
        padding: 0px 7px;
        color : #2E2E38;
        line-height: 15px; 

    }
    .appContainer {
        padding: 0px 10px 0px 10px;
        overflow:auto;
       .apps-container{
          position: relative;
          padding:5px;
          background:rgb(246, 246, 250) ;
          ::-webkit-scrollbar-corner {
              background:#FFFFFF ;
            }
          overflow : auto; 

          height: ${(props) => (props.pathName !== '/' ? (props.pathName == '/sectorChatPage' ? 'calc(40vh - 90px)' : props.pathName == '/uploadPage' ? 'calc(35vh - 20px)' : props.pathName == '/exploreData' ? 'calc(45vh - 80px)' : props.pathName == '/expertChatPage' ? 'calc(39vh - 80px)' : props.pathName == '/newChat' ? 'calc(60vh - 150px)' : props.pathName == '/surveyChatPage' ? 'calc(55vh - 150px)' : 'calc(55vh - 150px)') : '28vh')};
          @media screen and (min-width: 1100px) and (max-width: 1300px) {
            height: ${(props) => (props.pathName !== '/' ? (props.pathName == '/sectorChatPage' ? 'calc(55vh - 90px)' : props.pathName == '/uploadPage' ? 'calc(45vh - 20px)' : props.pathName == '/exploreData' ? 'calc(55vh - 80px)' : props.pathName == '/expertChatPage' ? 'calc(43vh - 80px)' : 'calc(65vh - 150px)') : '36vh')};
          }
             @media screen and (min-width: 1500px) and (max-width: 1600px) {
              height: ${(props) => (props.pathName !== '/' ? (props.pathName == '/sectorChatPage' ? 'calc(40vh - 90px)' : props.pathName == '/uploadPage' ? 'calc(35vh - 20px)' : props.pathName == '/exploreData' ? 'calc(45vh - 80px)' : props.pathName == '/expertChatPage' ? 'calc(39vh - 80px)' : props.pathName == '/newChat' ? 'calc(60vh - 150px)' : props.pathName == '/surveyChatPage' ? 'calc(55vh - 150px)' : 'calc(50vh - 150px)') : '28vh')};
          }
       }
          .iframe-apps-container{
             position: relative;
          padding:5px;
          height: ${(props) => (props.pathName !== '/' ? (props.pathName == '/sectorChatPage' ? 'calc(54vh - 90px)' : props.pathName == '/uploadPage' ? 'calc(45vh - 20px)' : props.pathName == '/exploreData' ? 'calc(55vh - 80px)' : props.pathName == '/expertChatPage' ? 'calc(43vh - 80px)' : 'calc(65vh - 150px)') : '28vh')};
          background:rgb(246, 246, 250) ;
          ::-webkit-scrollbar-corner {
              background:#FFFFFF ;
            }
          overflow : auto ; 
          @media screen and (min-width: 1196px) and (max-width: 1300px) {
            height: ${(props) => (props.pathName !== '/' ? (props.pathName == '/sectorChatPage' ? 'calc(54vh - 90px)' : props.pathName == '/uploadPage' ? 'calc(45vh - 20px)' : props.pathName == '/exploreData' ? 'calc(55vh - 80px)' : props.pathName == '/expertChatPage' ? 'calc(43vh - 80px)' : 'calc(65vh - 150px)') : '36vh')};

          }
          }
    }
    .chat-thread-container {
        margin-left:3%;
        height: 46vh;
        overflow:auto;
        p {
            // width:90%;
            padding-left: 10px;
            white-space: nowrap; 
            overflow: hidden;
            // text-overflow: ellipsis;
            cursor: default;
            font-family: EYInterstate;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-top: 9px;
            margin-bottom: 9px;
        }
        .thread-container:hover {
            background-color: #E6E6E9;
            cursor: default;
            border-radius: 4px;
            margin-top: 2px;
        }
    }
  
}

.right_sidebar {
	width: 0;
	position: absolute;
	top: 0;
	left: 0;
	transition: width 0.5s ease;
	background: #fff;
	z-index: 9;
  height: ${(props) => (props.isRightSideBarOpen ? '100%' : '90%')};
	overflow: hidden;
  box-shadow: -5px 0 16px -8px rgba(35, 35, 47, 0.2) inset
}


.new-chat-button {
  border-radius: 4px !important;
	.newChatText {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    position: relative;
    font-family: 'EY_INTERSTATE';
    padding: 8px, 14px, 8px, 14px
	}
  height: 36px;
  display: block;
  background-color: #000;
  color: white;
  text-decoration: none;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  top: 65%;
  border: 1px solid white;
  width: 85%;
  margin: auto; 
}
}
.chatTab {
position:relative;
top:-11px;
margin-left: 4px;
margin-right: 10px;
}
.show-options{
    display: none;

}
.thread-container {
    display: flex;
    margin-top : 2px;
    background-color: #F3F3F5;
    border-radius: 4px;
    margin-bottom: 8px;
}

.thread-container:hover .show-options{
  display: block;
  padding-top: 5%;
}

.action-icons {
    cursor: pointer;
    margin:0px 4px;
}

.thread-container:hover p{
    width:100%;
    text-overflow: ellipsis;
}
.reject-icon {
    position: relative;
}
.editable-div-container {    
    background-color: #C3C3CB;
    margin-top:2px;
    margin-bottom:8px;
    border-radius:4px;
}
.editable-div-container input{
  background-color: #C3C3CB;
    padding-left:10px;
    width: 80%;
    height: 38px;
    border: 1px solid #747480;
    border-radius: 4px;
}
.show-options-selected{
    display: block;
    padding-top: 5%;
}
.thread-container-selected 
{
  display:flex;
  background-color: #C3C3CB;
  cursor: default;
  border-radius: 4px;
  margin-top:2px;
  margin-bottom:8px;
}
.thread-container-selected  p{
  width:100%;
  text-overflow: ellipsis;
}
.edit-actions{
  padding-left:1%;
}
.overflow-wrapper{
  width:90%;
}
.thread-container:hover .overflow-wrapper,.thread-container-selected:hover .overflow-wrapper,.thread-container-selected .overflow-wrapper{
  width:78%;
}
.edit-icon{
  position:relative;
  top:-1px;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
cursor-default {
  cursor: default !important;
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .appContainer {
    .apps-container{
     height: ${(props) => (props.pathName !== '/' ? (props.pathName == '/sectorChatPage' ? 'calc(65vh - 90px)' : props.pathName == '/uploadPage' ? 'calc(45vh - 20px)' : props.pathName == '/exploreData' ? 'calc(55vh - 80px)' : props.pathName == '/expertChatPage' ? 'calc(43vh - 80px)' : 'calc(65vh - 150px)') : '45vh')};
    }
   }
}


`;

export default RightSidebar;
