import pptxgen from "pptxgenjs";
import { sanitizeHeaderText, downloadFilenameGenerator, downloadFilenameGeneratorForPPT } from ".";
import {
    IQueryContent,
    RESPONSE_BY,
    IRelevantFiles,
} from "../../../models/IQueryType";
import { publicSources } from "../../../label.constant";
import { getResponseSourceType } from "../../../utils/commonUtils";
import LABELS from '../../../ui-constants/en.json';

export type pptDataType = {
    userQueryWithResponse: any
    checkedCheckboxes?: any
    eyIPInterRadio?: any
    validURLinputValue?: any
};

export const exportToPPTSector = (
    userQueryWithResponse: any,
    checkedCheckboxes?: any,
    eyIPInterRadio?: any,
    validURLinputValue?: any
) => {
    let response = userQueryWithResponse?.SEResponse;
    let query = userQueryWithResponse?.user?.summary;
    const handleSourceSection = (sourceArray: string[]) => {
        let sourceType = '';
        if (checkedCheckboxes && checkedCheckboxes.length > 0) {
            if (checkedCheckboxes.length === 1 && checkedCheckboxes.includes(publicSources[0]))
                sourceType = publicSources[0];
            else if (checkedCheckboxes.length === 1 && checkedCheckboxes.includes(publicSources[1]))
                sourceType = publicSources[1];
            else if (
                checkedCheckboxes.length === 2 &&
                checkedCheckboxes.includes(publicSources[0]) &&
                checkedCheckboxes.includes(publicSources[1])
            )
                sourceType = 'Combined';
        }
        if (eyIPInterRadio) {
            sourceType = eyIPInterRadio;
        }
        if (validURLinputValue) {
            sourceType = 'Upload URL';
        }
        let getSources = getResponseSourceType(sourceType)
        sourceArray.shift();
        let modifiedSourceArray = [getSources, ...sourceArray];
        if (modifiedSourceArray && modifiedSourceArray.length > 0) {
            return modifiedSourceArray.join("\n");
        }
    };

    try {
        const sourceSection = response && handleSourceSection(response?.Sources);
        let presentation = new pptxgen();
        let slide = presentation.addSlide();
        let rows: pptxgen.TableRow[] = [];
        const sourceTable = [
          {
            text: `Source: ${sourceSection}`,
            options: {
              border: { type: 'none' },
              colspan: 2,
              margin: [10, 0, 10, 0],
              fill: {
                color: '#ffffff'
              }
            }
          }
        ];
        rows.push([{ text: query, options: { bold: true } }]);
        if (response) {
            let { relevantFiles, summary } = response;
            rows.push([{ text: summary, options: response?.responseType === "stop_generation" ? { color: '656579', italic: true } : {} }]);

            if (!relevantFiles) {
                if (response?.isParitallyAbortedResponse) {
                    rows.push([{ text: LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG, options: { color: '656579', italic: true } }]);
                }
                rows.push([{ text: `Source: ${sourceSection}` }]);
            }

            slide.addTable(rows, {
                autoPage: true,
            });

            if (
                relevantFiles &&
                relevantFiles.length > 0 &&
                relevantFiles.every((el: any) => Boolean(el) && typeof el === "object")
            ) {
                const documentTableRows: pptxgen.TableRow[] = [];
                const docuementTableHead: pptxgen.TableCell[] = [
                    {
                        text: sanitizeHeaderText("Relevant Document(s)"),
                        options: { bold: true },
                    },
                    {
                        text: sanitizeHeaderText("Author(s)"),
                        options: { bold: true },
                    },
                ];
                documentTableRows.push(docuementTableHead);
                relevantFiles.forEach((el: Partial<IRelevantFiles>) => {
                    const rowValues: pptxgen.TableCell[] = [];
                    const filesName = el.file_name;
                    let filesPage =
                        el?.page_number && el.page_number.length > 1
                            ? "(Slides "
                            : "(Slide ";

                    el.page_number?.forEach((slide: string, i) => {
                        if (el?.page_number && el.page_number.length > i + 1) {
                            filesPage = filesPage + slide + ", ";
                        } else {
                            filesPage = filesPage + slide + ")";
                        }
                    });
                    let authorData: string = "";
                    if (el.authors) {
                        authorData = el.authors.reduce((str, auth) => {
                            if (str === "") {
                                str = auth?.author_name;
                            } else {
                                str = str + ", " + auth?.author_name;
                            }
                            return str;
                        }, "");
                    }

                    rowValues.push({
                        text: filesName + "\n" + filesPage,
                    });

                    rowValues.push({
                        text: authorData,
                    });
                    documentTableRows.push(rowValues);
                });

                if (response?.isParitallyAbortedResponse) {
                    documentTableRows.push([{ text: LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG, options: { color: '656579', italic: true } }]);
                }

                documentTableRows.push(sourceTable as pptxgen.TableRow);
                presentation.addSlide().addTable(documentTableRows, {
                    border: { color: "e8e8ee", pt: 1, type: "solid" },
                    fill: {
                        color: "f7f7f8",
                    },
                    autoPage: true,
                    autoPageCharWeight: -0.5,
                    autoPageLineWeight: 0.5,
                });

            }
        }

        presentation
            .write({ outputType: "blob" })
            .then((file: any) => {
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file as Blob);
                link.download = downloadFilenameGeneratorForPPT(checkedCheckboxes, eyIPInterRadio, validURLinputValue, "ppt");
                link.click();
            })
            .catch((err: any) => console.log("::PPT catch errr", err));
    } catch (err) {
        console.log("::::PPT error", err);
    }
};
