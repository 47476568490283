export enum HttpStatusCode {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  Locked = 423,
  InternalServerError = 500,
  Forbidden = 403,
  TimedOut = 504,
  FunctionSelectionError = 501,
  NotAcceptable = 406
 }
 export enum TableExportType {
  PROSPECTIVE = "Prospective",
  ENGAGEMENT = "Engagement",
  DEFAULT = ""
}