import React from 'react';
import styled from 'styled-components';
import ReactDropdown, { Option } from 'react-dropdown';
import { arrowIcondownside, arrowIcondownsideBlack } from '../../commonImage';
import sassVars from '../../styles/variables.module.scss';
const { alternateblack, gray3A, gray60, fontInterstate } = sassVars;
interface IOwnProps {
  options: Option[];
  defaultValue?: string | Option;
  disabled?: boolean;
  onChange?: (arg: Option) => void;
  onFocus?: () => void;
  classCss?: string;
  placeholder?: string;
  displayOptions?: boolean;
  textTransform?: string;
  showBackground?: boolean;
  isSideBarOpen?: boolean;
  isLoading?: boolean;
  customRef?: any;
}

const Dropdown: React.FC<IOwnProps> = ({
  options,
  defaultValue,
  disabled = false,
  onChange,
  onFocus,
  classCss,
  placeholder,
  showBackground,
  isSideBarOpen,
  isLoading,
  customRef
}: IOwnProps) => {
  return (
    <StyledReactDropdown
      showBackground={showBackground}
      className={classCss ? classCss : ''}
      isSideBarOpen={isSideBarOpen}
      isLoading={isLoading}>
      <ReactDropdown
        arrowClosed={
          <span className='dropdown-arrow-wrapper'>
            {' '}
            <img
              alt='arrowIcondownside'
              src={showBackground ? arrowIcondownsideBlack : arrowIcondownside}
            />
          </span>
        }
        arrowOpen={
          <span className='arrow-open'>
            {' '}
            <img alt='arrowIconside' src={arrowIcondownside} />
          </span>
        }
        placeholderClassName={'overflow-content-class-none'}
        placeholder={placeholder}
        controlClassName={`${disabled && 'disabled'}`}
        menuClassName='gray-2'
        options={options}
        onChange={onChange}
        onFocus={onFocus}
        value={defaultValue}
        ref={customRef}
      />
    </StyledReactDropdown>
  );
};

const StyledReactDropdown = styled.div<{
  textTransform?: string;
  className?: string;
  showBackground?: boolean;
  isSideBarOpen?: boolean;
  isLoading?: boolean;
}>`
    cursor: ${({isLoading}): string => `${isLoading ? 'not-allowed' : 'pointer'}`};
    position: relative;
    max-width: fit-content;
    z-index: ${(props): string => `${props.className ? 11 : 10}`};
    .Dropdown-control {
        margin-bottom: 2px;
        height: 44px;
        &.disabled {
            pointer-events: none;
            opacity: 0.3;
        }
    }
    .arrow-open{
        transform: rotate(
            180deg);
    }
    .Dropdown-control {
          background: ${(props): string => `${props.showBackground ? '#E7E7EA' : 'transparent'}`} ;
          border: 1px solid #C3C3CB;
          border-color: white;
          color: ${(props): string => `${props.showBackground ? '#2E2E38' : '#f6f6fa'}`} ;
          padding: 6px 14px 6px 8px;
          text-align: left;
          font-size: 12px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 4px;
        }
        .Dropdown-option {
            // background: #f6f6fa;
            padding: 6px 14px 6px 8px;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            cursor: pointer;
            outline: 1px solid transparent;
            font-weight: lighter;
            font-family: ${fontInterstate};
            font-size: 14px;
            line-height: 16px;
            border-radius: 4px;
            text-transform: ${(props): string => `${props.textTransform}`};
              display: ${(props): string => `${props.className ? 'none' : 'block'}`}
        }
        .Dropdown-option: hover {
            background-color: #E7E7EA;
        }
    }

    .Dropdown-control {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        border: 1px solid #ccc;
        width: ${(props): string => `${props.isSideBarOpen ? '214px' : '286px'}`};
    }
    .Dropdown-placeholder{
      overflow: hidden;
      color: var(--Neutrals-Light-theme-700, #656579);
      text-overflow: ellipsis;
      font-family: EYInterstate;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
    }
    .Dropdown-arrow-wrapper {
        font-size: 0.8125rem;
        display: flex;
        padding-left: 14px;
        align-items: center;
        color: ${gray60};

    }

    .Dropdown-menu {
        position: absolute;
        top: 105%;
        z-index: 9;
        font-size:12px;
        border-radius: 4px;
        background: #f6f6fa;
        width:100%;
        color: ${gray3A};
        font-family: ${fontInterstate};

}
.dropdown-arrow-wrapper {
  padding-top: 8px;
}
`;

export default Dropdown;
