import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { breadcrumbImg } from '../../../commonImage';
import { useDispatch, useSelector } from 'react-redux';
import { type IStore } from '../../../store';
import {
  selectSourceOnNewChat,
  setByodDocOpenClose,
  setRightSideBarOpenClose,
  setSideBarOpenClose,
  setSurveyDocOpenClose
} from '../../../store/actions/commonActions';
import { useNavigate } from 'react-router-dom';
import {
  clearChatHistory,
  selectedDataSourceSection,
  setLastSavedThreadID,
  setSelectedChatThread,
  setSelectedDocumentThread
} from '../../../store/actions/userChatActions';
import { DataSourceTypes } from '../../../models/IDataSourceTypes';

const Breadcrumb = (items: any) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const selectedChatThread = useSelector((state: IStore) => state.userChat.selectedChatThread);
  const selectedDocumentThread = useSelector(
    (state: IStore) => state.userChat.selectedDocumentThread
  );
  const isLoading = useSelector((state: any) => state.userChat.isLoading);

  const homeIconClick = () => {
    if (!isLoading) {
      history('/');
      dispatch(selectSourceOnNewChat('radio-public'));
      dispatch(selectedDataSourceSection(DataSourceTypes.PublicData));
      selectedChatThread.isSelected = false;
      dispatch(setLastSavedThreadID(0));
      dispatch(setSelectedChatThread({}));
      selectedDocumentThread.isSelected = false;
      dispatch(setSelectedDocumentThread({}));
      dispatch(clearChatHistory());
      dispatch(setSideBarOpenClose(false));
      dispatch(setRightSideBarOpenClose(false));
      dispatch(setSurveyDocOpenClose(false));
      dispatch(setByodDocOpenClose(false));
      localStorage.removeItem('surveycustomperiod');
      localStorage.removeItem('surveyDateSelectedOption');
      localStorage.removeItem('selectedSurveyList');
      localStorage.removeItem('selectedDropSectors');
      localStorage.removeItem('surveytoDate');
      localStorage.removeItem('surveyfromDate');
      localStorage.removeItem('queryFromDate');
      localStorage.removeItem('queryToDate');
    }
  };

  return (
    <StyledBreadcrumb isLoading={isLoading}>
      {items.items.map((item: any, index: any) => (
        <Fragment key={index}>
          {index > 0 && (
            <span className='separator'>
              <img alt='/' src={breadcrumbImg} />
            </span>
          )}
          {!item.current ? (
            <span className={'link-route'} onClick={homeIconClick}>
              {item.label}
            </span>
          ) : (
            <span className={item.current ? 'current-route' : ''}>{item.label}</span>
          )}
        </Fragment>
      ))}
    </StyledBreadcrumb>
  );
};

const StyledBreadcrumb = styled.div<{ isLoading: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-bottom: 1rem;

  .separator {
    margin: 0 10px;
    color: #9897a6;
  }

  .link-route {
    color: #0f69ae;
    cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'pointer')};
    text-decoration: none;
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    padding: 4px 8px 4px 8px;
    border-radius: 6px 0px 0px 0px;
  }

  .current-route {
    padding: 5px 10px;
    color: #9897a6;
    border-radius: 6px;
    margin-right: 5px;
    font-family: EYInterstate;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    padding: 4px 8px 4px 8px;
  }
`;

export default Breadcrumb;
