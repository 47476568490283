import { get, getWithStatus, postWithStatus } from './api.service';
import apis from '../../src/services/api';
import { handleFetchException } from './chatQuery.service';
import { customErrorStatus } from './userProfile.service';
import ErrorStatus from '../utils/ErrorStatus';

export const initateNewProject = async (requestObject: Object) => {
  return postWithStatus(apis.createNewProject, requestObject)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const errorDetails = handleFetchException(error);
      return { validationError: error?.response?.data?.errors, ...errorDetails };
    });
};

export const createTeamsChannelAsync = async (projectId?: string) => {
  return postWithStatus(apis.createTeamsChannel + `/${projectId}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const addMembersAsync = async (projectId?: String): Promise<any> => {
  return postWithStatus(apis.addMembersToChannel + `/${projectId}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const installAppForTeams = async (projectId?: Number): Promise<any> => {
  return postWithStatus(apis.installAppForTeams + `/${projectId}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const uploadFolderAsync = async (projectId: string, templateId: string): Promise<any> => {
  return postWithStatus(apis.createTemplateFolders + `/${projectId}/${templateId}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const getPaceID = async (paceID: string): Promise<any> => {
  return get(apis.getPaceID + `/${paceID}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const allTemplateResponse = async (): Promise<any> => {
  return getWithStatus(apis.createProjectTemplate, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const searchMemberResponse = async (input: any): Promise<any> => {
  return postWithStatus(apis.createProjectSearchMember + `/${input}`, {})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return handleFetchException(error);
    });
};

export const getWebUrlforTeams = async (teamId?: String): Promise<any> => {
  try {
    const data = await getWithStatus(apis.getWebUrlforTeams + `/${teamId}`, {});
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return ErrorStatus(error?.response?.status);
  }
};

export const getSharePointTokenForTeams = async (): Promise<any> => {
  try {
    const data = await getWithStatus(apis.getSharePointTokenForTeams, {});
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return ErrorStatus(error?.response?.status);
  }
};