import { post } from './api.service';
import apiRoutes from './api';
import errorStatus from '../utils/ErrorStatus';
import { HttpStatusCode } from '../ui-constants/base/enum';
import { RESPONSE_STATUS_CODE } from '../app.constant';

export const customErrorStatus = (message: any): any => {
  switch (message) {
    case 'Network Error':
      return errorStatus(RESPONSE_STATUS_CODE.INTERNAL_SERVER_ERROR);
    default:
      return errorStatus(RESPONSE_STATUS_CODE.INTERNAL_SERVER_ERROR);
  }
};

export const saveUserFeedback = async (formData: FormData): Promise<any> => {
  try {
    const data = await post(apiRoutes.saveuserFeedback, formData);
    return { status: HttpStatusCode.Success, data: data };
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};
