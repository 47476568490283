import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IStore } from '../../../store';
import { MotifLabel } from "@ey-xd/motif-react";
import { unselectedStar, star, tooltip,  } from '../../../commonImage';
import OverflowTooltip from '../OverflowTooltip';
import { truncateString } from '../../../utils/commonUtils';

interface cardsProps {
    pragaphText?: string;
    appTitle: string;
    isFavourite?: boolean;
    onFavouriteClick: (e: any) => void;
    onCardClick: (e: any) => void;
    images?: string;
}

const Cards: React.FC<cardsProps> = ({ pragaphText, appTitle, isFavourite, onFavouriteClick, onCardClick, images}) => {
    const isSideBarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
    const imageUrl = images ? require(`../../../assets/images/cards-images/${images}.jpeg`) : '';

    const getAppTileToolTip = (pragaphText: any) => (
      <>
        <div className='tooltip-info-spacer'></div>
        <span className='tooltip-info-description'>{pragaphText}</span>
        <br />
      </>
    );

    return (
        //@ts-ignore
        <StyleCard isOpen={isSideBarOpen}>
            <div className="card">
                <div onClick={onCardClick}>
                    <img src={imageUrl} alt="embryonic" className='image-card'></img>
                    <div className="bottom">
                        <label className="title">{appTitle}</label>
                        <span className="favourite-class" onClick={onFavouriteClick}><img className='starImage' src={isFavourite ? star : unselectedStar}></img></span>
                    </div>
                </div>
                <div className='cards-tooltip'>
                    <span className='label-width' onClick={onCardClick}>
                        <MotifLabel className="text">{truncateString(pragaphText, 80)}</MotifLabel>
                    </span>
                    <OverflowTooltip 
                        className='tooltip-container' 
                        tooltipContent={getAppTileToolTip(pragaphText)} 
                        tooltipClassName="tooltip-placement"
                        placement='bottom-start' 
                        trigger='click'>
                        <img className="tooltip-image" src={tooltip} alt="tooltip"></img>
                    </OverflowTooltip>
                </div>
            </div>
          
        </StyleCard>
    )
}

const StyleCard = styled.div<{
    isOpen: boolean;
}>`
.label-width {
    width: 100%;
    margin-top: 7px;
}
.container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
  }

.card {
    flex: 1 0 20%;
    flex-shrink: 0; 
    width: 280px;
    height: 268px;
    border: 1px solid #E6E6E9;
    border-radius: 6px;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    box-shadow: 0px 4px 8px 0px #23232F14;
    background: #fff;
    cursor: pointer;
    margin: 34px 5px 0 7px;
}

.pointer {
    cursor: default!important;
}

.text {
    color: #9897A6;
    font-size: 13px;
    line-height: 20px;
}

.bottom {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 14px;
    font-weight: 400;
    position: relative;
    color: rgb(46, 46, 56);
    position: relative;
    top: 5px;
    cursor: pointer;
}

.favourite-class {
    position: relative;
    padding: 2px;
    height: 30px;
width: 30px;
text-align: center;
.starImage{
    position: relative; 
    top: 5px;
}
}
.favourite-class:hover {
 position: relative;
padding: 2px;
background: #E6E6E9;
height: 30px;
width: 30px;
text-align: center;
border-radius:5px;
.starImage{
    position: relative; 
    top: 5px;
}
}

.favourite-icon {
    font-size: 24px;
}

.tooltip-image {
    height: 30px;
    width: 30px;
    position: relative;
    top: 40px;
    left: 3px;
}
.cards-tooltip {
    display: flex;
    position: relative;
    top: -2px;
}

.image-card{
    width: 100%;
    height: 140px;
}

@media screen and (min-width: 1195px) and (max-width: 1350px) {
    .card {
        width: 260px;
    }
  }
`
export default Cards;
