import styled from 'styled-components';
import SourceSelection from './sourceSelectionBoxes/SourceSelection';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store';
import { sideBarExpanded, sidebarExpendedOpen } from '../../commonImage';
import { setByodDocOpenClose, setSideBarOpenClose } from '../../store/actions/commonActions';
import AIDisclaimerText from './AIDisclaimerText';
import { createGlobalStyle } from 'styled-components';

interface SideBarProps {
  isOpen?: boolean;
  dataSources?: any;
  isLoading?: boolean;
}

const Sidebar: React.FC<SideBarProps> = ({ isOpen, dataSources, isLoading }) => {
  const dispatch = useDispatch();
  const selectedDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const anySourceSelected = useSelector((state: IStore) => state?.userChat?.anySourceSelected);
  const [isSourceSelected, setSourceSelected] = useState<any>(!anySourceSelected);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);
  const isSourceSideBarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
  const pathName = window.location.pathname;

  useEffect(() => {
    setSourceSelected(anySourceSelected);
  }, [anySourceSelected, isSourceSelected]);

  const toggleSourceSideBar = () => {
    dispatch(setSideBarOpenClose(!isSourceSideBarOpen));
  };
  const checkIframe = window.location !== window.parent.location
  return (
    
    <>
    <GlobalStyle isSideBarOpen={isOpen ?? false} />
    <SideBarStyle isSideBarOpen={isOpen} isRightSideBarOpen={isRightSideBarOpen} pathName={pathName}>
      <img
        src={sideBarExpanded}
        onClick={toggleSourceSideBar}
        className={`toggleSourceSideBar-${!isSourceSideBarOpen ? 'show' : 'hide'}`}></img>
      <div className={`sidebar ${isSourceSideBarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <img
          src={sidebarExpendedOpen}
          onClick={toggleSourceSideBar}
          className='toggleSourceSideBar'></img>
        <div className='sidebar-component'>
          <label htmlFor='sidebar' className='select-source hide-source'>
            {'Select source(s)'}
          </label>
          {dataSources && <SourceSelection dataSources={dataSources} isLoading={isLoading} />}
          <div className={checkIframe ? "AITaxonomyTeams": 'AITaxonomy'}> <AIDisclaimerText /></div>
        </div>
      </div>
    </SideBarStyle>
    </>
  );
};

const SideBarStyle = styled.div<{
  isSideBarOpen?: boolean;
  isRightSideBarOpen?: boolean;
  pathName: string;
}>`
  .sidebar {
    position: absolute;
    width: 0;
    top: 0px;
    right: 0;
    background-color: #FFFFFF;
    color: #4D4D5C;;
    z-index: 9;
    height: 100%;
    border-width: 0px, 1px, 0px, 1px;
    border-style: solid;
    border-color: rgba(246, 246, 250, 1);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .sidebar-closed{
    overflow: hidden;
  }
  .sidebar.sidebar-open {
    width: 18.5rem;
  }
  .sidebar-component {
    padding: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .sidebar-open::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    box-shadow: rgba(35, 35, 47, 0.8) 2px 0px 7px 0.2px;
  }

  .option {
    margin: 15px 0 8px 0;
  }

  .checkboxes {
    margin-left: 30px;
  }
  .select-source {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .single-checkbox {
    padding: 5px 0;
  }

  .motif-check {
    border: 1px solid #000;
  }

  .sidebar-open .bg-img-div {
    width: calc(100% - 301px);
  }

.toggleSourceSideBar,
.toggleSourceSideBar-show,
.toggleSourceSideBar-hide {
  top: 5rem;
  position: absolute;
  border: none;
}

.toggleSourceSideBar,
.toggleSourceSideBar-show {
  cursor: pointer;
}

.toggleSourceSideBar {
  left: -14%;
}

.toggleSourceSideBar-show {
  right: 1rem;
  z-index: 999;
}

.toggleSourceSideBar-hide {
  display: none;
  right: 0;
}

  @media screen and (max-width: 1242px) {
    .sidebar {
      height: ${(props) =>
        props.isRightSideBarOpen ? 'calc(100% + -40px)' : 'calc(100% + -40px)'};
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1358px) {
    .sidebar {
      height: calc(100% + -40px);
    }
  }

  .AITaxonomy {
    bottom: 0;
    width: 100%;
    padding-bottom: 20px;
    @media screen and (min-width: 1100px) and (max-width: 1360px) {
      padding-bottom: 0px;
    }
  }
  .AITaxonomyTeams {
    bottom: 0;
    width: 100%;
    padding-bottom: 20px;
    @media screen and (min-width: 1100px) and (max-width: 1360px) {
      padding-bottom: 22px;
    }
  }
  .hide-source{
    visbility: none;
    padding-bottom: 20px;
  }
`;

const GlobalStyle = createGlobalStyle<{ isSideBarOpen: boolean }>`
  body {
    overflow-x: ${props => props.isSideBarOpen ? 'visible' : 'hidden'} !important;
  }
  `;
export default Sidebar;
