import { FC } from 'react';
import { MotifRadioButton } from "@ey-xd/motif-react";

type RadioButtonProps = React.InputHTMLAttributes<HTMLInputElement> & {
    id: string;
    checked?: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    name?: string;
    value?: string;
    className?: string;
    children?: React.ReactNode;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const RadioButton: FC<RadioButtonProps> = ({
    id,
    checked = false,
    indeterminate,
    disabled,
    name,
    value = '',
    className = '',
    children,
    onChange,
    ...other
}) => (
    <MotifRadioButton
        {...other}
        id={id}
        name={name}
        checked={checked}
        indeterminate={indeterminate}
        disabled={disabled}
        value={value}
        className={className}
        // @ts-ignore
        onChange={onChange}>
 {/* @ts-ignore */}
        {children}
    </MotifRadioButton>
);

export default RadioButton;
