import {
  createContext,
  useEffect,
  useState,
  useContext,
  Fragment,
  useCallback,
  useRef,
} from "react";
import _ from "lodash";
import MotifSearch from "@ey-xd/motif-react/Search";
import {
  MotifButton,
  MotifCheckbox,
  MotifDropdown,
  MotifIcon,
  MotifFormField,
} from "@ey-xd/motif-react";
import { useSelector } from "react-redux";
import { IStore } from "../../store";
import { MotifButtonGroup } from "@ey-xd/motif-react/Button";
import { v4 as uuidv4 } from "uuid";
import "../ExploreData/ExploreData.css";
import noDocumentImg from "../../assets/images/byod-images/noDocument.png";
import noLinkIcon from "../../assets/images/byod-images/no-link.svg";
import favoriteIcon from "../../assets/images/byod-images/favorite.svg";
import cancelIcon from "../../assets/images/byod-images/cancel.svg";
import { DashboardContext } from "../../context/dashboardContext.js";
import { MotifIconButton } from "@ey-xd/motif-react/components/button/index.js";
import Utils from "../../utils/Utils.js";
import ARROW_UP from "../../assets/images/byod-images/Arrow_up.svg";
import ARROW_DOWN from "../../assets/images/byod-images/Arrow_down.svg";
import PublicSearchIcon from "../../assets/images/byod-images/open-in-browser.svg";
import { FileStatus } from "../../label.constant";
import { useNavigate } from 'react-router-dom';
import upload from "../../assets/images/byod-images/upload.svg";
import {
  navigationIcClose24px,
  // @ts-ignore
} from "@ey-xd/motif-react/assets/icons";
import ExploreDataService from "../../services/exploreDataService.js";
import { explorerDataConstants } from "./constants.js";
import search from "../../assets/images/byod-images/search.svg";

const ExloreRightPanal = ({ publicOrConfidential, selectdocs,checkedSourceDocs,selectedDropDowndocs,checkedDropdownDocs }) => {
  const utils = new Utils();
  const exploreDataService = new ExploreDataService();
  const [srcdocClick, setSrcDocClick] = useState(true);
  const [favorClick, setFavorClick] = useState(false);
  const [historyClick, setHistoryClick] = useState(false);
  const [docs, setDocs] = useState([]);
  const docsref=useRef([]);
  const [favoritesPrompts, setFavoritesPrompts] = useState([]);
  const [historyPrompts, setHistoryPrompts] = useState([]);
  const [filteredFavoritesPrompts, setFilteredFavoritesPrompts] = useState([]);
  const [currentPromptType, setCurrentPromptType] = useState("");
  const [searchFavPrompt, setSearchFavPrompt] = useState("");
  const [searchHisPrompt, setSearchHisPrompt] = useState("");
  const [filteredHistoryPrompts, setFilteredHistoryPrompts] = useState([]);
  const [currentPromptTypeHistory, setCurrentPromptTypeHistory] = useState("");
  const [isSrcDocDrpDwnOpen, setIsSrcDocDrpDwnOpen] = useState(false);
  const [srcDocDrpDwnItems, setSrcDocDrpDwnItems] = useState(
    explorerDataConstants.Types
  );
  const [filterTypes, setFilterTypes] = useState([]);
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const selectedDocumentThread = useSelector(
    (state: IStore) => state.userChat.selectedDocumentThread
  );
  const initialSrcDocDrpDwnItems = [
    { id: "id1", type: "Select all", actualType:'', checked: false },
    { id: "id2", type: "Doc", actualType:'Docx,Doc', checked: false },
    { id: "id3", type: "Pdf", actualType:'Pdf', checked: false },
    { id: "id4", type: "Pptx", actualType:'Pptx', checked: false },
    { id: "id5", type: "Txt", actualType:'Txt', checked: false },
    { id: "id6", type: "Xls", actualType:'Xlsx,Xls', checked: false },
  ];
  const userEmail = useSelector((state: IStore) => state.common?.userEmail);
  const redirectTo = useNavigate();
    let {
      GetDocuments,
      getAlldocuments,
      setViewPrompt,
      setIsNewPromptCoversation,
    }:any = useContext(DashboardContext);
  

  useEffect(() => {
    const getDocs = async () => {
      await GetDocuments(selectedDocumentThread?.threadId);
    };
    getDocs();
    setCurrentPromptType("C");
    setCurrentPromptTypeHistory("C");
  }, [selectedDocumentThread?.threadId]);

  useEffect(() => {
    let Prompts = _.filter(historyPrompts, function (item) {
      return (
        item.requestContent
          ?.toLowerCase()
          .includes(searchHisPrompt.toLowerCase()) ||
        item.responseContent
          ?.toLowerCase()
          ?.includes(searchHisPrompt.toLowerCase())
      );
    });
    setFilteredHistoryPrompts(Prompts);
  }, [searchHisPrompt]);

  useEffect(() => {
    let prompts = _.filter(favoritesPrompts, function (item) {
      return (
        item.requestContent
          ?.toLowerCase()
          ?.includes(searchFavPrompt.toLowerCase()) ||
        item.responseContent
          ?.toLowerCase()
          ?.includes(searchFavPrompt.toLowerCase())
      );
    });
    setFilteredFavoritesPrompts(prompts);
  }, [searchFavPrompt]);

  useEffect(() => {
     if (historyClick) {
      getPromptDetails();
    }
  }, [ currentPromptType, historyClick, currentPromptTypeHistory]);

  const getPromptDetails = async () => {
    const promptType = "H";
    const dataType = "C";
    let confidentialHistoryprompts = await exploreDataService.GetPromptDetails(
      selectedDocumentThread?.threadId,
      promptType,
      dataType,
      userEmail
    );
    if (historyClick) {
      setHistoryPrompts(confidentialHistoryprompts);
      setFilteredHistoryPrompts(confidentialHistoryprompts);
    }
  };

  const searchHistoryPrompt = (e) => {
    setSearchHisPrompt(e.target.value);
  };
  const searchFavoritePrompt = (e) => {
    setSearchFavPrompt(e.target.value);
  };
  const isActivePromptTypeButton = () => {
    return currentPromptType === "C";
  };
  const isActivePromptTypeButtonHistory = () => {
    return currentPromptTypeHistory === "C";
  };
  const changePromptType = () => {
    if (currentPromptType === "C") {
      setCurrentPromptType("P");
    } else {
      setCurrentPromptType("C");
    }
  };
  const changePromptTypeHistory = () => {
    if (currentPromptTypeHistory === "C") {
      setCurrentPromptTypeHistory("P");
    } else {
      setCurrentPromptTypeHistory("C");
    }
  };

  const deletePrompt = async (event, promptId) => {
    event.stopPropagation();
     await exploreDataService.DeletePrompt(promptId);
     getPromptDetails();
  };

  const showPrompt = (prompt) => {
    setViewPrompt(prompt);
  };

  // Getting docs from backend API calla
  useEffect(() => {
    let readyDocuments = getAlldocuments.filter(function (doc) {
      return doc.status === FileStatus.COMPLETE && doc.errorMessage==null;
    });
    readyDocuments.map((item) => (item.selected = false));
    setDocs(readyDocuments);
    if(checkedSourceDocs && checkedSourceDocs.length > 0){
      setDocs(checkedSourceDocs);
    setSrcDocDrpDwnItems(checkedDropdownDocs);
    setFilterTypes(
      checkedDropdownDocs
        .filter((itm) => itm.type !== "Select all" && itm.checked)
        .map((object) => object.type)
    );
    
    const newFilteredDocs = checkedSourceDocs;
    if(checkedDropdownDocs[0].checked)
   {

    newFilteredDocs.map((item) => {
      item.selected = true;
    });
    setDocs(newFilteredDocs);
  }
  else if(checkedDropdownDocs.filter((itm) => itm.type !== "Select all" && itm.checked).length>0){
    const selectedItems = checkedDropdownDocs.filter((itm) => itm.type !== "Select all" && itm.checked);
    newFilteredDocs.map((item) => { 
      selectedItems.map((updatedItem) => {
      if (
        updatedItem.actualType.toLowerCase().includes(
        item.documentName.split(".").pop().toLowerCase())
      ) {
        item.selected = item.selected ? true : true;
      }
    });
    });
    setDocs(newFilteredDocs);
  }
    }
    else{
      setFilterTypes([])
      setSrcDocDrpDwnItems(initialSrcDocDrpDwnItems);
    }
    
   }, [getAlldocuments]);

  const setClickOutside = () => {
    setIsSrcDocDrpDwnOpen(false);
  };

  const createNewConversation = () => {
    setIsNewPromptCoversation(false);
    if (sessionStorage.getItem("PromptConversationId")) {
      sessionStorage.removeItem("PromptConversationId");
    }
    sessionStorage.setItem("PromptConversationId", uuidv4());
    setIsNewPromptCoversation(true);
  };

  const handleToggle = () => {
    setIsSrcDocDrpDwnOpen(!isSrcDocDrpDwnOpen);
  };

  const onChecked = (e, itemID) => {
    const currentIndex = srcDocDrpDwnItems.findIndex(
      (item) => item.id === itemID
    );
    const currentItem = srcDocDrpDwnItems.find((item) => item.id === itemID);

    setSelectedCheckbox(e.currentTarget);

    let checked = !currentItem.checked;

    if (currentItem.type === "Select all") {
      let newSrcDrpDwnItems = srcDocDrpDwnItems;
      let filtTypes = [];
      newSrcDrpDwnItems.forEach((iter) => {
        iter.checked = checked;
      });
      let newFilteredDocs = docs;
      if (checked) {
        newFilteredDocs.forEach((item) => {
          item.selected = item.selected ? true : true;
        });
        filtTypes = newSrcDrpDwnItems
          .filter((itm) => itm.type !== "Select all")
          .map((object) => object.type);
      } else {
        newFilteredDocs.forEach((item) => {
          item.selected = false;
        });
        filtTypes = [];
      }
      setFilterTypes(filtTypes);
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      selectedDropDowndocs(newSrcDrpDwnItems);
      setDocs(newFilteredDocs);
    } else {
      const updatedItem = {
        ...srcDocDrpDwnItems[currentIndex],
        checked: !currentItem.checked,
      };
      const newSrcDrpDwnItems = [...srcDocDrpDwnItems];
      newSrcDrpDwnItems[currentIndex] = updatedItem;
      setFilterTypes(
        newSrcDrpDwnItems
          .filter((itm) => itm.type !== "Select all" && itm.checked)
          .map((object) => object.type)
      );
      let newFilteredDocs = docs;
      if (checked) {
        newFilteredDocs.forEach((item) => {
          if (
            updatedItem.actualType.toLowerCase().includes(item.documentName.split(".").pop().toLowerCase())
          ) {
            item.selected = true;
          }
        });
      } else {
        newFilteredDocs.forEach((item) => {
          if (
            updatedItem.actualType.toLowerCase().includes(item.documentName.split(".").pop().toLowerCase())
          ) {
            item.selected = false;
          }
        });
        newSrcDrpDwnItems.forEach((item) => {
          if (item.type === "Select all") {
            item.checked = false;
          }
        });
        const selectAllIndex = srcDocDrpDwnItems.findIndex(
          (item) => item.type === "Select all"
        );
        const selectAllItem = {
          ...srcDocDrpDwnItems[selectAllIndex],
          checked: false,
        };
        newSrcDrpDwnItems[selectAllIndex] = selectAllItem;
      }
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      selectedDropDowndocs(newSrcDrpDwnItems);
      setDocs(newFilteredDocs);
    }
    if (docs?.length > 0) selectdocs(docs);
  };

  // Doc selection from source document tab
  const onDocChange = (e, docId) => {
    const currentIndex = docs.findIndex((item) => item.documentId === docId);
    const currentItem = docs.find((item) => item.documentId === docId);
    let checked = !currentItem.selected;
    const updatedDoc = {
      ...docs[currentIndex],
      selected: checked,
    };
    const newDocs = [...docs];
    newDocs[currentIndex] = updatedDoc;
    setDocs(newDocs);
    docsref.current=newDocs;

    // If we have only one type of document is deselected then we are removing the filters
    let noOfTypedDocs = getLastSelectedType(currentItem);
    if (noOfTypedDocs === 1 && !checked) {
      let item = currentItem.documentName.split(".").pop();
      if (
        currentItem.documentName.split(".").pop() ===
        explorerDataConstants.excelComparator
      ) {
        item = explorerDataConstants.dropdownExcelValue;
      } else if (
        currentItem.documentName.split(".").pop() ===
        explorerDataConstants.docComparator
      ) {
        item = explorerDataConstants.dropdownDocValue;
      }
      let newSrcDrpDwnItems = srcDocDrpDwnItems;
      newSrcDrpDwnItems.forEach((itm) => {
        if (itm.type.toLowerCase() === item.toLowerCase()) {
          itm.checked = false;
        }
      });
      let newFilterTypes = filterTypes.filter((itm) => {
        return itm.toLowerCase() !== item.toLowerCase();
      });
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      selectedDropDowndocs(newSrcDrpDwnItems);
      setFilterTypes(newFilterTypes);
    }
    if (docs?.length > 0)
    selectdocs(docsref.current)
     //selectdocs(docs);
  };

  const getLastSelectedType = (currentItem) => {
    let docsWithSelectedType = docs.filter((item) => {
      return (
        item.documentName.split(".").pop() ===
        currentItem.documentName.split(".").pop()
      );
    });
    return docsWithSelectedType.filter((item) => {
      return item.selected === true;
    }).length;
  };

  // source doc type tablet cancel click
  const handleCancelClick = (item) => {
    let actItem = item === "Doc" ? "Docx,Doc" : item === "Xls" ? "Xlsx,Xls" : item;

    setFilterTypes(
      filterTypes.filter((itm) => {
        return itm !== item;
      })
    );
    let currentItemIndex = srcDocDrpDwnItems.findIndex(
      (ite) => ite.actualType.includes(actItem)
    );
    let updatedItem = {
      ...srcDocDrpDwnItems[currentItemIndex],
      checked: false,
    };
    let selAllIndex = srcDocDrpDwnItems.findIndex(
      (ite) => ite.type === "Select all"
    );
    let updatedSelAllItem = {
      ...srcDocDrpDwnItems[selAllIndex],
      checked: false,
    };
    const newSrcDrpDwnItems = [...srcDocDrpDwnItems];
    newSrcDrpDwnItems[currentItemIndex] = updatedItem;
    newSrcDrpDwnItems[selAllIndex] = updatedSelAllItem;
    setSrcDocDrpDwnItems(newSrcDrpDwnItems);
    selectedDropDowndocs(newSrcDrpDwnItems);
    let newDocs = docs;
    newDocs.forEach((iteration) => {
      if (
        actItem.toLowerCase().includes(iteration.documentName.split(".").pop().toLowerCase())
      ) {
        iteration.selected = false;
      }
    });
    setDocs(newDocs);
  };

  return (
    <div className="right-panel-container">
      <div className="right-panel-container-newprompt-container">
      </div>
      { srcdocClick && docs.length > 0 ? (
         <div className="allupload-docs">
          <div className="right-panel-doc-count">
            <span className="right-panel-doc-count-text">
              Source documents
            </span>
            <span className="right-panel-doc-count-digit">
              {docs.length}
            </span>
          </div>
          <div className="right-panel-select-files-text">Select File Type</div>
          <div className="motif-col-xs-3 motif-col-sm-3 motif-col-md-2 motif-col-lg-2 motif-col-xl-2 right-panel-select-files-dropdown">
            <MotifDropdown
              open={isSrcDocDrpDwnOpen}
              placement="bottom-left"
              handleClickOutside={setClickOutside}
              trigger={
                <MotifIconButton
                  type="button"
                  onClick={handleToggle}
                  aria-expanded={isSrcDocDrpDwnOpen}
                  className="src-doc-dropdown-button"
                >
                  <div className="src-doc-dropdown-text" title="Select files">
                    {filterTypes.length === 0
                      ? `Select files`
                      : `Selected file type(${filterTypes.length})`}
                  </div>
                  {isSrcDocDrpDwnOpen ? (
                    <img
                      className={
                        filterTypes.length === 0
                          ? "src-doc-drpdwn-icon"
                          : "src-doc-drpdwn-icon-with-type-selection"
                      }
                      src={ARROW_UP}
                      alt="up"
                    />
                  ) : (
                    <img
                      className={
                        filterTypes.length === 0
                          ? "src-doc-drpdwn-icon"
                          : "src-doc-drpdwn-icon-with-type-selection"
                      }
                      src={ARROW_DOWN}
                      alt="down"
                    />
                  )}
                </MotifIconButton>
              }
            >
              <div className="src-doc-drpdwn-body-container">
                <div className="item-container">
                  <div>
                    {srcDocDrpDwnItems.map((item) => {
                      return (
                        <MotifCheckbox
                          id={item.id}
                          value={item.type}
                          name={item.type}
                          checked={item.checked}
                          onChange={(e) => onChecked(e, item.id)}
                        >
                          <span>{item.type.toUpperCase()}</span>
                        </MotifCheckbox>
                      );
                    })}
                  </div>
                </div>
              </div>
            </MotifDropdown>
            {filterTypes.length > 0 ? (
              <div
                className={
                  filterTypes.length > 4
                    ? "src-doc-filter-type-chip-container-wrap"
                    : "src-doc-filter-type-chip-container"
                }
              >
                {filterTypes.map((item) => {
                  return (
                    <div className="src-doc-filter-type">
                      <span>{item}</span>
                      <MotifButton
                        className="src-doc-filter-chip-cancel-button"
                        onClick={() => {
                          handleCancelClick(item);
                        }}
                      >
                        <MotifIcon src={navigationIcClose24px} />
                      </MotifButton>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : favorClick ? (
        <div className="right-panel-favorites">
          <MotifButtonGroup>
            <MotifButton
              className={`prompt-type-button ${
                isActivePromptTypeButton() ? "active-button" : ""
              }`}
              onClick={changePromptType}
            >
              Confidential
            </MotifButton>
            <MotifButton
              className={`prompt-type-button ${
                !isActivePromptTypeButton() ? "active-button" : ""
              }`}
              onClick={changePromptType}
            >
              Public
            </MotifButton>
          </MotifButtonGroup>
          <MotifFormField>
            <img className="explore-rp-search-icon" src={search} />
            <MotifSearch
              aria-label="Search"
              hideClearButton
              id="states-search"
              className="explore-rp-search-textbox"
              onChange={searchFavoritePrompt}
              onSearchButtonClick={function noRefCheck() {}}
              onSelect={function noRefCheck() {}}
              placeholder="Search"
              searchButtonProps={{
                title: "Search",
              }}
              value={searchFavPrompt}
            />
          </MotifFormField>
        </div>
      ) : historyClick ? (
        <div className="right-panel-history">
          <MotifFormField>
            <img className="explore-rp-search-icon" src={search} />
            <MotifSearch
              aria-label="Search"
              hideClearButton
              id="states-search"
              className="explore-rp-search-textbox"
              onChange={searchHistoryPrompt}
              onSearchButtonClick={function noRefCheck() {}}
              onSelect={function noRefCheck() {}}
              placeholder="Search"
              searchButtonProps={{
                title: "Search",
              }}
              value={searchHisPrompt}
            />
          </MotifFormField>
        </div>
      ) : (
        ""
      )}

      <div
        className={ `${srcdocClick?'documents-list':'history-prompts-list'}
           ${filterTypes.length > 4
            ? "right-panel-docs-container-with-more-chip"
            : filterTypes.length > 0
            ? "right-panel-docs-container"
            : "right-panel-docs-container-with-noselection"}
            `
        }
      >
        {srcdocClick ? ((
            docs.length > 0 ? (
              docs.map((doc) => {
                return (
                  <MotifCheckbox
                    id={doc.documentId}
                    value={doc.documentName}
                    name={doc.documentName}
                    className="right-panel-doc-checkbox"
                    checked={doc.selected}
                    onChange={(e) => onDocChange(e, doc.documentId)}
                  >
                    <span className="right-panel-doc-name">
                      {doc.documentName}
                    </span>
                  </MotifCheckbox>
                );
              })
            ) 
            : (
              <>
                <img src={noDocumentImg}></img>
                <div className="no-doc-found-txt">No documents found</div>
                <div className="no-doc-instruction-txt">
                  Please upload your documents in the document library
                </div>
                <MotifButton
                onClick={()=>
                  redirectTo('/uploadPage')}
                className="motif-upload-doc-button explore-right-docs"
              >
                <img src={upload} alt="upload document icon" />
                Upload Document
              </MotifButton>

              </>
            )
          ) 
          
        ) 
        : historyClick ? (
          <div className="favorites-prompts-list">
            {filteredHistoryPrompts.length > 0 ? (
              filteredHistoryPrompts.map((prompt) => (
                <div
                  className="favorites-prompts"
                  onClick={() => showPrompt(prompt)}
                >
                  <div className="favorites-prompts-content">
                    <div className="favorites-prompts-header">
                      <div className="favorites-prompt-title">
                        {prompt.promptLibraryName}
                      </div>
                      <div className="favorites-prompt-question">
                        {prompt.requestContent}
                      </div>
                    </div>
                    <div className="favorites-star">
                      <img
                        className="favorites-star-icon"
                        src={cancelIcon}
                        onClick={(e) => deletePrompt(e, prompt.promptId)}
                      ></img>
                    </div>
                  </div>
                  <div className="favorites-footer">
                    <div className="favorites-date">
                      {utils.formatDate(prompt.createTS)}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No Prompts in History!</div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="right-panel-nav-container-row">
        <div className="right-panel-nav-source-doc-container">
          <div
            // style={{ width: 129 }}
            className={
              srcdocClick
                ? "right-panel-active-card right-panel-active-select"
                : "right-panel-active-card"
            }
            onClick={() => {
              setSrcDocClick(true);
              setFavorClick(false);
              setHistoryClick(false);
            }}
          >
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExloreRightPanal;