import axios from "../services/axiosWithMsal";
import { deleteMethod, get, post } from './api.service';
import documentApiRoutes from './api';
import { customErrorStatus } from './userProfile.service';
import errorStatus from '../utils/ErrorStatus';
import { getAuthorizationToken } from '../utils/commonUtils';
class ExploreDataService {
  constructor() {
  }
  async GetPromptDetails(projectId, promptType, typeofData,userEmail) {
    const paramsurl="?Project_UUID="+projectId+"&PromptType="+promptType+"&TypeofData="+typeofData+"&Email="+userEmail;
    
try {
  const data = await get(documentApiRoutes.getPromtDetailsByProject+paramsurl);
  return data;
} catch (error) {
  if (!error?.response?.status) return customErrorStatus(error?.message);
  return errorStatus(error?.response?.status);
}

  }

  async DeletePrompt(PromptId) {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAuthorizationToken()}`
      }
    };
    return fetch(documentApiRoutes.deletePrompt+"?PromptId="+PromptId, { ...requestOptions });
  }
}
export default ExploreDataService;
