import React from 'react';
import styled from 'styled-components';
import sassVars from '../../../src/styles/variables.module.scss';
import {
  UNAUTHORIZED_HEADING,
  UNAUTHORIZED_LINK,
  UNAUTHORIZED_DESCRIPTION1,
  UNAUTHORIZED_DESCRIPTION2,
  UNAUTHORIZED_WARNINGICON
} from '../../label.constant';
import Header from '../common/header/Header';
const { fontInterstate, fontFeatureSetting, grey300, gray, white } = sassVars;

const UnAuthorizedAccess = (): JSX.Element => {
  return (
    <StyledUnauthorized>
      <Header />
      <StyledPageLayout>
        <div className='ie-warning'>
          <div className='ie-warning-header'>
            <div className='ie-warning-logo-div'>
              <img src={UNAUTHORIZED_WARNINGICON} alt='warningicon' />
            </div>
            <div className='ie-warning-heading-div'>
              <span className='ie-warning-heading'>{UNAUTHORIZED_HEADING}</span>
            </div>
          </div>

          <div className='ie-warning-box'>
            <p className='ie-warning-desc'>
              <span className='ie-warning-seconddesc1'>{UNAUTHORIZED_DESCRIPTION1}</span>
              <span className='ie-warning-seconddesc2'>{UNAUTHORIZED_DESCRIPTION2}</span>

              <a className='mailto' href={`mailto:${UNAUTHORIZED_LINK}`}>
                <address>{UNAUTHORIZED_LINK}</address>
              </a>
            </p>
          </div>
        </div>
      </StyledPageLayout>
    </StyledUnauthorized>
  );
};

const StyledPageLayout = styled.div`
  left: 50%;
  width: 72%;
  position: relative;
  transform: translate(-50%);
  margin-top: 40vh;

  .ie-warning-header {
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    border-top: 1px solid #eaeaf2;
    border-right: 1px solid #eaeaf2;
    border-left: 1px solid #eaeaf2;
    background: ${grey300};
  }

  .ie-warning-header img {
    margin-left: 4px;
  }

  .ie-warning-heading-div {
    display: inline-block;
    padding: 5px 0px;
    margin-left: 10px;
  }
  .ie-warning-logo-div {
    display: inline-block;
    height: 30px;
    vertical-align: middle;
    margin-left: 8px;
  }
  .ie-warning-heading {
    color: #1a1a24;
    font-feature-settings: ${fontFeatureSetting};

    /* Heading 6 */
    font-family: ${fontInterstate};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 112.5% */
  }
  .ie-warning .ie-warning-box {
    border-radius: 0px 0px 6px 6px;
    border-right: 1px solid ${grey300};
    border-bottom: 1px solid ${grey300};
    border-left: 1px solid ${grey300};
    background: ${gray};
    padding: 0px;
    margin: 0px;
    width: 100%;
  }
  .ie-warning-desc {
    margin: 0px;
    color: ${white};
    font-family: ${fontInterstate};
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px; /* 157.143% */
    padding: 24px 48px;
  }
  .ie-warning-desc a {
    color: ${white};
    // padding: 0 24px 0 48px;
    font-family: ${fontInterstate};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: ${white};
    text-decoration: none;
    cursor: pointer;
    display: block;
  }
  .ie-warning-desc a address {
    font-style: normal;
  }
  .ie-warning-seconddesc2,
  ie-warning-seconddesc1 {
    display: block;
  }
`;

const StyledUnauthorized = styled.div`
  .unauthorized-header {
    background: #fff;
    padding-bottom: 20px;
  }
`;
export default UnAuthorizedAccess;
