import React from 'react';
import styled from 'styled-components';
import scssVar from '../../../styles/variables.module.scss';
const { grey300, fontInterstate, fontFeatureSetting } = scssVar;
const Banner = (): JSX.Element => {
  return (
    <StyleBanner>
      <div className='ie-banner'>
        <div className='ie-banner-container'>
          <span className='ie-banner-info  title-1'>Enter the world of <span className='subTitle'>Insights</span></span>
          <div className='ie-banner-info title-2'>
            <div className='line-1'>A catalyst for change and growth in the modern business world.</div>
          </div>
        </div>
      </div>
    </StyleBanner>
  );
};

const StyleBanner = styled.div`
  .ie-banner-container {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    height: 22vh;
  }
  .ie-banner-info {
    color: ${grey300};
    font-feature-settings: ${fontFeatureSetting};
    font-family: ${fontInterstate};
    font-style: normal;
  }
  .title-1 {
    font-size: 46px;
    font-weight: 300;
    color: #747480;
    font-family: EYInterstate;
    line-height: 58px;
    margin-bottom: 5px;
  }
  .subTitle{
    color: #23232F;
  }
  .ie-banner-info.title-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #23232F;
  }

  .ie-banner-info.title-2 .line-1,
  .ie-banner-info.title-2 .line-2 {
      text-align: center;
      font-size: 16px;
      color: #23232F;
      font-weight: 300;
  }
  @media screen and (max-width: 768px) {
    .ie-banner-container {
      padding-left: 1.25rem;
    }
  }

  @media screen and (min-width: 1400px) and (max-width: 1750px) {
  .title-1  {
      font-size: 43px;
    }
    .ie-banner-info.title-2 .line-1,
    .ie-banner-info.title-2 .line-2 {
        text-align: center;
        font-size: 16px;
    }
  }

  @media screen and (min-width: 1230px) and (max-width: 1300px) {
  .title-1  {
      font-size: 40px;
    }
    .ie-banner-info.title-2 .line-1,
    .ie-banner-info.title-2 .line-2 {
        text-align: center;
        font-size: 14px;
    }
  }
  @media screen and (min-width: 1100px) and (max-width: 1230px) {
     .title-1  {
      font-size: 33px;
    }
    .ie-banner-info.title-2 .line-1,
    .ie-banner-info.title-2 .line-2 {
        text-align: center;
        font-size: 13px;
    }
  }
`;

export default Banner;
