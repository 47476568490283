import React, { useEffect } from 'react';
import styled from 'styled-components';
import sassVar from '../../styles/variables.module.scss';
import './SurveySectorCheckbox.scss';
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifFormField,
  MotifButton
} from '@ey-xd/motif-react';

const { fontInterstate } = sassVar;

interface IOwnProps {
  show: boolean;
  onClose: () => void;
  sector: any;
  geography: any;
  surveyView: any;
}
const SurveyFileDetailModal: React.FC<IOwnProps> = ({
  show,
  onClose,
  sector,
  geography,
  surveyView
}: IOwnProps) => {
  useEffect(()=>{
    setTimeout(()=>{
   show ? document.querySelectorAll(".motif-modal-overlay").forEach((el)=>el.classList.add('surveycheckbox__modal_overlay')):document.querySelectorAll(".motif-modal-overlay").forEach((el)=>el.classList.remove("surveycheckbox__modal_overlay"));
    },0);
  },[show])
  return (
    <div className={'surveycheckbox__modal'}>
      <MotifModal
        label='modalSurveyDetail'
        show={show}
        onClose={onClose}
        className={'surveycheckbox__modal'}>
        <div className='surveycheckbox__header'>
          <MotifModalHeader
            closeButtonProps={{
              'aria-label': 'Custom Close Button aria-label',
              title: 'Custom Close Button title'
            }}>
            <div className='surveycheckbox__headerText'>Survey Details</div>
          </MotifModalHeader>
        </div>
        <MotifModalBody>
          <SourceChangePopup>
            <div>
              <MotifFormField>
                <div className='bodyDiv'>
                  <div>
                    <div className='TopDiv'>
                      <div className='SectorDiv'>
                        <div className='subHeader'>Sector</div>
                        {sector.map((item: any, ind: number) => {
                          return (
                            <div className='Text' key={ind}>
                              {item}
                            </div>
                          );
                        })}
                      </div>
                      <div className='GeoDiv'>
                        <div className='subHeader'>Geography</div>
                        {geography.map((item: any, ind: number) => {
                          return (
                            <div className='Text' key={ind}>
                              {item}
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className='para'>
                      <div className='subHeader'>Survey Overview</div>
                      <div className='Text'>{surveyView}</div>
                    </div>
                  </div>
                  <div className='cancleButton'>
                    <MotifButton
                      onClick={onClose}
                      type='button'
                      variant='secondary'
                      label='surveyCancelButton'
                      className='butn'>
                      Cancel
                    </MotifButton>
                  </div>
                </div>
              </MotifFormField>
            </div>
          </SourceChangePopup>
        </MotifModalBody>
      </MotifModal>
    </div>
  );
};

const SourceChangePopup = styled.div`
  .bodyDiv {
    width: 552px;
    height: 324px;
    top: 105px;
    left: 24px;
    gap: 8px;
    opacity: 0px;
  }
  .butn {
    font-family: ${fontInterstate};
    font-size: 18px;
    font-weight: 400;
    margin-top: 15px;
    width: Hug (92px) px;
    height: Hug (48px) px;
    gap: 12px;
    opacity: 0px;
  }
  .para {
    display: column;
    width: 100% !important;
    margin-top: 2%;
  }
  .cancleButton {
    margin-left: auto;
    display: table;
    padding-bottom: 15px;
  }
  .TopDiv {
    align-self: stretch !important;
    background: rgba(0, 0, 0, 0);
    border: 1px rgba(0, 0, 0, 0) solid;
    display: flex;
    width: 100% !important;
  }
  .SectorDiv {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
  .GeoDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .subHeader {
    color: #23232f;
    font-family: ${fontInterstate};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .Text {
    color: #4d4d5c;
    font-family: ${fontInterstate};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 5px;
    text-align: left;
  }
`;

export default React.memo(SurveyFileDetailModal);
