import { AxiosError } from 'axios';
import { apiRoutes, post, get } from './api.service';

export const acceptPrivacyPolicy = (): Promise<any> => {
  return post(apiRoutes.acceptPrivacy, {})
    .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response } = error;
      if (response?.status === 400 || response?.status === 404) {
        return { error };
      }
      return error;
    });
};


/**
 * Uncomment the below code once API is ready
 */

export const getPrivacyPolicyContent = (policyType: number): Promise<any> => {
  let queryParams = { PolicyType: policyType };
  return get(apiRoutes.getPrivacyPolicy, queryParams)
    .then((response) => {
      return response;
    })
    .catch((error: AxiosError) => {
      const { response } = error;
      if (response?.status === 400 || response?.status === 404) {
        return { error };
      }
      return error;
    });
};



