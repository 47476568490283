import React from 'react';
import styled from 'styled-components';
import scssVar from '../../../styles/variables.module.scss';
const { grey300, fontInterstate, fontFeatureSetting } = scssVar;
const BottomBanner = (): JSX.Element => {
  return (
    <StyleBanner>
      <div className='bottom-text'>
            <div className='line-1'>Your virtual expert</div>
            <div className='line-2'>AI powered apps to give you specific insights</div>
          </div>
    </StyleBanner>
  );
};

const StyleBanner = styled.div`
    .bottom-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        color: #23232F;
        font-feature-settings: ${fontFeatureSetting};
        font-family: ${fontInterstate};
        margin-top: 6vh;
        .line-1{
            font-size: 20px;
            text-align: center;
            font-weight: 400;
        }
        .line-2 {
            font-family: EYInterstateLight;
            text-align: center;
            font-size: 16px;
            font-weight: 100;
        }
    }
    
  @media screen and (min-width: 1400px) and (max-width: 1750px) {
      .bottom-text {
        .line-1{
            font-size: 19px;
        }
        .line-2 {
          font-size: 16px;
        }
    }
    }
  
    @media screen and (min-width: 1230px) and (max-width: 1300px) {
      .bottom-text {
        .line-1{
            font-size: 17px;
        }
        .line-2 {
          font-size: 15px;
        }
    }
    }
    @media screen and (min-width: 1100px) and (max-width: 1230px) {
      .bottom-text {
        .line-1{
            font-size: 16px;
        }
        .line-2 {
          font-size: 14px;
        }
    }
    }
`;

export default BottomBanner;
