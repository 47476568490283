import styled from 'styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store';
import SurveyDropdown from '../SurveyDropdown/SurveyDropdown';
import {  surveyPeriod } from '../../app.constant';
import SurveyCheckboxDropdown from '../SurveySectorCheckboxes/SurveySectorCheckbox';
import SurveyCheckboxList from '../SurveySectorCheckboxes/SurveyCheckboxList';
import { surveyFileredList } from '../../store/actions/userChatActions';
import PageConfig from '../../ui-constants/en.json';
interface SideBarProps {
  isOpen?: boolean;
  dataSources?: any;
}
let timeDate = '';
const SurveySidebar: React.FC<SideBarProps> = ({ isOpen, dataSources }) => {
  const selectedDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const anySourceSelected = useSelector((state: IStore) => state?.userChat?.anySourceSelected);
  const [isSourceSelected, setSourceSelected] = useState<any>(!anySourceSelected);
  useEffect(() => {
    setSourceSelected(anySourceSelected);
  }, [anySourceSelected, isSourceSelected]);
  const [showOptions, setShowOptions] = useState(false);
  const [showSectorOptions, setSectorOptions] = useState(false);
  const [selectedRadioOption, setSelectedRadioOption] = useState('');
  const [sectors, setSectors] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const surveyDocList = useSelector((state: IStore) => state?.userChat?.surveyDocList);
  const [filteredSectors, setFilteredSectors] = useState<any>([]);
  const [filteredSurveyFiles, setFilteredSurveyFiles] = useState<any>([]);
  const startDate = useRef<any>();
  const endDate = useRef<any>();
  const [checkedSector, setCheckedSector] = useState<any>([]);
  const selectedSurveySectors = useSelector(
    (state: IStore) => state?.userChat?.selectedSurveySectors
  );
  const filteredList = useSelector((state: IStore) => state?.userChat?.filteredList);
  const pathName= window.location.pathname;
  const dispatch = useDispatch();
  const dropdownRef = useRef<HTMLElement>(null);
  const sectorRef = useRef<HTMLElement>(null);

  const toggleDropdown = () => {
    setShowOptions(!showOptions);
    if (showSectorOptions) {
      setSectorOptions(!showSectorOptions);
    }
  };

  const toggleSectorDropdown = () => {
    setSectorOptions(!showSectorOptions);
    if (showOptions) {
      setShowOptions(!showOptions);
    }
  };

  const handleSectorApi = () => {
    if ((surveyDocList && surveyDocList?.sectors?.length) > 0) {
      setSectors(surveyDocList?.sectors);
      setSurveyList(surveyDocList?.files.map((item: any) => item.title));
    }
  };

  const handleSurveyListFilter = useCallback((selectedSector: string[]) => {
    setCheckedSector(selectedSector);
    const getFilesForSectors = (data: { files: any[] }, selectedSectors: string[]) => {
      return data.files.filter((file) =>
        file.sectors.some((sector: string) => selectedSectors.includes(sector))
      );
    };
    const files: any =
      selectedSector && selectedSector.length > 0
        ? getFilesForSectors(surveyDocList, selectedSector)
        : surveyDocList.files;
    var extractedSectors: any = files ?? [];
    if (startDate.current && endDate.current) {
      if (startDate.current === '' && endDate.current === '') {
        extractedSectors = extractedSectors.map((file: any) => file.title);
      } else {
        extractedSectors = extractedSectors
          .filter(
            (file: any) =>
              new Date(file.surveydate) >= new Date(startDate.current) &&
              new Date(file.surveydate) <= new Date(endDate.current)
          )
          .map((file: any) => file.title);
      }
    } else if (startDate.current) {
      if (startDate.current === '') {
        extractedSectors = extractedSectors.map((file: any) => file.title);
      } else {
        extractedSectors = extractedSectors
          .filter((file: any) => new Date(file.surveydate) >= new Date(startDate.current))
          .map((file: any) => file.title);
      }
    } else {
      extractedSectors = extractedSectors.map((file: any) => file.title);
    }

    setFilteredSectors(extractedSectors);

    if(localStorage.getItem('surveyDateSelectedOption')  &&  localStorage.getItem('surveyDateSelectedOption')!= null && localStorage.getItem('surveyDateSelectedOption')!='')
    { 
      setSelectedRadioOption(localStorage.getItem('surveyDateSelectedOption')??'');
    }
  }, []);

  const handleSurveyDateFilter = (fromdate: any, todate?: any) => {
    const getFilesForSectors = (data: { files: any[] }) => {
      var filterfiles: any = data ?? { files: [] };
      if (fromdate && todate) {
        if (fromdate === '' && todate === '') {
          startDate.current = '';
          endDate.current = '';
          filterfiles = filterfiles.files;
        } else {
          startDate.current = fromdate;
          endDate.current = todate;
          filterfiles = filterfiles.files && filterfiles.files?.filter(
            (file: any) =>
              new Date(file.surveydate) >= new Date(fromdate) &&
              new Date(file.surveydate) <= new Date(todate)
          );
        }
        if (checkedSector && checkedSector.length > 0) {
          return filterfiles?.length > 0
            ? filterfiles
                .filter((file: any) =>
                  file.sectors.some((sector: string) => checkedSector.includes(sector))
                )
                .map((file: any) => file.title)
            : filterfiles;
        }
        return filterfiles?.length > 0 ? filterfiles.map((file: any) => file.title) : filterfiles;
      }
      if (fromdate === '') {
        startDate.current = '';
        filterfiles = filterfiles.files;
      } else {
        if (fromdate === PageConfig.MORE_THAN_3_YEARS) {
          filterfiles = surveyDocList;
        } else {
          startDate.current = fromdate;
          filterfiles = filterfiles.files && filterfiles.files?.filter(
            (file: any) => new Date(file.surveydate) >= new Date(fromdate)
          );
        }
      }
      if (checkedSector && checkedSector.length > 0) {
        return filterfiles?.length > 0
          ? filterfiles
              .filter((file: any) =>
                file.sectors.some((sector: string) => checkedSector.includes(sector))
              )
              .map((file: any) => file.title)
          : filterfiles;
      }
      return filterfiles?.length > 0 ? filterfiles.map((file: any) => file.title) : filterfiles;
    };
    const files = getFilesForSectors(surveyDocList) as string[];
    setFilteredSectors(files);
  };
  const handleSectorDate = async (fromdate?: string | undefined, todate?: string | undefined) => {
    fromdate && todate
      ? handleSurveyDateFilter(fromdate as string, todate as string)
      : handleSurveyDateFilter((fromdate as string) || '');
  };
 const clearDropdown = () => {
    setSelectedRadioOption('');
    timeLineFilter?.('', '');
    localStorage.setItem('surveyDateSelectedOption', '');
    localStorage.setItem('surveycustomperiod', '');
    localStorage.setItem('surveyfromDate', '' );
    localStorage.setItem('surveytoDate', '' );
    localStorage.setItem('queryFromDate', '');
    localStorage.setItem('queryToDate', '');
 }
  const timeLineFilter = (fromdate?: string, todate?: string) => {
    localStorage.setItem('queryFromDate', fromdate ?? '');
    localStorage.setItem('queryToDate', todate ?? '');
    timeDate = fromdate || '';
    fromdate && todate ? handleSectorDate(fromdate, todate) : handleSectorDate(fromdate);
  };

  useEffect(() => {
    handleSurveyListFilter(selectedSurveySectors ?? []);
  }, [selectedSurveySectors]);

  useEffect(() => {
    if (filteredSurveyFiles && filteredSurveyFiles.length > 0) {
      dispatch(surveyFileredList(filteredSurveyFiles));
    } else {
      dispatch(surveyFileredList([]));
    }
  }, [filteredSurveyFiles]);

  useEffect(() => {
    if (filteredSectors && filteredSectors.length > 0) {
      dispatch(surveyFileredList(filteredSectors));
    } else {
      dispatch(surveyFileredList([]));
    }
  }, [filteredSectors]);

  useEffect(() => {
    handleSectorApi();
  }, [surveyDocList]);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if((event.target.classList[0] && event.target.classList[0].indexOf('react-calendar')>-1)||(event.target.tagName.toLowerCase()=='abbr' && isNaN(event.target.innerHTML))){
        setShowOptions(true);
      }
      else if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
      if (sectorRef.current && !sectorRef.current.contains(event.target)) {
        setSectorOptions(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    //@ts-ignore
    <SideBarStyle isSideBarOpen={isOpen}>
      <div className={`sidebar ${isOpen ? 'sidebar-open' : 'sidebar-closed'} ${pathName==='/surveyChatPage'?"sidebar-surveychatpage":""}`}>
        <div className='sidebar-component'>
          <div className='title-section'>
            <label htmlFor='sidebar' className='select-source'>
              All survey documents
            </label>
            <div className='select-source-count'>
              {filteredSectors
                ? filteredList?.length
                : surveyList && surveyList.length
                  ? surveyList.length
                  : 0}
            </div>
          </div>
          <SurveyDropdown
            placeholder='Select period'
            surveyLabel={'Survey period'}
            options={surveyPeriod}
            showOptions={showOptions}
            selectedOption={selectedRadioOption}
            customRef={dropdownRef}
            setSelectedOption={setSelectedRadioOption}
            toggleDropdown={toggleDropdown}
            timeLineFilter={timeLineFilter}
            clearDropdown={clearDropdown}
          />

          <SurveyCheckboxDropdown
            placeholder='Select sectors'
            surveyLabel={'Sectors'}
            dropdownType={'checkbox'}
            options={surveyDocList?.sectors}
            showOptions={showSectorOptions}
            customRef={sectorRef}
            toggleDropdown={toggleSectorDropdown}
          />

          {surveyList && surveyList.length > 0 && (
            <SurveyCheckboxList
              options={(filteredSectors.length>0 ? surveyDocList?.files?.filter(function(item:any) {
                return filteredSectors.indexOf(item?.title) !== -1;
            }) : [])}
              surveyDocList={surveyDocList?.files}
            />
          )}
        </div>
      </div>
    </SideBarStyle>
  );
};

const SideBarStyle = styled.div<{
  isSideBarOpen: boolean;
}>`
  .sidebar {
    transition: all 0.5s ease;
    position: absolute;
    width: 0px;
    right: 0px;
    transition: width 0.2s ease 0s;
    background-color: #ffffff;
    z-index: 9;
    top:56px;
    height: calc(100% - 108px);
    border-left: 0.5px solid lightgray;
  }
  .sidebar-closed {
    overflow: hidden;
  }
  .sidebar.sidebar-open {
    width: 320px;
  }
  .sidebar-component {
    padding: 20px;
    position: relative;
    top: 20px;
    padding-top:0px;
    padding-right:15px;
  }
  .title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #202345;
    padding-bottom: 16px;
  }
  .option {
    margin: 20px 0 10px 0;
  }

  .checkboxes {
    margin-left: 30px;
  }
  .select-source {
    font-weight: 400;
    font-size: 16px;
    line-height:24px;
  }
  .select-source-count {
    height: 23px;
    background: #fffbfb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 2px 8px;
    border: 1px solid #9e9e9e;
    color: black;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
  .single-checkbox {
    padding: 5px 0;
  }

  .motif-check {
    border: 1px solid #000;
  }

  .sidebar-open .bg-img-div {
    width: calc(100% - 301px);
  }

  .surveydropdown ,.surveycheckbox{
   .Dropdown-placeholder {
    top: 0px !important;
    }
  }
`;
export default SurveySidebar;
