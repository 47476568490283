// import axios from "../services/axiosWithMsal";
// //import config from "../data/configuration.json";
import documentApiRoutes from './api';
import { getAuthorizationToken } from '../utils/commonUtils';
import { getCurrentSessionId } from './session.service';
import CommonService from './common.service';
import { HttpStatusCode } from '../ui-constants/base/enum';
import { customErrorStatus } from './userProfile.service';
import axios from 'axios';
import { deleteMethod, downloadDoc, get, post } from './api.service';
import errorStatus from '../utils/ErrorStatus';
class ExploreService {
  commonService = new CommonService();
  constructor() {
    this.sessionId = getCurrentSessionId();
  }
  async GetDownlodedDocument(projectId, documentName,title,userMailID) {
      const response = await downloadDoc(documentApiRoutes.downloadDocument,{Project_UUID:projectId,DocumentName:documentName})
      .catch((error) => {
        this.commonService.logException(
          error,
          "Explore Your Data",
          "Download Document",
          projectId,
          title,
          userMailID
        );
      });
      return response;
  }
  async GetPromptResponse(conversationId, projectId, Prompt, filetype, email, flag, fileList,title) {
    const userPromptRequest = {
      Conv_UUID: conversationId,
      Project_UUID: projectId,
      Prompt: Prompt,
      FileType: "All",
      userEmail: email,
      publicFlag: flag,
      fileList: fileList
    };
    const response = await post(documentApiRoutes.getPromptResponse,userPromptRequest) 
    .catch((error) => {
      this.commonService.logException(
        error,
        "Explore Your Data",
        "Get Prompt Response",
        projectId,
        title,
        userMailID,
        userPromptRequest
      );
    });
    return response;
   
  }
}



export default ExploreService;
