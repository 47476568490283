//*********************** configuration space ***********************//



 // the project name to be appended to each page names
 //export const projectName: string = 'Strategy Edge';

// const setAnlayticsEnvironment = () => {
//   let ENV = "UAT";
//   if( window.location.href.toLowerCase().indexOf("iap_dev") != -1 ){
//     ENV = "UAT";
//   }
//   else {
//     ENV = "PROD";
//   }
//   return ENV;
// }


// Environment Type (Change for UAT, QA, PROD)
// let ENV = "DEV";

// url supplied by launch
export const adobeLaunchURL: string = 'https://assets.adobedtm.com/launch-EN469e5679e44449d2bb930232a9d6eb5c.min.js';

// initial digital data, later modified
export const digitaldata = {
  page: {
    environment: process.env.REACT_APP_SE_APP_ENV,
    eYProduct: 'Competitive Edge',
    eYApplication: 'Competitive Edge',
    pageName: '',
    fileDownloadName: '',

  },
  engagement: {},
  user: {
    userId: '',
  },
  onclick: {
    actionLabel: '',
    actionValue: '',
    actionCategory: '',
    actionName: '',
    linkName:'',
    event: ''
  },
  component: {
    link: {
      linkIdentifier: ''
    }
  }
};

export const payload = {
  action:'',
  eventProperties: {
    category:'',
    label:'',
    value:'',
    function: "",
    subFunction: "",
    level2SubFunction: "",
  },
};
