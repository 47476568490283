import { useSelector } from 'react-redux';
import { type IStore } from '../../store';
import {
  useEffect,
  useState,
  useContext,
  Fragment,
  useRef
} from "react";
import "../ExploreData/ExploreData.css";

import {
  MotifCheckbox,
  MotifSearch,
  MotifFormField,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
  MotifButton,
  MotifTooltip,
} from "@ey-xd/motif-react";
import { useDispatch } from "react-redux";
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import star from "../../assets/images/byod-images/star.svg";
import bookmarkempty from "../../assets/images/byod-images/bookmarkempty.svg";
import bookmarkemptyblack from "../../assets/images/byod-images/bookmarkemptyblack.svg";
import mailout from "../../assets/images/byod-images/mailout.svg";
import editpencil from "../../assets/images/byod-images/editpencil.svg";
import page from "../../assets/images/byod-images/page.svg";
import peopletag from "../../assets/images/byod-images/peopletag.svg";
import copytag from "../../assets/images/byod-images/copy.svg";
import ExploreDocumentModalConfidential from "./ExploreDocumentModalConfidential";
import BadgePopup from "./BadgePopUp";
import ExploreService from "../../services/explore.service.js";
import Utils from "../../utils/Utils";
 import { DashboardContext } from "../../context/dashboardContext";
 import { toggleLoader } from "../../store/actions/userChatActions";
import{StyleType,PromptData_Byod} from "./constants"
import favoriteIcon from "../../assets/images/byod-images/favorite.svg";
import _ from "lodash";

const ExplorePhase = ({
  question,
  publicConf,
  addprompt,
  isPromptSubmit,
  showPromotHistory,
  selectedDocNames,
  elementsDisabled,
  isResponseLoadingFunc
}) => {
  const dispatch = useDispatch();
  const explorservice = new ExploreService();
  const utils = new Utils();
  const [show, setShow] = useState(false);
  const [showPublic, setShowPublic] = useState(false);
  const [showBadgePopup, setShowBadgePopup] = useState(false);
  const selectedDocumentThread = useSelector(
    (state: IStore) => state.userChat.selectedDocumentThread
  );
  const userMailID = useSelector((state: IStore) => state.common?.userEmail);
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  // let prompt =
  //   "Evaluate XYZ positioning in the market and how they compete with or differentiate from their peers?";
  // const constantData = require("../../../data/constant.js");
  const [position, setPosition] = useState(StyleType);
  const [promptResponse, setPromptResponse] = useState(null);
  const [promptId, setPromptId] = useState(null);
  const date = new Date().toLocaleDateString("en-us");
  const [promptDocuments, setPromptDocuments] = useState([]);

  const [arrForReqRes, setArrForReqRes] = useState([]);

  let PromptData = PromptData_Byod;

  const [showStar, setShowStar] = useState(true);
  const [showFavourite, setShowFavourite] = useState(false);
  const [showbookmarkempty, setShowBookMarkEmpty] = useState(true);
  const [showbookmarkemptyblack, setShowBookMarkEmptyBlack] = useState(false);
  const [initialdata, setInitialdata] = useState([]);
  const iData = useRef(null);
  const { viewPrompt, setViewPrompt } = useContext(DashboardContext);
  const [showCopyText, setShowCopyText] = useState(false);
  

  useEffect(() => {
    setShow(show);
    setShowPublic(showPublic);
    return()=>{
      setArrForReqRes([]);
      setViewPrompt({});
    }
  }, []);

  useEffect(()=>{
    elementsDisabled(false)
  },[selectedDocumentThread])
  useEffect(() => {
    if (isPromptSubmit) {
      GetResponse();

      // Filling up the req and res in an json array
      let arrReqRes = [];
      arrReqRes.push({
        req: question,
        res: promptResponse,
        publicprivate: publicConf,
      });
      setArrForReqRes(arrReqRes);
      setInitialdata(arrReqRes);
      iData.current=arrReqRes;
    }
    if (showPromotHistory) {
      // Filling up the req and res in an json array
      if (promptResponse) {
        promptResponse.reqTime = utils.getDateWithDayName(promptResponse.createTS);
        let arrReqRes = [];
        promptResponse.fileList = promptResponse.documentList ?? [];
        setPromptDocuments(promptResponse.fileList);arrReqRes.push({
          req: question,
          res: promptResponse,
          publicprivate: publicConf,
        });
        setArrForReqRes(arrReqRes);
        setInitialdata(arrReqRes);
      }
    }
    elementsDisabled(false);
  }, [question, promptResponse]);

  useEffect(() => {
    if (showPromotHistory) {
      if (viewPrompt && viewPrompt?.requestContent) {
        setPromptResponse(viewPrompt);
        setPromptId(viewPrompt?.promptId);
      }
    }
  }, [viewPrompt]);

  useEffect(() => {
    document.body.style.overflow = position;
  }, [position]);

  const onEditPencilClick = () => {
    addprompt(question);
    document.querySelectorAll("#question")[0].focus();
  };

  const initializePrompt = () => {
    PromptData.convid = "";
    PromptData.promptid = "";
    PromptData.project_uuid = "";
    PromptData.prompttype = "";
    PromptData.title = "";
    PromptData.tags = "";
    PromptData.prompttext = "";
    PromptData.IsFavOrSaved = "";
    PromptData.Email = "";
  };

  async function onStarButtonClick() {
    initializePrompt();
    PromptData.IsFavOrSaved = "F";
    if (promptId != null) {
      PromptData.promptid = promptId.toString();
    }
  }

  const onBadgeButtonClick = () => {
    initializePrompt();
    PromptData.project_uuid = sessionStorage.getItem("DashboardProjectId");
    PromptData.Email = sessionStorage.getItem("Email");
    PromptData.prompttext = question;
    showPopUp(true);
  };

  async function onBadgePopupConfirmClick(PromptData) {
    if (promptId != null) {
      PromptData.promptid = promptId.toString();
    }
  }

  const showPopUp = (isvisible) => {
    setShowBadgePopup(isvisible);
    if (!isvisible) {
      setPosition("");
    } else {
      setPosition(StyleType);
    }
  };

  async function handleDownload(filename) {
    const response = await explorservice.GetDownlodedDocument(
      selectedDocumentThread?.threadId,
      filename?.trim(),
      selectedDocumentThread?.title,
      userMailID
    );
    if(response) {
     const type = response.headers.get("content-type");
     const blob = new Blob([response.data], { type: type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();     
    }
  }

 async function GetResponse() {
    let response = {};
    let dateTime = utils.getDateWithDayName(new Date());
    try{
    if (iData.current ==null || iData?.current?.length == 0) {
       response = await explorservice.GetPromptResponse(
        sessionStorage.getItem("PromptConversationId"),
        selectedDocumentThread?.threadId,
        question,
        "All",
        userMailID,
        'N',
        selectedDocNames,
        selectedDocumentThread?.title
      );
      isResponseLoadingFunc(false);
      let resultarray=[];
      if(response?.fileList && response?.fileList!="" && response?.fileList!=" ")
       {
        let docArray = _.uniq(response?.fileList?.split(","));
        docArray.forEach(function (file) {
          resultarray.push({
            documentName: file,
            isAvailable: true,
          });
        });
       }
      setPromptDocuments(resultarray);
      setPromptResponse(response);
      setPromptId(response.promptID);
   }
  }catch (error) {
    isResponseLoadingFunc(false);
    setPromptDocuments([]);
    response.responseContent =
      "No response available for this request at this time. Please try again";
    response.promptID = "";
    setPromptResponse(response);
    setPromptId("");
  }
  response.reqTime = dateTime;
  }

  const onCopy = (indexval) => {
    let r = document.createRange();
    r.selectNode(
      document.getElementsByClassName("divresponse"+indexval)[0]
    );
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(r);
    document?.execCommand("copy");
    window.getSelection()?.removeAllRanges();

    setShowCopyText(true);
    setTimeout(() => {
      setShowCopyText(false);
    }, 1000);
  };


  return (
    <div className="explore-prompt-req-res">
      {arrForReqRes.map((element, index) => {
        const responseContent = element.res?.responseContent;
        const responseContentWithBreaks = responseContent?.replace('\n\nSource:', '<br><br>Source:');
        element.res  && element.res !== "" ?
         dispatch(toggleLoader(false)):dispatch(toggleLoader(true));
        return (
          <div key={index}>
            <div className="explore-prompt-question-container">
              <div className="explore-prompt-question-container-child">
                <div className="explore-prompt-question-container-text">
                  You
                </div>
                <div className="explore-prompt-question-container-date">
                {element.res?.reqTime
                    ? element.res?.reqTime
                    : utils.getDateWithDayName(new Date())}
                </div>
                <div className="explore-prompt-question-container-edit-icon">
                  <MotifIconButton onClick={onEditPencilClick}>
                    <img src={editpencil} alt="edit icon"/>
                  </MotifIconButton>
                </div>
              </div>

              <div className="explore-prompt-question-header">
                <div className="explore-prompt-question-text">
                  {element?.req}
                </div>
              </div>

              <div>
              
              </div>
            </div>
{element?.res && 
            <div className="explore-prompt-question-response-doc-note">
              <div className="explore-prompt-question-response-doc-text">
                Result from {promptDocuments?.length} documents
              </div>
              {element?.res?.fileList?.length>0 && element?.res?.fileList!="" && 
              <div
                className="explore-prompt-question-response-doc-container"
                // style={{ width: "100%" }}
              >
                {promptDocuments.slice(0, 3).map((item, index) => {
                  if (item && item != "") {
                    return (
                      <>
                        <div className={`explore-prompt-question-response-doc-fileicon ${
                                  item.isAvailable
                                    ? ""
                                    : "noaction-document-not-available"
                                }`}>
                          <img
                            src={page}
                            alt="file icon"
                            onClick={element?.res?.fileList?.length > 0 ? (e) => {
                              e.preventDefault();
                              handleDownload(item.documentName);
                            } : null}
                          />

                        </div>
                        <div className={`explore-prompt-question-response-doc-container contentfamily ${
                                  item.isAvailable
                                    ? ""
                                    : "document-not-available"
                                }`}>
                          {item?.documentName?.length > 20
                            ? item?.documentName?.substring(0, 15) + "..."
                            : item?.documentName}
                        </div>
                      </>
                    );
                  }
                })}
                <div className="contentfamily">
                  <MotifButton
                    onClick={()=>setShow(true)}
                    className="seeall"
                  >
                    View All
                  </MotifButton>
                </div>
              </div>
      }
            </div>
      } 

            {show && (
              <ExploreDocumentModalConfidential
                openShow={show}
                setOpenModal={setShow}
                documents={promptDocuments}
              />
            )}

            <div className="explore-prompt-question-response-container">
            {element.res !== "" && element.res ? (
              <>
              <div className="explore-prompt-question-response-icon">
                <img src={peopletag}  alt="response icon"/>
              </div>
             
                <div className="explore-prompt-question-response-container-child">
                <div className={"explore-prompt-question-response divresponse" + element.res?.promptID}
                    dangerouslySetInnerHTML={{
                      __html: utils.markdownToHtml(responseContent)
                  }}
                  >
                </div>
                  <div className="explore-prompt-copy-response-icon">
                    <MotifButton title="copy" onClick={()=>{onCopy(element.res?.promptID)}}>
                      <img src={copytag} alt="copy icon" />
                    </MotifButton>
                    {showCopyText && <span className="copy-text">Copied to clipboard</span>}
                  </div>
                </div>
                </>
              ) : (
                <div className="dotwithgenresponse">
                  <div className="snippet" data-title="dot-typing">
                    <div className="stage">
                      <div className="dot-typing"></div>
                    </div>
                  </div>
                  <div className="genname">Generating response..</div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ExplorePhase;