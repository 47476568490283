import pptxgen from 'pptxgenjs';
import { sanitizeHeaderText, downloadFilenameGenerator } from '.';
import { IQueryContent, RESPONSE_BY, IRelevantFilesEngagement } from '../../../models/IQueryType';
import LABELS from '../../../ui-constants/en.json';

export type pptDataType = {
  query?: string;
  response?: IQueryContent | null;
  userQueryWithResponse?: any;
};

export const exportKcExpertsToPPT = (userQueryWithResponse: any) => {
  try {
    const presentation = new pptxgen();
    presentation.defineLayout({name:'A4', width: 11, height: 7});
    presentation.layout = 'A4';
    let slide = presentation.addSlide();
    let rows: pptxgen.TableRow[] = [];
    const sourceTable = [
      {
        text: ` Source: Discover Knowledge Capture`,
        options: {
          border: { type: 'none' },
          colspan: 5,
          margin: [25, 0, 10, 5]
        }
      }
    ];

    const createEngagementResponseRows = (
      TableRows: pptxgen.TableRow[],
      response: IQueryContent | null
    ) => {
      slide.addText(userQueryWithResponse?.query, { x: 0.4, y: 0.25, fontSize: 12, bold: true });

      if (userQueryWithResponse?.response) {
        let { relevantFiles, summary, responseType, isCompletelyAbortedResposne, isParitallyAbortedResponse } = userQueryWithResponse?.response;
        if(!summary.includes("Oops!")) {
          slide.addText("Here is the list of experts :", { x: 0.4, y: 0.50, fontSize: 11 });
          if (responseType === 'stop_generation') {
            slide.addText(`\n\n\n${summary}`, {
              x: 0.4,
              y: 0.25,
              fontSize: 12,
              color: '656579',
              italic: true
            });
          }
        } else {
          slide.addText(summary, { x: 0.4, y: 0.25, fontSize: 12 });
        }
       

        if (
          relevantFiles &&
          relevantFiles.length > 0 &&
          relevantFiles.every((el: any) => Boolean(el) && typeof el === 'object')
        ) {
          const documentTableRows: pptxgen.TableRow[] = [];

          const docuementTableHead: pptxgen.TableCell[] = [
            {
              text: sanitizeHeaderText('Name(s)'),
              options: { bold: true }
            },
            {
              text: sanitizeHeaderText('Role(s)'),
              options: { bold: true }
            },
            {
              text: sanitizeHeaderText('Project type'),
              options: { bold: true }
            },
            {
              text: sanitizeHeaderText('Project end date'),
              options: { bold: true }
            },
            {
              text: sanitizeHeaderText('Geographic focus of work'),
              options: { bold: true }
            }
          ];
          documentTableRows.push(docuementTableHead);

          relevantFiles.forEach((el: Partial<IRelevantFilesEngagement>, index: number) => {
            const Authors = el?.Authors?.filter((author: any) => author.author_name);
            if (Authors && Authors.length > 0) {
              const projectType = el?.Project_type;
              const projectEndDate = new Date(el?.Project_End_Date || '')
                .toLocaleString('en-GB', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric'
                })
                .split(' ')
                .join(' ');

              Authors.forEach((author, authorIndex) => {
                const rowValues: pptxgen.TableCell[] = [];
                rowValues.push({ text: author.author_name });
                rowValues.push({ text: author.role });

                if (authorIndex === 0) {
                  rowValues.push({
                    text: projectType,
                    options: {
                      rowspan: Authors.length,
                      valign: 'middle'
                    }
                  });
                  rowValues.push({
                    text: projectEndDate,
                    options: {
                      rowspan: Authors.length,
                      valign: 'middle'
                      
                    }
                  });

                  let geos = el.Geography?.reduce((sum, geo) => {
                    return sum + '\n' + geo;
                  }, '');
                  rowValues.push({
                    text: geos,
                    options: {
                      rowspan: Authors.length || 1,
                      valign: 'middle'
                    }
                  });
                } else {
                  rowValues.push({
                    text: '',
                    options: {
                      rowspan: 1,
                      valign: 'middle',
                      fill: {
                        color: 'f7f7f8'
                      },
                    }
                  });
                  rowValues.push({
                    text: '',
                    options: {
                      rowspan: 1,
                      valign: 'middle',
                      fill: {
                        color: 'f7f7f8'
                      },
                    }
                  });
                  rowValues.push({
                    text: '',
                    options: {
                      rowspan: 1,
                      valign: 'middle',
                      fill: {
                        color: 'f7f7f8'
                      },
                    }
                  });
                }

                documentTableRows.push(rowValues);
              });
            }
          });

          documentTableRows.push(sourceTable as pptxgen.TableRow);
          slide.addTable(documentTableRows, {
            y: 0.90,
            border: { color: 'e8e8ee', pt: 1, type: 'solid' },
            fill: {
              color: 'f7f7f8'
            },
            autoPage: true,
            autoPageRepeatHeader: true,
            autoPageCharWeight: -0.5,
            autoPageLineWeight: 0.5,
            fontSize: 10,
            fontFace: 'Arial',
          });
        }
        else {
          let modifiedSourceTable = [];
          if (isCompletelyAbortedResposne || isParitallyAbortedResponse) {
            let partialStopGenerationMsg = [
              {
                text: LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG,
                options: {
                  border: { type: 'none' },
                  colspan: 5,
                  margin: [10, 0, 10, 0],
                  color: '656579',
                  italic: true
                }
              }
            ];
            modifiedSourceTable.push(partialStopGenerationMsg as pptxgen.TableRow);
          }
          modifiedSourceTable.push(sourceTable as pptxgen.TableRow);
          slide.addTable(modifiedSourceTable);
        }
      }
      return TableRows;
    };

    createEngagementResponseRows(rows, userQueryWithResponse);

    presentation
      .write({ outputType: 'blob' })
      .then((file: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(file as Blob);
        link.download = downloadFilenameGenerator(RESPONSE_BY.EXPERTS, 'ppt');
        link.click();
      })
      .catch((err: any) => console.log('::PPT catch errr', err));
  } catch (err) {
    console.log('::::PPT error', err);
  }
};
