import { type AnyAction } from 'redux';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const STORE_USER_PROFILE = 'STORE_USER_PROFILE';
export const STORE_TEAMS_USER_PROFILE = 'STORE_TEAMS_USER_PROFILE';
export const SET_USER_SHAREPOINT_TOKEN = 'SET_USER_SHAREPOINT_TOKEN';

export function storeUserProfile(token: string[]): AnyAction {
  return {
    type: STORE_USER_PROFILE,
    value: token
  };
}

export function fetchUserProfile(): AnyAction {
  return {
    type: FETCH_USER_PROFILE
  };
}

export function storeTeamsUserProfile(userValue: object): AnyAction {
  return {
    type: STORE_TEAMS_USER_PROFILE,
    value: userValue
  };
}

export function setUserSharePointTokenForTeamsApp(value: string): AnyAction {
  return {
    type: SET_USER_SHAREPOINT_TOKEN,
    value
  };
}