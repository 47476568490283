import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../modal/Modal';
import FooterModalContent from '../footerModalContent/FooterModalContent';
import { ModalType } from '../../../models/ModalType';
import scssVar from '../../../styles/variables.module.scss';
import { getPrivacyPolicyContent } from '../../../services/privacyPolicy.service';
import LoadIndicator from '../loadIndicator/LoadIndicator';
import ClientDemoModalContent from '../footer/content/ClientDemoModalContent';
import PrivacyModalContent from '../footer/content/PrivacyModalContent';
import TermsModalContent from '../footer/content/TermsModalContent';
import { content } from "../footer/content/ClientDemoDisclaimer"; 

interface IOwnProps {
  show: boolean;
  type: 'privacy' | 'terms' | 'client' | null;
  onClose: () => void;
  headerIcon: React.ReactChild;
}

const PrivacyAndTermsModal: React.FC<IOwnProps> = ({
  show,
  type,
  onClose,
  headerIcon
}: IOwnProps) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show == true)
      if (type !== 'client') {
        loadData();
        setLoading(true);
      }
  }, [show]);

  const loadData = async () => {
    let data = await getPrivacyPolicyContent(type == 'privacy' ? 1 : 2);
    if (data?.policyTypeList?.length > 0) {
      setLoading(false);
      setData(data.policyTypeList[0].content);
    } else {
      setLoading(false);
      setData([]);
    }
  };
  let headerTitle;
  if (type === 'privacy') {
    headerTitle = 'Privacy Notice – EY Competitive Edge';
  } else if (type === 'terms') {
    headerTitle = 'EY Competitive Edge Global Terms of Use';
  } 
  else {
    headerTitle = 'EY Competitive Edge Demo: Disclaimer';
  }
  return (
    <div className='se-model-main'>
      <Modal
        isOpen={show}
        width={+scssVar.modalwidth}
        type={type === 'privacy' ? ModalType.Privacy : type === 'terms' ? ModalType.Terms : ModalType.Error}
        headerTitle={headerTitle}
        actionBtnText={type === 'privacy' || type === 'terms' ? 'Accept' : 'Close'}
        actionBtnEnabled
        onCloseBtnClicked={onClose}
        headerIcon={headerIcon}
        showHeaderIcon={true}
        isLoading={loading}
        >
        {/* <StyledFooterModalContent className='se-modal-body gray-2 noto-sans'>
          {loading ? (
            <div className='center-wrapper'>
              <LoadIndicator color='#000'/>
            </div>
          ) : (
            <Fragment>
            <FooterModalContent content={data ? data : ''} type={type} />
            </Fragment>
          )}
        </StyledFooterModalContent> */}
          <StyledFooterModalContent className="se-modal-body gray-2 noto-sans">
          {loading ? 
          <div className="center-wrapper"><LoadIndicator color='#000'/></div> : 
          <Fragment>
          {type === "privacy" && <PrivacyModalContent source='footer' content={data ? data : ""} />}
            {type === "terms" && <TermsModalContent source='footer' content={data ? data : ""} />}
            {/* {type === "client" && <ClientDemoModalContent content={content} />} */}
          </Fragment>}
         
        </StyledFooterModalContent>
      </Modal>
    </div>
  );
};

export const StyledFooterModalContent = styled.div`
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  text-align: left;
  line-height: 1.375rem;
  min-width: 500px;

  h1,
  h4 {
    color: ${scssVar.alternateblack};
  }

  P{
    font-family: ${scssVar.fontInterstate};
    font-size: 14px;
    line-height: 20px;
    color: ${scssVar.alternateblack}!important;

  }
  h1 {
    margin: 0;
    padding-bottom: 1.25rem;
    font-weight: 500;
    font-size: 2.25rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 3.063rem;
  }

  h4 {
    font-weight: 500;
  }

  
  .privacy-modal .motif-h4 {
    font-family: ${scssVar.fontInterstate};
    font-size: 14px;
    line-height: 20px;
    color: ${scssVar.alternateblack}!important;
    
  }
  .privacy-modal .motif-h6 {
    font-family: ${scssVar.fontInterstate};
    font-size: 12px;
    line-height: 20px;
    color: ${scssVar.alternateblack} !important;
    font-weight: lighter;
  }
`; 

export default React.memo(PrivacyAndTermsModal);
