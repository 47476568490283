import React, { ReactElement, useState } from "react"
import styled from "styled-components";
import TabTitle from "./TabTitle"

type Props = {
  children: ReactElement[]
}

const Tabs: React.FC<Props> = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <StyleTab>
      <ul>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            index={index}
            className={item.props.className}
            setSelectedTab={setSelectedTab}
          />
        ))}
      </ul>
      {children[selectedTab]}
    </StyleTab>
  )
}

const StyleTab = styled.div`

ul{
  display: flex;
  padding: 11px  0;
  margin: unset;
}
;`
export default Tabs;