import internal from 'stream';
import { INTERNAL_SERVER_ERROR, PAGE_NOT_FOUND } from '../app.constant';

export const getErrorMessage = (status: any) => {
  switch (status) {
    case 404:
      return PAGE_NOT_FOUND;
    case 500:
      return INTERNAL_SERVER_ERROR;
    default:
      return INTERNAL_SERVER_ERROR;
  }
};

export default function ErrorStatus(status: any) {
  const errorStatus = {
    status: status,
    message: getErrorMessage(status),
    statusText: getErrorMessage(status)
  };
  return errorStatus;
}
