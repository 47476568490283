import { useEffect } from "react";
// import "./../../App.css";
import MotifProgressLoader from "@ey-xd/motif-react/components/progress-loader";

const LoaderComponent = () => {
  //   useEffect(() => {
  //     document.body.style.overflow = "hidden";
  //   }, []);
  return <MotifProgressLoader show={true} fullscreen={true} />;
};
export default LoaderComponent;
