import React, { Component } from 'react';
class Transcripts extends Component {
  constructor(props: {} | Readonly<{}>) {
    super(props);
    this.state = {};
  }
  render() {
    return <>Transcripts</>;
  }
}

export default Transcripts;
