import React, { Fragment, useEffect, useRef } from 'react';
import styled from 'styled-components';
import CustomButton from '../motifComponents/button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  chatSearchInputValue,
  selectedSourceValues,
  setRightSideBarOpenClose,
  setSideBarOpenClose
} from '../../store/actions/commonActions';
import { MotifLabel } from '@ey-xd/motif-react';
import { buttonImg, newChatIcon } from '../../commonImage';
import {
  MAX_TEXT_LENGTH,
  MIN_SYMBOLS_TO_START_SEARCHING,
  contentAge,
  sectorQueries
} from '../../app.constant';
import InsightHeader from '../sectorInsights/SectorComponents/InsightHeader';
import { getChatThreadConversations, getSectorsHierarchy } from '../../services/chatQuery.service';
import { RESPONSE_BY } from '../../models/IQueryType';
import {
  setAnySourceSelected,
  setChatHistory,
  setChatMessageHistory,
  setIsChatThreadLoaded,
  toggleLoader
} from '../../store/actions/userChatActions';
import SectorInsights from '../sectorInsights/SectorInsights';
import { initializeSession, setCurrentSessionId } from '../../services/session.service';
import { IsJSONResponse, handleSessionID } from '../../utils/commonUtils';
import { DataSourceTypes } from '../../models/IDataSourceTypes';
import './SectorChatPage.scss';
import errorStatus from '../../utils/ErrorStatus';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';

const SectorChatPage = () => {
  const textFieldValue = useSelector((state: IStore) => state?.common?.chatSearchInput);
  const isTextFieldEmpty = textFieldValue !== undefined && textFieldValue.length > 0;
  const anySourceSelected = useSelector((state: IStore) => state?.common?.anySourceSelected);
  const [isSourceSelected, setSourceSelected] = useState(!anySourceSelected);
  const [inputValuelength, setInputValueLength] = useState('');
  const dropdownRef = useRef(null);
  const [sector, setSector] = useState<any[]>([]);
  const [geography, setGeography] = useState<any[]>([]);
  const [allsector, setAllsector] = useState<any[]>([]);
  const [allgeography, setAllgeography] = useState<any[]>([]);
  const [checkedsector, setCheckedsector] = useState<[]>([]);
  const [checkedgeography, setCheckedgeography] = useState<[]>([]);
  const [selectedContentAge, setSelectedContentAge] = useState<string>('');
  const [sectorenable, setSectorenable] = useState<string>('none');
  const [geographyenable, setGeographyenable] = useState<string>('none');
  const [hideShowOptions, setShowOptions] = useState(false);
  const [chatSearchInput, setChatSearchInputValue] = useState('');
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const { isClearLoading, isLoading, chatDataSummmary,isClearChatHistory } = useSelector(
    (state: any) => state.userChat
  );
  const isSidebarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);

  const isChatThreadLoaded = useSelector((store: IStore) => store?.userChat?.isChatThreadLoaded);
  const lastSavedThreadID = useSelector((state: IStore) => state?.userChat?.lastSavedThreadID);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const refToScroll = useRef<HTMLDivElement>(null);
  const { length } = chatDataSummmary?.table;
  const [isFocused, setIsFocused] = useState(isSidebarOpen);

  let filteredData: Array<any> = [];
  let filteredlabels: Array<string> = [];
  dispatch(chatSearchInputValue(chatSearchInput));

  const loadSelectedThreadConversations = async () => {
    dispatch(setIsChatThreadLoaded(false));
    setCurrentSessionId(selectedChatThread?.sessionId);
    let currentThreadConversations = await getChatThreadConversations(selectedChatThread?.threadId);
    if (currentThreadConversations?.messages) {
      let sources: any = [];
      let formattedREsponse = currentThreadConversations.messages.map((message: any) => {
        if (sources.indexOf(message.dataSubSource) === -1) sources.push(message.dataSubSource);
        let computedResponse: any = {};
        if (IsJSONResponse(message.response)) {
          computedResponse = JSON.parse(message.response);
          computedResponse.messageId = message.messageId;
        } else {
          computedResponse = {
            summary: message.response,
            Sources: [message.dataSubSource],
            messageId: message.messageId
          };
        }

        const obj = {
          [RESPONSE_BY.USER]: {
            summary: message.question
          },
          [RESPONSE_BY.STRATEGY_EDGE]: computedResponse
        };
        return obj;
      });
      dispatch(setChatMessageHistory(formattedREsponse));
      dispatch(
        selectedSourceValues({
          eyIPInterRadio:
            selectedChatThread.dataSource === DataSourceTypes.EYIP
              ? selectedChatThread.dataSubSource
              : '',
          checkedCheckboxes: [],
          validURLinputValue: ''
        })
      );
      dispatch(setIsChatThreadLoaded(true));
    }
  };

  useEffect(() => {
    if (selectedChatThread?.threadId && lastSavedThreadID !== selectedChatThread?.threadId) {
      dispatch(setSideBarOpenClose(true));
      loadSelectedThreadConversations();
    }
  }, [selectedChatThread]);

  const handleSendMessage = (query?: string) => {
    if (!isClearLoading && !isLoading && anySourceSelected) {
      setSourceSelected(true);
      // getInputData?.(chatSearchInput || '');
      if ((chatSearchInput && chatSearchInput.trim().length) || query) {
        handleSessionID(selectedChatThread);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: chatSearchInput || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });

        setChatSearchInputValue('');
        dispatch(toggleLoader(true));
        setSourceSelected(true);
        backdropDropdown();
        if (Object.keys(selectedChatThread).length === 0 && !localStorage.isSidebarManuallyClosed) {
          dispatch(setRightSideBarOpenClose(true));
        }
      }
    } else {
      setSourceSelected(false);
      dispatch(setSideBarOpenClose(true));
      dispatch(setAnySourceSelected(false));
    }
  };

  useEffect(() => {
    if (anySourceSelected) setSourceSelected(true);
  }, [anySourceSelected]);

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length <= 1000) {
      // Adjust the length as needed
      setInputValueLength(newValue);
    }
    setChatSearchInputValue(event.target.value);
  };

  const getHierarchy = async () => {
    try {
      await getSectorsHierarchy(userData).then((data) => {
        data.text().then((text) => {
          setSector(JSON.parse(text)['sector']);
          setAllsector(JSON.parse(text)['sector']);
          setGeography(JSON.parse(text)['geography']);
          setAllgeography(JSON.parse(text)['geography']);
        });
      });
    } catch (error: any) {
      return errorStatus(error?.response?.status);
    }
  };
  function toggleSector() {
    if (sectorenable == 'block') {
      setSectorenable('none');
    } else {
      setSectorenable('block');
    }
    setGeographyenable('none');
  }

  function toggleGeography() {
    if (geographyenable == 'block') {
      setGeographyenable('none');
    } else {
      setGeographyenable('block');
    }
    setSectorenable('none');
  }

  function setContentAge(val: any) {
    setSelectedContentAge(val);
  }

  const filterSectorNodes = (text: string) => {
    const filteredNodes: any[] =
      text.length >= MIN_SYMBOLS_TO_START_SEARCHING
        ? filterData(text, sector)
        : defaultData(allsector);
    setSector(filteredNodes);
    if (text.length < 2) setSector(allsector);
  };

  const filterGeographyNodes = (text: string) => {
    const filteredNodes: any[] =
      text.length >= MIN_SYMBOLS_TO_START_SEARCHING
        ? filterData(text, geography)
        : defaultData(allgeography);
    setGeography(filteredNodes);
    if (text.length < 2) setGeography(allgeography);
  };

  const filterData = (value: string, nodes: Array<any>) => {
    nodes.filter((node: any) => {
      if (node?.label?.toLowerCase().includes(value.toLowerCase())) {
        const lebels: Array<string> = extractAllNodes(node);
        if (!haveCommonElements(filteredlabels, lebels)) {
          filteredData.push(node);
          filteredlabels.push(...lebels);
        }
      }
      if (node.children) {
        filterData(value, node.children);
      }
    });
    return filteredData;
  };

  function haveCommonElements(array1: Array<string>, array2: Array<string>) {
    return array1.some((element) => array2.includes(element));
  }

  const extractAllNodes = (allNodes: any) => {
    let childrenArray: Array<any> = [];
    allNodes &&
      allNodes.children &&
      allNodes.children.length > 0 &&
      allNodes.children.map((currentEntity: any) => {
        if (currentEntity.children) {
          const recursiveChildren: Array<string> =
            extractAllCheckedChildrenRecursive(currentEntity);
          childrenArray = [...childrenArray, ...recursiveChildren];
        }
        childrenArray = [...childrenArray, currentEntity.value];
      });
    childrenArray.push(allNodes.value);
    return childrenArray;
  };

  const extractAllCheckedChildrenRecursive = (entity: any): Array<string> => {
    let allChildren: Array<any> = [];
    const children: Array<any> = entity.children;
    children &&
      children.map((currentEntity: any) => {
        if (currentEntity.children) {
          const recursiveChildren: Array<any> = extractAllCheckedChildrenRecursive(currentEntity);
          allChildren = [...allChildren, ...recursiveChildren];
        }
        allChildren.push(currentEntity.value);
      });
    return allChildren;
  };

  const defaultData = (nodes: Array<string>) => {
    return nodes;
  };

  const backdropDropdown = () => {
    if ((dropdownRef?.current && sectorenable) || geographyenable || selectedContentAge) {
      setGeographyenable('none');
      setSectorenable('none');
      setShowOptions(!hideShowOptions);
    }
  };

  const clearContentAge = () => {
    setSelectedContentAge('');
  };
  useEffect(() => {
    getHierarchy();
  }, []);

  const clsoeOtherDropdowns = () => {
    setGeographyenable('none');
    setSectorenable('none');
  };
  const handleFocus = () => {
    if (!isFocused) {
      dispatch(setSideBarOpenClose(true));
      setIsFocused(true);
    }
  };
  const breadcrumbs = [{ label: 'Home' }, { label: 'EY Source Data', current: true }];
  const checkIframe = window.location !== window.parent.location;
  const handleQuerySelection = (query: string) => {
    if (query?.length <= 1000) {
      setInputValueLength(query);
    }
    // setChatSearchInputValue(query);
    handleSendMessage(query);
  };
  return (
    <StyleNewChatComponent
      isSourceSelected={isSourceSelected}
      isRightSideBar={isRightSideBarOpen || false}
      checkIframe={checkIframe}
      ref={dropdownRef}>
      {isChatThreadLoaded ? (
        <Fragment>
          <div className={isRightSideBarOpen ? 'breadcrumbOpen' : 'breadcrumb'}>
            <Breadcrumb items={breadcrumbs} />
          </div>
          <InsightHeader
            length={length}
            contentAge={contentAge}
            selectedContentAge={selectedContentAge}
            sectorenable={sectorenable}
            sector={sector}
            geographyenable={geographyenable}
            geography={geography}
            hideShowOptions={hideShowOptions}
            checkedgeography={checkedgeography}
            checkedsector={checkedsector}
            isClearChatHistory={isClearChatHistory}
            setContentAge={setContentAge}
            filterSectorNodes={filterSectorNodes}
            setCheckedsector={setCheckedsector}
            filterGeographyNodes={filterGeographyNodes}
            setCheckedgeography={setCheckedgeography}
            clearContentAge={clearContentAge}
            clsoeOtherDropdowns={clsoeOtherDropdowns}
            toggleSector={toggleSector}
            toggleGeography={toggleGeography}
          />
          {length > 0 ? (
            <div ref={refToScroll}>
              <SectorInsights
                sectors={checkedsector}
                geographies={checkedgeography}
                contentage={selectedContentAge}
                chatQueryApi={inputValuelength}
                handleFocus={handleFocus}
              />
            </div>
          ) : (
            <>
              <Fragment>
                <div className='center'>
                  <img src={newChatIcon} alt='new chat icon' className='responsive-image' />
                </div>
                <div className='search-bar'>
                  <div className='input-container'>
                    <input
                      type='text'
                      value={chatSearchInput}
                      onChange={handleInputChange}
                      className='input-bar'
                      disabled={inputValuelength.length >= MAX_TEXT_LENGTH}
                      placeholder='What would you like to ask ? '
                      onKeyDown={(event: any) => handleKeyDown(event)}
                      onFocus={handleFocus}
                      maxLength={1000}
                    />
                    <div className='warning-length'>
                      {!isSourceSelected && (
                        <div className='warning'>{'Please select a data source to continue'}</div>
                      )}
                      <div className='chat-page number-validate'>
                        <span className={'query-text-length'}>
                          {`(${inputValuelength.length ?? 0}/1000)`}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className='send-btn'>
                    <CustomButton disabled={!isTextFieldEmpty} onClickHandler={handleSendMessage}>
                      <img src={buttonImg} alt='search button' />
                    </CustomButton>
                  </span>
                </div>

                <div>
                  <MotifLabel className=''>Try these questions</MotifLabel>
                  <div className='card-container'>
                    {sectorQueries &&
                      sectorQueries.map((query, index) => (
                        <div
                          className='question-card'
                          onClick={() => handleQuerySelection(query.query)}
                          key={index}>
                          <p className='font-family'>{query.query}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </Fragment>
            </>
          )}
        </Fragment>
      ) : (
        <div className='loader-position'>
          <div className='loader'></div>
          <p>Loading...</p>
        </div>
      )}
    </StyleNewChatComponent>
  );
};

const StyleNewChatComponent = styled.div<{
  isSourceSelected: boolean;
  isRightSideBar: boolean;
  checkIframe: boolean;
}>`
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28vh;
    margin-top: 10%;
  }

  .responsive-image {
    max-width: 100%;
    height: auto;
  }
  .breadcrumbOpen {
    margin-left: 2%;
    margin-top: 3vh;
    @media screen and (min-width: 1000px) and (max-width: 1250px) {
      margin-left: 16px;
    }
    @media screen and (min-width: 1250px) and (max-width: 1370px) {
      margin-left: 22px;
    }
    @media screen and (min-width: 1370px) and (max-width: 1550px) {
      margin-left: 29px;
    }
    @media screen and (min-width: 1550px) and (max-width: 1750px) {
      margin-left: 40px;
    }
  }
  .breadcrumb {
    margin-left: 3%;
    margin-top: 3vh;
    @media screen and (min-width: 1000px) and (max-width: 1250px) {
      margin-left: 29px;
    }
    @media screen and (min-width: 1370px) and (max-width: 1550px) {
      margin-left: 41px;
    }
    @media screen and (min-width: 1550px) and (max-width: 1750px) {
      margin-left: 51px;
    }
  }
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .input-bar {
    width: 100%;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #c3c3cb;
  }
  .warning {
    color: red;
    font-size: 12px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    width: 95%;
    position: relative;
    top: 2px;
    margin-left: 4%;
  }
  .question-card {
    width: 15%;
    height: 100px;
    background-color: #f9f9fa;
    flex: 0.5;
    margin: 0 10px 0 30px;
    justify-content: space-around;
    align-items: center;
    display: flex;
    padding: 1%;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  .question-card: hover {
    background-color: #f3f3f5;
    cursor: pointer;
  }

  .card-container {
    display: flex;
    justify-content: space-between;
  }

  .motif-label {
    font-size: 18px;
    font-weight: 400;
    color: #2e2e38;
    // height: 7vh;
    text-align: center;
    position: relative;
    // margin-top: 4%;

    //sectot chat page
    padding-bottom: ${(props) => (props.isRightSideBar ? '4%' : '1.6%')};
    @media screen and (min-width: 1464px) and (max-width: 1920px) {
      padding-bottom: ${(props) => (props.isRightSideBar ? '3%' : '1.4%')};
    }
    @media screen and (min-width: 1000px) and (max-width: 1300px) {
      padding-bottom: ${(props) =>
        props.isRightSideBar && props.checkIframe
          ? '8%'
          : props.isRightSideBar && !props.checkIframe
            ? '3%'
            : props.checkIframe
              ? '4%'
              : '2%'};
    }
  }

  .chat-page {
    display: flex;
    justify-content: flex-end !important;
    width: 100%;
  }

  .number-validate {
    color: #747480;
    position: relative;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
  }
  .query-text-length {
    text-align: right;
  }
  .warning-length {
    width: 100%;
    display: flex;
    color: #747480;
  }
  .warning {
    color: red;
    font-size: 12px;
    width: 100%;
  }
  .send-btn {
    position: relative;
    top: -10px;
  }
  .font-family {
    font-family: 'EYInterstate';
  }
`;

export default SectorChatPage;
