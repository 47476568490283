import { useSelector } from 'react-redux';
import { IStore } from '../../store';
import { MotifLabel } from '@ey-xd/motif-react';
import styled from 'styled-components';
import { surveyQueries } from '../../app.constant';

interface QuestionsPageProps {
  handleSurveyQuerySelection: (query: string) => void;
}

const QuestionsPage: React.FC<QuestionsPageProps> = ({ handleSurveyQuerySelection }) => {
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const selectedDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );

  return (
    <StyledQuestionsPage>
      <div>
        <MotifLabel className=''>Try these questions</MotifLabel>
        {eyIPInterRadio !== 'Surveys' ||
          (selectedDataSourceSection === 5 && (
            <div className='card-container'>
              <div className='question-card'>
                <p className='font-family'>What are the PE investments made in India?</p>
              </div>
              <div className='question-card'>
                <p className='font-family'>
                  Give me list of Microsoft transaction valuations along with dates
                </p>
              </div>
              <div className='question-card'>
                <p className='font-family'>
                  How many countries are currently developing drugs of Oncology?
                </p>
              </div>
            </div>
          ))}

        {eyIPInterRadio !== 'Surveys' && selectedDataSourceSection === 4 && (
          <div className='card-container'>
            <div className='question-card'>
              <p className='font-family'>
                What are are the recent news or updates featured on the website?
              </p>
            </div>
            <div className='question-card'>
              <p className='font-family'>What are the main product lines listed on the website?</p>
            </div>
            <div className='question-card'>
              <p className='font-family'>
                Summarise the corporate social responsibility efforts mentioned on the website.
              </p>
            </div>
          </div>
        )}

        {eyIPInterRadio === 'Surveys' && (
          <div className='card-container'>
            {surveyQueries &&
              surveyQueries.map((query, index) => (
                <div
                  className='question-card'
                  onClick={() => handleSurveyQuerySelection(query.query)}
                  key={index}>
                  <p className='font-family'>{query.query}</p>
                </div>
              ))}
          </div>
        )}
      </div>
    </StyledQuestionsPage>
  );
};

const StyledQuestionsPage = styled.div`
  .question-card {
    width: 15%;
    height: 100px;
    background-color: #f9f9fa;
    flex: 0.5;
    margin: 0 10px 0 30px;
    font-size: 14px;
    line-height: 20;
    font-weight: 300;
    justify-content: space-around;
    align-items: center;
    display: flex;
    padding: 1%;
    cursor: pointer;
    text-align: center;
  }
  .question-card: hover {
    background-color: #f3f3f5;
    cursor: pointer;
  }
  .card-container {
    display: flex;
    justify-content: space-between;
  }

  .motif-label {
    font-size: 16px;
    font-weight: 600;
    color: #2e2e38;
    // height: 7vh;
    text-align: center;
    position: relative;
    // margin-top: 4%;
    padding-bottom: 9%;
    padding-top: 1%;
  }
  .font-family {
    font-family: 'EYInterstate';
  }
`;

export default QuestionsPage;
