import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setISActiveTab } from "../../../store/actions/userChatActions";

type Props = {
  title: string;
  className?: string;
  children: any
};

const Tab: React.FC<Props> = ({ children, className, title }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setISActiveTab(title));
  }, [title])
  
  return <div className={className}>{children}</div>;
};

export default Tab;
