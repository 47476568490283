import { type AnyAction } from 'redux';
import { type Loop, type LoopReducer } from 'redux-loop';
import * as commonActions from '../actions/commonActions';

export interface ICommonState {
  userEmail: string;
  isSideBarOpen?: boolean;
  isRightSideBarOpen?: boolean;
  eyIPInterRadio?: string;
  checkedCheckboxes?: Array<string>;
  validURLinputValue?: string;
  chatSearchInput?: string;
  anySourceSelected?: boolean;
  selectedSourceOnNewChat?: string;
  isSurveyDocOpen: boolean;
  isFavouriteStatus?: boolean;
  policyTypeList?:any;
  showPrivacyAndTermsModal?: boolean;
  showUnauthorizedAccess?: boolean;
  isByodDocOpen?: boolean;
  triggerDnfOpenAiSearchValue?:boolean;
}

export const initialState: ICommonState = {
  userEmail: '',
  isSideBarOpen: false,
  isRightSideBarOpen: false,
  eyIPInterRadio: '',
  checkedCheckboxes: [],
  validURLinputValue: '',
  chatSearchInput: '',
  anySourceSelected: false,
  selectedSourceOnNewChat: '',
  isSurveyDocOpen: false,
  isFavouriteStatus: false,
  policyTypeList:[],
  showPrivacyAndTermsModal: false,
  showUnauthorizedAccess: false,
  isByodDocOpen: false,
  triggerDnfOpenAiSearchValue:false
};

export const commonReducer: LoopReducer<ICommonState> = (
  state: ICommonState = initialState,
  action: AnyAction
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
): ICommonState | Loop<ICommonState, AnyAction> => {
  switch (action.type) {
    case commonActions.SET_USER_EMAIL: {
      return {
        ...state,
        userEmail: action.email
      };
    }
    case commonActions.SET_SIDEBAR_OPEN_CLOSE: {
      return {
        ...state,
        isSideBarOpen: action.value
      };
    }
    case commonActions.SET_SURVEY_DOC_OPEN_CLOSE: {
      return {
        ...state,
        isSurveyDocOpen: action.value
      };
    }
    case commonActions.SET_BYOD_DOC_OPEN_CLOSE: {
      return {
        ...state,
        isByodDocOpen: action.value
      };
    }
    case commonActions.SET_RIGHT_SIDEBAR_OPEN_CLOSE: {
      return {
        ...state,
        isRightSideBarOpen: action.value
      };
    }

    case commonActions.SELECTED_SOURCE_VALUES:
      return {
        ...state,
        eyIPInterRadio: action.value.eyIPInterRadio,
        checkedCheckboxes: action.value.checkedCheckboxes,
        validURLinputValue: action.value.validURLinputValue,
        anySourceSelected:
          !!action.value.eyIPInterRadio ||
          !!action.value.checkedCheckboxes.length ||
          !!action.value.validURLinputValue
      };

    case commonActions.CHAT_SEARCH_INPUT_VALUE:
      return {
        ...state,
        chatSearchInput: action.value
      };

    case commonActions.SELECT_SOURCE_ON_NEW_CHAT:
      return {
        ...state,
        selectedSourceOnNewChat: action.value
      };

    case commonActions.FAVOURITE_STATUS:
      return {
        ...state,
        isFavouriteStatus: action.value
      };

    case commonActions.SHOW_PRIVACY_AND_TERMS_MODAL: {
      return {
        ...state,
        showPrivacyAndTermsModal: action.data,
        policyTypeList:action.policyTypeList
      };
    };

   case commonActions.SHOW_UNAUTHORIZED_ACCESS: {
      return {
        ...state,
        showUnauthorizedAccess: action.value
      };
    }
//#region Handling 404 scenario
    case commonActions.TRIGGER_DNF_OPENAI_SEARCH: {
      return {
        ...state,
        triggerDnfOpenAiSearchValue: action.value
      };
    }
  // #endregion
    default:
      return state;
  }

  
};
