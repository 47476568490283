import React from 'react';
import { MotifButton } from '@ey-xd/motif-react';
interface CustomButtonProps {
  children: any; // Replace 'any' with the actual type of 'item'
  onClickHandler: () => void;
  disabled?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({ children, onClickHandler, disabled }) => (
  <MotifButton disabled={disabled} onClick={onClickHandler}>
    {children}
  </MotifButton>
);

export default CustomButton;
