import React from "react";
import "./LinkButton.scss";

type LinkButtonProps = {
  onClick: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
};
const LinkButton: React.FC<LinkButtonProps> = ({
  onClick,
  children,
  disabled,
}) => {
  return (
    <button
      className={`linkButton ${disabled ? "cursor-disabled" : "cursor-enabled"}`}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default LinkButton;
