import React, { ReactElement, ReactNode, ReactChild, Fragment, } from "react";
import { Styles } from "react-modal";
import styled from "styled-components";
import ModalHeader from "../modal/ModalHeader";
import PolicyFooter from "./PolicyFooter";
import { ModalType } from "../../../models/ModalType";
import scssVar from "../../../styles/variables.module.scss";

export interface IOwnProps {
  type: ModalType;
  width?: number;
  children: ReactNode;
  headerTitle: string;
  actionBtnEnabled: boolean;
  actionBtnText?: string;
  onActionBtnClicked?: () => void;
  onCloseBtnClicked: () => void;
  onChangeCheckBox?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  hideCloseIcon?: boolean;
  isLoading?: boolean;
  showModalFooter?: boolean;
  showHeaderToolTip?: boolean;
  headerToolTip?: string;
  showHeaderIcon?: boolean;
  headerIcon?: ReactChild;
}

const getPortalStyles = (width: number): Styles => {
  const obj: Styles = {
    //@ts-ignore
    backgroundColor: `#23232f`,
    border: "0px",
    padding: "0px",
    width: `${width}px`,
    margin: "auto",
    marginTop: "34px",
  };

  return obj;
};

const PolicyWrapper: React.FC<IOwnProps> = ({
  type,
  width = window.innerWidth * 0.3,
  children,
  headerTitle,
  actionBtnEnabled,
  actionBtnText = "Create",
  onActionBtnClicked,
  onCloseBtnClicked,
  hideCloseIcon,
  isLoading,
  showModalFooter = true,
  showHeaderToolTip = false,
  headerToolTip = "",
  showHeaderIcon,
  headerIcon,
  onChangeCheckBox,
}: IOwnProps): ReactElement => {

     const handleClick =()=>{
    onActionBtnClicked&&onActionBtnClicked();
      }

  return (
    <div style={getPortalStyles(width) as React.CSSProperties} className="se-react-model">
      <ModalHeader
        headerTitle={headerTitle}
        onCloseBtnClicked={onCloseBtnClicked}
        hideCloseIcon={hideCloseIcon}
        showHeaderToolTip={showHeaderToolTip}
        headerToolTip={showHeaderToolTip ? headerToolTip : ""}
        showHeaderIcon={showHeaderIcon}
        headerIcon={showHeaderIcon ? headerIcon : <Fragment></Fragment>}
        isLoading={isLoading}
        type={type}

      />

      <StyledModalContentWrapper  >
        {children}
        {showModalFooter && (
          <PolicyFooter
            type={type}
            actionBtnEnabled={actionBtnEnabled}
            actionBtnText={actionBtnText}
            onActionBtnClicked={handleClick}
            onCloseBtnClicked={onCloseBtnClicked}
            onChangeCheckBox={onChangeCheckBox}
            isLoading={isLoading}
          />
        )}
      </StyledModalContentWrapper>
    </div>
  );
};

const StyledModalContentWrapper = styled.div`
.se-modal-body { 
  max-height: calc(64vh - 100px); // removed the scroll bar
  overflow-y: auto;
  word-wrap: break-word;
}


`;

export default PolicyWrapper;
