import { useDispatch, useSelector } from "react-redux";
import { downloadFilenameGenerator, downloadFilenameGeneratorForPPT } from ".";
import apiRoutes from "../../../services/api";
import {
  getCurrentSessionId,
 } from "../../../services/session.service";
import { IStore } from "../../../store";
import { RESPONSE_BY } from "../../../models/IQueryType";

export const exportToCSV = async (query: string | undefined, source: RESPONSE_BY.OPENAI | RESPONSE_BY.EMBROYNIC, userData:any, checkedCheckboxes: any, eyIPInterRadio: any, validURLinputValue: any) => {
  if (query) {
    const sessionId = getCurrentSessionId();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${userData?.sid?.rawIdToken || userData?.sid || ""}`
      },
      body: JSON.stringify({ query: query }),
    };

    const file = await fetch(apiRoutes.exportCSVChat, requestOptions)
    
    if (!file.ok) {
      throw new Error("Something went wrong")
    } else {
      const response = await file.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(response);
      const extension = response.type === "application/zip" ? "zip" : "csv";
      link.download = downloadFilenameGeneratorForPPT(checkedCheckboxes, eyIPInterRadio, validURLinputValue, extension);
      link.click();
    }
  }
}