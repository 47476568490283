export const convertNumber = (data: string, decimalPoint: number) => {
  let num = Number(data);
  if (!Number.isNaN(num)) {
    if (num === 0 || num === null || num === undefined) {
      return {
        val: "  -",
        title: num,
      };
    }
    if (num > 0) {
      return {
        val: ("$" + conversion(num, decimalPoint)) as string,
        title: num,
      };
    } else {
      return {
        val: ("$ (" + conversion(num, decimalPoint) + ")") as string,
        title: num,
      };
    }
  } else {
    return {
      val: data,
    };
  }
};

const round = (value: number, decimalPoint: number) => {
  var multiplier = Math.pow(10, decimalPoint);
  return Math.round(value * multiplier) / multiplier;
};

const conversion = (labelValue: any, decimalPoint: number) => {
  // Twelve Zeroes for Trillions
  return round(Math.abs(Number(labelValue)), decimalPoint) >= 1.0e12
    ? round(Math.abs(Number(labelValue)) / 1.0e12, decimalPoint) + "T"
    : // Nine Zeroes for Millions
    round(Math.abs(Number(labelValue)), decimalPoint) >= 1.0e9
    ? round(Math.abs(Number(labelValue)) / 1.0e9, decimalPoint) + "B"
    : // Six Zeroes for Millions
    round(Math.abs(Number(labelValue)), decimalPoint) >= 1.0e6
    ? round(Math.abs(Number(labelValue)) / 1.0e6, decimalPoint) + "M"
    : // Three Zeroes for Thousands
    round(Math.abs(Number(labelValue)), decimalPoint) >= 1.0e3
    ? round(Math.abs(Number(labelValue)) / 1.0e3, decimalPoint) + "K"
    : round(Math.abs(Number(labelValue)), decimalPoint);
};
