import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkButton from '../LinkButton/LinkButton';
import LoadingIndicator from '../LoadingIndicator';
import PageConfig from '../../../ui-constants/en.json';
import './InputBox.scss';
import { sendImg } from '../../../commonImage';
import { IStore } from '../../../store';
import { setSurveyDocOpenClose } from '../../../store/actions/commonActions';
import SuggestionText from '../../common/errorPages/ErrorSuggestion';
type InputBoxProps = {
  queryText: string;
  selectedSegment?: boolean;
  refInput: React.RefObject<HTMLTextAreaElement>;
  hasQueries: boolean;
  handleOnChange: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSendMessage: (ev: React.MouseEvent<HTMLElement>) => void;
  handleResetMessage: () => void;
  handleKeyDown: (ev: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
  isEngagement?: boolean;
  selectedSurveyList?: string[];
  handleOnFocus?: () => void;
  chatLoadCompleted?: boolean;
};

const InputBox = ({
  selectedSegment,
  queryText = '',
  refInput,
  selectedSurveyList,
  handleOnChange,
  handleSendMessage,
  handleResetMessage,
  handleKeyDown,
  hasQueries,
  disabled,
  isEngagement = false,
  handleOnFocus,
  chatLoadCompleted = false
}: InputBoxProps) => {
  const {
    isClearLoading = false,
    downloadFileMessage,
    errorMessage,
    isSideBarOpen
  } = useSelector((state: any) => state.userChat);
  const dispatch = useDispatch();
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const anySourceSelected = useSelector((state: IStore) => state?.common?.anySourceSelected);
  const { isRightSideBarOpen } = useSelector((state: IStore) => state.common);
 const isSideOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
  const [errorText, setErrorMessage] = useState('');
  const selectedDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const errorMessageText = errorMessage || errorText;
  const INPUT_PLACEHOLDER = 'What would you like to ask?';
  const TEXT_HEIGHT = queryText.length > 150 ? 60 : 44;
  const labelClass = selectedSegment ? 'chatbox__label' : 'chatbox__label-dark';

  const handleQueryApi = (ev: any) => {
    if (!anySourceSelected && !isEngagement) {
      setErrorMessage('Please select a data source to continue');
    } else if (!selectedSurveyList?.length && eyIPInterRadio === 'Surveys') {
      dispatch(setSurveyDocOpenClose(true));
      setErrorMessage('Please select the relevant survey(s) to continue');
    } else {
      handleSendMessage(ev);
    }
  };

  return (
    <div className={isRightSideBarOpen ? 'chatbox' : 'chatbox__sidebar-margin'}>
      <div className={'chatbox__text-area-container'}>
        <textarea
          ref={refInput}
          // className={!isSideBarOpen ? 'chatbox__text-area' : 'chatbox__text-area sidebar-open'}
          className={`${!isSideBarOpen ? 'chatbox__text-area' : 'chatbox__text-area sidebar-open'} ${disabled ? "cursor-disabled" : "cursor-enabled"} ${queryText.length > 150 ? "textarea1" : "textarea2"}`}
          value={queryText}
          onChange={handleOnChange}
          rows={1}
          placeholder={isEngagement ? 'Find an EY expert ' : INPUT_PLACEHOLDER}
          maxLength={PageConfig.charLimit}
          // style={{
          //   height: TEXT_HEIGHT,
          //   cursor: disabled ? 'not-allowed' : 'default'
          // }}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          onFocus={handleOnFocus}
         />
        <div onClick={(ev) => handleQueryApi(ev)} className='chatbox__send-icon'>
          <span
            // style={{ cursor: queryText?.length <= 0 ? 'not-allowed' : 'pointer' }}
            className={`${queryText?.length <= 0 ? "cursor-disabled" : "cursor-enabled"}`}
          >
            <img src={sendImg} alt={'send'} />
          </span>
        </div>
      </div>

      <div className='warning-length-input'>
      <span className={!isSideOpen && !isRightSideBarOpen ? 'number-validate-both-closed' : isSideOpen && isRightSideBarOpen ? 'number-validate' : (isSideOpen || isRightSideBarOpen) ? 'number-validate-sidebar' : 'number-validate'}>        
      {<SuggestionText/>}
          <span className={'query-text-length'}>
            ({queryText.length}/{PageConfig.charLimit})
          </span>
        </span>
        {!anySourceSelected && (
          <div className='warning-message-input'>
            {selectedDataSourceSection === 4 ? 'Please upload a URL to continue' : errorText}
          </div>
        )}
        {!selectedSurveyList?.length && eyIPInterRadio === 'Surveys' && (
          <div className='warning'>
            {errorText || (
              <span className='warning-black'>
                {'Please select the relevant survey(s) to continue'}
              </span>
            )}
          </div>
        )}     
      </div>
    </div>
  );
};
export default InputBox;
