import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { EY_PROPRIETARY_TILES } from '../../label.constant';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../store';
import { IAppStoreProps } from '../appStoreComponent/AppStoreComponent';
import { openLinkInSameTab } from '../../utils/commonUtils';
import Cards from '../common/card/cards';
import Pagination from '../common/pagination/pagination';
import { setFavourite } from '../../services/userProfile.service';
import { favouriteStatus } from '../../store/actions/commonActions';
import { leftArrow, rightArrow } from '../../commonImage';

type UserApp = {
  isFavourite: boolean;
  name: string;
  url?: string;
};

const AppContainer = ({ userApps, isLoading }: IAppStoreProps): JSX.Element => {
  const isProd = process.env.REACT_APP_SE_APP_ENV === 'PROD';
  const containerRef = useRef<HTMLDivElement | null>(null);
  const cardContainerRef = useRef<HTMLDivElement | null>(null);
  const [currentPageCardsLength, setCurrentPageCardsLength] = useState(0);
  const isSidebarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);

  const dispatch = useDispatch();
  const isFavouriteStatus = useSelector((state: IStore) => state.common?.isFavouriteStatus);
  const userMailID = useSelector((state: IStore) => state.common?.userEmail);

  //@ts-ignore to show sustainability again remove this tile.name !== 'Sustainability' &&
  const eyProprietaryDataTiles = EY_PROPRIETARY_TILES.filter(tile =>
    userApps?.find(app => app.name === tile.name) && (!isProd || tile.name !== 'Value Accelerator')
  );

  //@ts-ignore
  const handleRouting = (e: any, item: any): void => {
    if (item) {
      e.preventDefault();
      openLinkInSameTab(item, userMailID);
    }
  };

  const appNameFavourite = (appName: string) => {
    const appNamesMap: { [key: string]: string } = {
      "Outside-In Web Data": "Secondary Research",
      "Private Equity & Venture Capital": "PEVC",
      "Embryonic": "Embryonic",
      "Generate": "Generate",
      "Sustainability": "Sustainability",
      "Value Accelerator": "Value Accelerator"
    };
    return appNamesMap[appName];
  }

  const [favouriteClicked, setFavouriteClicked] = useState(!isFavouriteStatus);
  const handleFavouriteClick = async (item: any) => {
    const newFavouriteStatus = !item.isFavourite;
    await setFavourite(appNameFavourite(item.name), newFavouriteStatus);
    setFavouriteClicked(prevState => !prevState);
    dispatch(favouriteStatus(favouriteClicked));
  };

  const nameMapping = {
    'PEVC': 'Private Equity & Venture Capital',
    'OUTSIDE_WEB_DATA': 'Outside-In Web Data'
  };

  const [cardsPerView, setCardsPerView] = useState(getCardsPerView());
  useEffect(() => {
    const updateCardsPerView = () => {
      setCardsPerView(getCardsPerView());
    };
    updateCardsPerView();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setCardsPerView(getCardsPerView());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  function getCardsPerView() {
    let flexContainerWidth : any = cardContainerRef?.current?.clientWidth;
    let currentPageCards = Math.floor(flexContainerWidth / 275)
    return currentPageCards;
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(eyProprietaryDataTiles.length / cardsPerView));

  useEffect(() => {
    setTotalPages(Math.ceil(eyProprietaryDataTiles.length / cardsPerView));
  }, [eyProprietaryDataTiles, cardsPerView]);

  const [indexOfLastCard, setIndexOfLastCard] = useState(currentPage * cardsPerView);
  const [indexOfFirstCard, setIndexOfFirstCard] = useState(indexOfLastCard - cardsPerView);

  useEffect(() => {
    const newIndexOfLastCard = currentPage * cardsPerView;
    setIndexOfLastCard(newIndexOfLastCard);
    setIndexOfFirstCard(newIndexOfLastCard - cardsPerView);
  }, [currentPage, cardsPerView]);

  const mergedData = eyProprietaryDataTiles.map(tile => {
    const userApp = userApps?.find(app => app.name === tile.name);
    return {
      ...tile,
      isFavourite: userApp ? userApp.isFavourite : false,
    };
  });

  //@ts-ignore
  const sortedInitialCards = [...mergedData].sort((a, b) => b.isFavourite - a.isFavourite);
  let copyEyProprietaryDataTiles = [...sortedInitialCards];

  const currentCards = copyEyProprietaryDataTiles.slice(indexOfFirstCard, indexOfLastCard);
  useEffect(() => {
    setCurrentPageCardsLength(currentCards.length);
  }, [indexOfFirstCard, indexOfLastCard]);

  const scrollLeft = () => {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  const scrollRight = () => {
    setCurrentPage(currentPage => Math.min(currentPage + 1, totalPages));
  }
  return (
    <StyleAppContainer
      isRightSideBarOpen={isRightSideBarOpen}
      isSidebarOpen={isSidebarOpen}
      currentCardsLength = {currentPageCardsLength} >
      <Pagination
        numberOftiles={eyProprietaryDataTiles.length}
        indexOfLastCard={indexOfLastCard}
        indexOfFirstCard={indexOfFirstCard}
        cardsPerView={cardsPerView}
        currentCards={currentCards}
        currentPage={currentPage}
        totalPages={totalPages}
      />
      <div className='fixed-card-container' ref={cardContainerRef}>
        <div className="arrow left-arrow" onClick={() => scrollLeft()}>
          <img className={`left-arrowImg ${currentPage === 1 ? "disabled" : ""}`} src={leftArrow} alt="tooltip"></img>
        </div>
        <div className='flex-container' ref={containerRef}>
          {currentCards.map((userApp, index) => {
            return (
              <div
                key={index}
                className={`flex-item flex-3 cursor-${userApp?.url ? `pointer` : `default`} ${index === eyProprietaryDataTiles?.length - 1 ? 'last-card' : ''}`}
              >
                <Cards
                  pragaphText={userApp?.description}
                  appTitle={nameMapping[userApp?.name as keyof typeof nameMapping] || userApp?.name}
                  isFavourite={userApp?.isFavourite}
                  images={userApp?.imageName}
                  onFavouriteClick={(e) => {
                    e.stopPropagation();
                    handleFavouriteClick(userApp);
                  }}
                  onCardClick={(e) => {
                    e.stopPropagation();
                    handleRouting(e, userApp);
                  }}
                />
              </div>
            )
          })}
        </div>
        <div className="arrow right-arrow" onClick={() => scrollRight()}>
          <img className={`right-arrowImg ${currentPage === totalPages ? "disabled" : ""}`} src={rightArrow} alt="tooltip"></img>
        </div>
      </div>
    </StyleAppContainer>
  );
};

const StyleAppContainer = styled.div<{
  isSidebarOpen?: boolean;
  isRightSideBarOpen?: boolean;
  currentCardsLength?: number;
}>`
position: relative;

.fixed-card-container {
  margin: auto;
  max-width: 89%;
  display: flex;
  margin-top: -25px;
  padding-bottom: 10px;
  @media (min-width: 1300px) {
    max-width: 79%; // Reduce max-width for larger screens
  }
}

.flex-container {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: ${(props) => (props.currentCardsLength && props.currentCardsLength <= 2) ? '0 25px' : 'auto'};
}

.flex-container::-webkit-scrollbar {
  display: none;
}
.pagination{
  position: relative;
  right: 7%;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  font-size: 12px;
  color: #656579;
}

.flex-item:empty {
  display: none;
}

.arrow {
  position: absolute;
  top: 58%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.left-arrow {
  left: 18px;
}

.right-arrow {
  right: 20px;
}
@media screen and (min-width: 1367px) and (max-width: 1507px) {
  .left-arrow {
    left: 4%
  }
  
  .right-arrow {
    right: 4%
  }
}

.disabled {
    cursor: default;
    opacity: 0.4;
}

@media screen and (min-width: 1508px) and (max-width: 1705px) {
  .left-arrow {
    left: ${(props) => (props.isSidebarOpen || props.isRightSideBarOpen) ? '2%' : '5%'};
  }
  
  .right-arrow {
    right: ${(props) => (props.isSidebarOpen || props.isRightSideBarOpen) ? '2%' : '5%'};
  }
}

@media screen and (min-width: 1706px) and (max-width: 1920px) {
  .left-arrow {
    left: ${(props) => props.isSidebarOpen || props.isRightSideBarOpen ? '2%' : '9%'};
  }
  
  .right-arrow {
    right: ${(props) => props.isSidebarOpen || props.isRightSideBarOpen ? '2%' : '9%'};
  }
}

@media screen and (min-width: 1921px) and (max-width: 2050px) {
  .left-arrow {
    left: ${(props) => props.isSidebarOpen || props.isRightSideBarOpen ? '5%' : '13%'};
  }
  
  .right-arrow {
    right: ${(props) => props.isSidebarOpen || props.isRightSideBarOpen ? '5%' : '13%'};
  }   
}

`;

export default AppContainer;