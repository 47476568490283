import styled from 'styled-components';
import {warningError} from '../../../commonImage';

const SuggestionText: React.FC = () => { 
    return (
        <SuggestionStyle>
            <div className="warningContainer">
                <img src={warningError} className='warningError'></img>
                <span className="suggestion">AI generated output may contain inaccuracies or errors. Verify output before use.</span>
            </div>
        </SuggestionStyle>
    );
}

const SuggestionStyle = styled.div<{}>`
.warningContainer {
    display: flex;
    align-items: center;
  }
  
  .warningError {
    margin-right: 10px;
  }
  .suggestion {
    color: #ED6500;
    font-size: 10px;
    font-weight: 300;
    font-style: 'EYInterstate';
  }
`;

export default SuggestionText;
 