import { useDispatch, useSelector } from 'react-redux';
import {
  clearDownloadFileMessage,
  setErrorMessage,
  clearErrorMessage
} from '../../../store/actions/userChatActions';
import { exportToCSV } from './exportToCSV';
import { exportToPPT } from './exportToPPT';
import exportToWord from './exportToWord';
import { RESPONSE_BY } from '../../../models/IQueryType';
import { IStore } from '../../../store';
import { exportToPPTSector } from './exportToPPTSectors';
import { exportKcExpertsToPPT } from './KcWithExpertsPPT';
import { exportToPPTPerspective } from './perspectivePPT';

const useExportToFile = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const csvExport = async (
    query: string | undefined,
    source: RESPONSE_BY.OPENAI | RESPONSE_BY.EMBROYNIC,
    checkedCheckboxes: any,
    eyIPInterRadio: any,
    validURLinputValue: any
  ) => {
    try {
      await exportToCSV(
        query,
        source,
        userData,
        checkedCheckboxes,
        eyIPInterRadio,
        validURLinputValue
      );
    } catch (error) {
      dispatch(clearDownloadFileMessage());
      dispatch(setErrorMessage('Something went wrong'));
      setTimeout(() => {
        dispatch(clearErrorMessage());
      }, 5000);
    }
  };
  return {
    exportToCSV: csvExport,
    exportToPPT,
    exportToWord,
    exportToPPTSector,
    exportToPPTPerspective,
    exportKcExpertsToPPT
  };
};

export default useExportToFile;
