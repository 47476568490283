import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../motifComponents/button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  chatSearchInputValue,
  setRightSideBarOpenClose,
  setSideBarOpenClose
} from '../../store/actions/commonActions';
import { setAnySourceSelected } from '../../store/actions/userChatActions';
import { IStore } from '../../store';
import { buttonImg } from '../../commonImage';
import { RESPONSE_BY } from '../../models/IQueryType';
import { setChatHistory, toggleLoader } from '../../store/actions/userChatActions';
type ISearchContainerProps = {
  getInputData?: (val: string) => void;
};
const SearchContainer = ({ getInputData }: ISearchContainerProps): JSX.Element => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
  const textFieldValue = useSelector((state: IStore) => state?.common?.chatSearchInput);
  const isTextFieldEmpty = textFieldValue !== undefined && textFieldValue.length > 0;
  const anySourceSelected = useSelector((state: IStore) => state?.common?.anySourceSelected);
  const selectedDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const [isSourceSelected, setSourceSelected] = useState(!anySourceSelected);
  const [isFocused, setIsFocused] = useState(isSidebarOpen);
  const history = useNavigate();
  const [chatSearchInput, setChatSearchInputValue] = useState('');
  const [inputValuelength, setInputValueLength] = useState('');
  const { isClearLoading, isLoading } = useSelector((state: any) => state.userChat);
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);

  dispatch(chatSearchInputValue(chatSearchInput));
  const refToScroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSourceSelected(anySourceSelected || true);
  }, [anySourceSelected]);

  useEffect(() => {
    setIsFocused(isSidebarOpen);
  }, [isSidebarOpen]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length <= 1000) {
      setInputValueLength(newValue);
    }
    setChatSearchInputValue(newValue);
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = (query?: string) => {
    if (!isClearLoading && !isLoading && anySourceSelected && selectedDataSourceSection !== 4 ) {
      dispatch(setAnySourceSelected(true));
      setSourceSelected(true);
      getInputData?.(chatSearchInput || '');
      if ((chatSearchInput && chatSearchInput.trim().length) || query) {
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: chatSearchInput || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });

        setChatSearchInputValue('');
        dispatch(toggleLoader(true));
        history('/publicChatPage');
        if(Object.keys(selectedChatThread).length === 0 && !localStorage.isSidebarManuallyClosed){
          dispatch(setRightSideBarOpenClose(true));
        }
      }
    } else if(!isClearLoading && !isLoading && anySourceSelected && selectedDataSourceSection === 4){
      dispatch(setAnySourceSelected(true));
      setSourceSelected(true);
      getInputData?.(chatSearchInput || '');
      if ((chatSearchInput && chatSearchInput.trim().length) || query) {
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: chatSearchInput || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });

        setChatSearchInputValue('');
        dispatch(toggleLoader(true));
        history('/uploadURLPage');
        dispatch(setRightSideBarOpenClose(true));
      }
    }
    else {
      dispatch(setAnySourceSelected(false));
      setSourceSelected(false);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.placeholder = '';
    if (!isFocused) {
      dispatch(setSideBarOpenClose(true));
      setIsFocused(true);
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.placeholder = 'What would you like to ask?';
    setIsFocused(false);
  }

  return (
    <StyleSearchBar isSourceSelected={isSourceSelected}>
      <div className='outer-border'>
        <div className='search-bar'>
          <div className='input-container'>
            <input
              type='text'
              value={chatSearchInput}
              onChange={handleInputChange}
              className='input-bar'
              placeholder='What would you like to ask?'
              onFocus={handleFocus}
              onBlur={handleOnBlur}
              maxLength={1000}
              onKeyDown={(event: any) => handleKeyDown(event)}
            />
            <div className='warning-length'>
              {isFocused && isSourceSelected &&<div className='messgeText'>{'Select a data source to submit your question'} </div>}
              {!isSourceSelected && (
                <div className='warning'>{selectedDataSourceSection === 4 ?'Please upload a URL to continue' : 'Please select a data source to continue'}</div>
              )}
              <span className='number-validate'>{`(${inputValuelength.length ?? 0}/1000)`}</span>
            </div>
          </div>
          <span>
            <CustomButton disabled={!isTextFieldEmpty} onClickHandler={handleSendMessage}>
              <img src={buttonImg} alt='search button' />
            </CustomButton>
          </span>
        </div>
      </div>
    </StyleSearchBar>
  );
};

const StyleSearchBar = styled.div<{
  isSourceSelected?: boolean;
}>`
  display: flex;
  justify-content: center;

  .outer-border {
    border: 1px solid #c3c3cb;
    height: 130px;
    width: 85%;
    border-radius: 11px;
  }
  .search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #F6F6FA;
    border-radius: 11px;
  }
  .input-bar {
    width: 100%;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #c3c3cb;
    padding-left: 10px;
  }
  .warning {
    color: red;
    font-size: 12px;
    width: 100%;
  }
  .messgeText {
    font-size: 12px;
    width: 100%;
  }
  .warning-length {
    display: flex;
    color: #747480;
  }
  .number-validate {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    top: 0px;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    top: ${(props) => (props.isSourceSelected ? '12px' : '11px')};
    margin-left: 37px;
  }
`;

export default SearchContainer;
