import pptxgen from "pptxgenjs";
import { toPascalCase, sanitizeHeaderText, downloadFilenameGenerator, downloadFilenameGeneratorForPPT } from ".";
import {
  RESPONSE_TYPE,
  IContent,
  RESPONSE_BY,
} from "../../../models/IQueryType";
import { convertNumber } from "./numberConverter";
import PageConfig from "../../../ui-constants/en.json";
import { publicSources } from "../../../label.constant";
import LABELS from '../../../ui-constants/en.json';
import { decodeHTML, getResponseSourceType } from "../../../utils/commonUtils";
type ITableContentProps = {
  Content: IContent[];
  ResponseType: RESPONSE_TYPE;
  checkedCheckboxes?: any;
  eyIPInterRadio?: any;
  validURLinputValue?: any;
};
export const exportToPPT = (
  source: RESPONSE_BY,
  userQueryWithResponse?: any,
  checkedCheckboxes?: any,
  eyIPInterRadio?: any,
  validURLinputValue?: any
) => {
  let pres = new pptxgen();
  const { user, SEResponse, LLMResponse } = userQueryWithResponse;
  const pathName = window.location.pathname;
  const handleCsvFormt = (data: string) => {
    try {
      return data
        .replaceAll("---|", "")
        .replaceAll("--", "")
        .replaceAll(/\s{3,}/g, "")
        .replaceAll("|", ",")
        .replaceAll(",\n,", ",\n")
        .replaceAll(",\n-", "\n")
        .replaceAll("\n\n", "\n")
        .replaceAll(", Fiscal Year", "Fiscal Year")
        .trim();
    } catch (err) {
      return "";
    }
  };

  const handleSourceSection = (sourceArray: string[]) => {
    try {
      let sourceType = '';
      if (checkedCheckboxes && checkedCheckboxes.length > 0) {
        if (checkedCheckboxes.length === 1 && checkedCheckboxes.includes(publicSources[0]))
          sourceType = publicSources[0];
        else if (checkedCheckboxes.length === 1 && checkedCheckboxes.includes(publicSources[1]))
          sourceType = publicSources[1];
        else if (
          checkedCheckboxes.length === 2 &&
          checkedCheckboxes.includes(publicSources[0]) &&
          checkedCheckboxes.includes(publicSources[1])
        )
          sourceType = 'Combined';
      }
      if (eyIPInterRadio) {
        sourceType = eyIPInterRadio;
      }
      if (validURLinputValue) {
        sourceType = 'Upload URL';
      }
      let modifiedSourceArray;
      if(sourceType==='Surveys'){
       if(sourceArray?.length>0 && sourceArray[0].includes('Surveys')) {
        modifiedSourceArray = ['EY conducted surveys'];
       }
       else{
         modifiedSourceArray = [...sourceArray];
       }
      }
      else{
      let getSources = getResponseSourceType(sourceType)
      sourceArray.shift();
       modifiedSourceArray = [getSources, ...sourceArray];
     }
      if (modifiedSourceArray && modifiedSourceArray.length > 0) {
        return modifiedSourceArray.join("\n");
      }
    }
    catch (err) {
      return "";
    }
  }

  const handleTbleGeneration = (data: ITableContentProps) => {
    if (
      data?.Content &&
      data.Content.length > 0 &&
      data.ResponseType === RESPONSE_TYPE.TABLE &&
      data.Content.every((el) => Boolean(el) && typeof el === "object")
    ) {
      const tableRows: pptxgen.TableRow[] = [];
      tableRows.push(
        Object.keys(data.Content[0]).map((el) => {
          return {
            text: toPascalCase(sanitizeHeaderText(el)),
            options: { bold: true },
          };
        })
      );
      data.Content.forEach((el: IContent) => {
        const rowValues: pptxgen.TableCell[] = [];
        Object.entries(el).forEach(([key, value]) => {
          if (
            key.toLowerCase().includes("year") ||
            key.toLowerCase().includes("id") ||
            key.toLowerCase().includes("quarter") ||
            key.toLowerCase().includes("count") ||
            key.toLowerCase().includes("date") ||
            key.toLowerCase().includes("Code") ||
            key.toLocaleLowerCase().includes("portfolio")
          ) {
            rowValues.push({
              text: value,
            });
          } else {
            rowValues.push({
              text: convertNumber(value, 1).val,
            });
          }
        });
        tableRows.push(rowValues);
      });

      if (tableRows[0].length < 12) {
        pres.addSlide().addTable(tableRows, {
          autoPage: true,
          color: "000000",
          fontSize: 9,
          border: { color: "e8e8ee", pt: 1, type: "solid" },
          fill: {
            color: "f7f7f8",
          },
          colW: tableRows[0].length > 12 ? 1.5 : undefined,
        });
      } else {
        let SESummaryRow = [
          [
            {
              text: PageConfig.EXTRA_COL_MESSAGE,
            },
          ],
        ];
        pres.addSlide().addTable(SESummaryRow, { autoPage: true});
      }
    }
  };

  try {
    const userQuery = user && decodeHTML(user?.summary);
    const SEChatSummary = SEResponse && handleCsvFormt(SEResponse?.summary);
    const sourceSection = SEResponse && handleSourceSection(SEResponse?.Sources); 
    const SESummaryRow: any = [
      [{ text: `${userQuery}`, options: { bold: true } }],
      [{ text: `${SEChatSummary}`, options: SEResponse?.responseType === "stop_generation" ? { color: '656579', italic: true } : {} }]
    ];

    if(SEResponse?.isParitallyAbortedResponse) {
      SESummaryRow.push([{ text: LABELS.chat_reply.PARTIAL_STOP_GENERATION_MSG, options: { color: '656579', italic: true }}]);
    }
    if(pathName=="/surveyChatPage") {
      sourceSection?.trim()==='EY conducted surveys'? SESummaryRow.push([{ text: `Source: ${sourceSection}` }]):SESummaryRow.push([{ text: `Source(s): ${sourceSection}` }]);
    }
    else{
    SESummaryRow.push([{ text: `Source: ${sourceSection}` }]);
    }

    pres.addSlide().addTable(SESummaryRow, { autoPage: true });
    handleTbleGeneration(SEResponse || LLMResponse);
 
    pres
      .write({ outputType: "blob" })
      .then((file) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file as Blob);
        link.download = downloadFilenameGeneratorForPPT(checkedCheckboxes, eyIPInterRadio, validURLinputValue, "ppt");
        link.click();
      })
      .catch((err) => {});
  } catch (err) {
    console.log("::::PPT error", err);
  }
};