const fireViewEndCustomEvent = () => {
  let event = new CustomEvent('event-view-end');
  let obj = document.querySelector('body');
  if(obj){
    obj.dispatchEvent(event);
  }
  
  
};
const fireViewStartCustomEvent = (data: CustomEventInit<unknown>)=> {
  let event = new CustomEvent('event-react-view-start', data);
  let obj = document.dispatchEvent(event);
};
const fireActionTriggerCustomEvent = (target: { dispatchEvent: (arg0: CustomEvent<unknown>) => any; }, data: CustomEventInit<unknown>) => {
  let event = new CustomEvent('event-action-trigger', data);
  let obj = target.dispatchEvent(event);
};
export { fireViewEndCustomEvent, fireViewStartCustomEvent, fireActionTriggerCustomEvent };
