export const explorerDataConstants = {
    Types : [
        { id: "id1", type: "Select all", actualType:'', checked: false },
        { id: "id2", type: "Doc", actualType:'Docx', checked: false },
        { id: "id3", type: "Pdf", actualType:'Pdf', checked: false },
        { id: "id4", type: "Pptx", actualType:'Pptx', checked: false },
        { id: "id5", type: "Txt", actualType:'Txt', checked: false },
        { id: "id6", type: "Xls", actualType:'Xlsx', checked: false },
      ],
    docComparator: 'docx',
    dropdownDocValue: 'doc',
    excelComparator: 'xlsx',
    dropdownExcelValue: 'excel',
}
export const StyleType= "relative";
export const PromptData_Byod =
{
    promptid:String,
    convid:String,
    project_uuid:String,
    prompttype: String,
    title: String,
    tags: String,
    prompttext: String,
    IsFavOrSaved: String,
    Email: String
}