import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import sassVars from "../../../styles/variables.module.scss";
import { smallInfoIcon } from "../../../commonImage";
import OverflowTooltip from "../../common/OverflowTooltip";
import { useSelector } from "react-redux";
import { IStore } from "../../../store";
import Tippy from "@tippy.js/react";

type Props = {
  title: string;
  index: number;
  className?: string;
  setSelectedTab: (index: number) => void;
};

const { white, alternateblack } = sassVars;
const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  className,
}) => {
  const activeTabValue = useSelector((store: IStore) => store.userChat.activeTabValue);
  useEffect(() => {
    if(activeTabValue === "Document history"){
      setSelectedTab(1);
    }
    else if(activeTabValue === "Chat history"){
      setSelectedTab(0);
    }
  }, [activeTabValue]);
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  const getTooltip = ()=>{
    if(title === 'Chat history'){
      return 'History of the user queries on data sources like External data, EY Source data etc.' ;
    }else{
      return 'History of user queries on their document library.' ; 
    }
  }
  return (
    <StyleTab>
      <div className='chatTooltip'>
        {title && (
          <button onClick={onClick} className={className}>
            {title}
            <Tippy
              boundary="viewport"
              trigger='click'
              placement='bottom'
              appendTo={() => document.body}
              content={<div><span className="nextlineSpan">{getTooltip()}</span></div>}
              className={`se-tooltip 
              ${title == 'Chat history'
                  ? 'chatHistory-tooltip-container'
                  : 'workspace-tooltip-container'} 
                with-tooltip`}
              allowHTML={true}
              arrow={true}
              theme={'custom'}
              enabled
              animateFill
              animation="shift-away"
              followCursor={'horizontal'}
            >
              <img className="tab-tooltip-icon" src={smallInfoIcon} />
            </Tippy>
          </button>
        )}
      </div>
    </StyleTab>
  );
};

const StyleTab = styled.div`

.tab-tooltip-icon {
  position: relative;
  top: 2px;
  left: 5px;
}
button.active{
    border-bottom: 2px solid #000;
    padding-bottom: 3px;
    cursor: pointer !important;
    color: #000 !important;
}
button{
    padding-right: 10px;
    margin-left : 10px;
    background: transparent;   
    outline: none;
    border: inherit;
    font-size: 14px;
    cursor: pointer !important;
    color:#9897A6 !important;
    font-family: EYInterstate;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    white-space: nowrap; 
    display:content !important ;
    span.overflow-wrapper:not(.allow-text-wrap), .overflow-content:not(.allow-text-wrap){
      display:content !important ;
    }
    :hover{
        cursor: pointer;
        background-color: #E6E6E9;
        outline: none;
        padding-right: 10px;
    }
}
}
;`;

export default TabTitle;
