import React from 'react';
import './ChatStreamResponseFormatting.scss'; // Assuming your CSS is in App.css

// Define the props type for the component
interface DynamicTextRendererProps {
    text: string;
  }
  
const DynamicTextRenderer: React.FC<DynamicTextRendererProps> = ({ text }) => {
    // Function to replace ###text with bold <span> and **text** with <strong>
    const renderText = (inputText: string) => {
    // Regular expressions to find ###text and **text** patterns
      const headerPattern1 = /^#\s*([^#].*)$/gm;
      const headerPattern2 = /^##\s*([^##].*)$/gm;
      const headerPattern3 = /^###\s*([^###].*)$/gm;
      const headerPattern4 = /^####\s*([^####].*)$/gm;
      const headerPattern5 = /^#####\s*([^#####].*)$/gm;
      const boldPattern = /\*\*(.*?)\*\*/g;

     const splitlines = inputText.split('\n');

    let formattedLines = [];

    // Iterate through each line using a for loop and apply replacements
    for (let i = 0; i < splitlines.length; i++) {
        let line = splitlines[i];
        let formattedLine = line; // Start with the original line

        // Check and apply header pattern 1
        if (headerPattern1.test(line)) {
            formattedLine = `<h1 class='divmargins'>${line.replace(headerPattern1, '$1')}</h1><br />`;
        }
        // Check and apply header pattern 2
        else if (headerPattern2.test(line)) {
            formattedLine = `<h2 class='divmargins'>${line.replace(headerPattern2, '$1')}</h2><br />`;
        }
        // Check and apply header pattern 3
        else if (headerPattern3.test(line)) {
            formattedLine = `<h3 class='divmargins'>${line.replace(headerPattern3, '$1')}</h3><br />`;
        }
        // Check and apply header pattern 4
        else if (headerPattern4.test(line)) {
            formattedLine = `<h4 class='divmargins'>${line.replace(headerPattern4, '$1')}</h4><br />`;
        }
        // Check and apply header pattern 5
        else if (headerPattern5.test(line)) {
            formattedLine = `<h5 class='divmargins'>${line.replace(headerPattern5, '$1')}</h5><br />`;
        }
        // Check and apply bold pattern
        else if (boldPattern.test(line)) {
            line = line.replace(/^-/, '  •')
            formattedLine = line.replace(boldPattern, '<strong>$1</strong>');

        }

        formattedLine = formattedLine.replace(/^ -/, '   -')
        // Add the formatted line to the array
        formattedLines.push(formattedLine);
    }
    // Join the formatted lines back into a single string
    let formattedText = formattedLines.join('\n');
  
    // Replace standalone new lines with <br />
    formattedText = formattedText.replace(/\n/g, '<br />');

    // Split the string by <br /> to safely create React elements
    const lines = formattedText.split('<br />').map((line, index) => {

        const containsStrong = line.includes('<strong>');
        if (line.trim().length === 0) 
        {
             return null;
        }
        else
        {
        // Create a new div for each line, and dangerously set the HTML
            return (
                <React.Fragment key={index}>
                    <div className='divmargins' dangerouslySetInnerHTML={{ __html: line }} />
                </React.Fragment>
            );
        }
    });

    if (lines.length > 0)
        return <div className='divmargins'>{lines}</div>;
    else
        return null;
    };
  
    return renderText(text);
  };


export default DynamicTextRenderer;