import { FC } from 'react';
import { MotifCheckbox } from '@ey-xd/motif-react';

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
    id: string;
    checked?: boolean;
    indeterminate?: boolean;
    disabled?: boolean;
    name?: string;
    value?: string;
    className?: string;
    children?: React.ReactNode;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: FC<CheckboxProps> = ({
    id,
    checked = false,
    indeterminate,
    disabled,
    name,
    value = '',
    className = '',
    children,
    onChange,
    ...other
}: any) => {
    return (
        <MotifCheckbox
            {...other}
            id={id}
            checked={checked}
            indeterminate={indeterminate}
            disabled={disabled}
            value={value}
            className={`checkbox ${className}`}
            onChange={onChange}>
            {children}
        </MotifCheckbox>
    );
};

export default Checkbox;
