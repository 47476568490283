import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import SectorGeographyHierarchy from '../..//Hierarchy/SectorGeographyHierarchy';
import Dropdown from '../../NewDropdown/Dropdown';
import { SectorGeographyHierarchyFilter } from '../../Hierarchy/SectorGeographyHierarchyFilter';
import DropdownFooter from './DropdownFooter';
// import Button from '../../Button/Button';
import labels from '../../../ui-constants/en.json';
import CustomDropdown from '../../CustomDropdown/CustomDropdown';
import scssVar from '../../../styles/variables.module.scss';
import { useSelector } from 'react-redux';
import { IStore } from '../../../store';
import '../SectorInsights.scss';

type ICustomHeader = {
  hideShowOptions?: boolean;
  checkedgeography?: string[];
  checkedsector?: string[];
  hasDefaultChecks?: boolean;
  defaultExpanded?: string[];
  isClearChatHistory?: boolean;
  handleNavigationHome?: () => void;
  clearContentAge?: () => void;
  clsoeOtherDropdowns?: () => void;
};

const { darkwhite, white, gray50, gray15, alternategray, fontInterstate } = scssVar;

const InsightHeader = ({
  length,
  toggleSector,
  toggleGeography,
  contentAge,
  setContentAge,
  selectedContentAge,
  sectorenable,
  filterSectorNodes,
  sector,
  setCheckedsector,
  geographyenable,
  filterGeographyNodes,
  geography,
  setCheckedgeography,
  clearContentAge,
  clsoeOtherDropdowns,
  hideShowOptions,
  checkedgeography,
  checkedsector,
  hasDefaultChecks,
  defaultExpanded,
  isClearChatHistory
}: any) => {
  const [geographyItem, setGeopgraphyItems] = useState(false);
  const [sectorItem, setSectorItems] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [isInputSectorCleared, setIsInputSectorCleared] = useState(false);
  const [isInputGeographyCleared, setIsInputGeographyCleared] = useState(false);
  const { isRightSideBarOpen, anySourceSelected, isSideBarOpen } = useSelector(
    (state: IStore) => state.common
  );

  const isLoading = useSelector((state: any) => state.userChat.isLoading);
  const clearGeographyItems = () => {
    setGeopgraphyItems(!geographyItem);
    isInputGeographyCleared ? setIsInputGeographyCleared(false) : setIsInputGeographyCleared(true);
  };
  const dropdownRef = useRef<HTMLElement>(null);

  const clearSectorItems = () => {
    setSectorItems(!sectorItem);
    isInputSectorCleared ? setIsInputSectorCleared(false) : setIsInputSectorCleared(true);
  };

  const toggleSectorDrops = () => {
    setShowOptions(false);
    toggleSector?.();
  };

  const toggleGeogDrops = () => {
    toggleGeography?.();
    setShowOptions(false);
  };
  const closeGeaographyFilter = () => {
    toggleGeography?.();
    isInputGeographyCleared ? setIsInputGeographyCleared(false) : setIsInputGeographyCleared(true);
  };

  const closeSectorFilter = () => {
    toggleSector?.();
    isInputSectorCleared ? setIsInputSectorCleared(false) : setIsInputSectorCleared(true);
  };

  const clearAllFilter = () => {
    if (!defaultExpanded?.length) {
      clearGeographyItems();
    }
    // setGeopgraphyItems(!geographyItem);
    setSectorItems(!sectorItem);
    clearContentAge?.();

    setShowOptions(false);
    clsoeOtherDropdowns?.();
    isInputGeographyCleared ? setIsInputGeographyCleared(false) : setIsInputGeographyCleared(true);
    isInputSectorCleared ? setIsInputSectorCleared(false) : setIsInputSectorCleared(true);
  };

  const toggleDropdown = () => {
    clsoeOtherDropdowns?.();
    setShowOptions(!showOptions);
  };

  const clearDropdownValues = () => {
    setShowOptions(!showOptions);
    clearContentAge?.();
  };

  useEffect(() => {
    setShowOptions(false);
  }, [hideShowOptions]);

  const getDisableMode = useCallback(() => {
    if (selectedContentAge !== '') {
      return false;
    } else if (checkedgeography.length) {
      return false;
    } else if (checkedsector.length) {
      return false;
    }
    return true;
  }, [checkedsector, checkedgeography, selectedContentAge]);

  useEffect(() => {
    function handleClickOutside(event: { target: any }) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
        clsoeOtherDropdowns?.();
      }
    }
    // Bind
    document.addEventListener('click', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('click', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (isClearChatHistory) {
      clearAllFilter();
    }
  }, [isClearChatHistory]);
  return (
    <StyleHeader
      buttonColor={getDisableMode()}
      sectorenable={sectorenable}
      geographyenable={geographyenable}>
      <div className='insight-header insight-header-fixed insight-header-has-search'>
        <div className={'sectorChatContainer__header-container'}>
          <div className='thin-white-line'>Filters</div>
          <div className='insight-dropdowns'>
            <div className='dropdowns' ref={dropdownRef as React.RefObject<HTMLDivElement>}>
              <div className='dropdown-parent'>
                <Dropdown
                  isLoading={isLoading}
                  disabled={isLoading}
                  options={[{ label: ' ', value: ' ' }]}
                  onFocus={toggleSectorDrops}
                  placeholder='Sectors'
                  classCss={'no-class dropdown-parent-next'}
                  showBackground={checkedsector?.length > 0 ? true : false}
                  isSideBarOpen={isRightSideBarOpen}
                />
                <div
                  // style={{ display: sectorenable }}
                  className={`${isRightSideBarOpen ? (isRightSideBarOpen && isSideBarOpen ? 'tableoverlapsector1-with-width-chat' : 'tableoverlapsector1-with-chat') : 'tableoverlapsector1'} sector-display`}>
                  <div className='hierarchy-box'>
                    <SectorGeographyHierarchyFilter
                      filter={filterSectorNodes}
                      placeholder={'Search'}
                      setInputFilter={isInputSectorCleared}
                    />

                    <div className='sticky-filter'>
                      <SectorGeographyHierarchy
                        treeNode={sector}
                        setCheckBoxValue={setCheckedsector}
                        title={'sector'}
                        setClearFilter={sectorItem}
                      />
                    </div>
                    <DropdownFooter clearAll={clearSectorItems} closeFilter={closeSectorFilter} />
                  </div>
                </div>
              </div>
              <div className='dropdown-parent'>
                <div ref={dropdownRef as React.RefObject<HTMLDivElement>}>
                  <Dropdown
                    isLoading={isLoading}
                    disabled={isLoading}
                    options={[{ label: ' ', value: ' ' }]}
                    onFocus={toggleGeogDrops}
                    placeholder='Geography'
                    classCss={'no-class dropdown-parent-next'}
                    showBackground={checkedgeography?.length > 0 ? true : false}
                    isSideBarOpen={isRightSideBarOpen}
                  />
                </div>
                <div
                  className={`${isRightSideBarOpen ? (isRightSideBarOpen && isSideBarOpen ? 'tableoverlapgeography-with-width-chat' : 'tableoverlapgeography-with-chat') : 'tableoverlapgeography'} geography-display`}>
                  <div className='hierarchy-box'>
                    <SectorGeographyHierarchyFilter
                      filter={filterGeographyNodes}
                      placeholder={'Search'}
                      setInputFilter={isInputGeographyCleared}
                    />

                    <div className='sticky-filter'>
                      <SectorGeographyHierarchy
                        title={'geography'}
                        treeNode={geography}
                        setCheckBoxValue={setCheckedgeography}
                        setClearFilter={geographyItem}
                        disabled={
                          geography &&
                          geography
                            .filter((item: { disabled: boolean }) => item.disabled)
                            .includes(true)
                        }
                        hasDefaultChecks={hasDefaultChecks}
                        defaultExpanded={defaultExpanded}
                      />
                    </div>

                    <DropdownFooter
                      clearAll={clearGeographyItems}
                      closeFilter={closeGeaographyFilter}
                    />
                  </div>
                </div>
              </div>
              <CustomDropdown
                isLoading={isLoading}
                options={contentAge}
                placeholder={'Content age'}
                setdropdownValue={setContentAge}
                showOptions={showOptions}
                selectedContentAge={selectedContentAge}
                isSideBarOpen={isRightSideBarOpen}
                customRef={dropdownRef as React.RefObject<HTMLDivElement>}
                toggleDropdown={toggleDropdown}
                clearDropdownValues={clearDropdownValues}
                isContentAgeWithRadio={true}
              />
            </div>
          </div>
        </div>
      </div>
    </StyleHeader>
  );
};
export default InsightHeader;
const StyleHeader = styled.div<{ buttonColor?: boolean; sectorenable: any; geographyenable: any }>`
  .dropdown-parent {
    position: relative;
    width: 33%;
  }
  .dropdown-parent-next {
    max-width: 100%;
  }
  .insight-header.insight-header-fixed {
    // width: 100vw;
    // padding-top: 62px;
    // position: fixed;
  }
  .insight-header {
    display: block;
    z-index: 100;
    min-height: 3.85714rem;
  }
  .insight-header-bg {
    background: var(--primary-ey-black-1-a-1-a-24, #1a1a24);
    padding: 36px 60px;
    opacity: 0.7;
  }
  .insight-header-bg-wc {
    background: var(--primary-ey-black-1-a-1-a-24, #1a1a24);
    padding: 22px 60px 4px;
    opacity: 0.7;
  }

  .vl {
    border-left: 3px solid #d1ead1;
    height: 22px;
    padding: 8px;
    position: relative;
    top: 23px;
  }
  .image-round {
    height: 2.51429rem;
    width: 2.51429rem;
    margin-right: 37px;
  }
  .logo-text {
    color: var(--primary-ey-yellow-ffe-600, #ffe600);
    font-family: EYInterstate;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
  .logo-descr {
    color: var(--primary-grey-100-f-6-f-6-fa, #f6f6fa);
    font-family: EYInterstate;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin: 0;
  }
  .insight-header .insight-header-app-name {
    position: relative;
    left: 5px;
    font-family: ${fontInterstate};
    font-size: 18px;
    line-height: 21px;
    color: ${darkwhite};
    height: auto;
    font-size: 18px;
    bottom: 9px;
  }
  .insight-header .se-search-input-wrapper .se-input-component .se-input {
    background: ${gray15};
    height: 3.20714rem !important;
    position: relative;
    top: 6px;
  }
  .insight-header.insight-header-fixed {
    height: 56px;
  }
  .insight-header-logo {
    left: 12px;
    bottom: 5px;
  }
  .insight-dropdowns {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    align-items: center;
  }
  .clear-all-filter {
    color: var(--specific-use-colors-disabled-text-747480, #747480);
    font-family: EYInterstate;
    font-size: 14px;
    padding: 6px 14px 6px 8px;
    width: 140px;
    text-overflow: ellipsis;
    font-weight: bold;
    border: 1px solid #c3c3cb;

    color: ${(props): string => `${props.buttonColor ? '#747480' : '#2E2E38'}`};
  }
  .dropdowns {
    display: flex;
    gap: 16px;
    width: 100%;
  }
  .hamburgur .se-icon {
    color: ${alternategray} !important;
  }
  .hamburgur {
    background-color: transparent !important ;
    background-color: transparent;
    position: relative;
    left: 5px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 5px;
    disaplay: none;
  }
  .hamburgur:hover {
    background-color: transparent;
  }
  .sticky-filter {
    max-height: 200px;
    overflow: auto;
    padding: 0px 0px 0px 16px;
  }
  @media screen and (max-width: 1600px) {
    .sticky-filter {
      max-height: 136px;
    }
  }
  @media screen and (max-width: 1500px) {
    .sticky-filter {
      max-height: 125px;
    }
  }
  @media screen and (max-width: 1300px) {
    .sticky-filter {
      max-height: 100px;
    }
  }
  .btn-filter {
    width: 44px;
    border: 1px solid ${scssVar.gray40};
    height: 44px;
    position: relative;
    cursor: pointer;
    .filter-icon {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
    }
    .se-icon-button {
      cursor: pointer;
      outline: none;
      background: none;
      border: none;
      padding: 0;
      transition: box-shadow 150ms;
      display: none;
      color: #1a1a24;
      & .se-icon {
        background: transparent;
        border: none;
        & img {
          width: 24px;
          height: 24px;
        }
      }

      .insight-header .se-row {
        height: 100%;
        flex-basis: 100%;
      }
    }
    .btn-filter-text {
      width: 100px;
      border: 1px solid ${scssVar.gray40};
      height: 44px;
      position: relative;
      cursor: pointer;
      .filter-text {
        position: absolute;
        top: 0.5rem;
        left: 0.9rem;
      }
    }
    @media screen and (min-width: 64em) {
      .insight-header-company-container {
        flex: 0 0 20%;
        max-width: 100%;
        box-sizing: border-box;
        box-flex: 0;
      }
    }
    @media screen and (min-width: 0) {
      .se-between-xs {
        justify-content: flex-start;
      }
    }
    @media screen and (min-width: 48em) {
      .insight-header .insight-header-search {
        width: 64.85714rem;
      }
    }
    .insight-header-search-field {
      border-color: black;
    }
    .insight-header-icons-placeholder {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
  .sector-display {
    display: ${(props): string => `${props.sectorenable}`};
  }
  .geography-display {
    display: ${(props): string => `${props.geographyenable}`};
  }
`;
