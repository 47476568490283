import React from 'react';
import styled from 'styled-components';
import sassVars from '../../../styles/variables.module.scss';
import { extractInitials } from '../../common/AvatarInitials';
import { isTeamsApp, truncateString } from '../../../utils/commonUtils';
import OverflowTooltip from '../OverflowTooltip';

const { grey3A, yellow, grey6, grey50, gray, lightbrown, darkwhite, lightBlue, fontInterstate } =
  sassVars;

interface IOwnProps {
  isActive: boolean;
  userData: { profileImage: string; displayName: string; sid?:{preferredName:string};  shortName?: string };
  profileMenuList: Array<{ id: number; item: string; icon: string }>;
  disableHelpMenu: boolean;
  dropdownRef?: React.Ref<HTMLElement>;
  onClickMenu: (e: React.SyntheticEvent<HTMLElement>, item: string) => void;
}
const ProfileMenu: React.FC<IOwnProps> = ({
  profileMenuList,
  userData,
  isActive,
  dropdownRef,
  onClickMenu
}: IOwnProps) => {
  const configureMenuList = isTeamsApp()
    ? profileMenuList.filter((x) => x.item !== 'Logout')
    : profileMenuList;
  return (
    <ProfileStyled>
      <nav ref={dropdownRef} className={`menu ${isActive ? 'active' : 'inactive'}`}>
        {/* <div className='top'></div> */}
        <ul>
          <li className='ie-dropdown-item'>
            {/* <div className='ie-avatar ie-avatar-size-small '>
              <span className='ie-avatar-initials' role='img' aria-label=''>
                {extractInitials(userData.displayName)}
              </span>
            </div> */}
            {<div className='name-font'>{userData.displayName}</div>}
            {
              (userData?.sid?.preferredName?? '')?.length > 30 ?
                <OverflowTooltip tooltipContent={userData?.sid?.preferredName} customClass='tooltip-class' placement='bottom'>
                  <div>{truncateString(userData?.sid?.preferredName, 30)}</div>
                </OverflowTooltip>
                :
                <div className='email-font'>{truncateString(userData?.sid?.preferredName, 30)}</div>
            }
          </li>
        </ul>
        <ul>
          {configureMenuList.length > 0 &&
            configureMenuList.map((menu) => {
              return (
                <li
                  onClick={(e) => {
                    onClickMenu(e, menu.item);
                  }}
                  key={menu.id}
                  className='widget-menu-list'>
                  <img
                    src={menu.icon}
                    alt='Menu Icons'
                    className={menu.id === 2 ? 'contactUs' : ''}
                  />{' '}
                  &nbsp; <span className='menuText'>{menu.item}</span>
                </li>
              );
            })}
        </ul>
      </nav>
    </ProfileStyled>
  );
};
export default ProfileMenu;

const ProfileStyled = styled.div`
      & .menu {
        border-radius: 1px;
        position: absolute;
        top: 41px;
        right: 1px;
        width: 18rem;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
        opacity: 0;
        visibility: hidden;
    & .ie-dropdown-item {
     color: #000 !important;
    outline: ${grey50} !important;
    z-index: 2;
    position: relative;
    display: block;
    align-items: center;
    width: 100%;
    padding-left: 0px;
    font-size: 14px;
    clear: both;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    outline: 1px solid transparent;
    font-family: ${fontInterstate};
    background: #fff;
    border-bottom: 1px solid ${'lightgrey'};
    padding: 10px  !important ;
  }
   & .ie-avatar-initials {
       font-size: 0.563rem;
       line-height: 22px;
       display: flex;
       align-items: center;
       justify-content: center;
       border-radius: 50%;
       position: absolute;
       width: 1.5rem;
      height: 1.5rem;
      background:${lightBlue};
      font-family: ${fontInterstate};
      font-size: 0.688rem;
      padding: 1px 0px 0px 1px;
    }
   }
      & .menu.active {
        opacity: 1;
        visibility: visible;
        z-index: 999;
        position: absolute;
        background:#fff;
      }
      & .menu ul {
        list-style: none;
        position: relative;
        right: 0px;
        padding: 0;
        margin: 0;
      }
      & .menu li.widget-menu-list{
        text-align: left;
        cursor: pointer;
        outline: 1px solid transparent;
        font-weight: lighter;
        font-family: ${fontInterstate};
        font-size: 14px;
        line-height: 1px;
        color: #2E2E38;
        position: relative;
        margin-bottom: 0px!important;
        padding: 10px;
      }
      & .menu .widget-menu-list:hover {
        color: #2E2E38;
        background: lightgrey;
        padding: 10px;
      }
      & .menu li a {
        text-decoration: none;
        color: ${lightbrown};
        padding: 15px 20px;
        display: block;
      }
    }
  
    & .ie-avatar-size-small {
      height: 2.71429rem;
      width: 2.71429rem;
    }
    & .ie-avatar {
      border-style: solid;
      border: 0.14286rem solid transparent;
      position: relative;
      border-radius: 50%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 2px;
      text-transform: uppercase;
    }
    .contactUs {
      height: 24px;
      width: 20px;
    }
    .name-font{
      font-size: 14px;
      font-weight: bold;
    }
    .menuText{
      position: relative;
      top: -7px;
      color: #2E2E38;
    }

    }

    .email-font{
      font-size: 12px;
      font-weight: 500;
    }
    
  `;
