import {
  createContext,
  useEffect,
  useState,
  useContext,
  Fragment,
} from "react";
import "../ExploreData/ExploreData.css";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";

import page from "../../assets/images/byod-images/page.svg";
import ExploreService from "../../services/explore.service.js";
import { useSelector } from 'react-redux';
import { type IStore } from '../../store';
const ExploreDocumentModalConfidential = ({ openShow, setOpenModal,documents }) => {
  const explorservice= new ExploreService();
  const [open, setOpen] = useState(openShow);
  const selectedDocumentThread = useSelector(
    (state: IStore) => state.userChat.selectedDocumentThread
  );
  const userMailID = useSelector((state: IStore) => state.common.userEmail);
  useEffect(() => {
    setOpenModal(openShow);
  }, [setOpenModal]);

  async function handleDownload(filename)  {
    const response = await explorservice.GetDownlodedDocument(
      selectedDocumentThread?.threadId,
      filename?.trim(),
      selectedDocumentThread?.title,
      userMailID
    );
    if(response) {
      const type=response.headers.get('content-type');
       const blob = new Blob([response.data], { type: type })
       var file = new File([blob], "name");
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click()
    }
    
   
  };

  return (
    <div>
      <MotifModal
        id="motifModalConfidential"
        className="motif-modal-confidential"
        show={setOpenModal}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          setOpenModal(!openShow);
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          Response from {documents?.length} confidential sources
        </MotifModalHeader>
        <MotifModalBody>
          <div className="motif-modal-confidential-body">
          <div className="motif-modal-confidential-row">
              {documents.map((item) => {
                return (
                  <>
                    <div className="motif-modal-confidential-row-Container">
                      <div
                        className={`motif-modal-confidential-row-fileIcon ${
                          !item.isAvailable
                            ? "noaction-document-not-available"
                            : ""
                        }`}
                      >
                        <img
                          src={page}
                          data-testid="downloadFile"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDownload(item.documentName);
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        className={`motif-modal-confidential-row-text ${
                          !item.isAvailable ? "document-not-available" : ""
                        }`}
                      >
                        {item.documentName}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
           
          </div>
        </MotifModalBody>
      </MotifModal>
    </div>
  );
};
export default ExploreDocumentModalConfidential;
