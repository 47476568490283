import React from "react";
import styled from "styled-components";
import PolicyWrapper from "./PolicyWrapper";
import PrivacyModalContent from "../footer/content/PrivacyModalContent";
import TermsModalContent from "../footer/content/TermsModalContent";
import ClientDemoModalContent from "../footer/content/ClientDemoModalContent";
import { ModalType } from "../../../models/ModalType";
import scssVar from "../../../styles/variables.module.scss";
import { IStore } from "../../../store";
import { useSelector } from "react-redux";
import { content } from "../footer/content/ClientDemoDisclaimer";
interface IOwnProps {
  type?: "Privacy" | null;
  onClose: () => void;
  onClickContinue: () => void;
  actionBtnEnabled: boolean;
  onChangeCheckBox?: (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => void;
  isLoading?:boolean
}

const PolicyContainer: React.FC<IOwnProps> = ({
  type,
  onClose,
  actionBtnEnabled,
  onChangeCheckBox,
  onClickContinue,
  isLoading=false
}: IOwnProps) => {
  const policyListType = useSelector((store:IStore)=>store.common.policyTypeList)
  let modelType = ModalType.Privacy ; 
 let headerTitle = "Privacy Notice – EY Competitive Edge";
  // if (type === "Privacy") {
  //   modelType = ModalType.Privacy;
  //   headerTitle = "Privacy Notice – EY Competitive Edge";
  // } 
  // else if (type === "Terms") {
  //   modelType = ModalType.Terms;
  //   headerTitle = "EY Competitive Edge Global Terms of Use";
  // } 
  // else {
  //   modelType = ModalType.Client;
  //   headerTitle = "EY Competitive Edge Demo: Disclaimer"
  // }
  return (
    <div className="se-model-main">
      <PolicyWrapper
        width={+scssVar.modalwidth}
        type={modelType}
        headerTitle={headerTitle}
        actionBtnText={"Acknowledge"}
        actionBtnEnabled={actionBtnEnabled}
        onCloseBtnClicked={onClose}
        onActionBtnClicked={onClickContinue}
        onChangeCheckBox={(e: React.SyntheticEvent<EventTarget>) => onChangeCheckBox?.({ target: { checked: (e.target as HTMLInputElement).checked } })}
        isLoading={isLoading}
      >
        <StyledFooterModalContent className="se-modal-body gray-2 noto-sans">
          {type === "Privacy" && <PrivacyModalContent content={policyListType?policyListType[0]?.content:""} />}
          {/* {type === "Terms"  && <TermsModalContent  content={policyListType?policyListType[1]?.content:""} />} */}
          {/* {type === "Client"  && <ClientDemoModalContent  content={content} />} */}
        </StyledFooterModalContent>
      </PolicyWrapper>
    </div>
  );
};

export const StyledFooterModalContent = styled.div`
  padding: 20px 50px;
  text-align: left;
  line-height: 1.375rem;

  h1,
  h4 {
    color: ${scssVar.lightgray};
  }

  .se-modal-body{
    position:relative !important;
    top:-25px !important;
  }
  h1 {
    margin: 0;
    padding-bottom: 1.25rem;
    font-weight: 500;
    font-size: 2.25rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 3.063rem;
  }

  h4 {
    font-weight: 500;
  }


  .privacy-modal .motif-h4 {
    font-family: ${scssVar.fontInterstate};
    font-size: 14px;
    line-height: 20px;
    color: ${scssVar.lightgray} !important;
  }
  .privacy-modal .motif-h6 {
    font-family: ${scssVar.fontInterstate};
    font-size: 12px;
    line-height: 20px;
    color: ${scssVar.lightgray} !important;
    font-weight: lighter;
  }
`;

export default React.memo(PolicyContainer);
