import { useSelector } from "react-redux";
import styled from "styled-components";
import { IStore } from "../../store";
import { ENGAGEMENT_MANAGEMENT_GUIDANCE_URL } from "../../label.constant";

type HeadingProps = {
    hideHeadingText?: boolean
}

const AIDisclaimerText: React.FC = (props: HeadingProps) => { 
    const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);
    const pathName = window.location.pathname;
    return (
        <SideBarStyle pathName = {pathName} isRightSideBarOpen = {isRightSideBarOpen}>
            <div className="borderTop">
                {!props.hideHeadingText && <p className="guidance">AI Guidance</p>}
                <p className="disc-text">
                    Please always be mindful of your obligations as an EY Professional and use Competitive Edge responsibly in line with the <a className='link' href="https://sites.ey.com/sites/Artificialintelligence/SitePages/AI-Principles.aspx" target="_blank" rel="noopener noreferrer">EY AI Principles</a>, <a className='link'
                        href="https://eur01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.microsoft.com%2Flicensing%2Fterms%2Fproduct%2FForOnlineServices%2Fall&data=05%7C02%7CVikram.Kumar1%40ey.com%7C8ea4bdab0d3146e36b3608dc806e01d3%7C5b973f9977df4bebb27daa0c70b8482c%7C0%7C0%7C638526457211745899%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C0%7C%7C%7C&sdata=%2F7NDEo8phP7pZBocA%2BiNov3oZqgGBz98pUps8C8Mr0A%3D&reserved=0" target="_blank"
                         rel="noopener noreferrer">Microsoft Acceptable Use Policy</a>, <a className='link'href="https://learn.microsoft.com/en-us/legal/cognitive-services/openai/code-of-conduct" target="_blank" rel="noopener noreferrer">Microsoft Code of Conduct for OpenAI Services</a> and the <a className='link' href={ENGAGEMENT_MANAGEMENT_GUIDANCE_URL} 
                         target='_blank'>Engagement Management Guidance</a>. Always make sure that you (a) have the required rights and permissions to use data with Competitive Edge and (b) validate any outputs prior to its use. Use of Competitive Edge must be in line
                          with SaT Service Line requirements as outlined in the <a href={ENGAGEMENT_MANAGEMENT_GUIDANCE_URL} target='_blank'
                        className='link'>Engagement Management Guidance</a> and any EY work product
                    using such output must be reviewed for accuracy and validity and follow all country and/or SaT Service Line specific quality processes and guidelines.
                </p>
            </div>
        </SideBarStyle>
    );
}

const SideBarStyle = styled.div<{
    pathName: string;
    isRightSideBarOpen?: boolean;
}>`
    .guidance {
        font-size: 12px;
        line-height: 13px;
        font-style: 'EYInterstate';
        font-weight: 400;
        color: #4D4D5C;
    }
    .disc-text {
        font-size: 10px;
        line-height: 13px;
        font-style: 'EYInterstate';
        font-weight: 300;
        color: #747480;
    }
    .link {
        font-size: 10px;
        color: #155CB4;
        line-height: 13px;
        font-style: 'EYInterstate';
        font-weight: 300;
        text-decoration: none;
        background-image: linear-gradient(120deg, #155CB4 0%, #155CB4 100%);
        background-repeat: no-repeat;
        background-size: 100% 1px;
        background-position: 0 100%;
        padding-bottom: 0px;
        transition: background-size .3s;
        letter-spacing: 0.3px;
    }
    .borderTop {
        border-top: 1px solid #E7E7EA;
    }
`;

export default AIDisclaimerText;
