import React, { useEffect, useState } from "react";
//import MotifModal, {MotifModalHeader,MotifModalBody,MotifModalFooter} from '@ey-xd/motif-react/Modal';
import { useSelector } from "react-redux";
import { IStore } from "../../store";
import {
  MotifTable,
  MotifButton,
  MotifCheckbox,
  MotifFormField,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
  MotifSearch,
  MotifTableCellRenderer, MotifTableHeaderRenderer
} from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import MotifToast from "@ey-xd/motif-react/Toast";
import _, { remove, set } from "lodash";
import Right from "../../assets/images/byod-images/Right.svg";
import check from "../../assets/images/byod-images/check.svg";
import clouderror from "../../assets/images/byod-images/clouderror.svg";
import noDocumentImg from "../../assets/images/byod-images/noDocument.png";
import page from "../../assets/images/byod-images/page.svg";
import reloadImg from "../../assets/images/byod-images/refresh-double.svg";
import DocumentService from "../../services/document.service";
import Utils from "../../utils/Utils";
import "./AddDocument.css";
import { DocumentDeleteConfirmModal } from "./DocumentDeleteConfirmModal";
import LoaderComponent from "../../components/motifComponents/LoaderComponent";
import searchIcon from "../../assets/images/byod-images/search.svg";
import cancelIcon from "../../assets/images/byod-images/cancel.svg";
import {byodToastCheckCircleIcon} from "../../../src/commonImage"
import { truncateString } from "../../utils/commonUtils";
const FilesUploadedGrid = ({
  fileCountcheck,
  openShow,
  filesLimit,
  fileSizeLimit,
  documentList,
  setinitialLoadData
}) => {
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen); 
  const selectedDocumentThread = useSelector(
  (state: IStore) => state.userChat.selectedDocumentThread
  );
  const userMailID = useSelector((state: IStore) => state.common.userEmail);
  const userData = useSelector((state: IStore) => state.userProfile.userData);
  const documentservice = new DocumentService();
  const utils = new Utils();
  let gridApi:any;
  let InititaData = [];
  const [searchText, setSearchText] = useState("");
  const [pagesize, SetPageSize] = useState(5);
  const [temRowData, SetTemRowData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const[isAllFilter,setIsAllFilter]=useState(false);
  const [isPdfFilter, setIsPdfFilter] = useState(false);
  const [isDocFilter, setIsDocFilter] = useState(false);
  const [isPptFilter, setIsPptFilter] = useState(false);
  const [isXlsFilter, setIsXlsFilter] = useState(false);
  const [isTxtFilter, setIsTxtFilter] = useState(false);
  const [fileLimitSize, setFileLimitSize] = useState(0);
  const [selected,setSelected]=useState(true)
  const [selectedExtensions, setSelectedExtensions] = useState([]);
  const [gridshow, setGridShow] = useState(false);
  const [deleteDocumentUUID, setDeleteDocumentUUID] = useState("");
  const [deleteDocumentName, setDeleteDocumentName] = useState("");
  const [showDocumentDeleteConfirmModal, setShowDocumentDeleteConfirmModal] =
    useState(false);
  const [showSuccessToastMessage, setShowSuccessToastMessage] = useState(false);
  const[faileddocLength,setFaileddocLength]=useState(0);
  const [failedDocuments,setFailedDocuments]=useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const handleGridReady = (params:any) => {
    ({ api: gridApi } = params);
    };

    const onPageSizeChanged = (newPageSize:any) => {
      SetPageSize(newPageSize);
      //gridApi.paginationSetPageSize(newPageSize);
      };
       const removeFailedDocuments=()=>{
         Promise.all(failedDocuments.map((item:any)=>{
          documentservice.deleteDocument(
            selectedDocumentThread.threadId,
            item.document_UUID,
            item.documentName,
            selectedDocumentThread.title,
            userMailID,
            userData
          );
        })).then(()=>{
        setTimeout(() => {
          getUploadedDocuments();
        }, 1000);
        });
       };
  useEffect(() => {
    getUploadedDocuments();
    setSelectedExtensions([]);
    setIsAllFilter(false);
    setIsPdfFilter(false);
    setIsDocFilter(false);
    setIsPptFilter(false);
    setIsXlsFilter(false);
    setIsTxtFilter(false);
  }, []);

  useEffect(() => {
    if (!openShow) getUploadedDocuments();
  }, [openShow]);

  useEffect(() => {
    filterDocumentsWithExtension();
  }, [selectedExtensions]);

  useEffect(() => {
    if (documentList) {
      checkFileSize(documentList);
      setRowData(documentList);
      SetTemRowData(documentList);
      localStorage.setItem("getdocsdata", JSON.stringify(InititaData));
      getFilterdata();
    }
  }, [documentList]);

  useEffect(() => {
    if (rowData?.length > 0) {
      setFaileddocLength(0);
      let count = 0;
      let failedDocs =[];
      rowData.filter((item:any) => {
        if (item.errorMessage !=null){ 
          count += 1;
          failedDocs.push(item);
          setFailedDocuments(failedDocs);
        }
        
      });
      setFaileddocLength(count);
    }
  }, [temRowData]);

  const getFilterdata =()=>{
    setTimeout(() => {
      if(isAllFilter)
      filterDocuments(["all"], true);
      if(isPdfFilter)
      filterDocuments(["pdf"], true);
      if(isDocFilter)
      filterDocuments(["doc", "docx"], true);
      if(isPptFilter)
      filterDocuments(["ppt", "pptx"], true);
      if(isXlsFilter)
      filterDocuments(["xls", "xlsx"], true);
      if(isTxtFilter)
      filterDocuments(["txt"], true);
      });
  }
  // Get the documents which are uploaded and set it to rowdata to display grid
  const getUploadedDocuments = async () => {
    setShowLoader(true);
    if(selectedDocumentThread?.threadId){
    InititaData = await documentservice.getDocuments(
     selectedDocumentThread?.threadId,selectedDocumentThread?.title,userMailID
    );
  }
  else{
    InititaData = [];
  }
    checkFileSize(InititaData);
    setRowData(InititaData);
    SetTemRowData(InititaData);
    setShowLoader(false);
    setinitialLoadData(InititaData)
    getFilterdata();
    localStorage.setItem("getdocsdata", JSON.stringify(InititaData)); 
  };
  //refresh the documents grid
  const reloadUploadedDocuments = () => {
    getUploadedDocuments();
  };
  // caluculate the total documnets uploaded size and count and send back to Adddocument component
  function checkFileSize(data) {
    if (data?.length > 0) {
      let count = 0;
      data.forEach((item) => {
        if (item.fileSize !== "") 
        count += parseInt(item.fileSize);
      });
      setFileLimitSize(count);
      fileCountcheck(data.length, count, data);
    } else {
      setFileLimitSize(0);
      let arary = [];
      fileCountcheck(0, 0, arary);
    }
  }
  // format the required date of grid column before displaying
  const dateFormatter = (params) => {
    if (params.value !== "") {
      const d = params.value.lastIndexOf("Z")>-1 && params.value.lastIndexOf("Z")===(params.value.length-1) ? new Date(params.value):new Date(params.value+'Z');
      const currentdate = new Date();
      if (
        currentdate.getFullYear() === d.getFullYear() &&
        currentdate.getMonth() === d.getMonth() &&
        currentdate.getDate() === d.getDate()
      ) {
        let result =
          "Today," + d.toLocaleTimeString("en-us", { timeStyle: "short" });
        return result;
      } else {
        const date = d.getDate();
        const year = d.getFullYear();
        const Month = d.toLocaleString("en-us", { month: "short" });
        return Month + " " + date + ", " + year;
      }
    } else {
      return "";
    }
  };
  /* Custom Cell Renderer (Display status complete, pending, failed */
  const statusResultRenderer = ({ value }) => (
    <span className="add-document-file-status-div"
    >
      {(() => {
        if (value === "Complete") {
          return (
            <span className="add-document-file-status">
              <img src={check} alt={`${value}`} /> ready{" "}
            </span>
          );
        } else if (value === "InProgress") {
          return (
            <span className="add-document-file-status">
              <img src={Right} alt={`${value}`} /> Uploading{" "}
            </span>
          );
        } else if (value === "Error") {
          return (
            <span className="add-document-file-status add-document-file-error">
              <img src={clouderror} alt={`${value}`} /> Failed to upload
            </span>
          );
        }
      })()}
    </span>
  );

  const [colDefs] = useState([
    {
      headerName: 'documentName',
      field: "documentName",
      displayName: "Files",
      width: 400,
      sortable: true,
      filter: true,
      //checkboxSelection: true,
      cellRenderer: (params) => (
        <div  title = {params.data.documentName} className={params.data.errorMessage!=null? "file-upload-error-message gridimagewithdoc":"gridimagewithdoc"}>
          <img src={page} alt={params.data.documentName} />{" "}
          {truncateString(params.data.documentName, 50)}
        </div>
      ),
    },
    {
      headerName:'fileUploadTS',
      field: "fileUploadTS",
      displayName: "Date",
      sortable: true,
      filter: true,
      valueFormatter: dateFormatter,
    },
    {
      headerName:'status',
      field: "status",
      displayName: "Ready for prompt",
      sortable: true,
      filter: true,
      cellRendererFramework: statusResultRenderer,
    },
    {
      width:350,
      cellRendererFramework: (params) => {
        if (params.data.errorMessage!=null) {
          return (
            <span className="add-document-file-status file-upload-error-message" title={params.data.errorMessage}>
              {truncateString(params?.data?.errorMessage,50)}
            </span>
          );
        } else {
          return (<span></span>);
        }
      },
    },
    {
      cellRendererFramework: (params) => (
        <div key={params.data.documentId}>
          <div className="add-document-grid-action-button-div">
            <MotifIconButton
              onClick={() => {
                showPopUp(
                  true,
                  params.data.document_UUID,
                  params.data.documentName
                );
              }}
            >
              <img src={cancelIcon} alt="cancel icon"/>
            </MotifIconButton>
          </div>
        </div>
      ),
    },
  ]);


  // filter the document grid based on input
  function updateFilter(event) {
    event.preventDefault();
    if (event.target.value !== "") setGridShow(true);
    setSearchText(event.target.value);
    let searchValue = event.target.value;
    if (searchValue !== "") {
      searchValue = searchValue.toString().toLowerCase().trim();
      let filteredDocs = _.filter(temRowData, function (item) {
        return (
          item.documentName.toLowerCase().includes(searchValue) ||
          item.status.toLowerCase().includes(searchValue)
        );
      });
      setRowData(filteredDocs);
    } else {
      setRowData(temRowData);
    }
  }
  // file extension based filter for the grid
  const filterDocumentsWithExtension = () => {
    if (temRowData.length > 0) {
      setGridShow(true);
    }
    if (selectedExtensions.length !== 0 && temRowData.length > 0) {
      let data = _.filter(temRowData, function (item) {
        return (
          selectedExtensions.indexOf(
            utils.getFileExtension(item.documentName)
          ) > -1
        );
      });
      setRowData(data);
    } else {
      setRowData(temRowData);
    }
  };
  // setting up the file types for doing the type based filter
  const filterDocuments = (extension, checked) => {
    if (extension.length > 0) {
      _.forEach(extension, function (ext) {
        switch (ext) {
          case "all":
            setIsAllFilter(checked);
            break;
          case "pdf":
            setIsPdfFilter(checked);
            break;
          case "ppt":
          case "pptx":
            setIsPptFilter(checked);
            break;
          case "doc":
          case "docx":
            setIsDocFilter(checked);
            break;
          case "xls":
          case "xlsx":
            setIsXlsFilter(checked);
            break;
          case "txt":
            setIsTxtFilter(checked);
            break;
          default:
            break;
        }
      });
    }

    if (checked) {
      let arrayExtension = [...selectedExtensions, ...extension];
      setSelectedExtensions(_.union(arrayExtension));
    } else {
      let removeExtensionArray = _.filter(selectedExtensions, function (e) {
        return extension.indexOf(e) <= -1;
      });
      setSelectedExtensions(removeExtensionArray);
    }
  };
  // modal popup setup before deleting the document
  const showPopUp = (isVisible, documentId, documentName) => {
    setDeleteDocumentUUID(documentId);
    setDeleteDocumentName(documentName);
    setShowDocumentDeleteConfirmModal(isVisible);
  };
  // Delete the selected document functionality
  const deleteSelectedDocument = (documentUUID, documentName) => {
    documentservice.deleteDocument(
    selectedDocumentThread.threadId,
      documentUUID,
      documentName,
      selectedDocumentThread.title,
      userMailID,
      userData
    );
    setTimeout(function () {
      getUploadedDocuments();
    }, 1000);
    setShowDocumentDeleteConfirmModal(false);
    setShowSuccessToastMessage(true);
    setTimeout(() => {setShowSuccessToastMessage(false)},3000);
  };
  // closing the motif-toaster notification
  const onCloseToastClick = () => {
    setShowSuccessToastMessage(false);
  };
  const handleFileChange = () => {
    // No longer need to cast to any - hooray for react!
    let targetEvent:any = event?.target;
    const fileType =targetEvent.parentElement.querySelectorAll(".motif-checkbox-label")[0].innerHTML;

    switch (fileType) {
      case "all":
        filterDocuments(["all"], targetEvent.checked);
        break
      case "PDF":
        filterDocuments(["pdf"], targetEvent.checked);
        break;
      case "PPTX":
        filterDocuments(["ppt", "pptx"], targetEvent.checked);
        break;
      case "DOC":
        filterDocuments(["doc", "docx"], targetEvent.checked);
        break;
      case "XLS":
        filterDocuments(["xls", "xlsx"], targetEvent.checked);
        break;
      case "TXT":
        filterDocuments(["txt"], targetEvent.checked);
        break;
      default:
        break;
    }
    return;
  }
  
  return (
    <>
      {showLoader && <LoaderComponent />}
      {temRowData?.length > 0 && (
        <div className={`Frame53401 ${isRightSideBarOpen?"chatbaropen":""}`}>
          <div className="Frame54261">
            <div className="Table1">
              <div className="CardHeader1">
                <div className="CardTitle1">
                  <div className="TextAndSupportingText1">
                    <div className="text12">
                      <div className="Text13">
                        Select files to explore your data
                      </div>
                      <MotifButton
                        className={`motif-failed-doc-button ${faileddocLength>0?'failed_button':'hide'}` }
                        onClick={() => {
                          removeFailedDocuments();
                        }}>
                          Remove {faileddocLength} Failed documents
                        </MotifButton>
                    </div>
                  </div>
                </div>
                <div className="CardActions1">
                  {/* <div className="Button1">
                    <div className="ButtonBase13">
                      <div className="Text14">Remove 3 Failed documents</div>
                    </div>
                  </div> */}
                  <div className="Button2">
                    <img className="searchIcon" src={searchIcon} alt="search icon" />
                    <MotifSearch
                      aria-label="Search"
                      hideClearButton
                      id="states-search2"
                      className="searchTxtBox"
                      onChange={(e) => {
                        updateFilter(e);
                      }}
                      onSelect={function noRefCheck() {}}
                      placeholder="Search"
                      value={searchText}
                    />
                  </div>
                </div>
              </div>

              <div className="main-motif-table-filter">
                <div className="doc-filter-container">
                  <div className="doc-filters">
                  <div>
                      <MotifCheckbox
                        className="ChkAll1"
                        id="filter-allFilter"
                        name="filter-allFilter"
                        onChange={(e) => {
                          filterDocuments(["all","pdf","ppt","pptx","doc","docx","xls","xlsx","txt"], e.target.checked);
                          var checkboxes = e.target.closest(".doc-filters").querySelectorAll('.doccheckbox input[type="checkbox"]');
                          for (var i = 0; i < checkboxes.length; i++) {
                            if (checkboxes[i].type === 'checkbox') {
                                checkboxes[i].checked = e.target.checked;
                            }
                          }
                        }}
                        checked={isAllFilter}
                      >
                        ALL
                      </MotifCheckbox>
                    </div>
                    <div className="doccheckbox">
                      <MotifCheckbox
                        className="ChkAll1"
                        id="filter-pdfFilter"
                        name="filter-pdfFilter"
                        //  disabled={isAllFilter}
                        onChange={(e) => {
                          filterDocuments(["pdf"], e.target.checked);
                        }}
                        checked={isPdfFilter}
                      >
                        PDF
                      </MotifCheckbox>
                    </div>
                    <div className="doccheckbox">
                      <MotifCheckbox
                        className="ChkAll1"
                        id="checkbox-pptFilter"
                        name="checkbox-pptFilter"
                        //  disabled={isAllFilter}
                        onChange={(e) => {
                          filterDocuments(["ppt", "pptx"], e.target.checked);
                        }}
                        checked={isPptFilter}
                      >
                        PPTX
                      </MotifCheckbox>
                    </div>
                    <div className="doccheckbox">
                      <MotifCheckbox
                        className="ChkAll1"
                        id="checkbox-docFilter"
                        name="checkbox-docFilter"
                        //  disabled={isAllFilter}
                        onChange={(e) => {
                          filterDocuments(["doc", "docx"], e.target.checked);
                        }}
                        checked={isDocFilter}
                      >
                        DOC
                      </MotifCheckbox>
                    </div>
                    <div className="doccheckbox">
                      <MotifCheckbox
                        className="ChkAll1"
                        id="checkbox-xlsFilter"
                        name="checkbox-xlsFilter"
                        //  disabled={isAllFilter}
                        onChange={(e) => {
                          filterDocuments(["xls", "xlsx"], e.target.checked);
                        }}
                        checked={isXlsFilter}
                      >
                        XLS
                      </MotifCheckbox>
                    </div>
                    <div className="doccheckbox">
                      <MotifCheckbox
                        className="ChkAll1"
                        id="checkbox-txtFilter"
                        name="checkbox-txtFilter"
                        //  disabled={isAllFilter}
                        onChange={(e) => {
                          filterDocuments(["txt"], e.target.checked);
                        }}
                        checked={isTxtFilter}
                      >
                        TXT
                      </MotifCheckbox>
                    </div>
                  </div>
                  <div className="doc-filter-text">
                    Select to filter the document library by file type.
                  </div>
                </div>
                <div className="doc-details-container">
                  <div className="doc-details-states">
                    {rowData?.length}/{filesLimit}
                  </div>
                  <div className="doc-details-states">
                    {utils.getBytesInMB(fileLimitSize)} MB/
                    {utils.getBytesInMB(fileSizeLimit)} MB
                  </div>
                  <div className="reload-doc-image-button">
                    <MotifIconButton
                      onClick={reloadUploadedDocuments}
                      alt="reload document table1"
                    >
                      <img src={reloadImg} alt="reload document table" />
                    </MotifIconButton>
                  </div>
                </div>
              </div>

              <div
                className={`ag-theme-quartz ${isRightSideBarOpen ? "chatbaropen" : "chatbarclose"}`}
              >
                <MotifTable
                   rowData={rowData}
                   columnDefs={colDefs}
                   suppressCellFocus
                   suppressRowClickSelection
                   rowSelection="multiple"
                   //title="Motif table example"
                   pagination
                   onGridReady={handleGridReady}
                   onSortChanged={() => gridApi?.redrawRows()}
                   components={{
                     agColumnHeader: MotifTableHeaderRenderer
                   }}
                   paginationPageSize={pagesize}
                  paginationSelect={
                    <MotifPaginationSelect>
                      <MotifPaginationSelectItem
                        onClick={function noRefCheck() {
                          SetPageSize(5);
                        }}
                        selected
                      >
                        Show 5
                      </MotifPaginationSelectItem>
                      <MotifPaginationSelectItem
                        onClick={function noRefCheck() {
                          SetPageSize(10);
                        }}
                      >
                        Show 10
                      </MotifPaginationSelectItem>
                      <MotifPaginationSelectItem
                        onClick={function noRefCheck() {
                          SetPageSize(15);
                        }}
                      >
                        Show 15
                      </MotifPaginationSelectItem>
                    </MotifPaginationSelect>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {(!rowData || (rowData?.length === 0 && !gridshow)) && (
        <div className="Frame534012">
          <div className="Frame681">
            <div className="Frame691"></div>
            <div className="EmptyState1">
              <div className="EmptyState2">
                <div className="Content2">
                  <img src={noDocumentImg} alt="no document image"></img>
                  <div className="TextAndSupportingText">
                    <div className="Text15">No documents found</div>
                    <div className="nodoctext">
                      Please upload your documents in the document library
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Rectangle5361"></div>
        </div>
      )}

      {showDocumentDeleteConfirmModal && (
        <DocumentDeleteConfirmModal
          setOpenModal={showPopUp}
          setConfirm={deleteSelectedDocument}
          documentUUID={deleteDocumentUUID}
          documentName={deleteDocumentName}
        ></DocumentDeleteConfirmModal>
      )}

      {showSuccessToastMessage && (
        <MotifToast
          variant="success"
          actionName=""
          className="delete-toast-message"
          icons={{toast:<img src={byodToastCheckCircleIcon} alt="checkcircle" />}}
          onClose={() => {
            onCloseToastClick();
          }}
        >
          <div>Document deleted successfully</div>
        </MotifToast>
      )}
    </>
  );
};

export default FilesUploadedGrid;
