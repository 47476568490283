import { useSelector } from "react-redux";
import { IStore } from "../store/index.js";
import { appInsights } from "./appInsights.js";

class CommonService {

  logException(error:any, componentName:any, methodName:any,threadId:any,title:any,userEmailId:any, requestObject=null) {
   appInsights.trackException({
      error: error,
      properties: {
        Component: componentName,
        Method: methodName,
        WorkSpaceThreadTitle: title,
        WorkSpaceThreadId:threadId,
        UserId: userEmailId,
        payload: requestObject,
        errorData:error?.response?.data
      },
    });
  }
}
export default CommonService;
