import React, { Fragment, useCallback, useState } from 'react';
import Modal from '../../common/modal/Modal';
import { ModalType } from '../../../models/ModalType';
import styled from 'styled-components';
import sassVar from '../../../styles/variables.module.scss';

const { lightgray, deleteReportModalWidth, alternateblack, fontInterstate } = sassVar;

interface IOwnProps {
  show: boolean;
  onClose: () => void;
  onContinue: () => void;
  reportId: string;
  souceChange: boolean;
  isUploadURL? :boolean
}
const SourceChangeModal: React.FC<IOwnProps> = ({
  show,
  onClose,
  onContinue,
  reportId,
  souceChange,
  isUploadURL
}: IOwnProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal
      isLoading={isLoading}
      isOpen={show}
      type={ModalType.SourceChange}
      headerTitle= {isUploadURL ? 'URL change' : 'Data source change'}
      onCloseBtnClicked={onContinue}
      headerIcon={<img onClick={onClose} className='se-close' src={''} />}
      showHeaderIcon={true}
      width={+deleteReportModalWidth}
      onActionBtnClicked={onClose}
      actionBtnText='Cancel'
      actionBtnEnabled={true}
      souceChange={souceChange}>
      <SourceChangePopup>
        <Fragment>
          <div className='sourceChange-report-popup'>
            <div>
              <label className='sourceChange-confirmation'>
              {!isUploadURL ?
                "Changing a data source will start new chat. Your current chat will be saved in chat history. Do you wish to continue?"
                : 'Changing the URL will start new chat and previous URL will be reset. Do you wish to continue?'}
              </label>
            </div>
          </div>
        </Fragment>
      </SourceChangePopup>
    </Modal>
  );
};

const SourceChangePopup = styled.div`
  .sourceChange-report-popup {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    .sourceChange-confirmation {
      font-family: ${fontInterstate};
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #2e2e38;
    }
  }
`;

export default React.memo(SourceChangeModal);
