import { FC, memo, useCallback } from 'react';
import CheckboxTreeNode from './CheckboxTreeNode';
import { MotifTree } from '@ey-xd/motif-react';

export type CheckboxTreeProps = {
  data: any[];
  className?: string;
  expandAll?: boolean;
  onChange: (node: any, isChecked: boolean) => void;
};

const CheckboxTree: FC<CheckboxTreeProps> = ({
  data,
  className,
  onChange,
  expandAll
}: CheckboxTreeProps) => {
  return (
    <MotifTree className={className} expandAll={expandAll}>
      {data.map((item, index) => (
        <CheckboxTreeNode
          key={item.key}
          data={item}
          ind={index}
          onChange={onChange}
          hierarchy={''}
          index={index}
        />
      ))}
    </MotifTree>
  );
};

export default memo(CheckboxTree);
