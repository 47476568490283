import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const AddMembersDropDown = ({ onSearch, placeholder, storageKey, allMembers, isValidSelection, onSelect }: any) => {
  const [selectedMembers, setSelectedMembers] = useState(() => {
    const storedData = localStorage.getItem(storageKey);
    return storedData ? JSON.parse(storedData) : [];
  });

  const [searchTerm, setSearchTerm] = useState('');

  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const wrapperRef = useRef<any>();

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(selectedMembers));
  }, [selectedMembers, storageKey]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsFocused(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const debounce = (func: any, wait: any) => {
    let timeout: any;
    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debouncedSearch = useRef(
    debounce(async (value: any) => {
      setIsLoading(true);
      await onSearch(value);
      setIsLoading(false);
    }, 500)
  ).current;

  const handleSelect = (member: any, storageKey: string) => {
    if (!selectedMembers.find((m: any) => m.objectId === member.objectId)) {
      setSelectedMembers([...selectedMembers, member]);
      setIsFocused(false);
      setSearchTerm('');
    }
    onSelect([...selectedMembers, member],storageKey)
  };

  const handleRemove = (memberId: any,storageKey:string) => {
    setSelectedMembers(selectedMembers.filter((m: any) => m.objectId !== memberId));
    onSelect(selectedMembers.filter((m: any) => m.objectId !== memberId),storageKey)
  };

  const handleSearchChange = async (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length >= 3) {
      debouncedSearch(value);
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  };

  const handleFocus = () => {
    if (searchTerm.length >= 3) {
      setIsFocused(true);
    }
  };

  const filteredMembers: any = allMembers?.filter(
    (member: any) =>
      (member?.displayName &&
        member.displayName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (member?.userEmailId && member.userEmailId.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const getInitials = (name: string): string => {
    const nameParts = name.trim().split(' ');
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    } else {
      return (nameParts[0][0] + nameParts[nameParts.length - 1][0]).toUpperCase();
    }
  };

  return (
    <StyledAddMembersDropDown>
      <div className='multi-select-dropdown' ref={wrapperRef}>
        <div className={`multi-select-container ${!isValidSelection ? 'invalid-selection' : 'valid-selection'}`} onClick={handleFocus}>
          {selectedMembers.map((member: any) => (
            <div key={member.objectId} className='selected-member'>
              {member.profileImageUrl ? (
                <img src={member.profileImageUrl} alt={member.displayName} className='selected' />
              ) : (
                <div className='selected'>{getInitials(member.displayName)}</div>
              )}
              <span>{member.displayName}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemove(member.objectId,storageKey);
                }}
                className='remove-button'>
                ×
              </button>
            </div>
          ))}
          <div>
            <input
              type='text'
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder={selectedMembers.length === 0 ? placeholder : ''}
              className='search-input'
              autoComplete='off'
            />
          </div>
        </div>
        {isFocused && (
          <div className='dropdown-menu'>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              filteredMembers?.map((member: any) => (
                <div
                  key={member.objectId}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect(member, storageKey);
                  }}
                  className='dropdown-item'>
                  {member.profileImageUrl ? (
                    <img
                      src={member.profileImageUrl}
                      alt={member.displayName}
                      className='dropdown'
                    />
                  ) : (
                    <div className='dropdown'>{getInitials(member.displayName)}</div>
                  )}
                  <span className='dropdown'>{member.displayName}</span>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </StyledAddMembersDropDown>
  );
};

const StyledAddMembersDropDown = styled.div`
  .multi-select-dropdown {
    position: relative;
    margin-bottom: 15px;
  }

  .multi-select-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    border: 1px solid #4d4d5c;
    border-radius: 4px;
    width: 500px;
  }

  .invalid-selection {
    border: 1px solid red;
  }
  .valid-selection {
    border: 1px solid #4d4d5c;
  }

  .selected-member {
    display: flex;
    align-items: center;
    margin: 2px;
    padding: 2px 5px;
    border: 1px solid #4d4d5c;
    border-radius: 4px;
  }

  .selected-member img,
  .selected-member div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .selected-member div {
    background-color: #ccc;
    text-align: center;
    line-height: 20px;
  }

  .selected-member span {
    width: max-content;
  }

  .remove-button {
    margin-left: 5px;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .search-input {
    border: none;
    padding: 5px;
    height: 30px;
    gap: 8px;
    border-radius: 4px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    outline: none;
    width: 300px;
  }

  .dropdown-menu {
    position: absolute;
    width: 100%;
    z-index: 1;
    background: white;
    border: 1px solid #ccc;
    border-top: none;
    max-height: 195px;
    overflow-y: auto;
    width: 500px;
  }

  .dropdown-item {
    display: flex;
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-item img,
  .dropdown-item div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .dropdown-item div {
    background-color: #ccc;
    text-align: center;
    line-height: 30px;
  }

  .dropdown-item span {
    margin-left: 10px;
  }
`;

export default AddMembersDropDown;
