import React, { Component } from 'react';
type IFooterProps = {
  clearAll?: () => void;
  closeFilter?: () => void;
  handleApply?: () => void;
  clearText?: string;
  customClass?: any;
  showApply?: boolean;
  showClearAll?: boolean;
};
const DropdownFooter = ({
  clearAll,
  closeFilter,
  handleApply,
  customClass,
  showApply = false,
  showClearAll = true,
  clearText = 'Clear filters'
}: IFooterProps) => {
  return (
    <div className={customClass ? customClass : 'filter-footer'}>
      {showClearAll && (
        <button id='clearAll' className='btn clear-all-btn' data-tfs='clearAll' onClick={clearAll}>
          {clearText}
        </button>
      )}

      <button
        id='close-btn'
        className='btn sector-dropdown-btn'
        data-tfs='close-btn'
        onClick={closeFilter}>
        Close
      </button>

      {showApply && (
        <button
          id='close-btn'
          className='btn sector-dropdown-btn'
          data-tfs='close-btn'
          onClick={handleApply}>
          Apply
        </button>
      )}
    </div>
  );
};

export default DropdownFooter;
