import React, { useState, useEffect, useCallback } from 'react';
import { MotifFormField, MotifLabel, MotifOption, MotifSelect } from '@ey-xd/motif-react';
import CheckboxTree from '../CheckboxTree/CheckboxTree';
import styled from 'styled-components';
import OverflowTooltip from '../OverflowTooltip';
import { tooltip } from '../../../commonImage';
import { allTemplateResponse } from '../../../services/teamsProject.service';

const getAllTemplates = async () => {
  const templatesResponseNew: any = await allTemplateResponse();
  return { status: 200, data: templatesResponseNew.data};
};

const TemplateProject = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [templateList, setTemplateList] = useState([]);
  const [template, setTemplate] = useState(props.selectedTemplate || {});
  const [templateStructure, setTemplateStructure] = useState(props.template || []);
  
  useEffect(() => {
    const fetchData = async () => {
      const templatesResponse: any = await getAllTemplates();
      const savedTemplate: any = localStorage.getItem('selectedTemplate');
      const saveTemplateName: any = localStorage.getItem('templateName');
      let saveTemplateNameArr = [];
      saveTemplateNameArr.push(JSON.parse(saveTemplateName));
      const firstTemplate = templatesResponse.data[0];
      // const matchingData = findMatchingFolders(templatesResponse.data, savedTemplate);

      const objectsEmpty = (obj: any) => {
        if (obj == null) {
          return false;
        }
        return Object.keys(obj).length === 0;
      };
      const isEmptyObject = saveTemplateNameArr?.every(objectsEmpty);

      if (savedTemplate) {
        const parsedSavedTemplate = JSON.parse(savedTemplate);
        setTemplateStructure(parsedSavedTemplate);
        setTemplateList(templatesResponse.data);
        setTemplate( firstTemplate);
        localStorage.setItem('selectedTemplateID', firstTemplate.id);
      } else {
        setTemplateList(templatesResponse.data);
        setTemplate(firstTemplate);
        const parsedFirstTemplate = JSON.parse(firstTemplate.template);
        setTemplateStructure(parsedFirstTemplate);
        saveTemplateToLocalStorage(parsedFirstTemplate);
        localStorage.setItem('selectedTemplateID', firstTemplate.id);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  // const findMatchingFolders = (data: any, saveData: any) => {
  //   const saveDataFolderNames = JSON.parse(saveData)?.map((folder: any) => folder.folder);

  //   const matchingDataObjects = data.filter((dataItem: any) => {
  //     const templateFolders = JSON.parse(dataItem.template);
  //     return templateFolders.some((folder: any) => saveDataFolderNames?.includes(folder.folder));
  //   });

  //   return matchingDataObjects;
  // };

  const saveTemplateToLocalStorage = (templateData: any) => {
    const checkedItems = templateData.map((folder: any) => {
      const subfolders = folder.subfolder
        ? folder.subfolder.map((subfolder: any) => {
            const subSubfolders = subfolder.subSubfolder
              ? subfolder.subSubfolder.map((subsubfolder: any) => {
                  return { ...subsubfolder };
                })
              : [];
            return { ...subfolder, subSubfolder: subSubfolders };
          })
        : [];
      return { ...folder, subfolder: subfolders };
    });

    localStorage.setItem('selectedTemplate', JSON.stringify(checkedItems));
    localStorage.setItem('templateName', JSON.stringify(template));
  };

  const onTemplateChange = (event: any) => {
    const selectedTemplateId = event;
    const selectedTemplate: any = templateList.find((t: any) => t.id === selectedTemplateId);
    if (selectedTemplate) {
      localStorage.setItem('selectedTemplateID', selectedTemplateId);
      setTemplate(selectedTemplate);
      const selectedTemplateStructure = JSON.parse(selectedTemplate.template)
      setTemplateStructure(selectedTemplateStructure);
      saveTemplateToLocalStorage(selectedTemplateStructure);
    }
  };

  const setCheckedState = (folder: any, isChecked: any) => {
    folder.checked = isChecked;
    if (folder.subfolder) {
      folder.subfolder = folder.subfolder.map((sub: any) => setCheckedState(sub, isChecked));
    }
    return folder;
  };

  const updateNode = useCallback(
    (nodes: any[], nodeToUpdate: any, isChecked: boolean, path: string = ''): any[] => {
      return nodes.map((node, index) => {
        const currentPath = path ? `${path}.${index}` : `${index}`;
        if (currentPath === nodeToUpdate.path) {
          const updatedNode = {
            ...node,
            checked: isChecked,
            status: isChecked ? 'Applicable' : 'Not applicable'
          };
          if (updatedNode.subfolder?.length > 0) {
            updatedNode.subfolder = updatedNode.subfolder.map((childNode: any) => {
              return {
                ...childNode,
                checked: isChecked,
                status: isChecked ? 'Applicable' : 'Not applicable'
              };
            });
          }
          return updatedNode;
        } else if (node.subfolder?.length > 0) {
          const updatedSubfolder = updateNode(node.subfolder, nodeToUpdate, isChecked, currentPath);
          const isParentChecked =
            node.subfolder.length === 0 ||
            updatedSubfolder.some((childNode: any) => childNode.checked);
          return {
            ...node,
            subfolder: updatedSubfolder,
            checked: isParentChecked,
            status: isParentChecked ? 'Applicable' : 'Not applicable'
          };
        }
        return node;
      });
    },
    []
  );

  const onNodeChange = useCallback(
    (node: any, isChecked: boolean) => {
      setTemplateStructure((prevStructure: any) => {
        const newData = updateNode(prevStructure, node, isChecked);
        saveTemplateToLocalStorage(newData);
        return newData;
      });
    },
    [templateStructure, updateNode]
  );

  return (
    <StyledFile>
      <div className='select-template'>
        <label htmlFor='templateDropdown' className='dropDown-title'>
          Select Template
        </label>
        <div className='select-dropdown'>
        <MotifFormField>
              
          <MotifSelect
            placeholder=''
            id='templateDropdown'
            className='inputBox'
            onChange={(event) => onTemplateChange(event)}
            value={template.id}
            visibleOptions={3}>
            {templateList.map((t: any, index) => (
              <MotifOption key={t.id} value={t.id}>
                {t.templateName}
              </MotifOption>
            ))}
          </MotifSelect>
          </MotifFormField>
          <span className='link'>
            <a
              href='https://sites.ey.com/sites/taspolicy/SitePages/Home.aspx#Home.aspx#InplviewHashabb6e0b0-ec5a-4593-8180-5f101ae1d382=FilterField1=Scope-FilterValue1=Global-FilterFields2=Competency-FilterValues2=Strategy%20and%20Transactions%253B%2523null#InplviewHash01cc7a5d-e83c-4caf-ae1c-9783cf64a1f2=FilterField1%3DScope-FilterValue1%3DGlobal-FilterFields2%3DCompetency-FilterValues2=Strategy%20and%20Transactions%253B%2523null'
              target='blank' className='url-link-font'>
              Go to SaT quality site
            </a>
          </span>
        </div>
        {templateList.length === 0 && <span className='error-text'>No templates available</span>}
      </div>
      <div className='cards-tooltip'>
        <span className='label-width'>
          <MotifLabel className='text'>! Untick if not applicable</MotifLabel>
        </span>
        <OverflowTooltip
          tooltipContent={
            'All folders and sub-folders are selected by default. Untick the boxes for any folders or sub-folders that are not applicable to your project, and they will not appear in your control file.'
          }
          placement='bottom'
          trigger='mouseenter'
          tooltipClassName='project-tooltip-placement'>
          <img className='tooltip-image' src={tooltip} alt='tooltip'></img>
        </OverflowTooltip>
      </div>
      <CheckboxTree data={templateStructure} onChange={onNodeChange} />
    </StyledFile>
  );
};

const StyledFile = styled.div`
  .inputBox {
    width: 330px;
    height: 44px;
    gap: 8px;
    border-radius: 4px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
  }
  .select-dropdown {
    display: flex;
  }
  .link {
    margin-left: 50px;
    font-family: EYInterstate !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #1a1a24;
    display: flex;
    align-items: center;
  }
  .warning-text {
    color: #b9251c;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  .warning {
    margin-bottom: 10px;
  }
  .tooltip-image {
    height: 30px;
    width: 30px;
    top: 40px;
    left: 3px;
  }
  .cards-tooltip {
    display: flex;
    position: relative;
    top: 5px;
  }
  .label-width {
    display: flex;
    margin-top: 4px;
  }
  .text {
    color: #b9251c;
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    display : contents;
  }
  .dropDown-title {
    font-family: EYInterstate !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px;
    text-align: left;
    color: #2e2e38;
  }
  .motif-tree-nodes {
    margin-top: 10px !important;
  }
  .motif-checkbox-checked-icon {
    background-color: black;
    color: #fff;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .url-link-font {
    color: #0f69ae;
  }
`;

export default TemplateProject;
