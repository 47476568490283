import styled from "styled-components"
import React from "react";
import { ITileData } from "../../../models/ITileData";
interface PaginationProps {
    numberOftiles: number;
    indexOfLastCard:number
    indexOfFirstCard:number;
    cardsPerView:number;
    currentCards: ITileData[];
    currentPage: number;
    totalPages: number;
  }

const Pagination: React.FC<PaginationProps> = ({ numberOftiles,indexOfFirstCard, indexOfLastCard, cardsPerView, currentCards, currentPage, totalPages}) => { 
    let visbleCardsLength =  cardsPerView;
    if(currentPage === totalPages - 1 && numberOftiles > 8) {
        visbleCardsLength = 8;
    } else if(currentPage === totalPages) {
        visbleCardsLength = numberOftiles;
    }
    return (
        <StylePaginationContainer >
            <div className='pagination'>
                showing {indexOfFirstCard + 1} - { indexOfLastCard < numberOftiles ? indexOfLastCard : numberOftiles } out of {numberOftiles} apps
            </div>
        </StylePaginationContainer>
    )
}

const StylePaginationContainer = styled.div`
`
export default Pagination;