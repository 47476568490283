import { createContext, useEffect, useState } from "react";
// import { paansConfig } from "../config/paansConfig";
import axios from "../services/axiosWithMsal";
import { deleteMethod, get, post } from '../services/api.service';
import documentApiRoutes from '../services/api';
import { customErrorStatus } from '../services/userProfile.service';
import errorStatus from '../utils/ErrorStatus';

export const DashboardContext = createContext();



const DashboardContextProvider = (props) => {
  let apiUrl = process.env.REACT_APP_API_URL;

  sessionStorage.setItem("Email", props.userEmail);
  const userEmail = props.userEmail;

  const [paans, setPaans] = useState([]);
  const [projects, setProjects] = useState([]);

  const [allProjects, setAllProjects] = useState([]);
  const [getAlldocuments, setDocuments] = useState([]);
  const [getDocumentSettings, setGetDocumentSettings] = useState([]);
  const [projectsAll, setProjectsAll] = useState([]);
  const [viewPrompt, setViewPrompt] = useState({});
  const [isNewPromptCoversation, setIsNewPromptCoversation] = useState(false);
  const [
    isDocumentUploadProcessInitiated,
    setIsDocumentUploadProcessInitiated,
  ] = useState(false);
  const [showDocumentUploadMessage, setShowDocumentUploadMessage] =
    useState(false);
  
  const GetDocuments = async (projectId) => {
    
  try {
    const data = await get(documentApiRoutes.getDocumentDetails+projectId);
    return setDocuments(data);
  } catch (error) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
  
  };

  const GetDocumentSettings = async () => {
    const data = await get(documentApiRoutes.getDocumentSetting)
    .catch((error) => {
      if (!error?.response?.status) return customErrorStatus(error?.message);
      return errorStatus(error?.response?.status);
    });
    return setGetDocumentSettings(data);
  };

  
  return (
    <DashboardContext.Provider
      value={{getAlldocuments,
        GetDocuments,
        getDocumentSettings,
        GetDocumentSettings,
        userEmail,
        isDocumentUploadProcessInitiated,
        setIsDocumentUploadProcessInitiated,
        showDocumentUploadMessage,
        setShowDocumentUploadMessage,
        viewPrompt,
        setViewPrompt,
        isNewPromptCoversation,
        setIsNewPromptCoversation,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
export default DashboardContextProvider;
