import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkboxes from './CheckBox';
import { setSurveyList } from '../../store/actions/userChatActions';
import { IStore } from '../../store';
import { isTeamsApp } from '../../utils/commonUtils';
import styled from 'styled-components';

type ISurveyCheckboxListProps = {
  toggleDropdown?: () => void;
  selectedContentAge?: string;
  options:
    | {
        value: string;
        label: string;
        children?: { value: string; label: string }[];
      }[]
    | string[];
  placeholder?: string;
  isSideBarOpen?: boolean;
  surveyLabel?: string;
  dropdownType?: string;
  surveyDocList? :any;
};

const SurveyCheckboxList = ({ options = [], isSideBarOpen, surveyDocList }: ISurveyCheckboxListProps) => {
  const [selected, setSelected] = React.useState<any>([]);
  const dispatch = useDispatch();
  const selectedSurveyList = localStorage.getItem('selectedSurveyList');
  const selectedSurveySectors = useSelector(
    (state: IStore) => state?.userChat?.selectedSurveySectors
  );
  const leftsidebar = useSelector((state: IStore) => state?.common?.isRightSideBarOpen);
  const [selectedsurveyclassName, setSelectedsurveyclassName] = useState<any>('');
  useEffect(() => {
  const sectorheight = (document.getElementsByClassName("surveycheckbox__selected")[0] as HTMLElement)?.offsetHeight || 0;
    setSelectedsurveyclassName("sectorsheight_" + sectorheight);
  }, [selectedSurveySectors]);
  const handleSelect = (value: any, name: any) => {
    if (value) {
      setSelected([...selected, name]); // add to selected
      localStorage.setItem('selectedSurveyList', JSON.stringify([...selected, name]));
    } else {
      setSelected(selected.filter((item: any) => item !== name)); // remove from selected
      localStorage.setItem(
        'selectedSurveyList',
        JSON.stringify(selected.filter((item: any) => item !== name))
      );
    }
  };

  const selectAll = (value: any) => {
    if (value) {
      const titles = options.map((item: any) => item.title);
      setSelected(titles); // select all
      localStorage.setItem('selectedSurveyList', JSON.stringify(titles));
    } else {
      setSelected([]); // unselect all
      localStorage.setItem('selectedSurveyList', JSON.stringify([]));
    }
  };

  useEffect(() => {
    dispatch(setSurveyList(selected));
  }, [selected]);

  useEffect(() => {
    if (selectedSurveyList && selectedSurveyList.length > 0) {
      setSelected(JSON.parse(selectedSurveyList));
    }
  }, []);

  return (
    <StyledSurveyCheckboxList isTeamsApp={isTeamsApp()}>
      <div className={`surveycheckboxlist ${leftsidebar?'surveychatbartrue':""}`}>
        <div
          className={`surveycheckboxlist__options ${isSideBarOpen ? "isSideBarOpen-width" : "isSideBarClose-width"}`}
          // style={{ width: isSideBarOpen ? 233 : 286 }}
        >
          {options && options.length > 0 && (
            <div className={`surveycheckboxlist__scroll-container surveychatbartrue__scroll-container  ${selectedSurveySectors && selectedSurveySectors.length>2?'sectorselected':""} ${selectedsurveyclassName}`}>
              <Checkboxes
                options={options}
                surveyDocList={surveyDocList}
                handleSelectedItems={handleSelect}
                handleSelectAll={selectAll}
                selections={selected}
              />
            </div>
          )}
        </div>
      </div>
    </StyledSurveyCheckboxList>
  );
};

const StyledSurveyCheckboxList = styled.div<{ isTeamsApp: boolean }>`
.surveycheckboxlist {
  position: relative;
  padding-top: 16px;
  border-bottom: 1px solid #e6e6e9;
  &__select {
    height: 44px;
    border-radius: 4px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 2px;
    border: 1px solid #c3c3cb;
    width: 286px;
  }
  &__options {
    background: var(--primary-ey-white-ffffff, #fff);

    position: absolute;
    z-index: 9999;
    color: #2e2e38;
    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 0;
    }
    // filter: drop-shadow(0px 0px 0px rgba(66, 66, 81, 0.25))
    //   drop-shadow(0px 1px 1px rgba(54, 55, 55, 0.5));
    label {
      padding: 8px 0px;
      display: table;
    }
    // label:first-child {
    //   border-bottom: 1px solid #e6e6e9;
    //   padding: 1px, 0px, 0px, 0px;
    // }
  }
  .surveycheckbox__options:has(.surveycheckbox__option-menu) {
    border: 1px solid #c3c3cb;
  }

  &__option-menu {
    margin-right: 8px;
  }
  &__option-menu:hover {
    background-color: #e7e7ea;
  }
  &__option-menu:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  &__footer {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 16px;
  }
  &__title {
    color: #2e2e38;
    font-size: 14px;
  }
  &__selected {
    display: flex;
    justify-content: flex-start;
    color: black;
    padding-top: 6px;
    flex-wrap: wrap;
    font-size: 12px;
    max-height: 185px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__selected::-webkit-scrollbar-thumb,&__selected::-webkit-scrollbar-thumb:hover{
    background-color: #D7D7DC;
  }
  &__selected::-webkit-scrollbar-track{
    box-shadow: none;
  }
  &__selected-items {
    border: 1px solid gray;
    border-radius: 16px;
    padding: 4px 16px;
    margin: 4px;
    display: flex;
  }
  &__scroll-container {
    max-height: 71vh!important;
    overflow-y: auto;
    @media screen and (min-width: 1800px) {
      max-height: 74vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 72vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 73vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 72vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 71vh!important;
    }
    @media screen and (max-width: 1517px) {
      max-height: 68vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 66vh!important;
    }
    @media screen and (max-width:1367px){
      max-height: 72vh!important;
    }
    @media screen and (max-width: 1360px) {
        max-height: 67vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: ${(props) => (props.isTeamsApp) ? '64vh' : '68vh'}!important;  
    }
    @media screen and (min-width:1241px) and (max-width: 1300px) {
      max-height: ${(props) => (props.isTeamsApp) ? '64vh' : '73vh'}!important; 
    }
   &.sectorselected{
    max-height: 37vh!important;
    @media screen and (min-width: 1800px) {
      max-height: 63vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 61vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1565px) {
      max-height: 57vh!important;
    }
    @media screen and (max-width: 1509px) {
      max-height: 55vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 56vh!important;
    }
    @media screen and (max-width: 1360px) {
      max-height: 52vh!important; 
  }
    @media screen and (max-width: 1357px) {
        max-height: 52vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: ${(props) => (props.isTeamsApp) ? '48vh' : '52vh'}!important;  
  }
  @media screen and (min-width:1241px) and (max-width: 1300px) {
    max-height: ${(props) => (props.isTeamsApp) ? '50vh' : '56vh'}!important;  
  }
   }
   &.sectorsheight_36,&.sectorsheight_37{
    @media screen and (min-width: 1800px) {
      max-height: 70vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 69vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 69vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 68vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 68vh!important;
    }
    @media screen and (max-width: 1565px) {
      max-height: 66vh!important;
    }
    @media screen and (max-width: 1517px) {
      max-height: 64vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 64vh!important;
    }
    @media screen and (max-width:1367px){
      max-height: 67vh!important;
    }
    @media screen and (max-width: 1360px) {
        max-height: 62vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: 63vh!important; 
    }
    @media screen and (min-width:1241px) and (max-width: 1300px) {
      max-height: 68vh!important; 
    }
   }
   &.sectorsheight_66, &.sectorsheight_67{
    @media screen and (min-width: 1800px) {
      max-height: 66vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 65vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 65vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 64vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 64vh!important;
    }
    @media screen and (max-width: 1565px) {
      max-height: 62vh!important;
    }
    @media screen and (max-width: 1517px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width:1367px){
      max-height: 62vh!important;
    }
    @media screen and (max-width: 1360px) {
        max-height: 58vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: 58vh!important; 
    }
    @media screen and (min-width:1241px) and (max-width: 1300px) {
      max-height: 63vh!important; 
    }
   }
   &.sectorsheight_96, &.sectorsheight_97{
    @media screen and (min-width: 1800px) {
      max-height: 63vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 61vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 61vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1565px) {
      max-height: 57vh!important;
    }
    @media screen and (max-width: 1517px) {
      max-height: 56vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 56vh!important;
    }
    @media screen and (max-width:1367px){
      max-height: 57vh!important;
    }
    @media screen and (max-width: 1360px) {
        max-height: 53vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: 53vh!important; 
    }
    @media screen and (min-width:1241px) and (max-width: 1300px) {
      max-height: 57vh!important; 
    }
   }
  }
  &__scroll-container::-webkit-scrollbar-thumb,&__scroll-container::-webkit-scrollbar-thumb:hover{
    background-color: #D7D7DC;
  }
  &__scroll-container::-webkit-scrollbar-track{
    box-shadow: none;
  }

  &__list-name {
    font-size: 14px;
    font-family: 'EYInterstate';
    font-weight: 400;
    line-height: 20px;
    color: #2e2e38;
  }
  &__text {
    width: 91%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: #2e2e38;
    font-size: 14px;
    font-family: eyinterstate;
  }
  &__text-cancel {
    width: 16px;
  }
  .surveycheckbox__input{
    display:table-cell;
    position: relative;
    top: 2px;
  }
}
.sidebar-surveychatpage .surveycheckboxlist__scroll-container.surveychatbartrue__scroll-container:not(.sectorselected , .sectorsheight_36, .sectorsheight_37, .sectorsheight_66, .sectorsheight_67, .sectorsheight_96, .sectorsheight_97){ 
  @media screen and (min-width: 1800px) {
    max-height: 73vh!important;
  }
  @media screen and (min-width: 1820px) {
    max-height: 70vh!important;
  }
  @media screen and (max-width: 1800px) {
    max-height: 69vh!important;
  }
  @media screen and (max-width: 1698px) {
    max-height: 71vh!important;
  }
  @media screen and (max-width: 1600px) {
    max-height: 65vh!important;
  }
  @media screen and (max-width: 1517px) {
    max-height: 68vh!important;
  }
  @media screen and (max-width: 1500px) {
    max-height: 61vh!important;
  }
  @media screen and (max-width:1367px){
    max-height: 67vh!important;
  }
  @media screen and (max-width: 1360px) {
      max-height: 71vh!important; 
  }
  @media screen and (max-width: 1300px) {
    max-height: 74vh!important; 
  }
  @media screen and (min-width:1241px) and (max-width: 1300px) {
    max-height: 70vh!important; 
  }
}

.isSideBarOpen-width {
  width: 233px !important;
}
.isSideBarClose-width {
  width: 286px !important;
}

.surveycheckboxlist.surveychatbartrue,.sidebar-surveychatpage{
.surveychatbartrue__scroll-container{
    max-height: 69vh!important;
    overflow-y: auto;
    @media screen and (min-width: 1800px) {
      max-height: 74vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 70vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 70vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 71vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 70vh!important;
    }
    @media screen and (max-width: 1517px) {
      max-height: 67vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 64vh!important;
    }
    @media screen and (max-width:1367px){
      max-height: 70vh!important;
    }
    @media screen and (max-width: 1360px) {
      max-height: 64vh!important; 
    }
    @media screen and (min-width:1241px) and (max-width: 1300px) {
      max-height: ${(props) => (props.isTeamsApp) ? '50vh' : '66vh'}!important; 
    }
    @media screen and (min-width:971px) and (max-width: 1240px) {
      max-height: ${(props) => (props.isTeamsApp) ? '56vh' : '66vh'}!important;  
    }
    @media screen and (min-width:850px) and (max-width: 970px) {
      max-height: ${(props) => (props.isTeamsApp) ? '50vh' : '66vh'}!important;  
    }
   &.sectorselected{
    @media screen and (min-width: 1800px) {
      max-height: 61vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 59vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 58vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 58vh!important;
    }
    @media screen and (max-width: 1565px) {
      max-height: 56vh!important;
    }
    @media screen and (max-width: 1509px) {
      max-height: 53vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 54vh!important;
    }
    @media screen and (max-width: 1360px) {
      max-height: 50vh!important; 
  }
    @media screen and (max-width: 1357px) {
        max-height: 50vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: ${(props) => (props.isTeamsApp) ? '40vh' : '50vh'}!important; 
  }
  @media screen and (min-width:1241px) and (max-width: 1300px) {
    max-height: ${(props) => (props.isTeamsApp) ? '58vh' : '54vh'}!important; 
  }
   }
   &.sectorsheight_36,&.sectorsheight_37{
    @media screen and (min-width: 1800px) {
      max-height: 68vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 67vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 65vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 66vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 66vh!important;
    }
    @media screen and (max-width: 1565px) {
      max-height: 66vh!important;
    }
    @media screen and (max-width: 1517px) {
      max-height: 62vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 62vh!important;
    }
    @media screen and (max-width:1367px){
      max-height: 65vh!important;
    }
    @media screen and (max-width: 1360px) {
        max-height: 60vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: 61vh!important; 
    }
    @media screen and (min-width:1241px) and (max-width: 1300px) {
      max-height: 66vh!important; 
    }
   }
   &.sectorsheight_66, &.sectorsheight_67{
    @media screen and (min-width: 1800px) {
      max-height: 64vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 63vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 62vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 62vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 62vh!important;
    }
    @media screen and (max-width: 1565px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1517px) {
      max-height: 58vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 58vh!important;
    }
    @media screen and (max-width:1367px){
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1360px) {
        max-height: 55vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: 56vh!important; 
    }
    @media screen and (min-width:1241px) and (max-width: 1300px) {
      max-height: 61vh!important; 
    }
   }
   &.sectorsheight_96, &.sectorsheight_97{
    @media screen and (min-width: 1800px) {
      max-height: 63vh!important;
    }
    @media screen and (min-width: 1820px) {
      max-height: 59vh!important;
    }
    @media screen and (max-width: 1800px) {
      max-height: 58vh!important;
    }
    @media screen and (max-width: 1698px) {
      max-height: 60vh!important;
    }
    @media screen and (max-width: 1600px) {
      max-height: 58vh!important;
    }
    @media screen and (max-width: 1565px) {
      max-height: 56vh!important;
    }
    @media screen and (max-width: 1517px) {
      max-height: 54vh!important;
    }
    @media screen and (max-width: 1500px) {
      max-height: 54vh!important;
    }
    @media screen and (max-width:1367px){
      max-height: 55vh!important;
    }
    @media screen and (max-width: 1360px) {
        max-height: 51vh!important; 
    }
    @media screen and (max-width: 1300px) {
      max-height: 51vh!important; 
    }
    @media screen and (min-width:1241px) and (max-width: 1300px) {
      max-height: 55vh!important; 
    }
   }
  }
}`

export default SurveyCheckboxList;
