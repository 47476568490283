import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifInput } from "@ey-xd/motif-react";
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React, { useState } from "react";
import information from "../../assets/images/byod-images/information.svg";
export const PromptData_ =
{
    promptid:String,
    convid:String,
    project_uuid:String,
    prompttype: String,
    title: String,
    tags: String,
    prompttext: String,
    IsFavOrSaved: String,
    Email: String
}
// const prompt = require("../../../data/model/prompt.js");
const prompt = PromptData_;
export function BadgePopup({ setOpenModal, promptdata = prompt.PromptData, setconfirm }) {
    const [open, setOpen] = useState(true);
    const [prompt, setPrompt] = useState(promptdata.prompttext);
    const [title, setTitle] = useState("");
    const [tag, setTag] = useState("");
    /**
   * Description
   * -----------
   * This method is to close popup once the user clicks cross button or cancel
   */
    const oncloseclick = () => {
        setOpen(false);
        setOpenModal(false);
    }
    /**
  * Description
  * -----------
  * This method is to get the prompt that user has given in popup
  * Parameters:
  * e: this one is onChange event parameter to get the input title
  */
    const onPromptChange = (e) => {
        setPrompt(e.target.value);
    };
    /**
    /**
   * Description
   * -----------
   * This method is to get the title that user has given in popup
   * Parameters:
   * e: this one is onChange event parameter to get the input title
   */
    const onTitleChange = (e) => {
        if (e.target.value.length <= 500) {
            setTitle(e.target.value);
        }
    };
    /**
   * Description
   * -----------
   * This method is to get the tag that user has given in popup
   * Parameters:
   * e: this one is onChange event parameter to get the input tag
   */
    const onTagChange = (e) => {
        setTag(e.target.value);
    };
    /**
   * Description
   * -----------
   * This method is to close popup and insert data into database once the user clicks confirm button
   */
    const onconfirmclick = () => {
        promptdata.prompttext = prompt;
        promptdata.title = title;
        promptdata.tags = tag;
        setconfirm(promptdata);
        setOpen(false);
        setOpenModal(false);
    }


    return (

        <div >
            <MotifModal id="badgepopupmodal" show={open} focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'legacy-full'
                }
            }}
                onClose={() => { oncloseclick() }}
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Custom Close Button aria-label',
                        title: 'Custom Close Button title'
                    }}
                    className="add-prompt-modal-header"
                >
                    Add to Personal Prompt Library
                </MotifModalHeader>
                <MotifModalBody>
                    <React.Fragment>
                        {/* <div>
                            <span className='spantext'>
                                <img src={information} />
                                Please ensure client information is not included in the prompt below.
                            </span>
                        </div> */}

                        <div className="aligned">
                        <img src={information} />
                                <span className='spaninfo'>Please ensure confidential information is not included in the prompt below.</span>
                        </div>

                        <div id="divspace">
                            <span >
                                Prompt
                            </span>
                        </div>
                        <div id="divspace">
                            <span>
                                {/* "{promptdata.prompttext}" */}
                                <MotifInput
                                    label="inputprompt"
                                    onChange={onPromptChange}
                                    type='text'
                                    value={prompt}
                                />
                            </span>
                        </div>
                        <div id="divspace">
                            <span >
                                Title
                            </span>
                        </div>
                        <div id="divspace">
                            <span >
                                <MotifInput
                                    label="inputtitle"
                                    onChange={onTitleChange}
                                    type='text'
                                    // required
                                    value={title}
                                />
                            </span>
                        </div>
                        {/* <div id="divspace">
                            <span >
                                Tag
                            </span>
                        </div>
                        <div id="divspace">
                            <span >
                                <MotifInput
                                    label="inputtag"
                                    onChange={onTagChange}
                                    type='text'
                                    // required
                                    value={tag}
                                />
                            </span>
                        </div> */}
                    </React.Fragment>
                </MotifModalBody>
                <MotifModalFooter>
                    <React.Fragment >
                        <div className="BadgeFooterButtonContent" >
                            <div class="EditProjectEditTitle" >
                                <div class="EditProjectEditTextAndSupportingText" >
                                    <div class="EditProjectProjectHeaderText" >
                                    </div>
                                </div>
                            </div>
                            <div class="EditProjectEditActions" >
                                <div class="EditProjectEditCancelButton" >
                                    <div class="EditProjectEditCancelButtonBase" >
                                        <div class="EditProjectEditCancelText" >
                                            <MotifButton
                                                className="CancelButton"
                                                onClick={() => { oncloseclick() }}
                                            >
                                                Cancel
                                            </MotifButton>
                                        </div>
                                    </div>
                                </div>

                                <div class="EditProjectEditSaveButton" >
                                    <div class="EditProjectEditSaveButtonBase" >
                                        <div >
                                            <MotifButton
                                                onClick={() => { onconfirmclick() }}
                                            >
                                                Save

                                            </MotifButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>

                </MotifModalFooter>

            </MotifModal>
        </div >
    )
}

export default BadgePopup;