import React, { Fragment } from "react";
import styled from "styled-components";
import { ModalType } from "../../../models/ModalType";
import scssVar from "../../../styles/variables.module.scss";
import Checkbox from "../Checkbox";
import LoadingIndicator from "../../openAIChat/LoadingIndicator";
const {  alternateblack, yellow, white} = scssVar;
interface IOwnProps {
  type: ModalType,
  actionBtnEnabled: boolean;
  actionBtnText: string;
  onActionBtnClicked?: () => void;
  onCloseBtnClicked: () => void;
  isLoading?: boolean;
  onChangeCheckBox?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
}

const PolicyFooter: React.FC<IOwnProps> = ({
  type,
  actionBtnEnabled,
  actionBtnText,
  onActionBtnClicked,
  onCloseBtnClicked,
  isLoading,
  onChangeCheckBox,
}: IOwnProps) => {
  const typeToLower = ModalType[type].toLowerCase();
  const  getTriggerBtnClass = () => {
    switch (type) {
      case ModalType.Privacy:
        return "yellow-btn";
      case ModalType.Client:
          return "yellow-btn";
      default:
        return "yellow-btn";
    }
  };
  return (
    <StyledModalFooter className={`${typeToLower}-modal`}>
      {isLoading? <span className="loader"><LoadingIndicator /></span>:
      <Fragment>
      <div className="statement-main">
        { typeToLower !== "client" &&
        <Checkbox
          text={
            (typeToLower === "privacy")
              ? "I agree to the privacy statement"
              : "I agree to the Terms of Use"
          }
          checked={actionBtnEnabled}
          key={
            typeToLower === "privacy"
              ? "I agree to the privacy statement"
              : "I agree to the Terms of Use"
          }
          name="agree" //@ts-ignore
          onChange={onChangeCheckBox}
        />
      }
        
      </div>
      {type === ModalType.Error && (
        <div>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <button onClick={onCloseBtnClicked} className="btn secondary-btn">
              {actionBtnText}
            </button>
          )}
        </div>
      )}

      {type === ModalType.Privacy && (
        <Fragment>
          <button
            className={`btn ${getTriggerBtnClass()}`}
            disabled={!actionBtnEnabled}
            onClick={onActionBtnClicked}
          >
            {actionBtnText}
          </button>
        
        </Fragment>
      )}
      {type === ModalType.Terms && (
        <Fragment>
          <button
            className={`btn ${getTriggerBtnClass()}`}
            disabled={!actionBtnEnabled}
            onClick={onActionBtnClicked}
          >
            {actionBtnText}
          </button>
          </Fragment>
      )}
      {type === ModalType.Client && (
        <Fragment>
          <button
            className={`btn ${getTriggerBtnClass()}`}
            disabled={actionBtnEnabled}
            onClick={onActionBtnClicked}
          >
            {actionBtnText}
          </button>
          </Fragment>
      )}
      </Fragment>
}
    </StyledModalFooter>
  );
};

const StyledModalFooter = styled.div`
  border-top: 1px solid ${scssVar.gray40};
  padding: 14px;
   .loader {
    align-items: center;
    display: flex;
    justify-content: center;
   } 
  .yellow-btn,
  .secondary-btn {
    font-weight: 700;
    font-size: 14px;
  }
  &.error-modal {
    justify-content: center;
  }
  &.submit-modal,
  &.confirmation-modal {
    justify-content: space-between;
  }
  & .statement-main {
    margin-top: 0.3rem;
    & label {
      width: max-content;
    }
    & .bg-header-gray {
      background-color: ${alternateblack} !important;
    }
    & .checkmark_checked {
      background-color: ${yellow} !important;
      border: none  !important;
    }
    & .checkmark {
      width: 18px;
      height: 18px;
      border: 1.4px solid ${white};
      border-radius: 2px;
    }
    & .se-statement {
      font-family: ${scssVar.fontInterstate};
      font-size: 14px;
      line-height: 43px;
      align-items: center;
      color: ${scssVar.gray2};
    }
    & .styled-checkbox {
      position: absolute;
      opacity: 0;

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }
      & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 18px;
        height: 18px;
        background: ${scssVar.alternateblack};
        border: 1px solid;
        border-radius: 2px;
      }
      &:checked + label:before {
        background: ${scssVar.yellow};
      }
      &:checked + label:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 9px;
        background: ${scssVar.yellow};
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 ${scssVar.alternateblack},
          4px 0 0 ${scssVar.alternateblack},
          4px -2px 0 ${scssVar.alternateblack},
          4px -4px 0 ${scssVar.alternateblack},
          4px -6px 0 ${scssVar.alternateblack},
          4px -8px 0 ${scssVar.alternateblack};
        transform: rotate(45deg);
      }
    }
  }
`;

export default PolicyFooter;
