import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { faChevronUp, faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SectorGeographyHierarchy.scss';
import isEqual from 'lodash/isEqual'; // Import lodash for deep comparison

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { includeParentsIfAllChildrenChecked } from '../../utils/commonUtils';
const iconClass = 'icon';
const SectorGeographyHierarchy: React.FC<any> = ({
  treeNode,
  setCheckBoxValue,
  setClearFilter,
  disabled = false,
  hasDefaultChecks = false,
  defaultExpanded = []
}: any) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>(defaultExpanded);
  const [totalchecked, setTotalchecked] = useState<string[]>([]);

  const extractAllNodes = (allNodes: any) => {
    let childrenArray: Array<any> = [];
    allNodes &&
      allNodes.children &&
      allNodes.children.map((currentEntity: any) => {
        if (currentEntity.children) {
          const recursiveChildren: Array<string> =
            extractAllCheckedChildrenRecursive(currentEntity);
          childrenArray = [...childrenArray, ...recursiveChildren];
        }
        childrenArray = [...childrenArray, currentEntity.value];
      });
    childrenArray.push(allNodes.value);
    return childrenArray;
  };

  const extractAllCheckedChildrenRecursive = (entity: any): Array<string> => {
    let allChildren: Array<any> = [];
    const children: Array<any> = entity.children;
    children &&
      children.map((currentEntity: any) => {
        if (currentEntity.children) {
          const recursiveChildren: Array<any> = extractAllCheckedChildrenRecursive(currentEntity);
          allChildren = [...allChildren, ...recursiveChildren];
        }
        allChildren.push(currentEntity.value);
      });
    return allChildren;
  };

  /***Old handleCheck Code***/
  // const handleCheck = (checked: any, targetNodes: any) => {
  //   let allValues: string[] = extractAllNodes(targetNodes);
  //   if (!(JSON.stringify(checked.sort()) === JSON.stringify(totalchecked.sort()))) {
  //     let allCheckedValues = totalchecked;
  //     let dupval = totalchecked.filter((element) => checked.includes(element));
  //     if (dupval.length > 0) {
  //       setTotalchecked(allCheckedValues.filter((element) => !dupval.includes(element)));
  //     } else {
  //       allCheckedValues.push(...checked);
  //       setTotalchecked(allCheckedValues);
  //     }
  //   }
  //   var newArray: string[] = totalchecked;
  //   if (targetNodes.checked) {
  //     newArray.push(...allValues);
  //   } else {
  //     newArray = newArray.filter((element) => !allValues.includes(element));
  //   }
  //   let uniqueArray = newArray.filter((value, index, self) => {
  //     return self.indexOf(value) === index;
  //   });
  //   // if(targetNodes?.parent?.children && targetNodes?.parent?.children?.length === checked?.length){
  //   //   let parentValue = targetNodes?.parent?.value;
  //   //   if(parentValue){
  //   //     uniqueArray.push(parentValue);
  //   //   }
  //   // }
  //   let allCheckedNodesIncludingParents = getAllCheckedNodesIncludingParents(checked, treeNode);
  //   console.log("allCheckedNodesIncludingParents", allCheckedNodesIncludingParents);

  //   setTotalchecked(uniqueArray);
  //   setCheckBoxValue(uniqueArray);
  //   setChecked(uniqueArray);
  // };

  /***New handleCheck Code***/
  const handleCheck = (checked: any) => {
    const allCheckedIncludingParents: any = includeParentsIfAllChildrenChecked(checked, treeNode);
    setTotalchecked(allCheckedIncludingParents);
    setCheckBoxValue(allCheckedIncludingParents);
    setChecked(checked);
  };

  const handleExpand = (expanded: any) => {
    setExpanded(expanded);
  };

  const extractCheckedValues = (nodes: any): string[] => {
    let checkedValues: string[] = [];

    nodes.forEach((node: any) => {
      if (node.checked) {
        checkedValues.push(node.value);
      }
      if (node.children) {
        checkedValues = [...checkedValues, ...extractCheckedValues(node.children)];
      }
    });
    return checkedValues;
  };

  useEffect(() => {
    if (hasDefaultChecks) {
      const checkedValues = extractCheckedValues(treeNode);
      // Only update state if checkedValues have changed
      if (!isEqual(checkedValues, checked)) {
        setChecked(checkedValues);
        setCheckBoxValue(checkedValues);
        setTotalchecked(checkedValues);
      }
    }
  }, [treeNode, checked, setCheckBoxValue, setTotalchecked]);

  useEffect(() => {
    setChecked([]);
    setCheckBoxValue([]);
    setTotalchecked([]);
  }, [setClearFilter]);

  return (
    <>
      <div>
        <div className='sectorgeography'>
          <CheckboxTree
            icons={{
              expandClose: (
                <FontAwesomeIcon className={iconClass} icon={faChevronDown as IconProp} />
              ),
              expandOpen: <FontAwesomeIcon className={iconClass} icon={faChevronUp as IconProp} />,
              check: (
                <FontAwesomeIcon className='rct-icon rct-icon-check' icon={faCheck as IconProp} />
              )
            }}
            iconsClass='fa5'
            nodes={treeNode}
            checked={checked}
            expanded={expanded}
            disabled={disabled}
            expandDisabled={disabled}
            onCheck={handleCheck}
            onExpand={handleExpand}
          />
        </div>
      </div>
    </>
  );
};

export default SectorGeographyHierarchy;
