import React, { useEffect, useRef, useState } from 'react';
import './OptionsButton.scss';

type OptionsButtonProps = {
  options: { onClick: any; label: string; display?: boolean }[];
};

const OptionsButton = ({ options }: OptionsButtonProps) => {
  const [openOptionsList, setOpenOptionsList] = useState(false);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
        setOpenOptionsList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={optionsRef}>
      <button onClick={() => setOpenOptionsList((prev) => !prev)} className='options_wrapper'>
        <span className='options_wrapper__dots'>...</span>
        {openOptionsList && (
          <div className='options_wrapper__list'>
            {options?.map(
              ({ onClick, label, display }: { onClick: any; label: string; display?: boolean }) =>
                display ? (
                  <button key={label} onClick={onClick}>
                    {label}
                  </button>
                ) : null
            )}
          </div>
        )}
      </button>
    </div>
  );
};

export default OptionsButton;
