import {
  IQuery,
  IQueryContent,
  IRelevantFilesEngagement,
  RESPONSE_BY
} from '../../../models/IQueryType';
import {
  BorderStyle,
  Document,
  ImageRun,
  Packer,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  WidthType,
  VerticalAlign,
  HeightRule
} from 'docx';
import USER_IMG from '../../../assets/images/user.svg';
import OPENAI_IMG from '../../../assets/images/KC.png';
import KC_ICON from '../../../assets/images/KC.png';

import { downloadFilenameGenerator, sanitizeHeaderText } from '.';
import LABELS from '../../../ui-constants/en.json';
import AppConst from '../../../ui-constants/appConstants.json';
const IMAGES = {
  [RESPONSE_BY.USER]: AppConst.AVATAR_IMAGE,
  [RESPONSE_BY.STRATEGY_EDGE]: OPENAI_IMG
};

const noBorder = {
  style: BorderStyle.NONE,
  size: 0,
  color: 'ff0000'
};
const singleBorder = {
  style: BorderStyle.SINGLE,
  size: 1,
  color: 'e8e8ee'
};
const noBorders = {
  top: noBorder,
  bottom: noBorder,
  left: noBorder,
  right: noBorder
};

const tableCellStyling = {
  borders: {
    top: singleBorder,
    bottom: singleBorder,
    left: singleBorder,
    right: singleBorder
  },
  shading: {
    color: 'f7f7f8',
    fill: '000000',
    type: ShadingType.SOLID
  },
  margins: {
    top: 10,
    bottom: 10,
    right: 10,
    left: 20,
    marginUnitType: WidthType.PERCENTAGE
  }
};
const emptyRow = new TableRow({
  children: [
    new TableCell({
      borders: noBorders,
      children: [new Paragraph('')]
    })
  ]
});
const tableCellRenderer = (data: any, isGeo: boolean, maxWidth?: boolean) => {
  return data?.map((namerol: string) => {
    return new TableRow({
      children: [
        new TableCell({
          ...tableCellStyling,
          borders: isGeo ? noBorders : tableCellStyling.borders,
          width: {
            size: maxWidth ? 4000 : 2000,
            type: WidthType.DXA
          },

          children: [
            new Paragraph({
              text: namerol || ''
            })
          ],
          verticalAlign: VerticalAlign.CENTER
        })
      ],

      height: {
        value: `${40}pt`,
        rule: 'exact'
      }
    });
  });
};

const getList = (data: any, maxWidth?: boolean, isGeo = false) => {
  if (Array.isArray(data) && data.length > 0) {
    return tableCellRenderer(data, isGeo, maxWidth);
  }
};

const generateTable = (tableContent: IRelevantFilesEngagement[] | null) => {
  if (Array.isArray(tableContent) && tableContent?.length) {
    const headersCells: TableCell[] = [];
    const valueRows: TableRow[] = [];

    headersCells.push(
      new TableCell({
        ...tableCellStyling,
        children: [
          new Paragraph({
            text: sanitizeHeaderText('Name(s)'),
            style: 'tableHeader',
            spacing: {
              after: 100
            }
          })
        ]
      }),
      new TableCell({
        ...tableCellStyling,
        children: [
          new Paragraph({
            text: sanitizeHeaderText('Role(s)'),
            style: 'tableHeader',
            spacing: {
              after: 100
            }
          })
        ]
      }),
      new TableCell({
        ...tableCellStyling,
        children: [
          new Paragraph({
            text: sanitizeHeaderText('Project type'),
            style: 'tableHeader',
            spacing: {
              after: 100
            }
          })
        ]
      }),
      new TableCell({
        ...tableCellStyling,
        children: [
          new Paragraph({
            text: sanitizeHeaderText('Project end date'),
            style: 'tableHeader',
            spacing: {
              after: 100
            }
          })
        ]
      }),
      new TableCell({
        ...tableCellStyling,
        children: [
          new Paragraph({
            text: sanitizeHeaderText('Geographic focus of work'),
            style: 'tableHeader',
            spacing: {
              after: 100
            }
          })
        ]
      })
    );

    tableContent.forEach((data: IRelevantFilesEngagement) => {
      const cells: TableCell[] = [];
      try {
        cells.push(
          new TableCell({
            ...tableCellStyling,
            children: [
              new Table({
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE
                },

                layout: TableLayoutType.AUTOFIT,
                rows: getList(
                  data && data.Authors.map((el: { author_name: string }) => el.author_name || ''),
                  true
                ) ?? [emptyRow]
              })
            ]
          })
        );

        cells.push(
          new TableCell({
            ...tableCellStyling,
            children: [
              new Table({
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE
                },
                layout: TableLayoutType.AUTOFIT,
                rows: getList(
                  data && data.Authors.map((el: { role: string }) => el.role || ''),
                  true
                ) ?? [emptyRow]
              })
            ]
          })
        );
        cells.push(
          new TableCell({
            ...tableCellStyling,
            children: [new Paragraph({ text: data.Project_type })],
            verticalAlign: VerticalAlign.CENTER
          })
        );
        if (data && data.Project_End_Date && !isNaN(new Date(data.Project_End_Date).getTime())) {
          cells.push(
            new TableCell({
              ...tableCellStyling,
              width: {
                size: 1500,
                type: WidthType.DXA
              },
              children: [
                new Paragraph({
                  text: new Date(data.Project_End_Date)
                    .toLocaleString('en-GB', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric'
                    })
                    .split(' ')
                    .join(' '),
                    alignment: 'center'
                })
              ],
              verticalAlign: VerticalAlign.CENTER,
               
            })
          );
        }

        cells.push(
          new TableCell({
            ...tableCellStyling,
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Table({
                width: {
                  size: 100,
                  type: WidthType.PERCENTAGE
                },

                layout: TableLayoutType.AUTOFIT,
                rows: getList(
                  data && data?.Geography ? data?.Geography?.map((el: string) => el) : [],
                  false,
                  true
                ) ?? [emptyRow]
              })
            ]
          })
        );
      } catch (error) {
        console.error(error);
      }

      valueRows.push(
        new TableRow({
          children: cells
        })
      );
    });

    return new Table({
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      },
      layout: TableLayoutType.AUTOFIT,
      rows: [
        new TableRow({
          tableHeader: true,
          children: headersCells
        }),
        ...valueRows
      ]
    });
  } else {
    return new Table({
      rows: [emptyRow]
    });
  }
};

const generateDocument = (chat: any[], respImg?: any) => {
  const paragraphs: any = [];
  Array.isArray(chat) &&
    chat.forEach((chat) => {
      const { SEResponse, user } = chat;
      const key = Object.keys(chat)[0];
      const img = AppConst.COMMON_DATASOURCE_IMG;
      const values: any = chat[key];
      let tableContent = [];
      if (
        SEResponse && // Add type guard to ensure SEResponse is not null or undefined
        SEResponse?.relevantFiles &&
        SEResponse?.relevantFiles.every((el: any) => Boolean(el) && typeof el === 'object')
      ) {
        tableContent = SEResponse?.relevantFiles?.map((el: IRelevantFilesEngagement) => {
          const obj: IRelevantFilesEngagement = {
            Geography: el?.Geography,
            Project_End_Date: el?.Project_End_Date,
            Project_type: el?.Project_type,
            Authors: el?.Authors.filter((author: any, i: Number) => {
              if (author.author_name) return author;
            })
          };
          return obj;
        });
      }

      if (user) {
        paragraphs.push(
          new TableRow({
            children: [
              new TableCell({
                borders: noBorders,
                margins: {
                  right: 200
                },
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: respImg ? respImg : IMAGES[RESPONSE_BY.USER],
                        transformation: {
                          width: 30,
                          height: 30
                        }
                      })
                    ]
                  })
                ]
              }),

              new TableCell({
                borders: noBorders,
                children: [
                  new Paragraph(user.summary?.replace(/\n\r+$/, '').trim()),
                  new Paragraph('\n')
                ]
              })
            ]
          })
        );
      }
      if (SEResponse) {
        paragraphs.push(
          new TableRow({
            children: [
              new TableCell({
                borders: noBorders,
                margins: {
                  right: 200
                },
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: img,
                        transformation: {
                          width: 30,
                          height: 30
                        }
                      })
                    ]
                  })
                ]
              }),

              new TableCell({
                borders: noBorders,
                children:
                  tableContent && tableContent.length > 0
                    ? [
                        new Paragraph(
                          tableContent.length > 0
                            ? 'Here is the list of experts:'
                            : LABELS.chat_reply.SE_ERROR_MSG_ENGAGEMENT
                        ),
                        new Paragraph('\n'),
                        generateTable(tableContent),
                        new Paragraph('\n'),
                        new Paragraph('\nSource: Discover Knowledge Capture'),
                        new Paragraph('\n')
                      ]
                    : [
                        new Paragraph(
                          tableContent.length > 0
                            ? 'Here is the list of experts:'
                            : LABELS.chat_reply.SE_ERROR_MSG_ENGAGEMENT
                        ),
                        new Paragraph('\n'),
                        new Paragraph('\nSource: Discover Knowledge Capture'),
                        new Paragraph('\n')
                      ]
              })
            ]
          })
        );
      }
    });
  const doc = new Document({
    styles: {
      default: {
        document: {
          run: {
            size: '10pt',
            font: 'Arial'
          }
        }
      },
      paragraphStyles: [
        {
          id: 'tableHeader',
          run: {
            bold: true
          }
        },
        {
          id: 'summary',
          quickFormat: true
        }
      ]
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              right: 940, 
              left: 940 
            }
          }
        },
        children: [
          new Table({
            rows: paragraphs,
            width: {
              size: 10000, // Width in twips (1/20th of a point)
              type: WidthType.DXA,
            },
          })
        ]
      }
    ]
  });
  return doc;
};

const exportEngagementChatToWord = async (data: IQuery[], respImg?: any) => {
  const clonedData = structuredClone(data);
  const doc = await generateDocument(clonedData, respImg);
  const blob = await Packer.toBlob(doc);
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = downloadFilenameGenerator(RESPONSE_BY.EXPERTS, 'docx');
  link.click();
};

export default exportEngagementChatToWord;
