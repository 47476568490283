import axios, {
  type AxiosResponse,
  type AxiosRequestConfig,
  type AxiosInstance,
  type CancelToken,
  type AxiosError
} from 'axios';
import { deleteMethod, get, post } from './api.service';
import documentApiRoutes from './api';
import { customErrorStatus } from './userProfile.service';
import errorStatus from '../utils/ErrorStatus';
import { getCurrentSessionId } from './session.service';
import { getAuthorizationToken, getSharePointTeamsToken, isTeamsApp } from '../utils/commonUtils';
import apiRoutes from './api';
import { handleFetchException } from './chatQuery.service';
import CommonService from "./common.service";
import { HttpStatusCode } from '../ui-constants/base/enum';
type IUserData = {
  displayName: string;
  email: string;
  isExternal: boolean;
  profileImage: string;
  sid: { preferredName: string; rawIdToken?: string };
  rawIdToken?: string;
};
class DocumentService {
  constructor() {
  }
  commonService = new CommonService();
  async createProject(userMailID: string, category: string, projectName: string) {

    const data = await post(documentApiRoutes.createProject, {
      adminId: userMailID,
      category: category,
      projectName: projectName,
      editFlag: 'N'
    }).catch((error) => {
      this.commonService.logException(
        error,
        "Upload Document",
        "createProject",
        null,
        projectName,
        userMailID
      );
    });
    return data;

  };

  async getDocuments(projectId: any, title: any, userMailID: any) {
    const data = await get(documentApiRoutes.getDocumentDetails + projectId).catch((error) => {
      this.commonService.logException(
        error,
        "Upload Document",
        "Get Documents",
        projectId,
        title,
        userMailID
      );
    });
    return data;
  }
  async getDocumentSettings(projectId: any, title: any, userMailID: any) {
    const data = await get(documentApiRoutes.getDocumentSetting)
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Get Document Settings",
          projectId,
          title,
          userMailID

        );
      });
    return data;

  }
  async UploadDocumentToSharePoint(sharepointDocuments: any, ProjectID: any, title: any, userMailID: any, userData: any) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'session-id': getCurrentSessionId() || '',
        'jwt-token': userData?.rawIdToken || '',
        'user-email': userData?.displayName || '',
        'user-id': userData?.sid?.preferredName || '',
        Authorization: `Bearer ${await getAuthorizationToken()}`,
        'IsMSTeamsClient': isTeamsApp() ? 'true' : 'false'
      },
      body: JSON.stringify(sharepointDocuments)
    }
    const uploadurl: any = documentApiRoutes.uploadSharePointDocument + "sp-document";

    return fetch(uploadurl, { ...requestOptions }).catch((error) => {
      this.commonService.logException(
        error,
        "Upload Document",
        "Upload Local Document",
        ProjectID,
        title,
        userMailID
      );
      return {
        status: 500,
      };
    });
  }

  async uploadLocalDocuments(files: any, ProjectID: any, title: any, userMailID: any, userData: any) {
    const formData = new FormData();
    for (const f of files) {
      formData.append(`files`, f);
    }
    const uploadurl: any = documentApiRoutes.uploadDocument + "?P_Blob_Container_Name=" + ProjectID + "&P_UploadUserName=ey";

    return post(uploadurl, formData)
      .then(response => {
        if (Object.keys(response).length === 0) {
          return {
            status: 200,
          };
        }
        return response.json();
      })
      .catch(error => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Upload Local Document",
          ProjectID,
          title,
          userMailID
        );
        return {
          status: 500,
        };
      });
  }

  async deleteDocument(projectId: any, documentUUID: any, documentName: any, title: any, userMailID: any, userData: IUserData) {
    const deleteDocumentRequest = {
      project_UUID: projectId,
      document_UUID: documentUUID,
      documentName: documentName,
    };

    const requestOptions = {
      method: 'DELETE',
      body: JSON.stringify({
        project_UUID: projectId,
        document_UUID: documentUUID,
        documentName: documentName,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAuthorizationToken()}`,
        'IsMSTeamsClient': isTeamsApp() ? 'true' : 'false'
      }
    };
    return fetch(documentApiRoutes.deleteDocument, { ...requestOptions })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Upload Document",
          "Delete Document",
          projectId,
          title,
          userMailID
        );
      });
  }
}
export default DocumentService;
