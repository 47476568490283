import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import Modal from '../../common/modal/Modal';
import sassVar from '../../../styles/variables.module.scss';
import { ModalType } from '../../../models/ModalType';
import LoadIndicator from '../loadIndicator/LoadIndicator';
import { useDispatch } from 'react-redux';
import { resetUserRole } from '../../../store/actions/userRolesActions';
import { authProvider } from '../../../services/msalConfig';
import { signUserOut } from '../../../services/userProfile.service';
import { logoutModalIcon } from '../../../commonImage';

const { lightgray, fontInterstate, logoutModalWidth , grey50} = sassVar;
interface IOwnProps {
  show: boolean;
  onClose: () => void;
  headerIcon: React.ReactChild;
}

const LogoutModal: React.FC<IOwnProps> = ({ show, onClose, headerIcon }: IOwnProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const onLogout = (): void => {
    dispatch(resetUserRole());
    setIsLoading(true);
    signUserOut()
      .then(() => {
        authProvider.logout();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      isOpen={show}
      type={ModalType.AdvancedSearch}
      headerTitle='Logout'
      actionBtnEnabled={!isLoading}
      actionBtnText='Log Out'
      onActionBtnClicked={onLogout}
      onCloseBtnClicked={onClose}
      headerIcon={headerIcon}
      showHeaderIcon={true}
      width={+logoutModalWidth}>
      <StyledLogoutModal className='noto-sans center'>
        {isLoading ? (
            <div className='loader'>
          <LoadIndicator color={"#000000"} />
          </div>
        ) : (
          <Fragment>
            <div className='ie-logout'>
              <div className='confirmation-text'>Are you sure you want to logout?</div>
            </div>
          </Fragment>
        )}
      </StyledLogoutModal>
    </Modal>
  );
};

const StyledLogoutModal = styled.div`
  padding: 20px 0;
  color: ${lightgray};
  .loader{
    position:relative;
    bottom:30px;
  }
  .ie-logout {
    margin: 15px 0px 15px 5px;
    text-align: left;
  }
  & .text-center {
    text-align: center;
  }
  .icon-i-sign-out {
    display: inline;
    font-size: 3.4375rem;
    margin-left: 2px;
  }
  .confirmation-text {
    margin-top: 1.375rem;
    margin-bottom: 1.875rem;
    letter-spacing: 0px;
    display: inline;
    vertical-align: 40px;
    margin-left: 14px;
    font-family: EYInterstate, "Segoe UI", sans-serif;
    font-size: 1.125rem;
    line-height: 22px;
    color:${grey50};
    position: relative;
    top: -20px;

  }
`;

export default React.memo(LogoutModal);
