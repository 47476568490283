import { type AnyAction } from 'redux';
import LABELS from '../../ui-constants/en.json';
import { IRelevantFiles } from '../../models/IQueryType';

export const SELECTED_DATA_SOURCE_SECTION = 'SELECTED_DATA_SOURCE_SECTION';
export const SELECTED_DATA_SOURCE = 'SELECTED_DATA_SOURCE';
export const SET_PROMPT_INPROGRESS = 'SET_PROMPT_INPROGRESS';
export const SAVE_USER_CHAT_THREAD_HISTORY = 'SAVE_USER_CHAT_THREAD_HISTORY';
export const SET_SELECTED_CHAT_THREAD = 'SET_SELECTED_CHAT_THREAD';
export const SAVE_USER_DOCUMENT_THREAD_HISTORY = 'SAVE_USER_DOCUMENT_THREAD_HISTORY';
export const SET_SELECTED_DOCUMENT_THREAD = 'SET_SELECTED_DOCUMENT_THREAD';
export const SET_CHAT_HISTORY = 'SET_CHAT_HISTORY';
export const GET_CHAT_HISTORY = 'GET_CHAT_HISTORY';
export const CLEAR_CHAT_HISTORY = 'CLEAR_CHAT_HISTORY';
export const TOGGLE_GPT_SIDEBAR = 'TOGGLE_GPT_SIDEBAR';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';
export const TOOBLE_CLEAR_LOADER = 'TOOBLE_CLEAR_LOADER';
export const SET_DOWNLOAD_FILE_MESSAGE = 'SET_DOWNLOAD_FILE_MESSAGE';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SHOW_GPT_BUTTON = 'SHOW_GPT_BUTTON';
export const SET_CHAT_SUMMARY = 'SET_CHAT_SUMMARY';
export const SET_CHAT_SOURCES = 'SET_CHAT_SOURCES';
export const SET_CLEAR_SUMMARY = 'SET_CLEAR_SUMMARY';
export const SET_CHAT_TABLE = 'SET_CHAT_TABLE';
export const SET_WHOLE_CHAT_TABLE = 'SET_WHOLE_CHAT_TABLE';
export const SET_CHUNK_DATA_LOADING = 'SET_CHUNK_DATA_LOADING';
export const SET_CHAT_ERRORS = 'SET_CHAT_ERRORS';
export const SET_RELEVANT_FILES = 'SET_RELEVANT_FILES';
export const IS_ACTIVE_TAB = "IS_ACTIVE_TAB";
export const SET_SECTOR_CHUNK_DATA_LOADING = "SET_SECTOR_CHUNK_DATA_LOADING";
export const LOAD_CHAT_MESSAGE_HISTORY = "LOAD_CHAT_MESSAGE_HISTORY";
export const REGENERATE_CHAT_SUMMARY = "REGENERATE_CHAT_SUMMARY";
export const SET_APPLY_CLICK = "SET_APPLY_CLICK";
export const SET_USER_FIRST_QUERY = "SET_USER_FIRST_QUERY";
export const SET_IS_ANY_SOURCE_SELECTED = "SET_IS_ANY_SOURCE_SELECTED";
export const SET_LAST_SAVED_THREAD_ID = "SET_LAST_SAVED_THREAD_ID";
export const SET_IS_CHAT_THREAD_LOADED = "SET_IS_CHAT_THREAD_LOADED";
export const SET_SELECTED_SURVEY_LIST = 'SET_SELECTED_SURVEY_LIST';
export const SET_SELECTED_SURVEY_SECTORS = 'SET_SELECTED_SURVEY_SECTORS';
export const SET_SURVEY_DOC_LISTS = 'SET_SURVEY_DOC_LISTS';
export const SET_SURVEY_FILTERED_LISTS = 'SET_SURVEY_FILTERED_LISTS';
export const SET_LAST_DELETED_THREAD_ID = 'SET_LAST_DELETED_THREAD_ID';
export const IS_NEW_CHAT_CLICKED = 'IS_NEW_CHAT_CLICKED';

export function selectedDataSourceSection(section: number): AnyAction {
  return {
    type: SELECTED_DATA_SOURCE_SECTION,
    section
  };
}

export function selectedDataSource(source: string): AnyAction {
  return {
    type: SELECTED_DATA_SOURCE,
    source
  };
}

export function setPromptInProgress(value: boolean): AnyAction {
  return {
    type: SET_PROMPT_INPROGRESS,
    value
  };
}

export function saveUserChatThreadHistory(history: any): AnyAction {
  return {
    type: SAVE_USER_CHAT_THREAD_HISTORY,
    history
  };
}

export function setSelectedChatThread(thread: object): AnyAction {
  return {
    type: SET_SELECTED_CHAT_THREAD,
    thread
  };
}

export function saveUserDocumentThreadHistory(history: any): AnyAction {
  return {
    type: SAVE_USER_DOCUMENT_THREAD_HISTORY,
    history
  };
}

export function setSelectedDocumentThread(thread: object): AnyAction {
  return {
    type: SET_SELECTED_DOCUMENT_THREAD,
    thread
  };
}

export const setChatErrors = (payload: any) => {
  return {
    type: SET_CHAT_ERRORS,
    payload
  };
};
export const setChatHistory = (payload: any) => {
  return {
    type: SET_CHAT_HISTORY,
    payload
  };
};

export const regenerateChatSummary = (payload: any) => {
  return {
    type: REGENERATE_CHAT_SUMMARY,
    payload
  };
};

export const setChatMessageHistory = (payload: any) => {
  return {
    type: LOAD_CHAT_MESSAGE_HISTORY,
    payload
  };
};

export const setChatTable = (payload: any) => {
  return {
    type: SET_CHAT_TABLE,
    payload
  };
};

export const setChatSummary = (payload: any) => {
  return {
    type: SET_CHAT_SUMMARY,
    payload
  };
};
export const setChatSources = (payload: any) => {
  return {
    type: SET_CHAT_SOURCES,
    payload
  };
};

export const setWhoelChatTable = (payload: any) => {
  return {
    type: SET_WHOLE_CHAT_TABLE,
    payload
  };
};

export const setClearSummary = () => {
  return {
    type: SET_CLEAR_SUMMARY
  };
};

export const getChatHistory = () => {
  return {
    type: GET_CHAT_HISTORY
  };
};

export const clearChatHistory = () => {
  return {
    type: CLEAR_CHAT_HISTORY
  };
};

export const toggleGPTSidebar = (payload: any) => {
  return {
    type: TOGGLE_GPT_SIDEBAR,
    payload
  };
};
export const showGPTButton = (payload: any) => {
  return {
    type: SHOW_GPT_BUTTON,
    payload
  };
};

export const toggleLoader = (payload: any) => {
  return {
    type: TOGGLE_LOADER,
    payload
  };
};
export const setClearLoader = (payload: any) => {
  return {
    type: TOOBLE_CLEAR_LOADER,
    payload
  };
};
export const setDownloadFileMessage = (payload: any) => {
  return {
    type: SET_DOWNLOAD_FILE_MESSAGE,
    payload
  };
};

export const setChunkLoading = (payload: boolean) => {
  return {
    type: SET_CHUNK_DATA_LOADING,
    payload
  };
};
export const setChatClicked = (payload: boolean) => {
  return {
    type: IS_NEW_CHAT_CLICKED,
    payload
  };
};
export const setSectorChunkLoading = (payload: boolean) => {
  return {
    type: SET_SECTOR_CHUNK_DATA_LOADING,
    payload
  };
};

export function setISActiveTab(value: string): AnyAction {
  return {
    type: IS_ACTIVE_TAB,
    value
  };
}

export const clearDownloadFileMessage = () => setDownloadFileMessage('');

export const setDownloadFileMessagePPT = () =>
  setDownloadFileMessage(LABELS.download_file_message.PPT);
export const setDownloadFileMessageWord = () =>
  setDownloadFileMessage(LABELS.download_file_message.WORD);
export const setDownloadFileMessageExcel = () =>
  setDownloadFileMessage(LABELS.download_file_message.EXCEL);

export const setErrorMessage = (payload: any) => {
  return {
    type: SET_ERROR_MESSAGE,
    payload
  };
};
export const clearErrorMessage = () => setErrorMessage('');
export const setRelevantFiles = (payload: Partial<IRelevantFiles>[]) => {
  return {
    type: SET_RELEVANT_FILES,
    payload
  };
};

export const getRelevantFiles = (payload: Partial<IRelevantFiles>[]) => {
  return {
    type: SET_RELEVANT_FILES,
    payload
  };
};
export const setApplyClick = (payload: any) => {
  return {
    type: SET_APPLY_CLICK,
    payload
  };
};
export const setUserFirstQuery = (payload: any) => {
  return {
    type: SET_USER_FIRST_QUERY,
    payload
  };
};
export const setAnySourceSelected = (value: any) => {
  return {
    type: SET_IS_ANY_SOURCE_SELECTED,
    value
  };
};
export const setSurveyList = (value: any) => {
  return {
    type: SET_SELECTED_SURVEY_LIST,
    payload: value
  };
};
export const setSurveySectors = (value: any) => {
  return {
    type: SET_SELECTED_SURVEY_SECTORS,
    payload: value
  };
};

export const setLastSavedThreadID = (value: number) => {
  return {
    type: SET_LAST_SAVED_THREAD_ID,
    value
  };
};

export const setLastDeletedThreadID = (value: number) => {
  return {
    type: SET_LAST_DELETED_THREAD_ID,
    value
  };
}
export const setIsChatThreadLoaded = (value: any) => {
  return {
    type: SET_IS_CHAT_THREAD_LOADED,
    value
  };
};

export const setIsSurveyDocList = (value: any) => {
  return {
    type: SET_SURVEY_DOC_LISTS,
    payload: value
  };
};

export const surveyFileredList = (value: any) => {
  return {
    type: SET_SURVEY_FILTERED_LISTS,
    payload: value
  };
};
