import React, { useEffect, useState } from 'react';
import { sanitizeHeaderText } from '../../../utils/commonUtils';
import label from '../../../ui-constants/en.json';
import { IContent, IRelevantFiles } from '../../../models/IQueryType';
import { Guid } from 'guid-typescript';
import SORT_ARROW from '../../assets/sort_arrows.svg';
import LABELS from '../../../ui-constants/en.json';

import './EngagementTable.scss';

type TableContentProps = {
  tableContent: Partial<IRelevantFiles>[];
};

const EngagementTableContent = ({ tableContent }: TableContentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAllRecords, setShowAllRecords] = React.useState<boolean>(false);
  const [visibleRecords, setVisibleRecords] = React.useState<any>();
  const [sortingDate, setSortingDate] = React.useState<any>(null);
  let showData = false;
  const visibledata = () => {
    const sortedArrray = tableContent?.sort(function (a, b) {
      let c = new Date(a.Project_End_Date ?? '').valueOf();
      let d = new Date(b.Project_End_Date ?? '').valueOf();
      return sortingDate ? c - d : d - c;
    });

    const calculatedData = sortedArrray.filter((value: any, i: number) => {
      value.Authors = value.Authors.filter((author: any, i: Number) => {
        if (author.author_name) return author;
      });
      return value && value.Authors.length > 0;
    });

    if (!showAllRecords) {
      setVisibleRecords(calculatedData?.slice(0, 3));
    } else {
      setVisibleRecords(calculatedData);
    }
    showData = true;
  };

  const getProjectEndDate = (value: any) => {
    let date = new Date(value?.Project_End_Date)
      .toLocaleString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
      .split(' ')
      .join(' ');
    if(date === 'Invalid Date'){
      {
        date = '';
      }
      return date;
    }
  };

  useEffect(() => {
    visibledata();
  }, [showAllRecords]);
  return (
    <div className='engagementtable' key={`key-${Guid.create()}`}>
      {visibleRecords && visibleRecords.length > 0 ? (
        <>
          <div>{label.REFERENCE_ENG_DOCS}</div>
          <table className='engagementtable-table'>
            <tbody>
              <tr key={`table-header-${'files'}`}>
                <th>{sanitizeHeaderText('Name(s)')}</th>
                <th key={`table-header-${'Name(s)'}`}>{sanitizeHeaderText('Role(s)')}</th>
                <th key={`table-header-${'project-type'}`}>{sanitizeHeaderText('Project type')}</th>
                <th key={`table-header-${'end-date'}`} className='engagementtable__sort-col'>
                  {sanitizeHeaderText('Project end date')}
                </th>
                <th key={`table-header-${'geographic-focus'}`}>
                  {sanitizeHeaderText('Geographic focus of work')}
                </th>
              </tr>

              {visibleRecords?.map((value: IContent, i: number) => (
                <tr key={`table-row-${value.Project_type}-${i}`}>
                  <td>
                    {Array.isArray(value?.Authors) &&
                      value.Authors.map((author: any, ind: Number) => {
                        const nameRoleLength = author.role.split(',').length;
                        return (
                          <div
                            className={
                              nameRoleLength === 2
                                ? 'engagementtable__nameroles double'
                                : nameRoleLength === 3
                                  ? 'engagementtable__nameroles triple'
                                  : 'engagementtable__nameroles'
                            }>
                            <a
                              className='table__author'
                              href={`${label.DOCUMENT_AUTHOR_URL}${author.author_email}`}
                              target='_blank'
                              key={`key-${Guid.create()}` + ind}>
                              {author.author_name}
                            </a>
                            <br />
                          </div>
                        );
                      })}
                  </td>

                  <td>
                    {Array.isArray(value?.Authors) &&
                      value.Authors.map((author: any, i: Number) => {
                        const nameRoleLength = author.role.split(',').length;
                        return (
                          <div
                            className={
                              nameRoleLength === 2
                                ? 'engagementtable__nameroles double'
                                : nameRoleLength === 3
                                  ? 'engagementtable__nameroles triple'
                                  : 'engagementtable__nameroles'
                            }
                            key={`key-${Guid.create()}  + i`}>
                            <span>{author.role}</span>
                            <br />
                          </div>
                        );
                      })}
                  </td>

                  <td key={`table-data-type-${value.Project_type}`}>
                    <div className='center-text'>{value?.Project_type}</div>
                    <br />
                  </td>

                  <td key={`table-data-date-${value.Project_End_Date}`}>
                    <span className='center-text'>
                      {value?.Project_End_Date !== ''
                        ? new Date(value?.Project_End_Date)
                            .toLocaleString('en-GB', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric'
                            })
                            .split(' ')
                            .join(' ')
                        : ''}
                    </span>
                  </td>
                  <td key={`table-data-enddate-${value.Project_End_Date}`}>
                    {Array.isArray(value?.Geography) &&
                      value.Geography.map((geo: any, i: Number) => {
                        return (
                          <div className='center-text' key={`table-data-${geo + i}`}>
                            <span>{geo}</span>
                            <br />
                          </div>
                        );
                      })}
                  </td>
                </tr>
              ))}

              {tableContent && tableContent?.length > 3 && (
                <tr key={`tableContent-row-${Guid.create()}`}>
                  <td colSpan={5} className='table__footer'>
                    <button
                      className='btn-show'
                      onClick={() => {
                        setShowAllRecords(!showAllRecords);
                      }}>
                      {showAllRecords ? '- See less' : '+ See more'}
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      ) : (
        <>{LABELS.chat_reply.SE_ERROR_MSG_ENGAGEMENT}</>
      )}
    </div>
  );
};

export default React.memo(EngagementTableContent);
