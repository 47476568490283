import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import Modal from '../../common/modal/Modal';
import sassVar from '../../../styles/variables.module.scss';
import { ModalType } from '../../../models/ModalType';
import { PLEASE_TRY_AFTER_SOMETIME, SOMETHING_WENT_WRONG } from '../../../app.constant';
import { errorModalHeaderIcon } from '../../../commonImage';

const { lightgray, fontInterstate } = sassVar;
interface IOwnProps {
  show: boolean;
}

const ErorModal: React.FC<IOwnProps> = ({ show }: IOwnProps) => { 
  return (
    //@ts-ignore
    <Modal
      isOpen={show}
      type={ModalType.Warning}
      headerTitle = {SOMETHING_WENT_WRONG}
      actionBtnEnabled={false}
      showModalFooter={false}
      showHeaderIcon={true}
      width={800}
      customHeaderClass='modal-header'
      showHeaderTitleIcon={true}
      headerTitleIcon={errorModalHeaderIcon}>
      <StyledErrorModal className='noto-sans center'>
        <Fragment>
          <div className='ie-error'>
            <div className='confirmation-text ml-45'>{PLEASE_TRY_AFTER_SOMETIME}</div>
          </div>
        </Fragment>
      </StyledErrorModal>
    </Modal>
  );
};

const StyledErrorModal = styled.div`
  padding: 20px 0;
  background: gray;
  color: ${lightgray};
  .ie-error {
    text-align: left;
  }
  & .text-center {
    text-align: center;
  }
  .icon-i-sign-out {
    display: inline;
    font-size: 3.4375rem;
    margin-left: 2px;
  }
  .confirmation-text {
    margin-top: 1.375rem;
    margin-bottom: 1.875rem;
    letter-spacing: 0;
    line-height: 22px;
    display: inline;
    vertical-align: 7px;
    font-family: ${fontInterstate};
    font-size: 14px;
    line-height: 22px;
  }
`;

export default React.memo(ErorModal);
