import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MessageEditMode from '../MessageEditMode/MessageEditMode';
import OptionsButton from '../OptionsButton/OptionsButton';
import { IQuery, IQueryContent, RESPONSE_BY } from '../../../models/IQueryType';
import { IStore } from '../../../store';
import LABELS from '../../../ui-constants/en.json';
import AvatarInitials from '../../common/AvatarInitials';
import useExportToFile from '../utils/useExportToFile';
import { exportToCSV } from '../utils/exportToCSV';
import { MotifToast } from '@ey-xd/motif-react';
import { exportKcExpertsToPPT } from '../utils/KcWithExpertsPPT';
import './SendMessage.scss';
import { decodeHTML } from '../../../utils/commonUtils';

type SendMessageProps = {
  message: IQueryContent;
  isNew?: boolean;
  loader: boolean;
  userQueryWithResponse?: any;
  chatData?: IQuery;
  isEngagement?: boolean;
  handleSendMessage: (ev: any) => void;
};

const SendMessage = ({
  message,
  isNew,
  loader,
  userQueryWithResponse,
  chatData,
  isEngagement = false,
  handleSendMessage
}: SendMessageProps) => {
  const dispatch = useDispatch();
  const { exportToPPT, exportToPPTSector,exportToPPTPerspective } = useExportToFile();
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [replyData, setReplyData] = React.useState<IQueryContent | null>();
  const userData = useSelector((state: any) => state.userProfile?.userData);
  const commonData = useSelector((state: any) => state.common);
  const checkedCheckboxes = useSelector((state: any) => state.common?.checkedCheckboxes);
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const validURLinputValue = useSelector((state: IStore) => state?.common?.validURLinputValue);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);
  const [showUploadInitiated, setShowUploadInitiated] = useState({ action: '', flag: false });
  const [csvDownloadError, setCsvDownloadError] = useState(false);

  const queryImageClass = isNew ? 'openaiquery__img-container' : 'openaiquery__img-container-dark';
  const { chatDataSummmary } = useSelector((state: any) => state.userChat);
  const handlePPTDownload = async () => {
    if (isEngagement) {
      const exportData = {
        query: message.summary,
        response: userQueryWithResponse?.SEResponse
      };
      setCsvDownloadError(false);
      setShowUploadInitiated({ action: 'ppt', flag: true });
      exportKcExpertsToPPT(exportData);
      setTimeout(() => {
        setShowUploadInitiated({ action: '', flag: false });
      }, 1000);
    } else {
      setCsvDownloadError(false);
      setShowUploadInitiated({ action: 'ppt', flag: true });
      await exportToPPT(
        RESPONSE_BY.EMBROYNIC,
        userQueryWithResponse,
        checkedCheckboxes,
        eyIPInterRadio,
        validURLinputValue
      );
      setTimeout(() => {
        setShowUploadInitiated({ action: '', flag: false });
      }, 1000);
    }
  };

  const handlePPTDownloadSector = async () => {
    setCsvDownloadError(false);
    setShowUploadInitiated({ action: 'ppt', flag: true });
    await exportToPPTSector(
      userQueryWithResponse,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue
    );
    setTimeout(() => {
      setShowUploadInitiated({ action: '', flag: false });
    }, 1000);
  };
  const handlePPTDownloadPersPective = async () => {
    setCsvDownloadError(false);
    setShowUploadInitiated({ action: 'ppt', flag: true });
    await exportToPPTPerspective(
      userQueryWithResponse,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue
    );
    setTimeout(() => {
      setShowUploadInitiated({ action: '', flag: false });
    }, 1000);
  };
  React.useEffect(() => {
    let data: IQueryContent | null = chatData
      ? chatData[RESPONSE_BY.STRATEGY_EDGE] || chatData[RESPONSE_BY.OPENAI]
      : null;
    setReplyData(data);
  }, [chatData]);

  const handlePPTDownloads = () => {
    if (eyIPInterRadio === 'Discover: Sector Insights') {
      handlePPTDownloadSector();
    } else if (eyIPInterRadio === 'EY-P Strategy projects') {
      handlePPTDownloadPersPective();
    } else {
      handlePPTDownload();
    }
  };
  return (
    <Fragment>
      <div className={!isRightSideBarOpen ? 'openaiquery' : 'openaiquery__sidebar-open'}>
        <div className={queryImageClass}>
          {userData.profileImage ? (
            <img className='openaiquery__image-round' alt='Profile' src={userData.profileImage} />
          ) : (
            <AvatarInitials title={userData.displayName} />
          )}
        </div>
        {isEditMode ? (
          <MessageEditMode
            message={decodeHTML(message.summary)}
            handleSendMessage={handleSendMessage}
            setIsEditMode={setIsEditMode}
          />
        ) : (
          <div className={'openaiquery__query-box'}>
            {decodeHTML(message.summary)}
            {!loader && (
              <OptionsButton
                options={[
                  {
                    label: LABELS.send_message.EXPORT_PPT_BTN,
                    onClick: handlePPTDownloads,
                    display: true
                  },
                  ...(replyData?.sqlQuery && checkedCheckboxes.length > 0
                    ? [
                        {
                          label: LABELS.chat_reply.EXPORT_EXCEL_BTN,
                          onClick: async () => {
                            setCsvDownloadError(false);
                            setShowUploadInitiated({ action: 'excel', flag: true });
                            try {
                              await exportToCSV(
                                replyData?.sqlQuery,
                                RESPONSE_BY.EMBROYNIC,
                                userData,
                                checkedCheckboxes,
                                eyIPInterRadio,
                                validURLinputValue
                              );
                              setShowUploadInitiated({ action: '', flag: false });
                            } catch (error) {
                              setCsvDownloadError(true);
                              setTimeout(() => {
                                setCsvDownloadError(false);
                                setShowUploadInitiated({ action: '', flag: false });
                              }, 3500);
                            }
                          },
                          display: chatData?.[RESPONSE_BY.STRATEGY_EDGE] ? true : false
                        }
                      ]
                    : []),
                  {
                    label: LABELS.send_message.EDIT_BTN,
                    onClick: () => setIsEditMode(true),
                    display: true
                  }
                ]}
              />
            )}
          </div>
        )}
      </div>
      {showUploadInitiated.flag && (
        <MotifToast
          className={
            eyIPInterRadio === 'Discover: Sector Insights' || isEngagement
              ? 'toast-container-sector'
              : !csvDownloadError
                ? 'toast-container-public'
                : 'toast-container-public-error'
          }
          variant={!csvDownloadError ? 'info' : 'error'}
          actionName=''
          position='bottom'
          onClose={() => {
            setShowUploadInitiated({ action: '', flag: false });
          }}>
          {showUploadInitiated.action !== 'ppt' ? (
            <div>
              {!csvDownloadError
                ? 'Downloading Excel'
                : 'Download excel is not supported in history chats, try asking the question again! '}
            </div>
          ) : (
            <div>Downloading PPT</div>
          )}
        </MotifToast>
      )}
    </Fragment>
  );
};
export default SendMessage;
