import { apiRoutes, get, post, getAuthToken, getTeamsAuthToken } from './api.service';
import chatApiRoutes from './api';
import { type IUserRolesResponse } from '../models/IUserRoles';
import errorStatus from '../utils/ErrorStatus';
import { LOCAL_STORAGE, RESPONSE_STATUS_CODE } from '../app.constant';
import { isTeamsApp } from '../utils/commonUtils';

export const signUserOut = async (): Promise<any> => {
  return await post(apiRoutes.signOut, null);
};

const handleNodataError = (error: any): any => {
  if (
    error?.response?.status === 404 ||
    error?.response?.data?.errors?.[0]?.message === 'Data Not Found.'
  ) {
    return null;
  }
};

const fomatted = (response: any): any => {
  return response.reduce(
    (a: any, c: any) =>
      c?.status === 'fulfilled'
        ? { ...a, ...(typeof c?.value === 'string' ? { image: c.value } : c.value) }
        : { ...a },
    {}
  );
};

export const getProfilePicture = async (): Promise<any> => {
  try {
    return await get(apiRoutes.profilePicture);
  } catch (error) {
    return handleNodataError(error);
  }
};

export const getUserProfile = async (): Promise<any> => {
  let response: any;
  if (isTeamsApp()) {
    response = await Promise.allSettled([await getTeamsAuthToken(), await getProfilePicture()]);
  } else {
    response = await Promise.allSettled([await getAuthToken(), await getProfilePicture()]);
  }
  localStorage.setItem(LOCAL_STORAGE.USER_PROFILE_PIC, response[1]?.value ? response[1]?.value : '');
  return fomatted(response);
};

export const fetchUserRoles = async (): Promise<IUserRolesResponse | any> => {
  const data = await get(apiRoutes.userRoles);
  return data.tenantRoles;
};

export const fetchUserApps = async (): Promise<IUserRolesResponse | any> => {
  try {
    const data = await get(apiRoutes.authorizationApps);
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};

export const getChatHistory = async (): Promise<any> => {
  try {
    const data = await get(chatApiRoutes.chatThreadHistory);
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};

export const customErrorStatus = (message: any): any => {
  switch (message) {
    case 'Network Error':
      return errorStatus(RESPONSE_STATUS_CODE.INTERNAL_SERVER_ERROR);
    default:
      return errorStatus(RESPONSE_STATUS_CODE.INTERNAL_SERVER_ERROR);
  }
};

export const fetchDataSources = async (): Promise<IUserRolesResponse | any> => {
  try {
    const data = await get(apiRoutes.applicationDataSource);
    return data;
  } catch (error: any) {    
    return error;
  }
};


export const setFavourite = async (appId: string, isFavourite: boolean): Promise<IUserRolesResponse | any> => {
  try {
    let req = {
      "app": appId,
      "isFavourite": isFavourite
    }
    const data = await post(apiRoutes.favouriteApps, req);
    return data;
  } catch (error: any) {
    if (!error?.response?.status) return customErrorStatus(error?.message);
    return errorStatus(error?.response?.status);
  }
};
