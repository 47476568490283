import React, { useEffect, useState } from 'react';
import { sanitizeHeaderText } from '../utils';
import label from '../../../ui-constants/en.json';
import { IContent, IRelevantFiles, ISectorContent } from '../../../models/IQueryType';
import { Guid } from 'guid-typescript';
import './TableContent.scss';
import apiRoutes from '../../../services/api';
import { DISCOVERY_URL } from '../../../app.constant';
type TableContentProps = {
  tableContent: Partial<IRelevantFiles>[];
  handleSlideDownload?: (data: any) => void;
};

const TableContent = ({ tableContent, handleSlideDownload }: TableContentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAllRecords, setShowAllRecords] = React.useState<boolean>(false);
  const [visibleRecords, setVisibleRecords] = React.useState<any>();

  const visibledata = () => {
    if (!showAllRecords) {
      setVisibleRecords(tableContent?.slice(0, 3));
    } else {
      setVisibleRecords(tableContent?.slice(0, 5));
    }
  };

  useEffect(() => {
    visibledata();
  }, [showAllRecords]);

  return (
    <div className='table__wrapper-sector' key={`key-${Guid.create()}`}>
      <div className='table__collapse'>
        <span onClick={() => setIsOpen(!isOpen)}>{`${isOpen ? '-' : '+'}`}</span>{' '}
        {label.reference_documents}
      </div>
      {isOpen ? (
        <table className='table__content-table-sector'>
          <tbody>
            <tr key={`table-header-${'files'}`}>
              <th>{sanitizeHeaderText('Relevant Document(s)')}</th>
              <th key={`table-header-${'Authors'}`}>{sanitizeHeaderText('Authors')}</th>
            </tr>

            {visibleRecords &&
              visibleRecords.length > 0 &&
              visibleRecords?.map((value: ISectorContent, i: number) => (
                <tr key={`table-row-${value.file_name}`}>
                  <td key={`table-data-value-${value.file_name}`}>
                    <a
                      className='files-download-btn'
                      target='_blank'
                      href={`${DISCOVERY_URL}${value.eyAEMID}`}>
                      {value?.file_name}
                    </a>
                    <br />
                    <span>
                      (Slides
                      {value?.page_number?.map((val: any, i: number) =>
                        value.page_number.length - 1 === i ? (
                          <React.Fragment key={i.toString() + val}>
                            {' '}
                            <span
                              className='files-slide-download-btn'
                              key={i.toString() + val}>{`${val}`}</span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment key={i.toString() + val}>
                            {' '}
                            <span
                              key={i.toString() + val}
                              className='files-slide-download-btn'>{`${val},`}</span>
                          </React.Fragment>
                        )
                      )}
                      )
                    </span>
                  </td>
                  <td>
                    {value?.authors?.map((author: any, i: Number) => {
                      if (value?.authors?.length - 1 == i) {
                        return (
                          <a
                            className='table__author'
                            href={`${label.DOCUMENT_AUTHOR_URL}${author.author_email}`}
                            target='_blank'
                            key={author.author_name}>
                            {author.author_name}
                          </a>
                        );
                      }
                      return (
                        <a
                          className='table__author'
                          href={`${label.DOCUMENT_AUTHOR_URL}${author.author_email}`}
                          target='_blank'
                          key={author.author_name}>
                          {`${author.author_name}, `}
                        </a>
                      );
                    })}
                  </td>
                </tr>
              ))}
            {tableContent && tableContent?.length > 10 && (
              <tr key={`table-row-${Guid.create()}`}></tr>
            )}
            {tableContent && tableContent?.length > 3 && (
              <tr key={`table-row-${Guid.create()}`}>
                <td colSpan={2} className='table__footer'>
                  <button
                    className='btn-show'
                    onClick={() => {
                      setShowAllRecords(!showAllRecords);
                    }}>
                    {showAllRecords ? 'View less' : 'View more'}
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        ''
      )}
    </div>
  );
};

export default React.memo(TableContent);
