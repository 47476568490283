import { useState, useLayoutEffect, useCallback, useEffect } from 'react';

export default function useWindowSize(): number[] {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  const updateSize = useCallback((): void => {
    setSize([window.innerWidth, window.innerHeight]);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    return (): void => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  return size;
}

export const useDetectOutsideClick = (el: React.RefObject<HTMLDivElement>, initialState: any) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e: any) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      document.addEventListener('click', onClick);
    }
  }, [isActive, el]);

  return [isActive, setIsActive];
};
