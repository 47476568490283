import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IQuery, IQueryContent, RESPONSE_BY } from '../../models/IQueryType';
import SendMessage from '../openAIChat/SendMessage';
import ChatReply from '../openAIChat/ChatReply';
import InputBox from '../openAIChat/InputBox';
import LABELS from '../../ui-constants/en.json';
import { useDispatch, useSelector } from 'react-redux';
import { HttpStatusCode } from '../../ui-constants/base/enum';
import { IStore } from '../../store';
import {
  setChatHistory,
  setChunkLoading,
  setWhoelChatTable,
  toggleLoader,
  clearDownloadFileMessage,
  setDownloadFileMessageWord,
  setRelevantFiles,
  setChatErrors,
  regenerateChatSummary,
  saveUserChatThreadHistory,
  setLastDeletedThreadID,
  setChatTable
} from '../../store/actions/userChatActions';
import LinkButton from '../LinkButton/LinkButton';
import { downloadImg } from '../../commonImage';
import exportToWord from '../openAIChat/utils/exportToWord';
import './EyExperts.scss';
import {
  getQueryResponseEngagement,
  saveDownloadChatActivity,
  saveUserChatHistory
} from '../../services/chatQuery.service';
import {
  checkOnySpecialCharacter,
  getComputedRequestObject,
  getTeamsSilentToken,
  handleSessionID,
  handleUserChatThreadHistory,
  isTeamsApp,
  setThreadsUnSelected
} from '../../utils/commonUtils';
import { initializeSession, logoutandClearSession } from '../../services/session.service';
import { MotifToast } from '@ey-xd/motif-react';
import exportEngagementChatToWord from '../openAIChat/utils/exportEngagementChatToWord';
import { setRightSideBarOpenClose, setUSerEmail } from '../../store/actions/commonActions';
import { getTeamsAuthToken } from '../../services/api.service';
import { jwtDecode } from 'jwt-decode';
import { storeTeamsUserProfile } from '../../store/actions/userProfileActions';
type ISectorChatProps = {
  chatQueryApi?: string;
  sectors: string[];
  geographies: string[];
  contentage: string;
  handleFocus?: () => void;
};
const EyExperts = ({
  chatQueryApi = '',
  sectors,
  geographies,
  contentage,
  handleFocus
}: ISectorChatProps) => {
  const [seQuery, setSeQuery] = useState<IQuery[]>([]);
  const [queryText, setQueryText] = useState<string>('');
  const [isValidQuery, setIsValidQuery] = useState<boolean>(false);

  const refInput = useRef<HTMLTextAreaElement>(null);
  const refToScroll = useRef<HTMLDivElement>(null);
  const { isClearLoading, chatDataSummmary, isLoading, isSideBarOpen, chunkLoading } = useSelector(
    (state: any) => state.userChat
  );
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const { isRightSideBarOpen, anySourceSelected } = useSelector((state: IStore) => state.common);
  const [showDownloadInitiated, setsShowDownloadInitiated] = useState(false);

  const dispatch = useDispatch();
  const timeoutError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: `\n\n\n${LABELS.chat_reply.SE_TOKEN_ERROR_MSG}`,
        content: [],
        responseType: 'error',
        sources: ['Discover']
      }
    }
  ];
  const replyServerError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: `\n\n\n${LABELS.chat_reply.OPENAI_ERROR_MSG}`,
        content: [],
        responseType: 'error',
        sources: ['Discover']
      }
    }
  ];
  const replyNotFoundError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: `\n\n\n${LABELS.chat_reply.OPENAI_NOT_FOUND_ERROR_MSG}`,
        content: [],
        responseType: 'error',
        sources: ['Discover']
      }
    }
  ];
  const [chatLoadCompleted, setChatLoadCompleted] = useState(false);
  const [regeneratedMessageId, setRegeneratedMessageId] = useState(0);
  const [firstResponseTime, setFirstResponseTime] = useState(0);
  const [totalResponseTime, setTotalResponseTime] = useState<number | null>();
  const userChatThreads = useSelector((store: IStore) => store.userChat.chatThreadHistory);
  const [currentUserChatThreads, setCurrentUserChatThreads] = useState(userChatThreads);
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);
  const checkedCheckboxes = useSelector((state: any) => state.common?.checkedCheckboxes);
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const validURLinputValue = useSelector((state: IStore) => state?.common?.validURLinputValue);
  const lastDeletedThreadID = useSelector((state: IStore) => state?.userChat?.lastDeletedThreadID);
  const selectedDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const [controller, setController] = useState<any>(null);
  const [isCompletelyAbortedResposne, setIsCompletelyAbortedResposne] = useState<boolean>(false);
  const [isParitallyAbortedResponse, setIsParitallyAbortedResponse] = useState<boolean>(false);
  useEffect(() => {
    setSeQuery(() => chatDataSummmary?.table);
  }, [chatDataSummmary, chunkLoading]);

  const updateFirstResponseTime = (firstByteTime: any, startTime: number) => {
    if (firstByteTime === null) {
      firstByteTime = performance.now();
      setFirstResponseTime((firstByteTime - startTime) / 1000);
    }
  };

  const updateTotalResponseTime = (startTime: number, isStaticResponse: boolean = false) => {
    if (isStaticResponse) {
      setTotalResponseTime(null);
      return;
    } else {
      const endTime = performance.now(); // Record the end time
      const duration = (endTime - startTime) / 1000; // Calculate the duration
      setTotalResponseTime(duration); // Update state with the response time
    }
  };

  const sendChatQuery = async (summary: string) => {
    scrollToBottom();
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    const controller = new AbortController();
    const startTime = performance.now();
    let firstByteTime: any = null;
    setController(controller);
    try {
      const summaryData = JSON.stringify({
        query: summary.trim(),
        sectors: sectors,
        geographies: geographies,
        content_ages: contentage == '4' ? '' : contentage
      });
      const response: any = await getQueryResponseEngagement(summaryData, userData, controller);
      const { status, statusText } = response;
      const tableData = await response.json();

      if (controller.signal.aborted) {
        const stopGenerationErrorMsg = [
          {
            [RESPONSE_BY.STRATEGY_EDGE]: {
              summary: LABELS.chat_reply.STOP_GENERATION_MSG,
              content: [],
              responseType: 'stop_generation',
              sources: [],
              isCompletelyAbortedResposne: true,
              isParitallyAbortedResponse: false
            }
          }
        ];
        dispatch(setChatErrors([...stopGenerationErrorMsg]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        setChatLoadCompleted(true);
      } else if (status == HttpStatusCode.Success) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChunkLoading(true));
        if (tableData && tableData.length > 0) {
          dispatch(
            setChatTable([
              {
                [RESPONSE_BY.STRATEGY_EDGE]: {
                  Content: [],
                  summary: '',
                  relevantFiles: [],
                  Sources: ['Discover Knowledge Capture']
                }
              }
            ])
          );
          scrollToBottom();
          updateTotalResponseTime(startTime);
          setChatLoadCompleted(true);
          dispatch(setChunkLoading(false));
          dispatch(setWhoelChatTable(true));
          dispatch(toggleLoader(false));
          dispatch(setRelevantFiles(tableData));
          scrollToBottom();
          return;
        } else {
          dispatch(setChunkLoading(false));
          dispatch(toggleLoader(false));
          // dispatch(setChatHistory([...replyServerError]));
          scrollToBottom();
          updateTotalResponseTime(startTime, true);
        }
      } else if (status === HttpStatusCode.NotFound) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(toggleLoader(false));
        dispatch(setChunkLoading(false));
        updateTotalResponseTime(startTime, true);
        setChatLoadCompleted(true);
        dispatch(setChatErrors([...replyNotFoundError]));
        setTimeout(() => {
          return scrollToBottom();
        }, 100);
      } else {
        if (status === HttpStatusCode.Unauthorized) {
          if (isTeamsApp()) {
            getTeamsSilentToken();
          } else logoutandClearSession();
        }
        if (
          status === HttpStatusCode.InternalServerError ||
          status === HttpStatusCode.TimedOut ||
          status === HttpStatusCode.BadRequest ||
          statusText === LABELS.SERVER_ERROR
        ) {
          updateFirstResponseTime(firstByteTime, startTime);
          dispatch(toggleLoader(false));
          dispatch(setChunkLoading(false));
          updateTotalResponseTime(startTime, true);
          setChatLoadCompleted(true);
          dispatch(setChatErrors([...replyServerError]));
          setTimeout(() => {
            return scrollToBottom();
          }, 100);
        } else {
          updateFirstResponseTime(firstByteTime, startTime);
          dispatch(toggleLoader(false));
          dispatch(setChunkLoading(false));
          updateTotalResponseTime(startTime, true);
          setChatLoadCompleted(true);
          dispatch(setChatErrors([...replyServerError]));
          setTimeout(() => {
            return scrollToBottom();
          }, 100);
        }
      }
    } catch (error) {
      setChatLoadCompleted(true);
      dispatch(setChunkLoading(false));
      if (controller.signal.aborted) {
        dispatch(setChatErrors([...timeoutError]));
        updateTotalResponseTime(startTime, true);
        // dispatch(setChatHistory([...timeoutError]));
        // dispatch(toggleLoader(false));
        // scrollToBottom();

        const stopGenerationErrorMsg = [
          {
            [RESPONSE_BY.STRATEGY_EDGE]: {
              summary: LABELS.chat_reply.STOP_GENERATION_MSG,
              content: [],
              responseType: 'stop_generation',
              sources: [],
              isCompletelyAbortedResposne: true,
              isParitallyAbortedResponse: false
            }
          }
        ];
        dispatch(setChatErrors([...stopGenerationErrorMsg]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        setChatLoadCompleted(true);
      } else {
        dispatch(setChatErrors([...replyServerError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        setChatLoadCompleted(true);
        scrollToBottom();
      }
    }
  };

  const handleSendMessage = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        setRegeneratedMessageId(0);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
        if (Object.keys(selectedChatThread).length === 0 && !localStorage.isSidebarManuallyClosed) {
          dispatch(setRightSideBarOpenClose(true));
        }
      }
    }
  };

  const handleRegenerate = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        setRegeneratedMessageId(
          chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1]?.SEResponse?.messageId ||
            regeneratedMessageId
        );
        dispatch(regenerateChatSummary([newMsg]));
        dispatch(setChatHistory([newMsg]));
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
      }
    }
  };

  const scrollToBottom = () => {
    refToScroll?.current?.scroll({
      left: 0,
      top: refToScroll.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  const handleScrollToBottom = () => {
    scrollToBottom();
  };

  const handleOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (checkOnySpecialCharacter(ev.target.value)) setIsValidQuery(false);
    else {
      setIsValidQuery(true);
    }
    setQueryText(ev.target.value);
  };

  useEffect(() => {
    initializeSession();
  }, []);

  useEffect(() => {
    refInput.current?.focus();
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [seQuery]);

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSendMessage(ev);
    }
  };

  useEffect(() => {
    if (chatQueryApi?.length > 0 && Object.keys(selectedChatThread).length === 0) {
      sendChatQuery(chatQueryApi);
    }
  }, [chatQueryApi]);

  useEffect(() => {
    setCurrentUserChatThreads(userChatThreads);
  }, [userChatThreads]);

  useEffect(() => {
    if (lastDeletedThreadID != 0) {
      const updated = userChatThreads.filter((item: any) => item.threadId !== lastDeletedThreadID);
      setCurrentUserChatThreads(updated);
      dispatch(saveUserChatThreadHistory(updated));
      dispatch(setLastDeletedThreadID(0));
    }
  }, [lastDeletedThreadID]);

  const handleDocDownload = async () => {
    dispatch(setDownloadFileMessageWord());
    setsShowDownloadInitiated(true);
    await exportEngagementChatToWord(seQuery, userData.profileImage);
    dispatch(clearDownloadFileMessage());
    saveDownloadChatActivity('Discover Knowledge Capture');
    setTimeout(() => {
      setsShowDownloadInitiated(false);
    }, 1000);
  };

  useEffect(() => {
    if (chatLoadCompleted) saveChatConversation();
  }, [chatLoadCompleted]);

  const saveChatConversation = async () => {
    if (chatDataSummmary?.table?.length === 0) return;
    let requestObject = getComputedRequestObject(
      selectedChatThread,
      regeneratedMessageId,
      chatDataSummmary,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue,
      firstResponseTime,
      totalResponseTime,
      false,
      isCompletelyAbortedResposne,
      isParitallyAbortedResponse,
      selectedDataSourceSection
    );
    const response: any = await saveUserChatHistory(requestObject);
    if (response && response?.threadId) {
      if (response.threadId !== selectedChatThread?.threadId) {
        setThreadsUnSelected(currentUserChatThreads);
        handleUserChatThreadHistory(
          requestObject,
          response.threadId,
          currentUserChatThreads,
          userData,
          dispatch
        );
      }
    }
    setRegeneratedMessageId(response?.messageId);
    setChatLoadCompleted(false);
  };

  const handleStopGeneration = () => {
    controller.abort();
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    if (chunkLoading) {
      setIsParitallyAbortedResponse(true);
      let lastChatData =
        chatDataSummmary?.table[chatDataSummmary?.table?.length - 1]?.SEResponse || undefined;
      if (lastChatData) {
        if (
          !('isCompletelyAbortedResposne' in lastChatData) &&
          !('isParitallyAbortedResponse' in lastChatData)
        ) {
          lastChatData.isCompletelyAbortedResposne = false;
          lastChatData.isParitallyAbortedResponse = true;
        }
      }
      setChatLoadCompleted(true);
    } else {
      setIsCompletelyAbortedResposne(true);
    }
  };

  const getResponseFlag = (SEResponse: any, flag: string) => {
    if (flag in SEResponse) {
      return SEResponse[flag];
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        className={'expertsChatContainer'}
        // style={{
        //   display: isRightSideBarOpen ? 'flex' : 'block'
        // }}
      >
        <div
          ref={refToScroll}
          className='expertsChatContainer__scroll-container'
          // style={{
          //   minHeight: seQuery?.length > 0 ? 160 : 0
          // }}
        >
          {seQuery?.map((chat: any, ind: number) => {
            const { user, SEResponse } = chat;

            return (
              <React.Fragment key={ind}>
                {showDownloadInitiated && (
                  <MotifToast
                    className={'expertsChatContainer__toast-container-public'}
                    variant='info'
                    actionName=''
                    position='bottom'
                    onClose={() => {
                      setsShowDownloadInitiated(false);
                    }}>
                    <div>Downloading</div>
                  </MotifToast>
                )}

                {user && (
                  <SendMessage
                    key={ind}
                    message={user}
                    isNew={true}
                    userQueryWithResponse={chat}
                    loader={isLoading}
                    chatData={{ SEResponse }}
                    isEngagement={true}
                    handleSendMessage={handleSendMessage}
                  />
                )}

                {SEResponse && (
                  <ChatReply
                    message={user?.summary}
                    chatData={{ SEResponse }}
                    key={ind.toString() + '__strctrd-se-chats'}
                    isNew={true}
                    isSE={true}
                    messageLoading={isLoading}
                    chunkLoading={chunkLoading}
                    isLastItem={ind === seQuery.length - 1}
                    isEngagement={true}
                    scrollToBottom={handleScrollToBottom}
                    handleRegenerate={handleRegenerate}
                    isCompletelyAbortedResposne={getResponseFlag(
                      SEResponse,
                      'isCompletelyAbortedResposne'
                    )}
                    isParitallyAbortedResponse={getResponseFlag(
                      SEResponse,
                      'isParitallyAbortedResponse'
                    )}
                  />
                )}
              </React.Fragment>
            );
          })}
          {isLoading && !chunkLoading && <ChatReply loader={isLoading} isNew={true} isSE={true} />}
          <div className='stop-generation-container'>
            {(isLoading || chunkLoading) && (
              <button onClick={handleStopGeneration} className='stop-generation-button'>
                Stop Generating
              </button>
            )}
          </div>
        </div>
      </div>
      <div className='expertsChatContainer__input-container'>
        <InputBox
          selectedSegment={true}
          queryText={queryText}
          refInput={refInput}
          hasQueries={seQuery?.length > 0}
          disabled={isLoading}
          isEngagement={true}
          handleOnChange={handleOnChange}
          handleResetMessage={() => {}}
          handleSendMessage={handleSendMessage}
          handleKeyDown={handleKeyDown}
          handleOnFocus={handleFocus}
        />
        <div className='expertsChatContainer__download-btn-box'>
          <LinkButton onClick={() => handleDocDownload()} disabled={isLoading || isClearLoading}>
            <img src={downloadImg} /> &nbsp;
            <span className='expertsChatContainer__download-text'>Download Chat</span>
          </LinkButton>
        </div>
      </div>
    </>
  );
};
export default EyExperts;
