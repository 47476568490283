import React, { Fragment, ReactChild } from "react";
import styled from "styled-components";
import scssVar from "../../../../styles/variables.module.scss";
 interface IOwnProps {
  content:string
  source?:string
 }
const PrivacyModalContent: React.FC<IOwnProps> = ({source , content}:IOwnProps) => {
  return (
    <StylePrivacy source={source}>
  <div dangerouslySetInnerHTML={{ __html: content }} />
   </StylePrivacy>
  );
};

const StylePrivacy = styled.div<{
  source?: string;
}>`

*{
  font-family: ${scssVar.fontInterstate} !important;
  font-size: ${(props) => ((props.source) ? '10px' : '12px')}  !important;
  color: ${(props) => ((props.source) ? scssVar.alternateblack : 'rgb(196, 196, 205)')};
}


ol {
  list-style-type: circle  ;
}

.term-modal .center_bold_text {
  font-weight: bold;
  text-align: center;
  display: flex;
  width: 100% !important;;
  justify-content: center !important;
  display : none; !important
}

p {
    color: ${(props) => ((props.source) ? scssVar.alternateblack : 'rgb(196, 196, 205)')} !important;
    font-size: 10px;
    line-height: 15px;
    font-weight: 100;
    
  }
  div {
    font-size: 10px;
    line-height: 20px;
  }
    li{
      margin-bottom : 4px !important;
    }
    .motif-h6{
      font-size: 10px;
      font-weight: 100;
    }

   
    .m-b-15{
        margin-bottom: -15px;
    }
  .center_bold_text{
    font-weight: bold;
    text-align: center;
    display : none; !important

  }
  .email-link {
    color: ${(props) => ((props.source) ? scssVar.alternateblack : '#FFE600')} !important;
    text-decoration: ${(props) => ((props.source) ? 'none' : 'uderline')};
    font-weight: ${(props) => ((props.source) ? 'bold' : '100')};
  }
  .hyperlink-link {
    color: ${(props) => ((props.source) ? '#b14891' : '#FFE600')} !important;
    text-decoration: ${(props) => ((props.source) ? 'none' : 'uderline')};
    font-weight: ${(props) => ((props.source) ? 'bold' : '100')};
  }
 
}`;


export default PrivacyModalContent;
