import React, { useEffect, useState } from 'react';
import Dropdown from '../NewDropdown/Dropdown';
import Checkboxes from './CheckBox';
import { cancelImg } from '../../commonImage';
import { useDispatch, useSelector } from 'react-redux';
import { setSurveySectors } from '../../store/actions/userChatActions';
import './SurveySectorCheckbox.scss';
import { IStore } from '../../store';

type ISurveyCheckboxDropdownProps = {
  setdropdownValue?: (val: string) => void;
  toggleDropdown?: () => void;
  clearDropdownValues?: () => void;
  selectedContentAge?: string;
  options: {
    value: string;
    label: string;
    children?: { value: string; label: string }[];
  }[];
  placeholder?: string;
  showOptions: boolean;
  isSideBarOpen?: boolean;
  surveyLabel?: string;
  dropdownType?: string;
  customRef?: any;
};

const SurveyCheckboxDropdown = ({
  options = [],
  placeholder,
  showOptions,
  selectedContentAge = '',
  isSideBarOpen,
  surveyLabel = '',
  customRef,
  toggleDropdown
}: ISurveyCheckboxDropdownProps) => {
  const [selectedSectorsCount,setSelectedSectorsCount]=useState<any>(0);
  const [selected, setSelected] = React.useState<any>([]);
  const dispatch = useDispatch();
  const handleSelect = (value: any, name: any) => {
    if (value) {
      setSelected([...selected, name]); // add to selected
      localStorage.setItem('selectedDropSectors', JSON.stringify([...selected, name]));
    } else {
      setSelected(selected.filter((item: any) => item !== name)); // remove from selected
      localStorage.setItem('selectedDropSectors', JSON.stringify(selected.filter((item: any) => item !== name)));
    }
  };
  const selectAll = (value: any) => {
    if (value) {
      setSelected(options); // select all
      localStorage.setItem('selectedDropSectors', JSON.stringify(options));
    } else {
      setSelected([]); // unselect all
      localStorage.setItem('selectedDropSectors', '');
    }
  };

  useEffect(() => {
    dispatch(setSurveySectors(selected));
    setSelectedSectorsCount(selected.length);
  }, [selected]);

  useEffect(() => {
    const selectedSurveyList = localStorage.getItem('selectedDropSectors');
    if (selectedSurveyList && selectedSurveyList.length > 0) {
      setSelected(JSON.parse(selectedSurveyList));
    }
  }, []);
  const handleRemoveItem = (name: string) => {
    setSelected((l: any) => l.filter((item: string) => item !== name));
    localStorage.setItem('selectedDropSectors', JSON.stringify(selected.filter((item: string) => item !== name)));
  };

  return (
    <div className='surveycheckbox' ref={customRef}>
      <label className='surveycheckbox__title'>{surveyLabel}</label>
      <div>
        <Dropdown
          options={[{ label: ' ', value: ' ' }]}
          placeholder={placeholder}
          classCss={'no-class'}
          showBackground={selectedContentAge !== '' ? true : false}
          isSideBarOpen={isSideBarOpen}
          onFocus={toggleDropdown}
        />
      </div>

      <div
        className={`surveycheckbox__options ${isSideBarOpen ? "isSideBarOpen-width" : "isSideBarClose-width"}`}
        // style={{ width: isSideBarOpen ? 233 : 286 }}
      >
        {options && options.length > 0 && showOptions && (
          <div className='surveycheckbox__scroll-container'>
            <Checkboxes
              options={options}
              handleSelectedItems={handleSelect}
              handleSelectAll={selectAll}
              selections={selected}
            />
          </div>
        )}
      </div>

      <div className={`surveycheckbox__selected ${"selectedselectors"+selectedSectorsCount}`}>
        {selected &&
          selected.length > 0 &&
          selected.map((item: string, index: number) => {
            return (
              <div key={index} className='surveycheckbox__selected-items'>
                <span className='surveycheckbox__text'>{item}</span>
                <img
                  src={cancelImg}
                  className='surveycheckbox__text-cancel'
                  onClick={() => handleRemoveItem(item)}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SurveyCheckboxDropdown;
