import React, { ReactNode } from "react";
import ErrorModal from './ErrorModal';
import  { ErrorLog } from '../../../services/ErrorBoundary.service';

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorInfo: string;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, errorInfo: ''};
    
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, errorInfo: error.toString() };
  }

  componentDidCatch(error: any, errorInfo: any) {
    ErrorLog(this.state.errorInfo.toString()); 
  }
  
  render() {
    if (this.state.hasError) {
      return <ErrorModal
        show={this.state.hasError}
      />
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
