import React, { useEffect, useState } from 'react';
import AddMembersDropDown from './AddMembersDropDown';
import styled from 'styled-components';
import { searchMemberResponse } from '../../../services/teamsProject.service';
import { USER_TYPE } from '../../../label.constant';

interface IOwnProps {
  isValidCoOwner: boolean;
  isValidEngagementPartner: boolean;
  isValidEngagementManager: boolean;
  onValidation: (isValid: boolean) => void;
}

const AddMembers: React.FC<IOwnProps> = ({
  isValidCoOwner,
  isValidEngagementPartner,
  isValidEngagementManager,
  onValidation
}) => {
  const [allMembers, setAllMembers] = useState([]);
  const [isValidCoOwnerSelection, setIsValidCoOwnerSelection] = useState(isValidCoOwner);
  const [isValidEngagementPartnerSelection, setIsValidEngagementPartnerSelection] =
    useState(isValidEngagementPartner);
  const [isValidEngagementManagerSelection, setIsValidEngagementManagerSelection] =
    useState(isValidEngagementManager);

  useEffect(() => {
    setIsValidCoOwnerSelection(isValidCoOwner);
    setIsValidEngagementPartnerSelection(isValidEngagementPartner);
    setIsValidEngagementManagerSelection(isValidEngagementManager);
  }, [isValidCoOwner, isValidEngagementPartner, isValidEngagementManager]);

  const handleSearch = async (searchTerm: any) => {
    if (searchTerm.length >= 2) {
      try {
        const response = await searchMemberResponse(searchTerm);
        setAllMembers(response.data);
      } catch (error) {
        console.error('Error fetching members:', error);
      }
    }
  };

  const handleSelect = (selectedMembers: any, storageKey: string) => {
    let validCoOwner = true;
    let validEngagementPartner = true;
    let validEngagementManager = true;
    if (storageKey === USER_TYPE.CO_OWNER) {
      if (selectedMembers.length > 0) {
        setIsValidCoOwnerSelection(true);
        validCoOwner = true;
      } else {
        setIsValidCoOwnerSelection(false);
        validCoOwner = false;
      }
    }
    if (storageKey === USER_TYPE.ENGAGEMENT_PARTNER) {
      if (selectedMembers.length > 0) {
        setIsValidEngagementPartnerSelection(true);
        validEngagementPartner = true;
      } else {
        setIsValidEngagementPartnerSelection(false);
        validEngagementPartner = false;
      }
    }
    if (storageKey === USER_TYPE.ENGAGEMENT_MANAGER) {
      if (selectedMembers.length > 0) {
        setIsValidEngagementManagerSelection(true);
        validEngagementManager = true;
      } else {
        setIsValidEngagementManagerSelection(false);
        validEngagementManager = false;
      }
    }
    onValidation(validCoOwner && validEngagementPartner && validEngagementManager);
  };

  return (
    <StyledAddMembersDropDown>
      <div className='multi-drop'>
        <div className='note-container'>
          <span className='note-title'>Note: </span>{' '}
          <span className='note-text'>
            A project specific Teams site will be created and you will automatically be the Teams
            site owner. Please add at least one more co-owner.
          </span>
        </div>
        <div className='dropDown-title'>
          Add co-owner {!isValidCoOwnerSelection && <span className='invalid'>*</span>}
        </div>
        <AddMembersDropDown
          onSearch={handleSearch}
          allMembers={allMembers}
          placeholder={'Select co-owner'}
          storageKey={USER_TYPE.CO_OWNER}
          isValidSelection={isValidCoOwnerSelection}
          onSelect={handleSelect}
        />

        <div className='dropDown-title'>Add member</div>
        <AddMembersDropDown
          onSearch={handleSearch}
          allMembers={allMembers}
          placeholder={'Select member'}
          storageKey={USER_TYPE.MEMBER}
          isValidSelection={true}
          onSelect={handleSelect}
        />

        <div className='dropDown-title'>
          Engagement partner{' '}
          {!isValidEngagementPartnerSelection && <span className='invalid'>*</span>}
        </div>
        <AddMembersDropDown
          onSearch={handleSearch}
          allMembers={allMembers}
          placeholder={'Select an engagement partner'}
          storageKey={USER_TYPE.ENGAGEMENT_PARTNER}
          isValidSelection={isValidEngagementPartnerSelection}
          onSelect={handleSelect}
        />

        <div className='dropDown-title'>
          Engagement manager{' '}
          {!isValidEngagementManagerSelection && <span className='invalid'>*</span>}
        </div>
        <AddMembersDropDown
          onSearch={handleSearch}
          allMembers={allMembers}
          placeholder={'Select an engagement manager'}
          storageKey={USER_TYPE.ENGAGEMENT_MANAGER}
          isValidSelection={isValidEngagementManagerSelection}
          onSelect={handleSelect}
        />
      </div>
    </StyledAddMembersDropDown>
  );
};

const StyledAddMembersDropDown = styled.div`
  .multi-drop {
    /* position: relative; */
    /* max-height: auto;
    overflow-y: auto; */
  }
  .note-container {
    /* width: 793px; */
    height: 40px;
    gap: 0px;
    opacity: 0px;
    margin-bottom: 10px;
  }
  .note-title {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #4d4d5c;
  }
  .note-text {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #4d4d5c;
  }
  .dropDown-title {
    font-family: EYInterstate !important;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #2e2e38;
    /* width: 90px; */
    height: 20px;
    margin-bottom: 5px;
  }
  .invalid {
    color: red;
  }
`;
export default AddMembers;
