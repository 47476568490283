import React from 'react';
import styled from 'styled-components';
import { LOCALSTORAGE_PROJECT_DATA, USER_TYPE } from '../../../label.constant';
import { projectDataConfig } from './addProjectUtils';

const teamMembers = ['John Doe', 'Jane Roe', 'Emily Johnson', 'James Johnson'];
const coOwners = ['Jane Smith', 'John Smith', 'Michael Brown', 'Michelle Brown'];
const engagementPartners = ['Emily Johnson', 'James Johnson', 'Jane Smith', 'John Smith'];
const engagementManagers = ['Michael Brown', 'Michelle Brown', 'John Doe', 'Jane Roe'];

const ProjectPreview = () => {
  const getInitials = (name: string): string => {
    const nameParts = name.trim().split(' ');
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase();
    } else {
      return (nameParts[0][0] + nameParts[nameParts.length - 1][0]).toUpperCase();
    }
  };

  const formatDate = (isoString: any) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return year ? `${day}-${month}-${year}` : '';
  };

  const selectedTemplete: any = localStorage.getItem('selectedTemplate');
  const selctedMilestone: any = localStorage.getItem('milestones');
  const coOwner: any = localStorage.getItem(USER_TYPE.CO_OWNER);
  const member: any = localStorage.getItem(USER_TYPE.MEMBER);
  const engagementPartner: any = localStorage.getItem(USER_TYPE.ENGAGEMENT_PARTNER);
  const engagementManager: any = localStorage.getItem(USER_TYPE.ENGAGEMENT_MANAGER);
  const projectDataLocalStorage: any = localStorage.getItem(LOCALSTORAGE_PROJECT_DATA);
  const projectData = projectDataConfig(JSON.parse(projectDataLocalStorage), false);

  return (
    <StyledAddMilestones>
      {/* 1st section */}
      <div className='preview'>Preview</div>
      <div className='project-card'>
        <div className='title-box'>
          <span className='title-1'>Step 1- Project details</span>
        </div>

        <div className='preview-item'>
          <div className='project-info'>
            <span className='project-label project-name-label'>Project Name : </span>
            <span className='project-name word-break-label'>{projectData?.projectName}</span>
          </div>

          <div className='project-info'>
            <span className='project-label'> Engagement TER (USD) : </span>
            <span className='project-name'>{projectData?.dealSize}</span>
          </div>
        </div>

        <div className='preview-item'>
          <div className='project-info'>
            <span className='project-label'> Category : </span>
            <span className='project-name'>{projectData?.category}</span>
          </div>

          <div className='project-info'>
            <span className='project-label'>Area : </span>
            <span className='project-name'>{projectData?.area}</span>
          </div>
        </div>

        <div className='preview-item'>
          <div className='project-info'>
            <span className='project-label'>Pace Id : </span>
            <span className='project-name'>{projectData?.paceId}</span>
          </div>

          <div className='project-info'>
            <span className='project-label'>Target Sector: </span>
            <span className='project-name'>{projectData?.targetSector}</span>
          </div>
        </div>

        <div className='preview-item'>
          <div className='project-info'>
            <span className='project-label'>Region:</span>
            <span className='project-name'>{projectData?.region}</span>
          </div>

          <div className='project-info'>
            <span className='project-label'>Client name: </span>
            <span className='project-name word-break-label'>{projectData?.clientName}</span>
          </div>
        </div>

        <div className='preview-item'>
          <div className='project-info'>
            <span className='project-label'>Country: </span>
            <span className='project-name'>{projectData?.country}</span>
          </div>
        </div>
      </div>

      {/* 2nd section */}
      <div className='project-card'>
        <div className='title-box'>
          <span className='title-1'>Step 2 - Milestones</span>
        </div>

        <div className='preview-item milestone-preview'>
          {JSON.parse(selctedMilestone) && JSON.parse(selctedMilestone).map((milestone: any) => (
            <div className='project-info'>
              <span className='project-label milestone-label'>{milestone.title}</span>
              <span className='project-name'>
                {milestone.date ? formatDate(milestone.date) : ''}
                {milestone.date && (milestone.fromTime || milestone.toTime) ? ', ' : ''}
                {milestone.fromTime || ''}
                {milestone.fromTime && milestone.toTime ? ' - ' : ''}
                {milestone.toTime || ''}
              </span>
            </div>
          ))}
        </div>
      </div>

      {/* 3rd section */}
      <div className='project-card-member'>
        <div className='title-box'>
          <span className='title-1'>Step 3 - Members</span>
        </div>
        <div className='team-overview'>
          <div className='role-container'>
            <div className='role-title'>Team Members</div>
            {JSON.parse(member) && JSON.parse(member).map((member: any, index: any) => (
              <div className='name'>
                {member.profileImageUrl ? (
                  <img src={member.profileImageUrl} alt={member.displayName} className='selected' />
                ) : (
                  <div className='member-icon'>{getInitials(member.displayName)}</div>
                )}
                <div className='role-name' key={index}>
                  {member.displayName}
                </div>
              </div>
            ))}
          </div>
          <div className='role-container'>
            <div className='role-title'>Co-owners</div>
            {JSON.parse(coOwner) && JSON.parse(coOwner).map((coOwner: any, index: any) => (
              <div className='name'>
                {coOwner.profileImageUrl ? (
                  <img
                    src={coOwner.profileImageUrl}
                    alt={coOwner.displayName}
                    className='selected'
                  />
                ) : (
                  <div className='member-icon'>{getInitials(coOwner.displayName)}</div>
                )}
                <div className='role-name' key={index}>
                  {coOwner.displayName}
                </div>
              </div>
            ))}
          </div>
          <div className='role-container'>
            <div className='role-title'>Engagement Partners</div>
            {JSON.parse(engagementPartner) && JSON.parse(engagementPartner).map((partner: any, index: any) => (
              <div className='name'>
                {partner.profileImageUrl ? (
                  <img
                    src={partner.profileImageUrl}
                    alt={partner.displayName}
                    className='selected'
                  />
                ) : (
                  <div className='member-icon'>{getInitials(partner.displayName)}</div>
                )}
                <div className='role-name' key={index}>
                  {partner.displayName}
                </div>
              </div>
            ))}
          </div>
          <div className='role-container'>
            <div className='role-title'>Engagement Managers</div>
            {JSON.parse(engagementManager) && JSON.parse(engagementManager).map((manager: any, index: any) => (
              <div className='name'>
                {manager.profileImageUrl ? (
                  <img
                    src={manager.profileImageUrl}
                    alt={manager.displayName}
                    className='selected'
                  />
                ) : (
                  <div className='member-icon'>{getInitials(manager.displayName)}</div>
                )}
                <div className='role-name' key={index}>
                  {manager.displayName}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </StyledAddMilestones>
  );
};

const StyledAddMilestones = styled.div`
  /* max-height: 377px;
  overflow-y: auto; */
  .project-card {
    border: 1px solid #9897a6;
    background-color: #f9f9fa;
    /* width: 888px; */
    /* height: 207px; */
    border-radius: 6px;
    margin-bottom: 5px;
  }
  .preview {
    font-family: EYInterstate !important;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #4d4d5c;
    margin-left: 3px;
    margin-bottom: 5px;
  }
  .project-card-member {
    border: 1px solid #9897a6;
    background-color: #f9f9fa;
    border-radius: 6px;
    margin-bottom: 5px;
    max-height: 250px;
    overflow-y: auto;
  }

  .title-box {
    background-color: #9897a6;
    position: relative;
    /* width: 888px; */
    height: 45px;
    border-radius: 6px 6px 0px 0px;
  }

  .title-1 {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: 10px;
    left: 20px;
    line-height: 20px;
    color: #ffffff;
  }

  .preview-item {
    display: flex;
    padding: 6px;
  }

  .project-info {
    display: flex;
    width: 98%;
    margin-left: 20px;
  }

  .project-label {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #4d4d5c;
  }

  .project-name {
    font-family: EYInterstate;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 4px;
    text-align: left;
    color: #4d4d5c;
  }

  .team-overview {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .role-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 24%;
    margin-left: 25px;
  }

  .role-title {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .member-entry {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .member-icon {
    background-color: #007bff;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .role-name {
    text-align: left;
  }
  .name {
    display: flex;
  }

  .name img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  .project-name-label {
    max-width: 100%;
  }
  .word-break-label {
    word-break: break-word;
  }
  .milestone-preview {
    flex-wrap: wrap;
  }
  .milestone-label {
    max-width: 70%;
  }
`;

export default ProjectPreview;
