import React from "react";
import styled, { keyframes } from "styled-components";

interface IOwnProps {
  color?: string;
  parentWidth?: string;
  zeroPercentheight?: string;
  fifltyPercentheight?: string;
  thirdDotSpace?: string;
  secDotSpace?: string;
  indicatorHeight?: string;
}

const LoadingIndicator: React.FC<IOwnProps> = ({
  color,
  parentWidth,
  zeroPercentheight,
  fifltyPercentheight,
  thirdDotSpace,
  secDotSpace,
  indicatorHeight,
}: IOwnProps) => {
  return (
    <StyledLoader
      color={color}
      parentWidth={parentWidth}
      zeroPercentheight={zeroPercentheight}
      fifltyPercentheight={fifltyPercentheight}
      thirdDotSpace={thirdDotSpace}
      secDotSpace={secDotSpace}
      indicatorHeight={indicatorHeight}
    >
      <div> </div>
      <div> </div>
      <div> </div>
    </StyledLoader>
  );
};
const moveVertically = (zeroPercentheight: string, fifltyPercentheight: string) => keyframes`
0% {
  top: 6px;
  height: ${zeroPercentheight ? zeroPercentheight : "38px"};
}
50%,
100% {
  top: 11px;
  height: ${fifltyPercentheight ? fifltyPercentheight : "16px"};
}
`;
const StyledLoader = styled.div<{
  color?: string;
  parentWidth?: string;
  zeroPercentheight?: string;
  fifltyPercentheight?: string;
  thirdDotSpace?: string;
  secDotSpace?: string;
  indicatorHeight?: string;
}>`
  display: inline-block;
  position: relative;
  width: 64px;
  height: ${({ indicatorHeight }) =>
    indicatorHeight ? indicatorHeight : "38px"};

  div {
    display: inline-block;
    position: absolute;
    left: 6px;
    width: ${({ parentWidth }) => (parentWidth ? parentWidth : "13px")};
    background: ${({ color }) => (color ? color : "#2e2e38")};
    // animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    animation: ${({ zeroPercentheight = "", fifltyPercentheight = "" }) =>
      moveVertically(zeroPercentheight, fifltyPercentheight)}
      1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  div:nth-child(1) {
    left: 6px;
    animation-delay: -0.24s;
  }
  div:nth-child(2) {
    left: ${({ secDotSpace }) => (secDotSpace ? secDotSpace : "26px")};
    animation-delay: -0.12s;
  }
  div:nth-child(3) {
    left: ${({ thirdDotSpace }) => (thirdDotSpace ? thirdDotSpace : "45px")};
    animation-delay: 0;
  }
`;

export default LoadingIndicator;
