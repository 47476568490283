import { IUserRolesResponse } from '../models/IUserRoles';
import { apiRoutes, post } from './api.service';
import { customErrorStatus } from './userProfile.service';

export const ErrorLog = async (errorMessage: string): Promise<IUserRolesResponse | any> => {
  const date = new Date();
  const dateTimeString = date.toISOString();
    try {
      let req = {
          "type": "FE",
          "errorMessage": errorMessage,
          "endPoint": "",
          "dateTime": dateTimeString,
          "responseCode": 400,
          "app": "IE"
      }
      const data = await post(apiRoutes.errorLog, req);
      return data;
    } catch (error: any) {
      return customErrorStatus(error?.message);
    }
  };