import { type AnyAction } from 'redux';
import { type Loop, type LoopReducer } from 'redux-loop';
import { IQuery, ISurveyList } from '../../models/IQueryType';
import {
  SELECTED_DATA_SOURCE_SECTION,
  SELECTED_DATA_SOURCE,
  SET_PROMPT_INPROGRESS,
  SAVE_USER_CHAT_THREAD_HISTORY,
  SET_SELECTED_CHAT_THREAD,
  GET_CHAT_HISTORY,
  SET_CHAT_ERRORS,
  SET_CHAT_HISTORY,
  SET_CHAT_TABLE,
  SET_CHAT_SUMMARY,
  SET_CHAT_SOURCES,
  SET_WHOLE_CHAT_TABLE,
  SET_CLEAR_SUMMARY,
  CLEAR_CHAT_HISTORY,
  TOGGLE_GPT_SIDEBAR,
  TOGGLE_LOADER,
  TOOBLE_CLEAR_LOADER,
  SET_DOWNLOAD_FILE_MESSAGE,
  SET_ERROR_MESSAGE,
  SET_CHUNK_DATA_LOADING,
  IS_ACTIVE_TAB,
  SET_SECTOR_CHUNK_DATA_LOADING,
  SET_RELEVANT_FILES,
  SAVE_USER_DOCUMENT_THREAD_HISTORY,
  SET_SELECTED_DOCUMENT_THREAD,
  LOAD_CHAT_MESSAGE_HISTORY,
  REGENERATE_CHAT_SUMMARY,
  SET_USER_FIRST_QUERY,
  SET_IS_ANY_SOURCE_SELECTED,
  SET_SELECTED_SURVEY_LIST,
  SET_SELECTED_SURVEY_SECTORS,
  SET_LAST_SAVED_THREAD_ID,
  SET_IS_CHAT_THREAD_LOADED,
  SET_SURVEY_DOC_LISTS,
  SET_SURVEY_FILTERED_LISTS,
  SET_LAST_DELETED_THREAD_ID,
  IS_NEW_CHAT_CLICKED
} from '../actions/userChatActions';

import { GenrateRandomAuthor } from '../../utils/commonUtils';

export interface IUserChatState {
  selectedDataSourceSection: string;
  selectedDataSource: string;
  promptInProgress: boolean;
  chatThreadHistory: any;
  documentThreadHistory: any;
  selectedChatThread: any;
  selectedDocumentThread: any;
  activeTabValue: string;
  chatData: IQuery[];
  isSideBarOpen: boolean;
  isLoading: boolean;
  isClearLoading: boolean;
  downloadFileMessage?: string;
  errorMessage?: string;
  chatDataSummmary: any;
  chunkLoading: boolean;
  userFirstQuery: string;
  anySourceSelected?: boolean;
  selectedSurveyList?: string[];
  selectedSurveySectors?: string[];
  lastSavedThreadID?: number;
  isChatThreadLoaded?: boolean;
  surveyDocList: any;
  filteredList?: string[];
  lastDeletedThreadID?: number;
  isClearChatHistory?: boolean;
}

export const userChatInitialState: IUserChatState = {
  selectedDataSourceSection: '',
  selectedDataSource: '',
  promptInProgress: false,
  chatThreadHistory: [],
  documentThreadHistory: [],
  selectedChatThread: {},
  selectedDocumentThread: {},
  chatData: [],
  isSideBarOpen: false,
  isLoading: false,
  isClearLoading: false,
  downloadFileMessage: '',
  errorMessage: '',
  activeTabValue: 'Chat history',
  chatDataSummmary: {
    table: []
  },

  chunkLoading: false,
  userFirstQuery: '',
  anySourceSelected: undefined,
  selectedSurveyList: [],
  selectedSurveySectors: [],
  lastSavedThreadID: 0,
  isChatThreadLoaded: true,
  surveyDocList: [],
  filteredList: [],
  lastDeletedThreadID: 0,
  isClearChatHistory: false
};
export const userChatReducer = (
  state: IUserChatState = userChatInitialState,
  action: AnyAction
) => {
  const { type, payload } = action;
  switch (type) {
    case SELECTED_DATA_SOURCE_SECTION: {
      return {
        ...state,
        selectedDataSourceSection: action.section
      };
    }
    case SELECTED_DATA_SOURCE: {
      return {
        ...state,
        selectedDataSource: action.source
      };
    }
    case SET_PROMPT_INPROGRESS: {
      return {
        ...state,
        promptInProgress: action.value
      };
    }
    case SAVE_USER_CHAT_THREAD_HISTORY: {
      return {
        ...state,
        chatThreadHistory: action.history
      };
    }
    case SET_SELECTED_CHAT_THREAD: {
      return {
        ...state,
        selectedChatThread: action.thread
      };
    }
    case GET_CHAT_HISTORY: {
      return {
        ...state
      };
    }
    case SET_CHAT_ERRORS: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };
      existingTable.table[tableLength - 1] = Object.assign(
        {},
        ...state.chatDataSummmary.table,
        { ...payload[0] },
        { ...payload[1] }
      );

      return {
        ...state,
        chatDataSummmary: { ...existingTable }
      };
    }

    case LOAD_CHAT_MESSAGE_HISTORY: {
      return {
        ...state,
        chatDataSummmary: {
          table: payload
        }
      };
    }
    case SET_CHAT_HISTORY: {
      return {
        ...state,
        chatDataSummmary: {
          table: [...state.chatDataSummmary.table, { ...payload[0] }]
        }
      };
    }
    case SET_CHAT_TABLE: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };
      existingTable.table[tableLength - 1] = Object.assign(
        {},
        ...state.chatDataSummmary.table,
        { ...payload[0] },
        { ...payload[1] }
      );

      return {
        ...state,
        chatDataSummmary: { ...existingTable }
      };
    }

    case SET_CHAT_SUMMARY: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };

      if (tableLength > 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            summary: existingTable.table[tableLength - 1].SEResponse.summary
              ? existingTable.table[tableLength - 1].SEResponse.summary.concat(payload)
              : payload
          }
        );
      }
      return {
        ...state,
        chatDataSummmary: { ...existingTable }
      };
    }
    case SET_CHAT_SOURCES: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };

      if (tableLength > 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            Sources: payload
          }
        );
      }
      return {
        ...state,
        chatDataSummmary: { ...existingTable }
      };
    }
    case REGENERATE_CHAT_SUMMARY: {
      const existingTable = { ...state.chatDataSummmary };
      existingTable.table.pop();
      return {
        ...state,
        chatDataSummmary: { ...existingTable }
      };
    }

    case SET_WHOLE_CHAT_TABLE: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable = { ...state.chatDataSummmary };

      if (tableLength >= 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            showTable: payload
          }
        );
      }
      return {
        ...state,
        chatDataSummmary: { ...existingTable }
      };
    }
    case SET_RELEVANT_FILES: {
      const tableLength = state.chatDataSummmary.table.length;
      const existingTable: any = { ...state.chatDataSummmary };
      if (tableLength > 0) {
        existingTable.table[tableLength - 1].SEResponse = Object.assign(
          {},
          existingTable.table[tableLength - 1].SEResponse,
          {
            relevantFiles: payload.map((c: any) => {
              return { ...c, authors: c.authors };
            })
          }
        );
      }

      return {
        ...state,
        chatDataSummmary: { ...existingTable }
      };
    }
    case SET_CLEAR_SUMMARY: {
      return {
        ...state,
        chatDataSummmary: ''
      };
    }
    case CLEAR_CHAT_HISTORY: {
      return {
        ...state,
        chatDataSummmary: {
          table: []
        },
        isLoading: false,
        isClearLoading: false
      };
    }

    case TOGGLE_GPT_SIDEBAR: {
      return {
        ...state,
        isSideBarOpen: payload
      };
    }
    case TOGGLE_LOADER: {
      return {
        ...state,
        isLoading: payload
      };
    }
    case TOOBLE_CLEAR_LOADER: {
      return {
        ...state,
        isClearLoading: payload
      };
    }
    case SET_DOWNLOAD_FILE_MESSAGE: {
      return {
        ...state,
        downloadFileMessage: payload
      };
    }

    case SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: payload
      };
    }
    case SET_CHUNK_DATA_LOADING: {
      return {
        ...state,
        chunkLoading: payload
      };
    }
    case IS_ACTIVE_TAB: {
      return {
        ...state,
        activeTabValue: action.value
      };
    }
    case SAVE_USER_DOCUMENT_THREAD_HISTORY: {
      return {
        ...state,
        documentThreadHistory: action.history
      };
    }
    case SET_SELECTED_DOCUMENT_THREAD: {
      return {
        ...state,
        selectedDocumentThread: action.thread
      };
    }
    case SET_SECTOR_CHUNK_DATA_LOADING: {
      if (payload === false) {
        const tableLength = state.chatDataSummmary.table.length;
        const existingTable: any = { ...state.chatDataSummmary };

        if (tableLength > 0 && existingTable.table[tableLength - 1].SEResponse) {
          let newsummary = existingTable.table[tableLength - 1].SEResponse.summary;
          if (newsummary.split(' ').pop() === 'files' || newsummary.split(' ').pop() === 'files:') {
            newsummary = newsummary.substring(0, newsummary.lastIndexOf(' '));
          }
          existingTable.table[tableLength - 1].SEResponse = Object.assign(
            {},
            existingTable.table[tableLength - 1].SEResponse,
            {
              summary: newsummary
            }
          );
        }
        return {
          ...state,
          chatDataSummmary: { ...existingTable },
          chunkLoading: payload
        };
      } else {
        return {
          ...state,
          chunkLoading: payload
        };
      }
    }
    case SET_USER_FIRST_QUERY:
      return {
        ...state,
        userFirstQuery: payload
      };
    case SET_IS_ANY_SOURCE_SELECTED:
      return {
        ...state,
        anySourceSelected: action.value
      };
    case SET_SELECTED_SURVEY_LIST:
      return {
        ...state,
        selectedSurveyList: payload
      };
    case SET_SELECTED_SURVEY_SECTORS:
      return {
        ...state,
        selectedSurveySectors: payload
      };
    case SET_LAST_SAVED_THREAD_ID:
      return {
        ...state,
        lastSavedThreadID: action.value
      };
    case SET_LAST_DELETED_THREAD_ID:
      return {
        ...state,
        lastDeletedThreadID: action.value
      };
    case SET_IS_CHAT_THREAD_LOADED:
      return {
        ...state,
        isChatThreadLoaded: action.value
      };
    case SET_SURVEY_DOC_LISTS:
      return {
        ...state,
        surveyDocList: payload
      };
    case SET_SURVEY_FILTERED_LISTS:
      return {
        ...state,
        filteredList: payload
      };
    case IS_NEW_CHAT_CLICKED:
      return {
        ...state,
        isClearChatHistory: payload
      };
    default: {
      return state;
    }
  }
};
