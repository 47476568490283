import React from 'react';
import PageConfig from '../../../ui-constants/en.json';
import './MessageEditMode.scss';

type MessageEditModeProps = {
  message: string;
  handleSendMessage: any;
  setIsEditMode: any;
};

const MessageEditMode = ({ message, handleSendMessage, setIsEditMode }: MessageEditModeProps) => {
  const [inputValue, setInputValue] = React.useState(message);
  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (inputValue.trim().length && ev.key === 'Enter') {
      ev.preventDefault();
      handleSendMessage?.(null, inputValue);
      setIsEditMode(false);
    }
  };
  return (
    <div className={'openaiquery__query-box edit-mode'}>
      <input
        value={inputValue}
        maxLength={PageConfig.charLimit}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
      />
      <div className='actions-wrapper'>
        <button
          className='btn-openAi btn-openAi_primary'
          onClick={() => {
            if (inputValue !== message) {
              handleSendMessage(null, inputValue);
              setIsEditMode(false);
            }
          }}>
          Save and submit
        </button>
        <button
          className=' btn-openAi btn-openAi_secondary'
          onClick={() => {
            setInputValue('');
            setIsEditMode(false);
          }}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default MessageEditMode;
