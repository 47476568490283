import styled from 'styled-components';
import {warningError} from '../../../commonImage';

const EyExpertDisclaimer: React.FC = () => { 
    return (
        <EyExpertDisclaimerStyle>
            <div className="warningContainer">
                <img src={warningError} className='warningError'></img>
                <span className="suggestion">Please avoid entering any personally identifiable information (PII) such as company names, individual names or other sensitive data in your query.</span>
            </div>
        </EyExpertDisclaimerStyle>
    );
}

const EyExpertDisclaimerStyle = styled.div<{}>`
.warningContainer {
   text-align: left;
    display: flex;
    align-items: center;
}
  
  .warningError {
    margin-right: 10px;
  }
  .suggestion {
    color: #ED6500;
    font-size: 10px;
    font-weight: 300;
    font-style: 'EYInterstate';
  }
 img.warningError {
    height: 12px;
    width: 12px;
    position: relative;
}
`;

export default EyExpertDisclaimer;
 