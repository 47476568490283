import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IQuery, RESPONSE_BY } from '../../models/IQueryType';
import SendMessage from '../openAIChat/SendMessage';
import ChatReply from '../openAIChat/ChatReply';
import InputBox from '../openAIChat/InputBox';
import LABELS from '../../ui-constants/en.json';
import { useDispatch, useSelector } from 'react-redux';
import { HttpStatusCode } from '../../ui-constants/base/enum';
import {
  setChatHistory,
  setChatSummary,
  setWhoelChatTable,
  toggleLoader,
  setChunkLoading,
  setUserFirstQuery,
  setDownloadFileMessageWord,
  clearDownloadFileMessage,
  setChatErrors,
  setChatTable,
  setRelevantFiles,
  regenerateChatSummary,
  setAnySourceSelected,
  setIsChatThreadLoaded,
  setChatMessageHistory,
  saveUserChatThreadHistory,
  setLastDeletedThreadID,
  setErrorMessage,
  setChatSources
} from '../../store/actions/userChatActions';

import {
  IsJSONResponse,
  checkOnySpecialCharacter,
  getComputedRequestObject,
  handleSessionID,
  handleUserChatThreadHistory,
  setThreadsUnSelected
} from '../../utils/commonUtils';
import { initializeSession, setCurrentSessionId } from '../../services/session.service';
import './SurveyInsights.scss';
import { IStore } from '../../store';
import {
  getChatThreadConversations,
  getSurveysQueryResponse,
  saveDownloadChatActivity,
  saveUserChatHistory
} from '../../services/chatQuery.service';
import { downloadImg } from '../../commonImage';
import LinkButton from '../openAIChat/LinkButton/LinkButton';
import exportToWord from '../openAIChat/utils/exportToWord';
import { setSurveyDocOpenClose } from '../../store/actions/commonActions';
import { selectedSourceValues, setSideBarOpenClose } from '../../store/actions/commonActions';
import { DataSourceTypes } from '../../models/IDataSourceTypes';
import errorStatus from '../../utils/ErrorStatus';
import { MotifToast } from '@ey-xd/motif-react';
import Breadcrumb from '../common/breadcrumb/Breadcrumb';

function getXMonthsDate(date: Date, months: number) {
  date.setMonth(date.getMonth() - (months - 1));

  return date;
}

const fileRegex = new RegExp('relevant_files:', 'i');

const timeoutError = [
  {
    [RESPONSE_BY.STRATEGY_EDGE]: {
      summary: LABELS.chat_reply.SE_TOKEN_ERROR_MSG,
      content: [],
      responseType: 'error',
      sources: ['Discover']
    }
  }
];

const replyServerError = [
  {
    [RESPONSE_BY.STRATEGY_EDGE]: {
      summary: LABELS.chat_reply.OPENAI_ERROR_MSG,
      content: [],
      responseType: 'error',
      sources: ['Discover']
    }
  }
];
const replyNotFoundError = [
  {
    [RESPONSE_BY.STRATEGY_EDGE]: {
      summary: `\n\n\n${LABELS.chat_reply.OPENAI_NOT_FOUND_ERROR_MSG}`,
      content: [],
      responseType: 'error',
      sources: ['Discover']
    }
  }
];
let chatResponse: string = '';
const SurveyInsights = () => {
  const [seQuery, setSeQuery] = useState<IQuery[]>([]);
  const { chatDataSummmary, isClearLoading, isLoading, chunkLoading, userFirstQuery } = useSelector(
    (state: any) => state.userChat
  );
  const [queryText, setQueryText] = useState('');
  const refToScroll = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLTextAreaElement>(null);
  const [isValidQuery, setIsValidQuery] = useState<boolean>(false);
  const [isTableApplyClick, setIsTableApplyClick] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { isRightSideBarOpen, anySourceSelected } = useSelector((state: IStore) => state.common);
  const userData = useSelector((state: IStore) => state.userProfile?.userData);

  const [chatLoadCompleted, setChatLoadCompleted] = useState(false);
  const [regeneratedMessageId, setRegeneratedMessageId] = useState(0);
  const [firstResponseTime, setFirstResponseTime] = useState(0);
  const [totalResponseTime, setTotalResponseTime] = useState<number|null>();
  const userChatThreads = useSelector((store: IStore) => store.userChat.chatThreadHistory);
  const [currentUserChatThreads, setCurrentUserChatThreads] = useState(userChatThreads);
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);
  const checkedCheckboxes = useSelector((state: any) => state.common?.checkedCheckboxes);
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const validURLinputValue = useSelector((state: IStore) => state?.common?.validURLinputValue);
  const selectedSurveyList = useSelector((store: IStore) => store.userChat.selectedSurveyList);
  const isSurveyDocOpen = useSelector((state: IStore) => state.common.isSurveyDocOpen);
  const selectedSurveySectors = useSelector(
    (store: IStore) => store.userChat.selectedSurveySectors
  );
  const isChatThreadLoaded = useSelector((store: IStore) => store?.userChat?.isChatThreadLoaded);
  const lastSavedThreadID = useSelector((state: IStore) => state?.userChat?.lastSavedThreadID);
  const [isSurveyDocument, setIsSurveyDocument] = useState(isSurveyDocOpen);
  const lastDeletedThreadID = useSelector((state: IStore) => state?.userChat?.lastDeletedThreadID);
  const [showDownloadInitiated, setsShowDownloadInitiated] = useState(false);
  const [controller, setController] = useState<any>(null);
  const [isCompletelyAbortedResposne, setIsCompletelyAbortedResposne] = useState<boolean>(false);
  const [isParitallyAbortedResponse, setIsParitallyAbortedResponse] = useState<boolean>(false);

  const loadSelectedThreadConversations = async () => {
    dispatch(setIsChatThreadLoaded(false));
    setCurrentSessionId(selectedChatThread?.sessionId);
    let currentThreadConversations = await getChatThreadConversations(selectedChatThread?.threadId);
    if (currentThreadConversations?.messages) {
      let sources: any = [];
      let formattedREsponse = currentThreadConversations.messages.map((message: any) => {
        if (sources.indexOf(message.dataSubSource) === -1) sources.push(message.dataSubSource);
        let computedResponse: any = {};
        if (IsJSONResponse(message.response)) {
          computedResponse = JSON.parse(message.response);
          computedResponse.messageId = message.messageId;
        } else {
          computedResponse = {
            summary: message.response,
            Sources: [message.dataSubSource],
            messageId: message.messageId
          };
        }

        const obj = {
          [RESPONSE_BY.USER]: {
            summary: message.question
          },
          [RESPONSE_BY.STRATEGY_EDGE]: computedResponse
        };
        return obj;
      });
      dispatch(setChatMessageHistory(formattedREsponse));
      dispatch(
        selectedSourceValues({
          eyIPInterRadio:
            selectedChatThread.dataSource === DataSourceTypes.EYIP
              ? selectedChatThread.dataSubSource
              : '',
          checkedCheckboxes: [],
          validURLinputValue: ''
        })
      );
      dispatch(setIsChatThreadLoaded(true));
    }
  };

  useEffect(() => {
    if (selectedChatThread?.threadId && lastSavedThreadID !== selectedChatThread?.threadId) {
      dispatch(setSideBarOpenClose(true));
      loadSelectedThreadConversations();
    }
    if (!selectedChatThread?.threadId) {
      setTimeout(() => {
        setIsSurveyDocument(false);
        dispatch(setSurveyDocOpenClose(false));

        dispatch(setSideBarOpenClose(true));
      }, 100);
    }
  }, [selectedChatThread]);

  useEffect(() => {
    setCurrentUserChatThreads(userChatThreads);
  }, [userChatThreads]);

  useEffect(() => {
    if (lastDeletedThreadID != 0) {
      const updated = userChatThreads.filter((item: any) => item.threadId !== lastDeletedThreadID);
      setCurrentUserChatThreads(updated);
      dispatch(saveUserChatThreadHistory(updated));
      dispatch(setLastDeletedThreadID(0));
    }
  }, [lastDeletedThreadID]);

  useEffect(() => {
    setSeQuery(() => chatDataSummmary?.table);
  }, [chatDataSummmary]);

  const updateFirstResponseTime = (firstByteTime: any, startTime: number) => {
    if (firstByteTime === null) {
      firstByteTime = performance.now();
      setFirstResponseTime((firstByteTime - startTime) / 1000);
    }
  }

  const updateTotalResponseTime = (startTime: number, isStaticResponse: boolean = false) => {
    if (isStaticResponse) {
      setTotalResponseTime(null);
      return;
    } else {
      const endTime = performance.now(); // Record the end time
      const duration = (endTime - startTime) / 1000; // Calculate the duration
      setTotalResponseTime(duration); // Update state with the response time
    }
  };

  const genericChatApiCall = async (genericbody: any) => {
    // setIsTableApplyClick(false);
    scrollToBottom();
    let chunks = '';
    let check = false;
    let surveySummary = '';
    const controller = new AbortController();
    const startTime = performance.now();
    let firstByteTime : any = null;
    setController(controller);
    try {
      const response: any = await getSurveysQueryResponse(genericbody, controller);
      scrollToBottom();
      const { status, statusText } = response;
      if (controller.signal.aborted) {
        const stopGenerationErrorMsg = [{
          [RESPONSE_BY.STRATEGY_EDGE]: {
            summary: LABELS.chat_reply.STOP_GENERATION_MSG,
            content: [],
            responseType: 'stop_generation',
            sources: [],
            isCompletelyAbortedResposne: true,
            isParitallyAbortedResponse: false
          }
        }];
        dispatch(setChatErrors([...stopGenerationErrorMsg]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        setChatLoadCompleted(true);
      }
      else if (
        status === HttpStatusCode.InternalServerError ||
        status === HttpStatusCode.TimedOut ||
        status === HttpStatusCode.BadRequest ||
        statusText === LABELS.SERVER_ERROR
      ) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChatErrors([...replyServerError]));
        dispatch(toggleLoader(false));
        setTimeout(()=>{return scrollToBottom()},100);
        setChatLoadCompleted(true);
        updateTotalResponseTime(startTime, true);
      } else if (status === HttpStatusCode.NotFound) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChatErrors([...replyNotFoundError]));
        dispatch(toggleLoader(false));
        setTimeout(()=>{return scrollToBottom()},100);
        setChatLoadCompleted(true);
        updateTotalResponseTime(startTime, true);
      }  else {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               
        let checksummary=false;
        updateFirstResponseTime(firstByteTime, startTime);
        const reader = response.body.getReader();
        reader
          .read()
          .then(function pump({ done, value }: any) {
            const decodedChunks = new TextDecoder().decode(value);
            chunks += decodedChunks.replaceAll('\n\n', '\n');
            dispatch(setChunkLoading(true));
            if (!check) {
              dispatch(
                setChatTable([
                  {
                    SEResponse: { Content: [], Sources: '', downloadURL: '' }
                  }
                ])
              );
              if (chunks && checksummary==false) {
                if(decodedChunks?.trim().toLowerCase().indexOf('source_files:')>-1)
                   {
                    dispatch(setChatSummary(decodedChunks?.split('source_files:')[0]))
                    checksummary=true;
                   }
                  else{
                   dispatch(setChatSummary(decodedChunks));
                  }
              }
              check = true;
            } else {
              if ((decodedChunks && !(decodedChunks?.trim().toLowerCase().indexOf('source_files:')>-1) && checksummary==false)) {
                dispatch(setChatSummary(decodedChunks));
                scrollToBottom();
              }
              else if(checksummary===false){
                 dispatch(setChatSummary(decodedChunks?.split('source_files:')[0]));
                checksummary=true;
                scrollToBottom();
              }
            }
            if (done) {
              let surveySources:any = chunks?.indexOf('source_files:')>-1?chunks?.split('source_files:')[1]?.replace("[","").replace("]",""):"";
             if(surveySources.length>0){
              surveySources.trim().lastIndexOf(".")>-1 ?surveySources=surveySources?.trim()?.slice(0,-1):"";
              dispatch(
                setChatSources(surveySources)
              );
             }
             else{
              dispatch(
                setChatSources('EY conducted surveys ')
              );
             }
              updateTotalResponseTime(startTime);
              setChatLoadCompleted(true);
              dispatch(setChunkLoading(false));
              dispatch(setWhoelChatTable(true));
              dispatch(toggleLoader(false));
              scrollToBottom();
              setRelevantTableData(chunks);
              chunks = '';
              surveySummary='';
              return;
            }
            return reader.read().then(pump);
          })
          .catch((err: any) => {
            // dispatch(setChatHistory([...replyServerError]));
            // dispatch(setChatErrors([...replyServerError]));
            dispatch(
              setChatSources('EY conducted surveys ')
            );
            updateTotalResponseTime(startTime, true);
            dispatch(setChunkLoading(false));
            scrollToBottom();
            dispatch(toggleLoader(false));
          });
      }
    } catch (error) {
      setChatLoadCompleted(true);
      dispatch(setChunkLoading(false));
      if (controller.signal.aborted) {
        dispatch(setChatErrors([...timeoutError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        scrollToBottom();
      } else {
        dispatch(setChatErrors([...replyServerError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        scrollToBottom();
      }
    }
  };
  const setRelevantTableData = (str: string) => {
    try {
      const splitwiseFiles = str.split(fileRegex);
      if (splitwiseFiles.length > 1) {
        const filesstring = splitwiseFiles[1];
        const result = JSON.parse(filesstring.replace('\n', '').replace('\t', ''));

        if (result.length) {
          const updatedRes = result.map((res: any) => {
            let pagelist = res.page_number.reduce((newPages: any, page: any) => {
              if (newPages.includes(page)) {
                return newPages;
              }
              return [...newPages, page];
            }, []);
            return { ...res, page_number: pagelist };
          });
          dispatch(setRelevantFiles(updatedRes));
        }
      }
    } catch (er: any) {
      return errorStatus(er?.response?.status);
    }
  };
  useEffect(() => {
    if (userFirstQuery?.length > 0 && Object.keys(selectedChatThread).length === 0) {
      sendChatQuery(userFirstQuery);
    }
  }, [userFirstQuery]);

  const handleOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (checkOnySpecialCharacter(ev.target.value)) setIsValidQuery(false);
    else {
      setIsValidQuery(true);
    }
    setQueryText(ev.target.value);
  };

  const handleOnFocus = () => {
    if (selectedSurveyList && selectedSurveyList.length == 0) setIsSurveyDocument(true);
    return;
  };
  const scrollToBottom = () => {
    refToScroll?.current?.scroll({
      left: 0,
      top: refToScroll.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  const handleScrollToBottom = () => {
    scrollToBottom();
  };

  useEffect(() => {
    initializeSession();
  }, []);

  useEffect(() => {
    refInput.current?.focus();
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [seQuery]);

  //API call to get chat response with chat query
  const handleSendMessage = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    (selectedSurveyList && selectedSurveyList.length == 0) ?? setIsSurveyDocument(true);
    var chatreplyelements = document.getElementsByClassName('chatreply');
    for (let indx = 0; indx < chatreplyelements.length; indx++) {
      chatreplyelements[indx].classList.add('disabled');
    }
    if (!isClearLoading && !isLoading && anySourceSelected) {
      if (queryText || query) {
        handleSessionID(selectedChatThread);
        setRegeneratedMessageId(0);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
      }
    } else if (!anySourceSelected) {
      dispatch(setSideBarOpenClose(true));
      dispatch(setAnySourceSelected(false));
    }
  };

  const handleRegenerate = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading && anySourceSelected) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        setRegeneratedMessageId(
          chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1]?.SEResponse?.messageId ||
            regeneratedMessageId
        );
        dispatch(regenerateChatSummary([newMsg]));
        dispatch(setChatHistory([newMsg]));
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
      }
    }
  };

  const sendChatQuery = async (summary: string) => {
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    const surveyFromdate=localStorage.getItem('queryFromDate');
    const surveyToDate=localStorage.getItem('queryToDate');
    const surveyCheckedvalues=document.querySelectorAll(".surveycheckboxlist__scroll-container input:checked");
    let idx;
    let selectedSurveyListArr = [];
    for (idx = 0; idx < surveyCheckedvalues.length; idx++) {
      if (
        surveyCheckedvalues[idx]
          ?.closest('label')
          ?.querySelector('span')
          ?.innerHTML.toLowerCase() != 'select all'
      )
        selectedSurveyListArr.push(
          surveyCheckedvalues[idx]?.getAttribute('name')
        );
    }
    let queryBody;
    if (surveyFromdate && surveyFromdate.length > 0 && surveyToDate && surveyFromdate.length > 0) {
      queryBody = JSON.stringify({
        query: summary.trim(),
        surveys:
          selectedSurveyListArr && selectedSurveyListArr.length > 0
            ? selectedSurveyListArr
            : selectedSurveyList,
        sectors: selectedSurveySectors,
        fromdate: surveyFromdate,
        todate: surveyToDate
      });
    } else if (surveyFromdate && surveyFromdate.length > 0) {
      queryBody = JSON.stringify({
        query: summary.trim(),
        surveys:
          selectedSurveyListArr && selectedSurveyListArr.length > 0
            ? selectedSurveyListArr
            : selectedSurveyList,
        sectors: selectedSurveySectors,
        fromdate: surveyFromdate
      });
    } else {
      queryBody = JSON.stringify({
        query: summary.trim(),
        surveys:
          selectedSurveyListArr && selectedSurveyListArr.length > 0
            ? selectedSurveyListArr
            : selectedSurveyList,
        sectors: selectedSurveySectors,
        fromdate: getXMonthsDate(new Date(), 36).toISOString()
      });
    }
    dispatch(toggleLoader(true));
    genericChatApiCall(queryBody);
    setIsTableApplyClick(true);
  };

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      if (!anySourceSelected) {
        dispatch(setErrorMessage('Please select a data source to continue'));
      } else if (!selectedSurveyList?.length && eyIPInterRadio === 'Surveys') {
        dispatch(setSurveyDocOpenClose(true));
        dispatch(setErrorMessage('Please select the relevant survey(s) to continue'));
      } else {
        handleSendMessage(ev);
      }
    }
  };

  useEffect(() => {
    if (chatLoadCompleted) saveChatConversation();
  }, [chatLoadCompleted]);

  const saveChatConversation = async () => {
    if (chatDataSummmary?.table?.length === 0) return;
    let requestObject = getComputedRequestObject(
      selectedChatThread,
      regeneratedMessageId,
      chatDataSummmary,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue,
      firstResponseTime,
      totalResponseTime,
      false,
      isCompletelyAbortedResposne,
      isParitallyAbortedResponse
    );
    const response: any = await saveUserChatHistory(requestObject);
    if (response && response?.threadId) {
      if (response.threadId !== selectedChatThread?.threadId) {
        setThreadsUnSelected(currentUserChatThreads);
        handleUserChatThreadHistory(
          requestObject,
          response.threadId,
          currentUserChatThreads,
          userData,
          dispatch
        );
      }
    }
    setRegeneratedMessageId(response?.messageId);
    setChatLoadCompleted(false);
  };

  const handleDocDownload = async () => {
    dispatch(setDownloadFileMessageWord());
    setsShowDownloadInitiated(true);
    await exportToWord(
      seQuery,
      userData.profileImage,
      RESPONSE_BY.SURVEY,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue
    );
    dispatch(clearDownloadFileMessage());
    saveDownloadChatActivity('EY conducted surveys');
    setTimeout(() => {
      setsShowDownloadInitiated(false);
    }, 1000);
  };
  const handleSurveyDocView = () => {
    setIsSurveyDocument(!isSurveyDocument);
  };
  useEffect(() => {
    dispatch(setSurveyDocOpenClose(isSurveyDocument));
  }, [isSurveyDocument]);

  const handleStopGeneration = () => {
    controller.abort();
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    if(chunkLoading) {
      setIsParitallyAbortedResponse(true);
      let lastChatData = chatDataSummmary?.table[chatDataSummmary?.table?.length - 1]?.SEResponse || undefined;
      if (lastChatData) {
        if (!("isCompletelyAbortedResposne" in lastChatData) && !("isParitallyAbortedResponse" in lastChatData)) {
          lastChatData.isCompletelyAbortedResposne = false;
          lastChatData.isParitallyAbortedResponse = true;
        }
      }
      setChatLoadCompleted(true);
    } 
    else {
      setIsCompletelyAbortedResposne(true);
    }
  }

  const getResponseFlag = (SEResponse: any, flag: string) => {
    if(flag in SEResponse) {
      return SEResponse[flag];
    } else {
      return false;
    }
  };
  const breadcrumbs = [{ label: 'Home' }, { label: 'EY Source Data', current: true }];

  return (
    <>
      <div
        className={isRightSideBarOpen ? 'surveyChatContainer' : 'surveyChatContainer__sidebarClosed'}
        // style={{
        //   display: isRightSideBarOpen ? 'flex' : 'block',
        //   flexDirection: isRightSideBarOpen ? 'column' : 'row'
        // }}
      >
        <div
          className={
            isRightSideBarOpen ? 'surveyChatContainer__breadcrumbOpen' : 'surveyChatContainer__breadcrumb'
          }>
          <Breadcrumb items={breadcrumbs} />
        </div>
        <div>
          <div
            className={`surveyChatContainer__survey-button-container ${isSurveyDocument ? "isSurveyDoc" : "isNotSurveyDoc"}`}
            // style={{ margin: isSurveyDocument ? '18px 26px' : '18px 34px' }}
          >
            <div className='surveyChatContainer__survey-button' onClick={handleSurveyDocView}>
              {!isSurveyDocument ? LABELS.VIEW_DOCS : LABELS.HIDE_DOCS}
            </div>
          </div>
        </div>
        <div
          ref={refToScroll}
          className={`surveyChatContainer__scroll-container ${seQuery?.length > 0 && "scroll-height"}`}
          // style={{
          //   minHeight: seQuery?.length > 0 ? 160 : 0
          // }}
        >
          {isChatThreadLoaded ? (
            seQuery?.map((chat: IQuery, ind: number) => {
              const { user, SEResponse } = chat;
              return (
                <React.Fragment key={ind}>
                  {showDownloadInitiated && (
                    <MotifToast
                      className={'surveyChatContainer__toast-container-public'}
                      variant='info'
                      actionName=''
                      position='bottom'
                      onClose={() => {
                        setsShowDownloadInitiated(false);
                      }}>
                      <div>Downloading</div>
                    </MotifToast>
                  )}
                  {user && (
                    <SendMessage
                      key={ind}
                      message={user}
                      isNew={true}
                      userQueryWithResponse={chat}
                      loader={isLoading}
                      chatData={{ SEResponse }}
                      handleSendMessage={handleSendMessage}
                    />
                  )}

                  {SEResponse && (
                    <ChatReply
                      message={user?.summary}
                      chatData={{ SEResponse }}
                      key={ind.toString() + '__strctrd-se-chats'}
                      isNew={true}
                      isSE={true}
                      messageLoading={isLoading}
                      chunkLoading={chunkLoading}
                      isLastItem={ind === seQuery.length - 1}
                      scrollToBottom={handleScrollToBottom}
                      handleRegenerate={handleRegenerate}
                      isCompletelyAbortedResposne={getResponseFlag(SEResponse, "isCompletelyAbortedResposne")}
                      isParitallyAbortedResponse={getResponseFlag(SEResponse, "isParitallyAbortedResponse")}
                    />
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <div className='loader-position'>
              <div className='loader'></div>
              <p>Loading...</p>
            </div>
          )}
          {isLoading && !chunkLoading && <ChatReply loader={isLoading} isNew={true} isSE={true} />}
          <div className="stop-generation-container">
            {(isLoading || chunkLoading) && <button onClick={handleStopGeneration} className='stop-generation-button'>Stop Generating</button>}
          </div>
        </div>

        {isChatThreadLoaded && (
          <div className='surveyChatContainer__input-container'>
            <InputBox
              selectedSegment={true}
              queryText={queryText}
              refInput={refInput}
              hasQueries={seQuery?.length > 0}
              handleOnChange={handleOnChange}
              handleResetMessage={() => {}}
              handleSendMessage={handleSendMessage}
              handleKeyDown={handleKeyDown}
              disabled={isLoading}
              selectedSurveyList={selectedSurveyList}
              handleOnFocus={handleOnFocus}
            />
            <div className='chatcontainer__download-btn-box'>
              <LinkButton
                onClick={() => handleDocDownload()}
                disabled={isLoading || isClearLoading}>
                <img src={downloadImg} /> &nbsp;
                <span className='chatcontainer__download-text'>Download Chat</span>
              </LinkButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SurveyInsights;