import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { type Configuration } from 'msal/lib-commonjs/Configuration';
import { type AuthenticationParameters } from 'msal';
import { type IActiveDirectoryConfig } from '../models/IAppConfig';
export let authProvider: MsalAuthProvider;

const configureMsalAuthProvider = async (
  config: IActiveDirectoryConfig,
  searchObj: any
): Promise<MsalAuthProvider> => {
  const authConfig: Configuration = {
    auth: {
      authority: 'https://login.microsoftonline.com/' + config.tenant,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'clientId' does not exist on type 'IActiveDirectoryConfig'.
      clientId: config.clientId,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: window.location.origin
    },
    cache: {
      cacheLocation: 'localStorage'
    }
  };

  const options = {
    loginType: LoginType.Redirect
  };

  const authParams: AuthenticationParameters = {
    scopes: ['user.read', 'openid', 'profile', 'email', 'Sites.Read.All', 'Files.Read.All']
  };
  authProvider = new MsalAuthProvider(authConfig, authParams, options);

  return authProvider;
};

export default configureMsalAuthProvider;
